import { architectureDefaultSettings } from "modulus-interop/architectures";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Handle, NodeProps, Position, useNodeId, useReactFlow } from "reactflow";

import "./architecture-node.css";
import { useVisualUIStore } from "components/VisualUI/store";

export const architecturesOptions = [
  { value: "fully_connected", label: "Fully Connected Neural Network" },
  // { value: "fno", label: "Fourier neural operator (FNO) model" },
  { value: "afno", label: "Adaptive Fourier neural operator (AFNO) model" },
  { value: "fourier", label: "Fourier encoded fully-connected neural network" },
  // { value: "highway_fourier_net", label: "Modified highway network using Fourier features" }, // TODO: not yet supported
  { value: "dgm", label: "DGM - Variation of the fully connected network" },
  // { value: "hash_encoding_net", label: "Hash encoding network (HashNeRF)" }, // TODO: not yet supported
  // { value: "highway_fourier_net", label: "Modified highway network using Fourier features" }, // TODO: not yet supported
  // { value: "moving_time_window", label: "Moving time window model for transient simulators" }, // TODO: not yet supported
  // { value: "multiplicative_filter_net", label: "Multiplicative Filter Net with Activations" }, // TODO: not yet supported
  // { value: "multiscale_fourier_net", label: "Multi-scale Fourier Network" }, // TODO: not yet supported
  // { value: "pix2pix", label: "Convolutional encoder-decoder" }, // TODO: not yet supported
  // { value: "radial-basis", label: "Radial Basis Neural Network" }, // TODO: not yet supported
  { value: "siren", label: "Sinusoidal Representation Network (SiReN)" },
  // { value: "super_res_net", label: "3D super resolution network" }, // TODO: not yet supported
];

export function NeuralNetNode({ data }: NodeProps) {
  const nodeId = useNodeId();
  const { getNode, setNodes, deleteElements, getNodes } = useReactFlow();
  const { setSidebarNodeId } = useVisualUIStore();
  const [neuralNet, setNeuralNet] = useState(
    architecturesOptions.find((o) => o.value === data.mode) ?? { value: "fully_connected", label: "Fully Connected Neural Network" }
  );

  useEffect(() => {
    const settings = {
      ...architectureDefaultSettings[neuralNet.value],
      mode: neuralNet.value,
      id: nodeId,
    };

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...settings,
            ...data,
          };
        }

        return node;
      })
    );
  }, []);

  const handleSetNeuralNet = (option: { value: string; label: string }) => {
    setNeuralNet(option);

    const settings = {
      ...architectureDefaultSettings[option.value],
      mode: option.value,
      id: nodeId,
    };

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...settings,
          };
        }

        return node;
      })
    );
  };


  return (
    <div className="architecture-node">
      <Handle type="target" position={Position.Top} id="equation" />

      <section data-tour='tour_neural_network_node' id="tour_btn_open_sidebar_settings">
        <div className="title">
          <div className="node-icons">
            {/* <i className="dripicons-gear" onClick={() => setSidebarNodeId(nodeId)} /> */}
            <i
              className="dripicons-cross"
              onClick={() => {
                deleteElements({ nodes: [{ id: nodeId }] });
                setSidebarNodeId("");
              }}
            />
          </div>
          <span>Neural network</span>
        </div>

        <div className="content" onClick={(event) => {
            const node = getNode(nodeId);
            node.selected = true;

            const nodes = getNodes();
            nodes.forEach((n) => {
              if (n.id !== nodeId) {
                n.selected = false;
              }
            });
            setNodes((nds) => nodes.map((n) => n));
        }}>
          <Select
            className="react-select nodrag"
            options={architecturesOptions}
            value={neuralNet}
            onChange={handleSetNeuralNet}
            data-tour='tour_click_neural_network_node'
            styles={{
              option: (baseStyles, state) => ({
                // ...baseStyles,
                backgroundColor: state.isFocused ? '#474b58' : '#373A45',
                display: 'inline-block',
                color: '#B4B6C3 !important',
                padding: '10px 5px',
                width: '100%'
              })
            }}
          />
        </div>
      </section>

      <Handle type="source" position={Position.Right} id="geometry-group" />
    </div>
  );
}
