import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Loader } from "components/Loader";

type PrivateRouteProps = {
  component: React.ComponentType;
  roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
export const PrivateRoute = ({ component: RouteComponent }: PrivateRouteProps) => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <Loader />
      </div>
    );
  }

  const Component = withAuthenticationRequired(RouteComponent, {
    onRedirecting: () => (
      <div className="page-layout">
        <Loader />
      </div>
    )
  });

  // TODO: check if route is restricted by role

  return <Component />;
};
