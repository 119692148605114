export const changelogItems = {
  version: "1.5.0",
  changelog: 
    [
      "added an ability to selectively render specific parts of a geometry",
      "added a new tutorial tour that will guide you through creating your first simulator through our model-engineer visual-editor",
      "added a compute credits usage chart to enable users to see insights from their compute consumption from last 30 days or 24 hours",
      "added geometry thumbnails to the simulator cards",
      "added e-mail notifications for a completion or a failure of your simulations",
      "swapped the order of visual-editor and geometry viewer, the first thing you see after opening a simulator is it's amazing geometry",
      "simplified the left sidebar, to better accomodate the workflow through the platform and make navigation easier",
      "fixed several UI & UX bugs, to ensure smoother and more pleasant experience for you"
    ]
}
