import { checkRequiredFields } from "modulus-interop/utils";
import { v4 as uuidv4 } from "uuid";
import {
  LinearElasticityCreator,
  LinearElasticityPlaneStressCreator,
  LinearElasticityPlaneStressType,
  LinearElasticityType,
} from "./types";

function checkRequiredPhysicsFields<T>(requiredFields: string[], settings: T) {
  if (requiredFields.includes("E") && !settings.hasOwnProperty("E")) {
    throw Error("The Young's modulus (E) not set!");
  }

  if (requiredFields.includes("nu") && !settings.hasOwnProperty("nu")) {
    throw Error("The Poisson's ratio (nu) not set!");
  }

  if (requiredFields.includes("lambda") && !settings.hasOwnProperty("lambda")) {
    throw Error("Lamé's first parameter (lambda) not set!");
  }

  if (requiredFields.includes("mu") && !settings.hasOwnProperty("mu")) {
    throw Error("Lamé's second parameter (shear modulus - mu) not set!");
  }

  if (requiredFields.includes("rho") && !settings.hasOwnProperty("rho")) {
    throw Error("Mass density (rho) not set!");
  }
}

export const defaultLinearElasticitySettings: Partial<LinearElasticityType> = {
  E: { symbol: "E_LinearElasticity", value: undefined, parameterized: false },
  rho: { symbol: "rho_LinearElasticity", value: 1.0, parameterized: false },
  nu: { symbol: "nu_LinearElasticity", value: undefined, parameterized: false },
  lambda: { symbol: "lambda_LinearElasticity", value: undefined, parameterized: false },
  mu: { symbol: "mu_LinearElasticity", value: undefined, parameterized: false },
  dim: 3,
  time: false,
};

export function LinearElasticity(settings?: LinearElasticityType): LinearElasticityCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("LinearElasticity"),
    slug: Object.freeze("linear_elasticity"),
    settings: {
      ...defaultLinearElasticitySettings,
      ...settings,
    },
    set(settings: Partial<LinearElasticityType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredPhysicsFields(["E", "rho", "nu", "lambda", "mu"], this.settings);
      checkRequiredFields(["dim"], this.settings);
    },
    generateCode() {
      this.validate();
      const { E, nu, rho, lambda, mu, dim } = this.settings;

      return `
    from modulus.eq.pdes.linear_elasticity import LinearElasticity
    ${this.slug} = LinearElasticity(
        E=${E.parameterized ? `Symbol("${E.symbol}")` : E.value || E}},
        nu=${nu.parameterized ? `Symbol("${nu.symbol}")` : nu.value || nu}},
        lambda=${lambda.parameterized ? `Symbol("${lambda.symbol}")` : lambda.value || lambda}},
        mu=${mu.parameterized ? `Symbol("${mu.symbol}")` : mu.value || mu}},
        rho=${rho.parameterized ? `Symbol("${rho.symbol}")` : rho.value || rho}},
        dim=${dim})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}

export const defaultLinearElasticityPlaneStressSettings: Partial<LinearElasticityPlaneStressType> = {
  E: { symbol: "E_LinearElasticityPlaneStress", value: undefined, parameterized: false },
  rho: { symbol: "rho_LinearElasticityPlaneStress", value: 1.0, parameterized: false },
  nu: { symbol: "nu_LinearElasticityPlaneStress", value: undefined, parameterized: false },
  lambda: { symbol: "lambda_LinearElasticityPlaneStress", value: undefined, parameterized: false },
  mu: { symbol: "mu_LinearElasticityPlaneStress", value: undefined, parameterized: false },
  time: false,
};

export function LinearElasticityPlaneStress(
  settings?: LinearElasticityPlaneStressType
): LinearElasticityPlaneStressCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("LinearElasticityPlaneStress"),
    slug: Object.freeze("linear_elasticity_plane_stress"),
    settings: {
      ...defaultLinearElasticityPlaneStressSettings,
      ...settings,
    },
    set(settings: Partial<LinearElasticityPlaneStressType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredPhysicsFields(["E", "rho", "nu", "lambda", "mu"], this.settings);
    },
    generateCode() {
      this.validate();
      const { E, nu, rho, lambda, mu } = this.settings;

      return `
    from modulus.eq.pdes.linear_elasticity import LinearElasticityPlaneStress
    ${this.slug} = LinearElasticityPlaneStress(
      E=${E.parameterized ? `Symbol("${E.symbol}")` : E.value || E}},
      nu=${nu.parameterized ? `Symbol("${nu.symbol}")` : nu.value || nu}},
      lambda=${lambda.parameterized ? `Symbol("${lambda.symbol}")` : lambda.value || lambda}},
      mu=${mu.parameterized ? `Symbol("${mu.symbol}")` : mu.value || mu}},
      rho=${rho.parameterized ? `Symbol("${rho.symbol}")` : rho.value || rho}})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}
