import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { SideBarContent } from "./SidebarContent";
import css from "./styles.module.css";
import BottomSidebarContent from "./BottomSidebarContent";

type LeftSidebarProps = {
  hideLogo?: boolean;
  hideUserProfile?: boolean;
  isLight?: boolean;
  isCondensed?: boolean;
  isMenuOpened?: boolean;
  isSimStudio?: boolean;
  sidebarOpened?: boolean;
};

export const LeftSidebar = ({
  isCondensed = false,
  isLight = false,
  hideLogo = false,
  hideUserProfile = false,
  isSimStudio = false,
  sidebarOpened = false,
}: LeftSidebarProps) => {
  const menuNodeRef: any = useRef(null);

  /**
   * Handle the click anywhere in doc
   */
  const handleOtherClick = (e: any) => {
    if (menuNodeRef && menuNodeRef.current && menuNodeRef.current.contains(e.target)) return;
    // else hide the menubar
    if (document.body) {
      document.body.classList.remove("sidebar-enable");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOtherClick, false);

    return () => {
      document.removeEventListener("mousedown", handleOtherClick, false);
    };
  }, []);
  const titleText = isSimStudio ? "Simulation Studio" : "Model Engineer";

  return (
    <React.Fragment>
      <div className="leftside-menu" ref={menuNodeRef} style={{display: sidebarOpened ? "flex" : "none", flexDirection: "column", justifyContent: "space-between"}}>
        {!hideLogo && (
          <React.Fragment>
            <Link to="/" className="logo text-center logo-light">
              <span className="logo-lg">
                <img src="/img/simlai-logo.svg" className={css.logoImg} alt="logo" height="12" />
                <span className="text-white font-14 mt-0">{titleText}</span>
              </span>
              <span className="logo-sm">
                <img src="/img/simlai-logo.svg" className={css.logoImg} alt="logo" height="12" />
                <span className="text-white font-14 mt-0">{titleText}</span>
              </span>
            </Link>

            <Link to="/" className="logo text-center logo-dark">
              <span className="logo-lg">
                <img src="/img/simlai-logo.svg" className={css.logoImg} alt="logo" height="12" />
                <span className="text-white font-14 mt-0">{titleText}</span>
              </span>
              <span className="logo-sm">
                <img src="/img/simlai-logo.svg" className={css.logoImg} alt="logo" height="12" />
                <span className="text-white font-14 mt-0">{titleText}</span>
              </span>
            </Link>
          </React.Fragment>
        )}

        {!isCondensed && (
          <SimpleBar style={{ maxHeight: "100%" }} timeout={500} scrollbarMaxSize={320}>
            <SideBarContent menuClickHandler={() => {}} isLight={isLight} hideUserProfile={hideUserProfile} />
          </SimpleBar>
        )}
        {isCondensed && <SideBarContent isLight={isLight} hideUserProfile={hideUserProfile} />}
        
        <BottomSidebarContent />
      </div>
    </React.Fragment>
  );
};
