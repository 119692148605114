import MonacoEditor from "components/CodeEditor/Monaco";
import { Col, Row } from "react-bootstrap";

const PluginCardPopup = ({ code }: any) => {
  return (
    <Row>
      <Col>
        <MonacoEditor 
          height={"60vh"}
          language="python"
          fileName="equation.py"
          defaultCode={code ?? "No code found in this plugin."}
          disabled={true}
          id="equation-editor"
        />
      </Col>
    </Row>
  )
}

export default PluginCardPopup;