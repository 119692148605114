const normalDotVec = 
`from sympy import Symbol, Function, Number
from modulus.sym.eq.pde import PDE
from modulus.sym.node import Node

class NormalDotVec(PDE):
    """
    Normal dot velocity

    Parameters
    ==========
    dim : int
        Dimension of the equations (1, 2, or 3). Default is 3.
    """

    name = "NormalDotVec"

    def __init__(self, vec=["u", "v", "w"]):
        # normal
        normal = [Symbol("normal_x"), Symbol("normal_y"), Symbol("normal_z")]

        # make input variables
        self.equations = {}
        self.equations["normal_dot_vel"] = 0
        for v, n in zip(vec, normal):
            self.equations["normal_dot_vel"] += Symbol(v) * n
`;

export { normalDotVec };