export const templateEquation = 
`from sympy import Symbol, Function, Number
from modulus.sym.eq.pde import PDE
from modulus.sym.node import Node

class YourCustomEquation(PDE):

  name = "your custom equation name"

  def __init__(self, your_custom_params):
    # your code goes here

    self.equations[“equation_output”] =  # Your SymPy-based equation goes here`;