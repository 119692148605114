import classNames from "classnames";
import Dropzone from "react-dropzone";
import { useFileUploader } from "./hooks";
import css from "./styles.module.css";
import { Node } from "reactflow";
import { useEffect, useState } from "react";
import { useGeometryContext } from "../nodes/GeometryGroupNode/geometryContext";
import { SimlLoaderIntegrated } from "components/SimlLoader";

type FileUploaderProps = {
  droptext?: string;
  onFileUpload?: (fileNodes: Node[]) => void;
  onFileRemove?: (nodeId: string) => void;
  accept?: string[];
};

const acceptedFormatValidator = (accept: string[], file: File) => {
  if (accept === undefined) return null;
  const extension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
  return accept.includes(extension)
    ? null
    : {
        code: "not supported",
        message: "File is not supported",
      };
};

export const FileDropzone = ({ droptext, onFileUpload, onFileRemove, accept }: FileUploaderProps) => {
  const simulator_id = window?.location?.pathname?.split("/")?.[3];
  const { handleAcceptedFiles } = useFileUploader(simulator_id);
  const [uploading, setUploading] = useState(false);
  const { numUploaded, numFiles, setNumFiles, setNumUploaded, message, setMessage } = useGeometryContext();

  useEffect(() => {
    if (numUploaded !== 0 && numUploaded === numFiles) {
      setUploading(false);
      setNumFiles(0);
      setNumUploaded(0);
      setMessage("Processing files, this can take some time...");
    }
  }, [numUploaded, numFiles]);

  const uploadGeometries = async (acceptedFiles: File[]) => {
    setUploading(true);

    try {
      await handleAcceptedFiles(acceptedFiles, onFileUpload);
    } catch (err) {
      console.log(err);
    }

    setMessage("");
  };

  const text = uploading ? (
    <span>
      Uploading, <strong>don't refresh</strong>...
    </span>
  ) : message ? (
    message
  ) : droptext ? (
    droptext
  ) : (
    "Drop files here or click to upload."
  );

  return (
    <>
      {(uploading || message) ? (
        <div className={classNames([css.dropzone])}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div style={{position: "relative", display: "flex", justifyContent: "center", height: "2rem"}}>
              <SimlLoaderIntegrated />
            </div>
            <div className="text-secondary font-10" style={{ textAlign: "center", paddingTop: "1.2rem" }}>
              {text}
            </div>
          </div>
        </div>
      ) : (
        <Dropzone
          onDrop={(acceptedFiles) => {
            setNumFiles(acceptedFiles?.length);
            uploadGeometries(acceptedFiles);
          }}
          validator={(file) => acceptedFormatValidator(accept, file)}>
          {({ getRootProps, getInputProps }) => (
            <div className={classNames([css.dropzone])}>
              <div className={classNames([css.dzNessage, "needsclick"])} {...getRootProps()}>
                <input {...getInputProps()} />
                <i className="h1 text-secondary dripicons-plus" />
                <div className="text-secondary font-10">{text}</div>
              </div>
            </div>
          )}
        </Dropzone>
      )}
    </>
  );
};
