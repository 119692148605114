import { useState } from "react";
import classNames from "classnames";
import css from "./styles.module.css";
import { useControls, useCreateStore, LevaPanel, button } from "leva";
import { Environment } from "types";

import { signallingServerAddress, commands } from "./constants";
import { compileSelectedParameters } from "./utils";

import { GlobalControls } from "./controls/GlobalControls";
import { RightControls } from "./controls/RightControls";

import { usePixelStreaming } from "./hooks/usePixelStreaming";
import { useLocalStorage } from "./hooks/useLocalStorage";

import MagnitudeScale from "./components/MagnitudeScale";
import LeftBottomPanel from "./components/LeftBottomPanel";
import PixelStreamingVideo from "./components/PixelStreamingVideo";

import { useInference } from "mutations/environments";

type PixelStreamingEditorProps = {
  environment: Environment;
};

export const PixelStreamingEditor = ({ environment }: PixelStreamingEditorProps) => {
  const { simulators } = environment;
  const [logHistory, setLogHistory] = useState<String[]>([]);

  const inferenceOnSimulator = useInference();
  const { cache, updateLs, envSimId } = useLocalStorage({ environment });
  const [appVersion, setAppVersion] = useState(cache.appVersion || null);
  const [minMaxMagnitude, setMinMaxMagnitude] = useState(
    cache.minMagnitude && cache.maxMagnitude ? [cache.minMagnitude, cache.maxMagnitude] : null
  );
  const { videoParent, sendMessage, getApplication } = usePixelStreaming({
    environment,
    setAppVersion,
    setMinMaxMagnitude,
    updateLs,
    setLogHistory,
  });

  // TODO: let people set their own serverSITEPath if environment.cloud_provider === "external"
  const stores = { left: useCreateStore(), right: useCreateStore() };

  const { globalControls, simulatorDataPath } = GlobalControls({
    environment,
    simulators,
    getApplication,
    cache,
    stores,
    updateLs,
    sendMessage,
  });

  const { rightControls, setSlicerMode, onSlicerMove } = RightControls({
    simulators,
    cache,
    stores,
    updateLs,
    sendMessage,
  });

  const [{ ...controls }, set, get] = useControls(() => globalControls, { store: stores.left });

  useControls(
    () => ({
      [inferenceOnSimulator.isLoading ? "Loading…" : "Simulate"]: button(() => onClickSimulate(), {
        disabled: inferenceOnSimulator.isLoading,
      }),
    }),
    { store: stores.left },
    [inferenceOnSimulator.isLoading]
  );

  useControls(() => rightControls, { store: stores.right });

  const onClickSimulate = () => {
    const { left, right } = stores;
    //calculateSlicerWidth(pointCount); // TODO: find a better slicing width calculation
    const simulatorLabels = simulators.map((simulator) => simulator.label);
    Promise.all(
      simulators.map((simulator) =>
        inferenceOnSimulator.mutateAsync({
          environment,
          simulatorId: simulator?.id,
          useCpu: false, // settingsPanel.useCpu,
          resX: left.get(`${simulator.label}.View.resX`), // settingsPanel.resX,
          resY: left.get(`${simulator.label}.View.resY`), // settingsPanel.resY,
          resZ: left.get(`${simulator.label}.View.resZ`), // settingsPanel.resZ,
          saveVtk: true, // settingsPanel.saveVtk,
          saveJson: true, // settingsPanel.saveJson,
          saveLaz: false, // settingsPanel.saveLaz,
          downloadVti: false, // settingsPanel.downloadVti,
          parameters: compileSelectedParameters(simulator, controls),
        })
      )
    )
      .then(() => {
        simulatorLabels.forEach((simLabel) => {
          const pointCount =
            left.get(`${simLabel}.View.resX`) * left.get(`${simLabel}.View.resY`) * left.get(`${simLabel}.View.resZ`);
          const geometry = left.get(`${simLabel}.View.geometry`);

          const data = {
            type: "message",
            event: commands.initPointCloud,
            payload: {
              inlet_velocity: left.get(`${simLabel}.View.inlet_velocity`),
              pointCloudSelect: simulatorDataPath,
              // pointCloudSelect: `D:\\git\\simulation-studio\\Content\\Data\\inference_data_fmr.json`,
              geometry: geometry,
              pointCount: pointCount,
              magnitudeSource: right.get(`${simLabel}.Magnitude Source`),
            },
          };
          if (geometry) {
            // temp validation in case we have multiple simulators in the data
            sendMessage(data);
            const niagaraData = {
              type: "message",
              event: commands.onNiagaraParamsUpdate,
              payload: {
                // Docs: Once payload has niagaraParams it will be used to update the Niagara system parameters
                niagaraParams: {
                  PointCount: { val: pointCount, type: "int" },
                },
              },
            };
            sendMessage(niagaraData);

            setSlicerMode(simLabel);
            onSlicerMove(simLabel);
          } else {
            console.log("No geometry selected");
          }
        });
      })
      .catch((e) => {
        console.log("Problem with inference: ", e);
      });
  };

  console.log(environment?.url && `${environment?.url}/pixelstreaming`);

  return (
    <div className={classNames([css.pixelContainer])}>
      <div className={classNames([css.relative])}>
        <MagnitudeScale
          value={minMaxMagnitude}
          selected={cache.colorProfile}
          sendMessage={sendMessage}
          updateLs={updateLs}
        />
        <PixelStreamingVideo videoParent={videoParent} />

        <div className={classNames(["d-flex", "justify-content-between", css.absolute, "mt-5", "mx-2"])}>
          <div className="d-flex flex-column align-items-start ">
            <div className="mb-auto">
              <LevaPanel store={stores.left} fill titleBar={false} />
            </div>
            <LeftBottomPanel logs={logHistory} environment={environment} appVersion={appVersion} />
          </div>
          <div></div>
          <div>
            <LevaPanel store={stores.right} fill titleBar={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
