import { Environment } from "types";

export function useLocalStorage({ environment }: { environment: Environment }) {
  const { simulators } = environment;
  const simulatorId = simulators[0]?.id;
  let envSimId = `${environment.id}_${simulatorId}`;
  let ls = window.localStorage;
  let cache = JSON.parse(window.localStorage?.getItem(envSimId)) || {};
  if (!cache) ls.setItem(envSimId, JSON.stringify({}));
  const updateLs = (obj: any) => {
    for (const key in obj) {
      cache[key] = obj[key];
      ls.setItem(envSimId, JSON.stringify(cache));
    }
  };

  return { cache, updateLs, envSimId };
}
