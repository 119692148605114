import React, { Suspense, useState, useCallback, useEffect } from "react";
import { Outlet, useMatch } from "react-router-dom";
import { Container } from "react-bootstrap";
import { changeBodyAttribute } from "utils";
import { useToggle, useViewport } from "hooks";
import * as layoutConstants from "constants/layout";
import { Topbar } from "layouts/Topbar";
import { LeftSidebar } from "layouts/LeftSidebar";
import { Footer } from "layouts/Footer";
import NotificationsProvider from "components/NotificationsProvider";

const loading = () => <div className="text-center" />;

function IsSimStudio() {
  return !!useMatch('/model-engineer/projects/:id');
}

export const VerticalLayout = () => {
  const { width } = useViewport();
  const [isMenuOpened, toggleMenu] = useToggle();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  // eslint-disable-next-line
  const [leftSideBarTheme, setLeftSideBarTheme] = useState(layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_DARK);
  // eslint-disable-next-line
  const [leftSideBarType, setLeftSidebarType] = useState(layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED);

  // useEffect(() => {
  //   changeBodyAttribute("data-layout-mode", layoutWidth);
  // }, [layoutWidth]);

  // useEffect(() => {
  //   changeBodyAttribute("data-leftbar-compact-mode", leftSideBarType);
  // }, [leftSideBarType]);

  const toggleSidebar = () => {
    if (width <= 768) {
      setSidebarOpen(!sidebarOpen);
    }
  }

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    toggleMenu();

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable");
      } else {
        document.body.classList.add("sidebar-enable");
      }
    }
  };

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (width > 768 && width <= 1028) {
      changeBodyAttribute("data-leftbar-compact-mode", layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_CONDENSED);
    } else if (width > 1028) {
      changeBodyAttribute("data-leftbar-compact-mode", layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED);
    }
  }, [width]);

  useEffect(() => {
    if (width > 768) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [width]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [updateDimensions]);

  const isCondensed = leftSideBarType === layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_CONDENSED;
  const isLight = leftSideBarTheme === layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT;
  const isSimStudio = IsSimStudio();
  return (
    <>
      <div className="wrapper">
        <NotificationsProvider />
        <Suspense fallback={loading()}>
          <LeftSidebar isSimStudio={isSimStudio} isMenuOpened={isMenuOpened} isCondensed={isCondensed} isLight={isLight} hideUserProfile={true} sidebarOpened={sidebarOpen}/>
        </Suspense>
        <div className={`content-page ${isSimStudio ? 'content-page-no-pad' : ''}`}>
          <div className="content">
            <Suspense fallback={loading()}>
              <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} toggleSidebar={toggleSidebar}/>
            </Suspense>
            <Container fluid={true}>
              <Outlet />
            </Container>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
};
