import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { ReactNode, useState } from "react";
import { Button } from "react-bootstrap";
import Select, { StylesConfig, defaultTheme } from "react-select";

type ParameterOption = {
  value: string;
  label: string;
};

const { colors } = defaultTheme;

const selectStyles: StylesConfig<ParameterOption, false> = {
  container: (baseStyles) => ({
    ...baseStyles,
    position: "absolute",
    width: 150,
    right: -20,
  }),
  menu: () => ({
    position: "absolute",
    top: 25,
    width: 150,
    background: "#c7c7c7",
    border: "1px solid #c7c7c7",
    boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)"
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
  }),
};

export function ConnectedInputSelect({
  options,
  callback,
}: {
  options: ParameterOption[];
  callback: (selectedOption: ParameterOption) => any;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<ParameterOption | null>();

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button className="btn-remove-small" onClick={() => setIsOpen((prev) => !prev)}>
          <Tooltip>
            <TooltipTrigger>
              <i className="mdi mdi-connection" />
            </TooltipTrigger>
            <TooltipContent className="Tooltip">Use variable parameter</TooltipContent>
          </Tooltip>
        </Button>
      }>
      <Select
        className="react-select settings-select"
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        menuPlacement="auto"
        onChange={(selectedOption) => {
          setValue(selectedOption);
          setIsOpen(false);
          callback(selectedOption);
        }}
        options={options}
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
      />
    </Dropdown>
  );
}

// styled components

const Menu = (props: JSX.IntrinsicElements["div"]) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p: JSX.IntrinsicElements["svg"]) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
