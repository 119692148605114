import { omit } from "lodash";
import { Arch } from "modulus-interop/architectures/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { useState } from "react";
import { useReactFlow } from "reactflow";

export function useParameterizedInputs<T extends ModulusComponentDataCommon & Arch>() {
  const { setNodes } = useReactFlow();
  const [newParameterToAdd, setNewParameterToAdd] = useState("");

  const handleAddParameterizedInput = (data: T) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data.parameterized_inputs = {
            ...data.parameterized_inputs,
            [newParameterToAdd]: {
              symbol: newParameterToAdd,
            },
          };
        }

        return nd;
      })
    );
    setNewParameterToAdd("");
  };

  const handleRemoveParameterizedInput = (data: T, key: string) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data.parameterized_inputs = omit(data.parameterized_inputs, key);
        }

        return nd;
      })
    );
  };

  return {
    newParameterToAdd,
    setNewParameterToAdd,
    handleAddParameterizedInput,
    handleRemoveParameterizedInput,
  }
}
