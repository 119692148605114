import { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Button, Alert, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { uniqueNamesGenerator, colors, animals } from "unique-names-generator";
import { useAuth0 } from "@auth0/auth0-react";
import { PageTitle } from "components/PageTitle";
import { FormInput } from "components/FormInput";
import { cloudTiers, externalTiers } from "cloud-settings";
import { toUsd } from "utils/numbers";
import {
  Simulator,
  CreateEnvironmentData,
  SelectedSimulator,
  SimulatorModelFromRetrieveModelsForSimulatorsEndpoint,
} from "types";
import { useQuerySimulators, useSubscription } from "queries";
import { useCreateEnvironment } from "mutations";
import { useQueryRetrieveModelsForSimulators } from "queries/simulatorModels";
import { formatDate, sortModelsByUpdatedAt } from "utils";
import { useLocation } from "react-router-dom";
import cardCss from "./card.module.css";
import { randInt } from "three/src/math/MathUtils";
import placeholder_geometry from "assets/images/placeholder_geometry.png";
import { useDeleteModel } from "mutations/models";
import { ConfirmationBox } from "components/ConfirmationBox/ConfirmationBox";

interface EnvironmentForm {
  serverType: string;
  startServer: boolean;
  externalUrl: boolean;
  selectedSimulators: SelectedSimulator[];
  selectedSimulatorModels: string[];
  isFineTune: boolean;
  label: string;
  url: string;
  num_gpus: number;
}

export const CreateEnvironmentPage = () => {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const createMutation = useCreateEnvironment();
  const [isWrongInput, setIsWrongInput] = useState(false);
  const subscription = useSubscription(user.customer_id);
  const [envForm, setEnvForm] = useState<EnvironmentForm>({
    serverType: "",
    startServer: false,
    externalUrl: false,
    selectedSimulators: [],
    selectedSimulatorModels: [],
    isFineTune: false,
    label: uniqueNamesGenerator({
      dictionaries: [colors, animals],
      separator: "-",
      length: 2,
    }),
    url: "",
    num_gpus: 1,
  });

  const deleteModel = useDeleteModel();

  const [askConfirmation, setAskConfirmation] = useState({ show: false, model_id: ""});

  const handleSettingChange = (props: { [key: string]: any }) => {
    setEnvForm({
      ...envForm,
      ...props,
    });
  };

  // reset error message after 5 seconds
  useEffect(() => {
    setTimeout(() => {
      setIsWrongInput(false);
    }, 5000);
  }, [isWrongInput]);

  const {
    isLoading: isSimulatorsLoading,
    isError: isSimulatorsError,
    data: simulators,
    error: simulatorsError,
  } = useQuerySimulators(1, 150);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const simIDfromQuery = params.get("simulator");

  useEffect(() => {
    if (!simulators || !simulators.results) {
      return;
    }
    if (simIDfromQuery) {
      const tmpObject: SelectedSimulator = {
        id: simIDfromQuery,
        copied_from: simulators.results.find((sim) => sim.id === simIDfromQuery).copied_from,
        label: simulators.results.find((sim) => sim.id === simIDfromQuery).label,
      };

      handleSettingChange({
        selectedSimulators: envForm.selectedSimulators.concat(tmpObject),
      });
    }
  }, [simulators, simIDfromQuery]);

  const getIdsForQuery = (selectedSimulators: SelectedSimulator[]) => {
    const arr: string[] = [];

    selectedSimulators &&
      selectedSimulators.map((sim) => {
        sim.id && arr.push(sim.id);
        sim.copied_from && arr.push(sim.copied_from);
      });

    return arr;
  };

  const {
    isLoading: isModelsForSimulatorsLoading,
    isError: isModelsForSimulatorsError,
    data: modelsForSimulators,
    error: modelsForSimulatorsError,
    refetch: refetchModelsForSimulators
  } = useQueryRetrieveModelsForSimulators(getIdsForQuery(envForm.selectedSimulators), envForm.isFineTune);

  const getSimulatorLabel = (simulatorId: string) => {
    return simulators?.results.find((sim) => sim.id === simulatorId)?.label;
  };

  /*
   * Handle form submission
   */
  const onSubmit = () => {
    setIsWrongInput(false);
    if (envForm.externalUrl) {
      try {
        const url = new URL(envForm.url);
        if (url.protocol !== "http:" && url.protocol !== "https:") {
          setIsWrongInput(true);
          return;
        }
      } catch (e) {
        setIsWrongInput(true);
        return;
      }
    }
    const tier = envForm.externalUrl ? "tier_1" : envForm.serverType;
    const provider = envForm.externalUrl ? "external" : cloudTiers[tier]?.cloud_provider;

    const state = envForm.startServer ? "starting" : "stopped";

    if (!envForm.externalUrl) {
      handleSettingChange({ startServer: false });
    }
    const newEnv: CreateEnvironmentData = {
      label: envForm.label,
      desc: envForm.externalUrl ? externalTiers[tier]?.label : cloudTiers[tier]?.label,
      cloud_provider: provider,
      server_type: tier,
      mode: "model_engineer",
      storage_gb: 250, // TODO: don't hardcode (even though we really use 250GB everywhere for now), use from server metadata
      simulators: envForm.selectedSimulators.map((sim) => sim.id),
      source_simulator_models: envForm.selectedSimulatorModels
        ? envForm.isFineTune
          ? envForm.selectedSimulatorModels
          : []
        : [],
      output_simulator_models: envForm.selectedSimulatorModels
        ? envForm.isFineTune
          ? []
          : envForm.selectedSimulatorModels
        : [],
      stripe_customer_id: user.customer_id,
      num_gpus: envForm.externalUrl ? envForm.num_gpus || 1 : cloudTiers[tier]?.gpus,
      version: "1.5.2",
      owner: user.sub,
      state: envForm.externalUrl ? "running" : state,
    };
    if (envForm.externalUrl) {
      newEnv.url = envForm.url;
    }
    if (process.env.ENVIRONMENT !== "production") {
      console.log("sending", newEnv);
    }

    createMutation.mutate(newEnv);
  };

  const whenCreateBtnDisabled = () => {
    const hasSimulators = envForm.selectedSimulators.length > 0;
    const hasServerType = envForm.serverType !== "";

    if (isWrongInput) {
      return true;
    }

    // fine-tuning requires at least one simulator model to be selected
    if (envForm.isFineTune) {
      if (envForm.externalUrl) {
        return !hasSimulators || createMutation.isLoading || !(envForm.selectedSimulatorModels.length > 0);
      } else {
        return (
          !hasSimulators || !hasServerType || createMutation.isLoading || !(envForm.selectedSimulatorModels.length > 0)
        );
      }
    } else {
      // externalURL doesnt require server type
      if (envForm.externalUrl) {
        return !hasSimulators || createMutation.isLoading;
      } else {
        return !hasSimulators || !hasServerType || createMutation.isLoading;
      }
    }
  };

  const isWithinSelectedSimulators = (id: string, type: "simulators" | "models") => {
    switch (type) {
      case "simulators":
        return envForm.selectedSimulators.map((ss) => ss.id).includes(id);
      case "models":
        return envForm.selectedSimulatorModels.includes(id);
    }
  };

  const getSimulatorThumbnail = (simulator: Simulator) => {
    const filenameUseAsThumbnail =
      simulator &&
      simulator?.modulus_components &&
      simulator?.modulus_components?.geometries &&
      Object.values(simulator?.modulus_components?.geometries)
        ?.map((geo) => geo?.data)
        ?.map((data) => {
          if (data?.thumbnail) {
            return data?.file_name;
          }
        })
        ?.filter((filename) => filename !== undefined)?.[0];

    const thumbnails = simulator?.geometries
      ?.map((geo) => geo?.geometries?.map((geo) => geo?.thumbnail_url))
      ?.flatMap((geo) => geo);
    const thumbnailUrlUser = thumbnails?.find((thumbnail) =>
      thumbnail?.includes(filenameUseAsThumbnail?.split(".")?.[0])
    );
    const randomThumbnailIndex = randInt(0, thumbnails?.length - 1);
    const thumbnailUrl = thumbnailUrlUser ? thumbnailUrlUser : thumbnails?.[randomThumbnailIndex];

    return thumbnailUrl;
  };

  // memoize thumbnails so they are not recomputed on every re-render
  const simulatorThumbnails = useMemo(() => {
    return simulators?.results?.map((simulator) => {
      return {
        [simulator.id]: getSimulatorThumbnail(simulator)
      }
    }).reduce((accumulator, currentObject) => {
      return { ...accumulator, ...currentObject };
    }, {});
  }, [simulators])

  const parseTierLabel = (label: string) => {
    const tier_name = label.split("(")[0];
    return {
      tier_name,
    };
  };

  const handleDeleteMutation = (id: string) => {
    deleteModel.mutateAsync(id, {
      onSuccess: () => {
        handleSettingChange({ selectedSimulatorModels: envForm.selectedSimulatorModels.filter((model) => model !== id)});
        refetchModelsForSimulators();
        setAskConfirmation({ show: false, model_id: "" });
      }
    });
  };

  const handleOnConfirmationResponse = (res: boolean) => {
    if(res) {
      handleDeleteMutation(askConfirmation.model_id);
    } else {
      setAskConfirmation({ show: false, model_id: "" });
    }
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Environments", path: "/model-engineer/environments" },
          { label: "Create an environment", path: "/model-engineer/environments/new", active: true },
        ]}
        title={"Create new simulator training environment"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {/* <Row className="mb-4">
                <Col lg={12}>There will be some inforation about environment setup</Col>
              </Row> */}

              <Row>
                <Col>
                  {createMutation.error && (
                    <Alert variant="danger" className="my-2" onClick={() => createMutation.reset()}>
                      {createMutation.error.message}
                    </Alert>
                  )}

                  <Row>
                    <Col>
                      <FormInput
                        type="text"
                        name="label"
                        label={t("Environment label")}
                        placeholder={t("Enter label for the environment")}
                        containerClass={"mb-3"}
                        key="label"
                        value={envForm.label}
                        onChange={(e) => handleSettingChange({ label: e.target.value })}
                      />

                      <Form.Group>
                        <Form.Label>Select simulators</Form.Label>
                        {isSimulatorsLoading ? (
                          <div className={cardCss.cardGridContainer}>
                            {[...Array(20).keys()].map((item, index) => (
                              <div key={index} className={cardCss.cardInGridSkeleton}>
                                <h5>Loading...</h5>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className={cardCss.cardGridContainer}>
                            {simulators?.results
                              ?.sort((a: Simulator, b: Simulator) => (a.updated_at > b.updated_at ? -1 : 1))
                              .map((simulator, index) => (
                                <div
                                  key={index}
                                  className={cardCss.cardInGrid}
                                  onClick={() => {
                                    if (isWithinSelectedSimulators(simulator.id, "simulators")) {
                                      handleSettingChange({
                                        selectedSimulators: envForm.selectedSimulators.filter(
                                          (sim) => sim.id !== simulator.id
                                        ),
                                      });
                                    } else {
                                      handleSettingChange({
                                        selectedSimulators: envForm.selectedSimulators.concat({
                                          label: simulator.label,
                                          id: simulator.id,
                                          copied_from: simulator.copied_from,
                                        }),
                                      });
                                    }
                                  }}
                                  style={{
                                    border: `2px solid ${
                                      isWithinSelectedSimulators(simulator.id, "simulators") ? "cyan" : "transparent"
                                    }`,
                                  }}>
                                  <img src={simulatorThumbnails[simulator.id] ?? placeholder_geometry} alt="" />
                                  <h5>
                                    {simulator?.label?.length > 40
                                      ? `${simulator?.label?.slice(0, 37)}...`
                                      : simulator?.label}
                                  </h5>
                                  <p style={{ color: "gray" }}>
                                    {simulator?.desc?.length > 100
                                      ? `${simulator?.desc?.slice(0, 97)}...`
                                      : simulator?.desc}
                                  </p>
                                </div>
                              ))}
                          </div>
                        )}
                      </Form.Group>

                      {modelsForSimulators && modelsForSimulators.length > 0 && (
                        <Form.Group>
                          <Form.Label>Select models</Form.Label>
                          {isModelsForSimulatorsLoading ? (
                            <div className={cardCss.cardGridContainer}>
                              {[...Array(20).keys()].map((item, index) => (
                                <div key={index} className={cardCss.cardInGridSkeleton}>
                                  <h5>Loading...</h5>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className={cardCss.cardGridContainer}>
                              {modelsForSimulators &&
                                sortModelsByUpdatedAt(modelsForSimulators)
                                  .map((model: SimulatorModelFromRetrieveModelsForSimulatorsEndpoint) => model)
                                  .map((model, index) => (
                                    <div
                                      key={index}
                                      className={cardCss.cardInGrid}
                                      onClick={() => {
                                        if (isWithinSelectedSimulators(model.id, "models")) {
                                          handleSettingChange({
                                            selectedSimulatorModels: envForm.selectedSimulatorModels.filter(
                                              (mod) => mod !== model.id
                                            ),
                                          });
                                        } else {
                                          handleSettingChange({
                                            selectedSimulatorModels: envForm.selectedSimulatorModels.concat(model.id),
                                          });
                                        }
                                      }}
                                      style={{
                                        border: `1px solid ${
                                          isWithinSelectedSimulators(model.id, "models") ? "cyan" : "transparent"
                                        }`,
                                      }}>
                                      {model?.owner === user?.sub && (
                                        <Row>
                                          <Col xs={9}>
                                            <h3>{getSimulatorLabel(model.simulator_id)}</h3>
                                          </Col>
                                          <Col xs={3}>
                                            <button
                                              className="dripicons-cross"
                                              style={{
                                                fontSize: "24px",
                                                color: "white",
                                                backgroundColor: "transparent",
                                                border: "none",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => setAskConfirmation({ model_id: model.id, show: true })}
                                            />
                                          </Col>
                                        </Row>
                                      )}
                                      <Row>
                                        <Col>
                                          <h5>{model.public ? "Public" : "Private"}</h5>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h5>{formatDate(model.updated_at)}</h5>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h5>{model.owner === user.sub ? "My Own" : ""}</h5>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                            </div>
                          )}
                        </Form.Group>
                      )}

                      <FormInput
                        name="fine-tune-check"
                        label={t("Fine-tune model? (simulator model must be selected)")}
                        type="checkbox"
                        containerClass={"mb-3"}
                        key="fine-tune-check"
                        checked={envForm.isFineTune}
                        onChange={(e) => handleSettingChange({ isFineTune: e.target.checked })}
                      />

                      <FormInput
                        name="external_url"
                        label={t("Connect to Simulator Training Environment running on your server?")}
                        type="checkbox"
                        containerClass={"mb-3"}
                        key="external_url"
                        checked={!!envForm.externalUrl}
                        onChange={(e) => handleSettingChange({ externalUrl: e.target.checked })}
                      />

                      {envForm.externalUrl ? (
                        <>
                          <FormInput
                            type="text"
                            name="url"
                            label={t(`${isWrongInput ? `Enter correct URL` : `URL of your own training server`}`)}
                            placeholder={`Example: http://111.22.333.44:9000`}
                            containerClass={"mb-3"}
                            className={`${isWrongInput ? `is-invalid` : ``}`}
                            key="url"
                            value={envForm.url}
                            onChange={(e) => handleSettingChange({ url: e.target.value })}
                          />
                          <FormInput
                            type="number"
                            name="num_gpus"
                            label={t("Number of active GPUs on your server")}
                            defaultValue={1}
                            containerClass={"mb-3"}
                            key="num_gpus"
                            value={envForm.num_gpus}
                            onChange={(e) => handleSettingChange({ num_gpus: e.target.value })}
                          />
                          <h5>Instructions for running our environment on your own server:</h5>
                          <pre className="w-100 p-3 border rounded-2">
                            <code>
                              {
                                "docker run --gpus all -d --ipc=host --ulimit memlock=-1 --ulimit stack=67108864 \\\n   --restart always -p 9000:9000 —env-file ./.env -v ${PWD}/outputs:/code/app/outputs \\\n   public.ecr.aws/t2o8f2e6/dimensionlab/simlai-site:latest"
                              }
                            </code>
                          </pre>
                        </>
                      ) : (
                        <Form.Group>
                          <Form.Label>Select server type</Form.Label>
                          <div className={cardCss.cardGridContainer}>
                            {subscription?.data?.length > 0 ? (
                              Object.entries(cloudTiers)
                                .filter(([key]) => !["local", "external"].includes(key))
                                .filter(([key]) => key !== "tier_2")
                                .map(([key, value], index) => (
                                  <div
                                    key={index}
                                    className={cardCss.cardInGrid}
                                    onClick={() => {
                                      if (envForm.serverType === key) {
                                        handleSettingChange({ serverType: "" });
                                      } else {
                                        handleSettingChange({ serverType: key });
                                      }
                                    }}
                                    style={{
                                      border: `1px solid ${envForm.serverType === key ? "cyan" : "transparent"}`,
                                    }}>
                                    <Row>
                                      <Col>
                                        <h4 style={{ fontWeight: "bold" }}>
                                          {parseTierLabel(cloudTiers[key].label).tier_name}
                                        </h4>
                                      </Col>
                                      <Col style={{ textAlign: "end", color: "cyan" }}>
                                        <h4 style={{ fontWeight: "bolder" }}>
                                          {toUsd(Number((value.price_per_minute * 60).toFixed(2)))} / hour
                                        </h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <h3>{`${cloudTiers[key].gpus + " x " + cloudTiers[key].gpu_label} GPU`}</h3>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <h4>{`${cloudTiers[key].vcpus} vCPUs`}</h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <h4>{`${cloudTiers[key].ram} GB RAM`}</h4>
                                      </Col>
                                    </Row>
                                  </div>
                                ))
                            ) : (
                              <div
                                className={cardCss.cardInGrid}
                                onClick={() => {
                                  if (envForm.serverType === "tier_1") {
                                    handleSettingChange({ serverType: "" });
                                  } else {
                                    handleSettingChange({ serverType: "tier_1" });
                                  }
                                }}
                                style={{
                                  border: `1px solid ${envForm.serverType === "tier_1" ? "cyan" : "transparent"}`,
                                }}>
                                <Row>
                                  <Col>
                                    <h4 style={{ fontWeight: "bold" }}>
                                      {parseTierLabel(cloudTiers["tier_1"].label).tier_name}
                                    </h4>
                                  </Col>
                                  <Col style={{ textAlign: "end" }}>
                                    <h4 style={{ color: "cyan" }}>
                                      {toUsd(Number((cloudTiers["tier_1"].price_per_minute * 60).toFixed(2)))} / hour
                                    </h4>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h3>{`${
                                      cloudTiers["tier_1"].gpus + " x " + cloudTiers["tier_1"].gpu_label
                                    } GPU`}</h3>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h4>{`${cloudTiers["tier_1"].vcpus} vCPUs`}</h4>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h4>{`${cloudTiers["tier_1"].ram} GB RAM`}</h4>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </div>
                        </Form.Group>
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <Button
                        onClick={() => onSubmit()}
                        className={`${isWrongInput ? `btn-danger` : `blue`} btn-rounded`}
                        disabled={whenCreateBtnDisabled()}>
                        {t("Create new environment")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <ConfirmationBox 
                message="Are you sure you want to delete the model?" 
                show={askConfirmation.show}
                onResponse={handleOnConfirmationResponse}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
