import { defaultGradNormalSettings } from "modulus-interop/equations/navier-stokes";
import { GradNormalType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useReactFlow } from "reactflow";

export function GradNormalSettings({ data }: { data: ModulusComponentDataCommon & GradNormalType }) {
  const { setNodes } = useReactFlow();

  const handleSettingChange = (key: string, value: any) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            [key]: value,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h5>The dependent variable (T)</h5>
          <input
            type="text"
            className="form-control"
            placeholder="Please specify a value"
            defaultValue={data.T ?? defaultGradNormalSettings.T}
            onChange={(e) => handleSettingChange("T", e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Dimensions</h5>
          <Select
            className="react-select settings-select"
            options={[
              { value: 2, label: "2D" },
              { value: 3, label: "3D" },
            ]}
            defaultValue={data.dim ? { value: data.dim, label: data.dim + "D" } : { value: 3, label: "3D" }}
            onChange={(option) => handleSettingChange("dim", option.value)}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Time-dependent</h5>
          <input
            type="checkbox"
            checked={data.time ?? defaultGradNormalSettings.time}
            onChange={(e) => handleSettingChange("time", e.target.checked)}
          />
        </Col>
      </Row>
    </>
  );
}
