import classNames from "classnames";
import { Badge, Card } from "react-bootstrap";

import { Project } from "types";
import css from "./styles.module.css";

export type SingleProjectProps = {
  project: Project;
};

export const SingleProjectCard = (props: SingleProjectProps) => {
  const project = props.project;

  return (
    <Card className="d-block card-single-project">
      <Badge className="running">running</Badge>

      <Card.Body>
        {/* {!project.image && (
          <Dropdown className="card-widgets" align="end">
            <Dropdown.Toggle variant="link" as="a" className="card-drop arrow-none cursor-pointer p-0 shadow-none">
              <i className="dripicons-dots-3" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Anchor}>
                <i className="mdi mdi-pencil me-1" /> Edit
              </Dropdown.Item>
              <Dropdown.Item as={Anchor}>
                <i className="mdi mdi-delete me-1" /> Delete
              </Dropdown.Item>
              <Dropdown.Item as={Anchor}>
                <i className="mdi mdi-email-outline me-1" /> Invite
              </Dropdown.Item>
              <Dropdown.Item as={Anchor}>
                <i className="mdi mdi-exit-to-app me-1" /> Leave
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )} */}

        <h4 className="mt-0">
          <a href={`/model-engineer/projects/${project.id}`} className="text-title">
            {project.label}
          </a>
        </h4>

        <p className="text-muted font-13 mb-0">{project.desc}</p>
      </Card.Body>
    </Card>
  );
};
