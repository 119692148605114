import { useAuth0 } from "@auth0/auth0-react";
import { CreditsSpendingWidget } from "pages/profile/BillingPage/CreditsSpendingWidget";
import { getDate, getNowAsNiceString } from "pages/profile/BillingPage/CreditsSpendingWidget/getDate";
import { parseLast24h } from "pages/profile/BillingPage/CreditsSpendingWidget/parseLast24h";
import { useQueryCreditsUsage } from "queries/environments";
import { useState } from "react";
import { Card, CardBody } from "react-bootstrap";

export const CostWrapper = () => {
  const { user } = useAuth0();
  const [duration, setDuration] = useState("month");
  const [discountCode, setDiscountCode] = useState("");
  const [isMonthly, setIsMonthly] = useState(true);
  const {
    isLoading: isEnvHistoryLoading,
    isError: isEnvHistoryError,
    data: envHistory,
    error: envHistoryError,
  } = useQueryCreditsUsage(
    getDate(isMonthly ? "month" : "day", new Date()),
    getNowAsNiceString(),
    isMonthly ? "day" : "hour"
  ); //day = get last month of data, hour = get last day of data

  const handleChangeRange = (val: boolean) => {
    setIsMonthly(val);
  };

  return (
    <Card>
      <CardBody>
        <CreditsSpendingWidget
          data={
            isEnvHistoryLoading || isEnvHistoryError
              ? []
              : isMonthly
              ? Object.values(envHistory || {})?.map((item) => Number(item) || 0)
              : parseLast24h(Object.values(envHistory || {})?.map((item) => Number(item) || 0))
          }
          dates={
            isEnvHistoryLoading || isEnvHistoryError
              ? []
              : isMonthly
              ? Object.keys(envHistory || {})?.map((item) =>
                  isMonthly
                    ? new Date(item || 0)?.getDate().toString()
                    : new Date(item || 0)?.getHours().toString().padStart(2, "0") || "0"
                )
              : parseLast24h(
                  Object.keys(envHistory || {})?.map((item) => Number(new Date(item || 0)?.getHours().toString()))
                )?.map((item) => item.toString().padStart(2, "0"))
          }
          isMonthly={isMonthly}
          handleChangeRange={handleChangeRange}
        />
      </CardBody>
    </Card>
  );
};
