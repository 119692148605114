import { track } from "@vercel/analytics";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface TryTutorialPopUpProps {
  type: "simulator" | "environment" | "project";
  id: string;
  show: boolean;
  close: (showAgain: boolean) => void;
}

export const TryTutorialPopUp = ({ type, show, id, close} : TryTutorialPopUpProps) => {
  const navigate = useNavigate();
  return (
    <Modal show={show} centered size="lg">
      <Modal.Header>
        <Modal.Title>
          {type === "simulator" ? "Simulator" : type === "environment" ? "Environment" : "Project"} tutorial
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>We will <strong style={{color: "rgba(124, 168, 255, 1)"}}>guide you</strong> through creating <strong style={{color: "rgba(124, 168, 255, 1)"}}>your first {type}</strong>.</p>
        <p>Do you want to proceed?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-info" onClick={() => {
          navigate(`${id}/true`);
          track("Start Simulator Tutorial");
          }}
        >Start tutorial</button>
        <button className="btn btn-primary" onClick={ () => close(true)}>Close</button>
        <button className="btn btn-danger" onClick={ () => close(false)}>Don't show again</button>
      </Modal.Footer>
    </Modal>
  )
}