import React from "react";
import { Card } from "react-bootstrap";

import profileImg from "assets/images/users/avatar-1.jpg";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { FormInput } from "components/FormInput";

export const UserBox = () => {
  const { user } = useAuth0();
  process.env.REACT_APP_ENVIRONMENT !== "production" && console.log(user);
  const { t } = useTranslation();

  if (!user) return <div>{t("No user data available")}</div>;

  const isUserVerified = user?.email_verified ? t("Yes") : t("No");
  const userPicture = user?.picture || profileImg;
  const userNickname = user?.nickname || user?.name || t("No nickname");
  const userEmail = user?.email || t("No email");

  return (
    <Card className="text-center">
      <Card.Body>
        <img src={userPicture} className="rounded-circle avatar-lg img-thumbnail" alt="" />
        <h4 className="mb-0 mt-2">{userNickname}</h4>
        <div className="text-start mt-3">
          {/* <p className="font-13 mb-3">
            ...bio goes here...
          </p> */}

          <p className="mb-2 font-13">
            <strong>Email:</strong>
            <span className="ms-2 user-profile-email">{userEmail}</span>
          </p>
          <p className="mb-2 font-13">
            <strong>Verified:</strong>
            <span className="ms-2">{isUserVerified}</span>
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};
