import { Button } from "react-bootstrap"

interface PaginationRowProps {
  activePage: number,
  isDatasetContentLoading: boolean,
  noNextPage: boolean,
  updateActivePage: (page: number) => void
}

export const PaginationRow = ({ updateActivePage, activePage, isDatasetContentLoading, noNextPage }: PaginationRowProps) => {
  return (
    <div style={{display: "flex", flexDirection: "row", columnGap: "1rem", justifyContent: "end", alignItems: "center", height: "min-content"}}>
      <Button 
        variant="primary" 
        disabled={activePage === 1 || isDatasetContentLoading} 
        style={{borderRadius: "25%", padding: "0px 10px"}}
        onClick={() => {
          updateActivePage(activePage - 1)}
        }
      >
        <i className="mdi mdi-chevron-left"  style={{ fontSize: "24px"}}/>
      </Button>
      <div style={{borderRadius: "25%", background: "#1f2124", height: "100%", width: "3rem", display: "flex", justifyContent: "center"}}>
        <span style={{fontSize: "16px", padding: "7px 5px", userSelect: "none"}}>
          {activePage}
        </span>
      </div>
      <Button 
        variant="primary" 
        style={{borderRadius: "25%", padding: "0px 10px"}}
        disabled={isDatasetContentLoading || noNextPage}
        onClick={() => {
          updateActivePage(activePage + 1)}
        }
      >
        <i className="mdi mdi-chevron-right"  style={{ fontSize: "24px"}}/>
      </Button>
    </div>
  )
}