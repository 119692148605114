import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { defaultLinearElasticityPlaneStressSettings } from "modulus-interop/equations/linear-elasticity";
import { LinearElasticityPlaneStressType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Col, Row } from "react-bootstrap";
import { useReactFlow } from "reactflow";
import { containsOnlyNumbers } from "utils/string";

export function LinearElasticityPlaneStressSettings({
  data,
}: {
  data: ModulusComponentDataCommon & LinearElasticityPlaneStressType;
}) {
  const { setNodes } = useReactFlow();

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h5>
            The Young's modulus (E)
            <span className="float-end text-muted">
              <Tooltip>
                <TooltipTrigger>
                  Parameterized
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={data.E.parameterized ?? defaultLinearElasticityPlaneStressSettings.E.parameterized}
                    onChange={(e) =>
                      handleSettingChange({
                        E: {
                          ...data.E,
                          parameterized: e.target.checked,
                          min: e.target.checked ? data.E.min : null,
                          max: e.target.checked ? data.E.max : null,
                        },
                      })
                    }
                  />
                </TooltipTrigger>
                <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
              </Tooltip>
            </span>
          </h5>
          {data.E.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.E.symbol ?? defaultLinearElasticityPlaneStressSettings.E.symbol}
                  onChange={(e) => handleSettingChange({ E: { ...data.E, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.E.value ?? defaultLinearElasticityPlaneStressSettings.E.value}
                  onChange={(e) => handleSettingChange({ E: { ...data.E, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.E.min ?? defaultLinearElasticityPlaneStressSettings.E.min}
                  onChange={(e) => handleSettingChange({ E: { ...data.E, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.E.max ?? defaultLinearElasticityPlaneStressSettings.E.max}
                  onChange={(e) => handleSettingChange({ E: { ...data.E, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.E.value ?? defaultLinearElasticityPlaneStressSettings.E.value}
              onChange={(e) =>
                handleSettingChange({
                  E: {
                    ...data.E,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>
            The Poisson's ratio (nu)
            <span className="float-end text-muted">
              <Tooltip>
                <TooltipTrigger>
                  Parameterized
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={data.nu.parameterized ?? defaultLinearElasticityPlaneStressSettings.nu.parameterized}
                    onChange={(e) =>
                      handleSettingChange({
                        nu: {
                          ...data.nu,
                          parameterized: e.target.checked,
                          min: e.target.checked ? data.nu.min : null,
                          max: e.target.checked ? data.nu.max : null,
                        },
                      })
                    }
                  />
                </TooltipTrigger>
                <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
              </Tooltip>
            </span>
          </h5>
          {data.nu.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.nu.symbol ?? defaultLinearElasticityPlaneStressSettings.nu.symbol}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.nu.value ?? defaultLinearElasticityPlaneStressSettings.nu.value}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.nu.min ?? defaultLinearElasticityPlaneStressSettings.nu.min}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.nu.max ?? defaultLinearElasticityPlaneStressSettings.nu.max}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.nu.value ?? defaultLinearElasticityPlaneStressSettings.nu.value}
              onChange={(e) =>
                handleSettingChange({
                  nu: {
                    ...data.nu,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col>
              <h5>
                Lamé's 1st parameter (lambda)
                <span className="float-end text-muted">
                  <Tooltip>
                    <TooltipTrigger>
                      Parameterized
                      <input
                        type="checkbox"
                        className="ms-1"
                        checked={
                          data.lambda.parameterized ?? defaultLinearElasticityPlaneStressSettings.lambda.parameterized
                        }
                        onChange={(e) =>
                          handleSettingChange({
                            lambda: {
                              ...data.lambda,
                              parameterized: e.target.checked,
                              min: e.target.checked ? data.lambda.min : null,
                              max: e.target.checked ? data.lambda.max : null,
                            },
                          })
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
                  </Tooltip>
                </span>
              </h5>
            </Col>
          </Row>
          {data.lambda.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.lambda.symbol ?? defaultLinearElasticityPlaneStressSettings.lambda.symbol}
                  onChange={(e) => handleSettingChange({ lambda: { ...data.lambda, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.lambda.value ?? defaultLinearElasticityPlaneStressSettings.lambda.value}
                  onChange={(e) =>
                    handleSettingChange({ lambda: { ...data.lambda, value: parseFloat(e.target.value) } })
                  }
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.lambda.min ?? defaultLinearElasticityPlaneStressSettings.lambda.min}
                  onChange={(e) => handleSettingChange({ lambda: { ...data.lambda, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.lambda.max ?? defaultLinearElasticityPlaneStressSettings.lambda.max}
                  onChange={(e) => handleSettingChange({ lambda: { ...data.lambda, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.lambda.value ?? defaultLinearElasticityPlaneStressSettings.lambda.value}
              onChange={(e) =>
                handleSettingChange({
                  lambda: {
                    ...data.lambda,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col>
              <h5>
                Lamé's 2nd parameter (mu)
                <span className="float-end text-muted">
                  <Tooltip>
                    <TooltipTrigger>
                      Parameterized
                      <input
                        type="checkbox"
                        className="ms-1"
                        checked={data.mu.parameterized ?? defaultLinearElasticityPlaneStressSettings.mu.parameterized}
                        onChange={(e) =>
                          handleSettingChange({
                            mu: {
                              ...data.mu,
                              parameterized: e.target.checked,
                              min: e.target.checked ? data.mu.min : null,
                              max: e.target.checked ? data.mu.max : null,
                            },
                          })
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
                  </Tooltip>
                </span>
              </h5>
            </Col>
          </Row>
          {data.mu.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.mu.symbol ?? defaultLinearElasticityPlaneStressSettings.mu.symbol}
                  onChange={(e) => handleSettingChange({ mu: { ...data.mu, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.mu.value ?? defaultLinearElasticityPlaneStressSettings.mu.value}
                  onChange={(e) => handleSettingChange({ mu: { ...data.mu, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.mu.min ?? defaultLinearElasticityPlaneStressSettings.mu.min}
                  onChange={(e) => handleSettingChange({ mu: { ...data.mu, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.mu.max ?? defaultLinearElasticityPlaneStressSettings.mu.max}
                  onChange={(e) => handleSettingChange({ mu: { ...data.mu, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.mu.value ?? defaultLinearElasticityPlaneStressSettings.mu.value}
              onChange={(e) =>
                handleSettingChange({
                  mu: {
                    ...data.mu,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>
            Mass density (rho)
            <span className="float-end text-muted">
              <Tooltip>
                <TooltipTrigger>
                  Parameterized
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={data.rho.parameterized ?? defaultLinearElasticityPlaneStressSettings.rho.parameterized}
                    onChange={(e) =>
                      handleSettingChange({
                        rho: {
                          ...data.rho,
                          parameterized: e.target.checked,
                          min: e.target.checked ? data.rho.min : null,
                          max: e.target.checked ? data.rho.max : null,
                        },
                      })
                    }
                  />
                </TooltipTrigger>
                <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
              </Tooltip>
            </span>
          </h5>
          {data.rho.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.rho.symbol ?? defaultLinearElasticityPlaneStressSettings.rho.symbol}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.rho.value ?? defaultLinearElasticityPlaneStressSettings.rho.value}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.rho.min ?? defaultLinearElasticityPlaneStressSettings.rho.min}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.rho.max ?? defaultLinearElasticityPlaneStressSettings.rho.max}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.rho.value ?? defaultLinearElasticityPlaneStressSettings.rho.value}
              onChange={(e) =>
                handleSettingChange({
                  rho: {
                    ...data.rho,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
}
