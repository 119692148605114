import { useParams } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import prettyBytes from "pretty-bytes";
import slugify from "slugify";

import { PageTitle } from "components/PageTitle";
import { FormInput } from "components/FormInput";
import { useDatasetForm } from "../hooks/useDatasetForm";
import { useDeleteDataset, useUpdateDataset } from "mutations";
import { useQueryDataset } from "queries";
import { Dataset } from "types";
import { useState } from "react";
import { useQueryDatasetContent } from "queries/datasets";
import { Table } from "components/Table";
import { parseDatasetPreview } from "components/VisualUI/nodes/DatasetNode/utils/parseDatasetPreview";
import { PaginationRow } from "components/VisualUI/nodes/DatasetNode/PaginationRow";

export const SingleDatasetPage = () => {
  const { t } = useTranslation();
  const { handleSubmit, register, control, formErrors } = useDatasetForm();
  let { id } = useParams();

  const updateMutation = useUpdateDataset();
  const deleteMutation = useDeleteDataset();

  const { isLoading, isError, data: dataset, error: datasetError } = useQueryDataset(id!);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error loading the data</span>;
  }

  if (!dataset) {
    return (
      <div>
        {t("No data available. ")} {datasetError}
      </div>
    );
  }

  /*
   * handle form submission
   */
  const onSubmit = handleSubmit((formData: Dataset) => {
    console.log(JSON.stringify(formData));
    updateMutation.mutate({
      id,
      label: formData["label"],
      desc: formData["desc"],
      slug: slugify(formData["label"].toLowerCase()),
      url: formData["url"],
      public: formData["public"],
      metadata_url: formData["metadata_url"],
      format: dataset!.format,
      is_compressed: dataset!.is_compressed,
      compression_format: dataset!.compression_format,
    });
    console.log(formErrors);
  });

  /*
   * Handle clicking on delete button
   */
  const handleDelete = (e: any) => {
    e.preventDefault();

    if (window.confirm(`${t("Do you really want to delete the dataset")} "${dataset!.label}"?`)) {
      //eslint-disable-line
      deleteMutation.mutate(dataset.id);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Datasets", path: "/model-engineer/datasets" },
          { label: "Dataset detail", path: `/datasets/${id}`, active: true },
        ]}
        title={"Dataset detail"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  {updateMutation.error && (
                    <h5 onClick={() => updateMutation.reset()}>{updateMutation.error}</h5>
                  )}
                  <form onSubmit={onSubmit}>
                    <Row>
                      <Col xl={6}>
                        <FormInput
                          type="text"
                          name="label"
                          label={t("Dataset name")}
                          placeholder={t("Enter dataset name")}
                          containerClass={"mb-3"}
                          register={register}
                          key="label"
                          errors={formErrors}
                          control={control}
                          defaultValue={dataset!.label}
                        />

                        <FormInput
                          name="desc"
                          label={t("Description")}
                          placeholder={t("Dataset description")}
                          type="textarea"
                          rows="3"
                          containerClass={"mb-3"}
                          register={register}
                          key="desc"
                          errors={formErrors}
                          control={control}
                          defaultValue={dataset!.desc}
                        />

                        <FormInput
                          label={t("Public?")}
                          type="checkbox"
                          name="public"
                          containerClass={"mb-3"}
                          register={register}
                          key="public"
                          errors={formErrors}
                          control={control}
                        />

                        <FormInput
                          type="text"
                          name="url"
                          label={t("URL to the folder with dataset files")}
                          placeholder={t("")}
                          containerClass={"mb-3"}
                          register={register}
                          key="url"
                          errors={formErrors}
                          control={control}
                          defaultValue={dataset!.url}
                        />

                        <FormInput
                          type="text"
                          name="metadata_url"
                          label={t("Metadata URL")}
                          placeholder={t("")}
                          containerClass={"mb-3"}
                          register={register}
                          key="metadata_url"
                          errors={formErrors}
                          control={control}
                          defaultValue={dataset!.metadata_url}
                        />
                      </Col>
                      <Col xl={6}>
                        <p className="mb-0">
                          <span className="text-nowrap d-inline-block">
                            Format: <b>{dataset!.format}</b>
                          </span>
                        </p>
                        <p className="mb-0">
                          <span className="text-nowrap d-inline-block">
                            Size: <b>{prettyBytes(dataset!.size)}</b>
                          </span>
                        </p>
                        <p className="mb-0">
                          <span className="text-nowrap d-inline-block">
                            Compressed: <b>{dataset!.is_compressed ? "yes" : "No"}</b>
                          </span>
                        </p>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col>
                        <Button type="submit" variant="success" disabled={updateMutation.isLoading}>
                          {t("Update")}
                        </Button>{" "}
                        <Button onClick={handleDelete} variant="danger" disabled={deleteMutation.isLoading}>
                          {t("Delete")}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h3>Preview of the dataset</h3>
          <Card>
            <Card.Body>
              <DatasetContentPreview datasetId={id} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const ROWS_PER_PAGE = 11;

const DatasetContentPreview = ({ datasetId }: { datasetId: string }) => {
  const [activePage, updatePage] = useState(() => 1)
  const handleUpdatePage = (n: number) => {
    updatePage((_) => n)
  }

  const { 
    isLoading: isDatasetLoading,
    isError: isDatasetErorr,
    error: datasetError,
    data: dataset
  } = useQueryDataset(datasetId)

  const {
    isLoading: isDatasetContentLoading,
    isError: isDatasetContentError,
    error: datasetContentError,
    data: datasetContent
  } = useQueryDatasetContent(
    datasetId,
    activePage,
    ROWS_PER_PAGE,
    "csv",
    dataset?.dataset_details?.filename,
    // dataset?.dataset_details.filename,
    // dataset.dataset_details
  )

  const parsedDatasetContent = parseDatasetPreview(datasetContent);
  const columns = parsedDatasetContent?.headers?.map((header) => ({ Header: header, accessor: header })) ?? [];
  const rows = parsedDatasetContent?.data;

  if (isDatasetLoading || isDatasetContentLoading) {
    return (
      <Row>
        <Col>
          <h4>Preview of <strong style={{ color: "cyan"}}>{dataset?.label} </strong> is loading...</h4>
        </Col>
      </Row>
    )
  }

  if (isDatasetErorr || isDatasetContentError) {
    return (
      <Row>
        <Col>
          <h4>There's an error gathering the preview of this dataset.</h4>
        </Col>
      </Row>
    )
  }

  if (!datasetContent) {
    return (
      <Row>
        <Col>
          <h4>There's nothing in the dataset to display.</h4>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Col>
          { rows && columns && (
            <Table  columns={columns} data={rows} pageSize={10} />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
        { rows && columns && (
          <PaginationRow 
            updateActivePage={handleUpdatePage} 
            activePage={activePage} 
            isDatasetContentLoading={isDatasetContentLoading} 
            noNextPage={rows?.length < 11}/>
        )}
        </Col>
      </Row>
    </>
  )
}