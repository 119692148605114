import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

export type Props = {
  data: {
    title: string,
    key: string,
    callback: Function
  }[];
  activeTitle?: string;
}

export default function DropdownMenu({data, activeTitle}: Props) {
  const [activeItem, setActiveItem] = useState(activeTitle ?? data[0].title);

  useEffect(() => {
    setActiveItem(activeTitle)
  }, [activeTitle])

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic">
        {activeItem}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data.map(item => (
          /* This has to be ignored because of some compatibility issues.. */
          /* @ts-ignore */
          <Dropdown.Item 
          key={item.key} 
          onClick={() => {
            item.callback();
            setActiveItem(item.title)
          }}
          >
            {item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}