import { Row, Col, Card, Button } from "react-bootstrap";
// components
import { PageTitle } from "components/PageTitle";
import { Dataset } from "types";
import { LeftPanel } from "../LeftPanel";
import { DatasetsTable } from "../DatasetsTable";
import { useQueryDatasets } from "queries";
import SimlLoader from "components/SimlLoader";

export const DatasetsPage = () => {
  const { status, error, data: datasets } = useQueryDatasets();

  if (status === "loading") {
    return <SimlLoader />;
  }

  if (error) {
    return <span>Error loading the data: {error.message}</span>;
  }

  console.log(datasets);

  const sizes = (datasets?.results.map((d: Dataset) => d.size) as number[]) || [];
  const combinedSize = sizes.reduce((size: number, total: number) => size + total, 0);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Datasets", path: "/model-engineer/datasets", active: true },
        ]}
        title={"Datasets"}
      />

      <Row>
        <Col>
          <Card className="datasets-list-page">
            <Card.Body>
              <div className="page-aside-left">
                <LeftPanel combinedSize={combinedSize} />
              </div>

              <div className="page-aside-right">
                <div className="d-flex justify-content-between align-items-center top-part">
                  <div className="app-search">
                    <form>
                      <div className="mb-2 position-relative search-container">
                        <input type="text" className="form-control" placeholder="Search files..." />
                        <span className="mdi mdi-magnify search-icon" />
                        <Button>Search</Button>
                      </div>
                    </form>
                  </div>

                  <div className="top-right-buttons">
                    <button type="submit" className="btn btn-sm active">
                      <i className="mdi mdi-format-list-bulleted" />
                    </button>
                    <button type="submit" className="btn btn-sm">
                      <i className="mdi mdi-view-grid" />
                    </button>
                    <button type="submit" className="btn btn-sm">
                      <i className="mdi mdi-information-outline" />
                    </button>
                  </div>
                </div>

                <DatasetsTable datasets={datasets?.results} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
