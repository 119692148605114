import css from "../styles.module.css";
import classNames from "classnames";

export default function PixelStreamingVideo({ videoParent }: { videoParent: any }) {
  return (
    <div ref={videoParent} className={classNames([css.pixelStreamingVideo])}>
      <button style={{ display: "none" }} id="myCustomXRButton" />
      <button style={{ display: "none" }} id="myCustomFullscreenButton" />
    </div>
  );
}
