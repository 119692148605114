import { Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
// Components
import { PageTitle } from "components/PageTitle";
import { useState } from "react";
import { VisualUI } from "components/VisualUI";
// Example code
import { useDeleteSimulator } from "mutations";
import { useQuerySimulator } from "queries";
import { GeometryViewer } from "components/GeometryViewer";
import { SimulatorConfigurator } from "./SimulatorConfigurator";
import { ReactFlowProvider } from "reactflow";
import { CopySimulatorModal } from "./CopySimulatorModal";
import { TabSwitchContent } from "components/tabSwitch";
import SimlLoader from "components/SimlLoader";
import { isDevelopment } from "lib/env";
import css from "./styles.module.css";
import EditSimulatorModal from "./EditSimulatorModal";
import { isEmpty } from "lodash";
import { SimulatorControlBar } from "components/VisualUI/Topbar";
import { TourProvider } from "./TourContext";

export const SimulatorDetailPage = () => {
  const { user } = useAuth0();
  let { id, isGuide } = useParams();
  const { t } = useTranslation();
  const [editing, setEdit] = useState<boolean>(false);
  const deleteMutation = useDeleteSimulator();
  const [copyModalActive, setCopyModalActive] = useState(false);

  const {
    isLoading: isSimulatorsLoading,
    isError: isSimulatorsError,
    data: simulator,
    error: simulatorError,
  } = useQuerySimulator(id);

  if (isSimulatorsLoading) {
    return <SimlLoader/>;
  }

  if (isSimulatorsError) {
    return <span>Error loading the simulator data: {simulatorError}</span>;
  }

  if (!simulator) {
    return <span>Simulator with this ID doesn't exist</span>;
  }

  isDevelopment && console.log(simulator);

  /*
   * Handle clicking on archive button
   */
  const handleArchive = (e: any) => {
    e.preventDefault();

    // Step 0: Let the user know how many projects depend on this Simulator and what is the archiving process
    if (window.confirm(`${t("Do you really want to archive simulator")} "${simulator?.label}"?`)) {
      // Step 1: Start gracefully destroying the cloud infrastructure (deployed servers, etc.)

      // Step 2: Show the progress bar while the graceful shutdown is in process.

      // Step 3: Remove simulator from the DB
      //eslint-disable-line
      deleteMutation.mutate(simulator?.id);
    }
  };

  const handleShowDescription = () => {
    const descParagraph = document.getElementById("sim-desc-paragraph");
    if (descParagraph) {
      descParagraph.style.display = descParagraph.style.display === "none" ? "block" : "none";
    }

    const chevronDown = document.getElementById("chevron-down-sim-label");
    if (chevronDown) {
      chevronDown.style.transform = chevronDown.style.transform === "rotate(180deg)" ? "rotate(0deg)" : "rotate(180deg)";
    }
  }

  return (
    <ReactFlowProvider>
      <PageTitle
        breadCrumbItems={[
          { label: "Simulators", path: "/model-engineer/simulators" },
          { label: "Simulator Details", path: `/model-engineer/simulators/${simulator.id}`, active: true },
        ]}
        title={"Simulator Details"}
      />

      <Row>
        <Col>
          <Card data-tour='tour_select_nodes'>
            <Card.Body>
              <section style={{ display: "flex", justifyContent: "space-between", width: "100%"}}>
                <div className={css.labelAndButtonsContainer}>
                  <div className={css.titleChevronContainer}>
                    <h4>{simulator.label}</h4>
                    <i className="dripicons-chevron-down" id="chevron-down-sim-label" onClick={() => handleShowDescription()}></i>
                  </div>
                  <div className={css.buttonsContainer}>
                    {user?.sub === simulator?.owner && <button className={css.btnCopySimulatorDark} onClick={() => setEdit(true)}>Edit</button>}
                    {user?.sub === simulator?.owner && <button className={css.btnCopySimulatorDark} onClick={handleArchive}>Delete</button>}
                    <button className={css.btnCopySimulatorDark} onClick={() => setCopyModalActive(true)}>Copy simulator</button>
                  </div>
                </div>
                <div style={{ display: "flex"}}>
                  <SimulatorControlBar simulator={simulator}/>
                </div>
                <p id="sim-desc-paragraph" style={{color: "gray", display: "none", paddingTop: "10px"}}>{simulator.desc}</p>
              </section>
              <TourProvider>
                <TabSwitchContent
                  tabs={[
                    {
                      key: isGuide ? `visual-ui` : `geometry-viewer`,
                      text: isGuide ? `Visual UI` : `Geometry Viewer`
                    },
                    {
                      key: isGuide ? 'geometry-viewer' : 'visual-ui',
                      text: isGuide ? 'Geometry Viewer' : 'Visual UI'
                    },
                    {
                      key: 'simulator-config',
                      text: 'Configuration'
                    }
                  ]}
                  defaultValue={isGuide ? "visual-ui" : (isEmpty(simulator?.modulus_components?.geometries) ? 'visual-ui' : 'geometry-viewer') }
                >
                  <div>
                    <GeometryViewer simulator={simulator} key="geometry-viewer"/>
                    <VisualUI simulator={simulator} key="visual-ui"/>
                    <SimulatorConfigurator simulator={simulator} key="simulator-config"/>
                  </div>
                </TabSwitchContent>
              </TourProvider>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CopySimulatorModal
        show={copyModalActive}
        onHide={() => setCopyModalActive(false)}
        simulator={simulator}
      />

      <EditSimulatorModal 
        show={editing}
        onHide={() => setEdit(false)}
        simulator={simulator}    
      />
    </ReactFlowProvider>
  );
};
