import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import Dropzone from "react-dropzone";
import useFileUploader from "./useFileUploader";
import { FileType } from "./types";

type FileUploaderProps = {
  onFileUpload?: (files: FileType[]) => void;
  showPreview?: boolean;
  className: string,
  accept?: string[]
  multiple?: boolean
};

const acceptedFormatValidator = (file: File, accept?: string[]) => {
  if(accept === undefined) return null;
  const extension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
  return accept.includes(extension) ? null : {
    code: 'not supported',
    message: 'File is not supported'
  };
};

export const FileUploader = ({ showPreview = true, onFileUpload, className, accept, multiple }: FileUploaderProps) => {
  const { selectedFiles, handleAcceptedFiles, removeFile } = useFileUploader(showPreview);

  return (
    <>
      <Dropzone 
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles, onFileUpload)}
        validator={(file) => acceptedFormatValidator(file, accept)}
        multiple={multiple ?? true}
        >
        {({ getRootProps, getInputProps }) => (
          <div className={`dropzone ${className}`}>
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <i className="h3 text-muted dripicons-cloud-upload" />
              <h5>Drop files here or click to upload.</h5>
              {/* <span className="text-muted font-13">
                (This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)
              </span> */}
            </div>
          </div>
        )}
      </Dropzone>

      {showPreview && selectedFiles.length > 0 && (
        <div className="dropzone-previews mt-3" id="uploadPreviewTemplate">
          {(selectedFiles || []).map((f, i) => {
            return (
              <Card className="mt-1 mb-0 shadow-none border" key={i + "-file"}>
                <div className="p-2">
                  <Row className="align-items-center">
                    {f.preview && (
                      <Col className="col-auto">
                        <img data-dz-thumbnail="" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                      </Col>
                    )}
                    {!f.preview && (
                      <Col className="col-auto">
                        <div className="avatar-sm">
                          <span className="avatar-title bg-primary rounded">{f.type.split("/")[0]}</span>
                        </div>
                      </Col>
                    )}
                    <Col className="ps-0">
                      <Link to="#" className="text-muted fw-bold">
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col className="text-end">
                      <Link to="#" className="btn btn-link btn-lg text-muted shadow-none">
                        <i className="dripicons-cross" id={"remove-file-from-upload"} onClick={() => removeFile(f)} />
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
};
