export const calculateRunningTimeFormated = (intervalSeconds: number) => {
  let seconds = Math.floor(intervalSeconds % 60) || 0;
  let minutes = Math.floor((intervalSeconds / 60) % 60) || 0;
  let hours = Math.floor(intervalSeconds / 60 / 60) || 0;
  let days = (intervalSeconds / 86400).toFixed(2) || 0;

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  } (${days} days)`;
};
