import { constraintDefaultSettings } from "modulus-interop/constraints";
import { useEffect, useState } from "react";
import { Handle, Position, useEdges, useNodeId, useReactFlow } from "reactflow";
import Select from "react-select";
import { useVisualUIStore } from "components/VisualUI/store";

import "./constraint-nodes.css";

interface ConstraintOption {
  readonly value: string;
  readonly label: string;
}

const options: readonly ConstraintOption[] = [
  { value: "pointwise_boundary_constraint", label: "Boundary constraint" },
  { value: "pointwise_interior_constraint", label: "Interior constraint" },
  { value: "integral_boundary_constraint", label: "Integral constraint" },
];

type ConstraintNodeProps = {
  data: any;
};

// TODO: Add quick edit for constraint and lambda values?

export function ConstraintNode({ data }: ConstraintNodeProps) {
  const [isExpanded, setExpanded] = useState(false);
  const nodeId = useNodeId();
  const { deleteElements, setNodes, getNode, getEdges, getNodes } = useReactFlow();
  const edges = useEdges();
  const { setSidebarNodeId } = useVisualUIStore();
  const [constraint, setConstraint] = useState(
    options.find((o) => o.value === data.mode) ?? { value: "", label: "Select constraint..." }
  );

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...data,
          };
        }

        return node;
      })
    );
  }, []);

  const handleSetConstraint = (option: { value: string; label: string }) => {
    setConstraint(option);

    const settings = {
      ...constraintDefaultSettings[option.value],
      mode: option.value,
      id: nodeId,
    };

    const edge = getEdges().find((e: any) => e.source === nodeId);

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...settings,
            geometry: edge ? edge.target : null,
            geometry_label: edge ? getNode(edge.target)?.data?.label : "",
          };
        }

        return node;
      })
    );
  };

  useEffect(() => {
    const edge = getEdges().find((e: any) => e.source === nodeId);

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            geometry: edge ? edge.target : null,
            geometry_label: edge ? getNode(edge.target)?.data?.label : "",
          };
        }

        return node;
      })
    );
  }, [edges]);

  const handleToggle = () => {
    setExpanded(!isExpanded);
  };

  const constValues =
    data.outvar &&
    Object.entries(data.outvar).map(([key, value]) => {
      const roundedValue: any = Number(value).toFixed(2);
      const val = roundedValue % 1 === 0 ? roundedValue.split(".")[0] : roundedValue;

      return (
        <span className="position-relative ms-1" key={key}>
          <span className="badge bg-secondary">{val}</span>
          <span className="position-absolute top-0 start-50 translate-middle" style={{ marginTop: -8 }}>
            <span className="text-sm">{key.substring(0, 2)}</span>
          </span>
        </span>
      );
    });

  const lambdaValues =
    data.lambda_weighting &&
    Object.entries(data.lambda_weighting).map(([key, value]) => {
      const roundedValue: any = Number(value).toFixed(2);
      const val = roundedValue % 1 === 0 ? roundedValue.split(".")[0] : roundedValue;

      return (
        <span className="position-relative ms-1" key={key}>
          <span className="badge bg-secondary">{val}</span>
          <span className="position-absolute top-0 start-50 translate-middle" style={{ marginTop: -8 }}>
            <span className="text-sm">{key.substring(0, 2)}</span>
          </span>
        </span>
      );
    });

  const shouldShowLambda = Object.keys(data?.lambda_weighting || {}).length > 0;
  const shouldShowConstraint = Object.keys(data?.outvar || {}).length > 0;

  // sets the default (true) value to used_for_training property
  useEffect(() => {
    if ((data?.used_for_training === null) || data?.used_for_training === undefined) {
      setNodes((node) => {
        return node.map((n) => {
          if (n.id === nodeId) {
            n.data.used_for_training = true;
          }
          return n;
        })
      })
    }
  }, [data])

  return (
    <div className="constraint-node" data-tour={`tour_constraint_node_${data.id}`}>
      <div className="title">
        <div className="node-icons">
          {/* <i className="dripicons-gear" onClick={() => setSidebarNodeId(nodeId)} /> */}
          <i
            className="dripicons-cross"
            onClick={() => {
              deleteElements({ nodes: [{ id: nodeId }] });
              setSidebarNodeId("");
            }}
          />
        </div>
        <span>Constraint</span>
      </div>
      <div className="content" onClick={(event) => {
          const node = getNode(nodeId);
          node.selected = true;

          const nodes = getNodes();
          nodes.forEach((n) => {
            if (n.id !== nodeId) {
              n.selected = false;
            }
          });
          setNodes((nds) => nodes.map((n) => n));
      }}>
        <Select
          className="react-select nodrag"
          options={options}
          value={constraint}
          onChange={handleSetConstraint}
          styles={{
            option: (baseStyles, state) => ({
              backgroundColor: state.isFocused ? "#474b58" : "#373A45",
              display: "inline-block",
              color: "#B4B6C3 !important",
              padding: "10px 5px",
              width: "100%",
            }),
          }}
        />
        <div>
          <div
            className={`d-flex ${isExpanded ? "expanded" : ""} ${
              shouldShowConstraint || shouldShowLambda ? "show" : "visually-hidden"
            }`}
            onClick={handleToggle}
            aria-expanded={isExpanded}>
            <div className="mx-auto mt-1">
              <i className={`dripicons-chevron-${isExpanded ? "up" : "down"}`}></i>
            </div>
          </div>
          <div className="">
            {data.outvar && (
              <div className={`collapse ${isExpanded && shouldShowConstraint ? "show" : ""}`}>
                <div className="row">
                  <div className="col-auto h6">Constrains:</div>
                  <div className="col-auto ms-auto h6">{constValues}</div>
                </div>
              </div>
            )}
            {data.lambda_weighting && (
              <div className={`collapse ${isExpanded && shouldShowLambda ? "show" : ""}`}>
                <div className="row">
                  <div className="col-auto h6">Lambda:</div>
                  <div className="col-auto ms-auto  h6">{lambdaValues}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Left} id={nodeId} />
    </div>
  );
}
