// import { useAuth0 } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { FileType } from "components/FileUploader/types";
import { ReactChild, ReactFragment, ReactPortal, ReactNode } from "react";
import { ToastContentProps, toast } from "react-toastify";
import { SimulatorGeometry } from "types/geometry";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useCreateGeometry() {

  return useMutation<SimulatorGeometry & {name: string}, Error, { file: FileType, presignedUrl: string}>({
    mutationFn: async ({file, presignedUrl}: {file: FileType, presignedUrl: string}) => {
      const res = await fetch(`${presignedUrl}`, {
        method: "PUT",
        body: file,
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || `There was an error while uploading ${file.name} geometry.`
          throw new Error(errorMsg);
        } catch(err) {
          throw new Error(`There was an error while uploading ${file.name} geometry.`);
        }
      }
      const newPromise = new Promise<any>((resolve, reject) => { 
          resolve({id: "1234", name: file.name, file: file});
      });
      return newPromise;
    },
    onError: (error: boolean | ReactChild | ReactFragment | ReactPortal | ((props: ToastContentProps<unknown>) => ReactNode), __: any, ___: any) => {
      toast(error, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (data, __: any, ___: any) => {
      toast.success(`Geometry ${data.name} uploaded successfully.`, {
        theme: "dark",
      });
    }
  });
}

export function useProcessGeometry() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<SimulatorGeometry, Error, FormData>({
    mutationFn: async (geometry: FormData) => {
      console.log(geometry)
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/geometry/`, {
        method: "POST",
        body: geometry,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || `There was an error while processing ${geometry.get("label")} geometry.`
          throw new Error(errorMsg);
        } catch(err) {
          throw new Error(`There was an error while processing ${geometry.get('label')} geometry.`);
        }
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast(error, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (data, __, ___) => {
      toast.success(`Geometry ${data.label} processed successfully.`, {
        theme: "dark",
      });
    }
  });
}