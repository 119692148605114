import { useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import type { MonacoEditorProps } from "./types";
import { useModulusInterop } from "modulus-interop/reactflow";
import { useReactFlow } from "reactflow";
import { generateFullSimulatorCode } from "modulus-interop/code-generator";

function MonacoEditor({ height, language, fileName, defaultCode, code, setCustomSourceCode, disabled }: MonacoEditorProps) {
  const editorRef = useRef(null);
  // const { getNodes, getEdges } = useReactFlow();
  // const { serializeNodes, deserializeNodes } = useModulusInterop();
  // const [simulatorCode, setSimulatorCode] = useState("");

  // console log every prop without useEffect
  // console.log(height)
  // console.log(language)
  // console.log(fileName)
  // console.log(defaultCode)

  // useEffect(() => {
  //   const modulusComponents = serializeNodes();
  //   console.log(modulusComponents)
  //   setSimulatorCode(generateFullSimulatorCode(modulusComponents))
  // }, []);

  return (
    <div>
      <Editor
        height={height}
        onMount={(editor) => (editorRef.current = editor)}
        path={fileName}
        defaultLanguage={language}
        value={code}
        defaultValue={defaultCode}
        theme="vs-dark"
        onChange={(value) => disabled ? null : setCustomSourceCode(value)}
        options={{readOnly: disabled}}
      />
    </div>
  );
}

export default MonacoEditor;
