import { useCallback, useState } from "react";
import { Handle, Position, useNodeId, useReactFlow } from "reactflow";
import { FileDropzone } from "../../FileDropzone";
import { useVisualUIStore } from "../../store";
import type { FileType } from "../../types";

import "./validator-node.css";

export function ValidatorNode({ data }: { data: any }) {
  const nodeId = useNodeId();
  const reactFlowInstance = useReactFlow();
  const [files, setFiles] = useState<FileType[]>();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const handleCloseSettings = () => setShowSettingsModal(false);
  const handleShowSettings = () => setShowSettingsModal(true);

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className="node-icons">
        {/* <i className="dripicons-gear" onClick={handleShowSettings} /> */}
        <i
          className="dripicons-cross"
          onClick={() => {
            reactFlowInstance.setNodes(reactFlowInstance.getNodes().filter((n: any) => n.id !== nodeId));
          }}
        />
      </div>
      <div className="title">Validation</div>
      <div>
        <FileDropzone
          droptext={"Drop file containing validation data or click to upload"}
        />
      </div>
      <Handle type="source" position={Position.Bottom} id="validator" />
    </>
  );
}
