import { useState } from "react";
import isEmpty from 'lodash/isEmpty';
import { PageTitle } from "components/PageTitle";
import { Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";
import { CreditsWidget } from "./CreditsWidget";
import { CreditsSpendingWidget } from "./CreditsSpendingWidget";
import { PricingCard } from "./Pricing/PricingCard";
import { useAuth0 } from "@auth0/auth0-react";
import { useSubscription } from "queries";
import { useCustomerPortal } from "mutations";
import { FormInput } from "components/FormInput";
import SimlLoader from "components/SimlLoader";
import { useQueryCreditsUsage } from "queries/environments";
import { getDate, getNowAsNiceString } from "./CreditsSpendingWidget/getDate";
import { parseLast24h } from "./CreditsSpendingWidget/parseLast24h";

export const BillingPage = () => {
  const { user } = useAuth0();
  const [duration, setDuration] = useState("month");
  const [discountCode, setDiscountCode] = useState("");
  const [isMonthly, setIsMonthly] = useState(true);
  const {
    isLoading: isEnvHistoryLoading,
    isError: isEnvHistoryError,
    data: envHistory,
    error: envHistoryError,
  } = useQueryCreditsUsage(
    getDate(isMonthly ? "month" : "day", new Date()), 
    getNowAsNiceString(), 
    isMonthly ? "day" : "hour"
  ); //day = get last month of data, hour = get last day of data

  const customerPortalRedirect = useCustomerPortal()

  const { isLoading: isSubscriptionLoading, isError: isSubscriptionError, data: subscription, error: subscriptionError } = useSubscription(user.customer_id);

  const handleChangeRange = (val: boolean) => {
    setIsMonthly(val);
  }

  if (isSubscriptionLoading) {
    return <SimlLoader/>;
  }

  if (isSubscriptionError || isEnvHistoryError) {
    return <span>Error loading {subscriptionError && "subscription"}{envHistory && "credits usage"} data: {subscriptionError || envHistoryError}</span>;
  }

  if (!user) {
    return <span>User not found or not logged in.</span>;
  }

  return (
    <div className="billing-container">
      <PageTitle
        breadCrumbItems={[
          { label: "Billing", path: "/model-engineer/billing", active: true },
        ]}
        title={"Billing"}
      />

      <div className="credits-and-stats-container">
        <CreditsWidget
          stats="200" // TODO: remove, but requires clean-up of CreditsWidget
        />

        <CreditsSpendingWidget
          data={(isEnvHistoryLoading || isEnvHistoryError) ? [] :
            isMonthly ? 
              Object.values(envHistory || {})?.map((item) => Number(item) || 0) : 
              parseLast24h(Object.values(envHistory || {})?.map((item) => Number(item) || 0))}
          dates={(isEnvHistoryLoading || isEnvHistoryError) ? [] :
            isMonthly ?
              Object.keys(envHistory || {})?.map((item) => isMonthly ? new Date(item || 0)?.getDate().toString() : new Date(item || 0)?.getHours().toString().padStart(2, '0') || "0") :
              parseLast24h(Object.keys(envHistory || {})?.map((item) => Number(new Date(item || 0)?.getHours().toString())))?.map((item) => item.toString().padStart(2, '0'))}
          isMonthly={isMonthly}
          handleChangeRange={handleChangeRange}
        />
      </div>


      <Row>
        <Col>
          <Card>
            <Card.Body>
              {subscription && !isEmpty(subscription.filter((sub: any) => sub?.items?.data?.[0]?.plan?.usage_type !== "metered")) ? (
                <Row className="justify-content-center mb-4">
                  <Col md="auto">
                    <Button variant="primary" size="lg" onClick={() => customerPortalRedirect.mutate(user.customer_id)}>
                      Manage subscription
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-center pricing-container">
                  <Col>
                    <div className="text-center">
                      <h2 className="mb-2">Our Plans and Pricing</h2>
                      {/* was text-muted, but contrast was really bad */}
                      <p className="text-secondary m-auto plans-subtitle"> 
                        We have plans and prices fit you or your business perfectly.
                      </p>

                      <Row className="justify-content-md-center mt-3">
                        <Col md="auto">
                          <div className="text-center">
                            <FormInput
                              type="text"
                              name="discount"
                              label="Apply your Siml.ai Early Adopter discount code:"
                              placeholder="Enter discount code"
                              containerClass={"mb-2"}
                              key="label"
                              defaultValue=""
                              onChange={(e) => setDiscountCode(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>

                      <p className="trial-period-info">14-day trial!</p>
                    </div>

                    <Container>
                      <Row className="justify-content-md-center mt-3">
                        <Col md="auto">
                          <ButtonGroup className="mb-2">
                            <Button
                              className={duration === "month" ? "blue" : ""}
                              onClick={() => setDuration("month")}>
                              Monthly
                            </Button>
                            <Button
                              className={duration === "year" ? "blue" : ""}
                              onClick={() => setDuration("year")}>
                              Yearly
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </Container>

                    <PricingCard
                      duration={duration}
                      discountCode={discountCode}
                    />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
