import { defaultHelmholtzEquationSettings } from "modulus-interop/equations/wave-equation";
import { HelmholtzEquationType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useReactFlow } from "reactflow";
import { containsOnlyNumbers } from "utils/string";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";

export function HelmholtzEquationSettings({ data }: { data: ModulusComponentDataCommon & HelmholtzEquationType }) {
  const { setNodes } = useReactFlow();

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row className="mt-2 mb-2">
        <Col>
          <small>Wave equation</small>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>The dependent variable (u)</h5>
          <input
            type="text"
            className="form-control"
            placeholder="Please specify a value"
            defaultValue={data.u ?? defaultHelmholtzEquationSettings.u}
            onChange={(e) => handleSettingChange({ u: e.target.value })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>
            Wave number (k)
            <span className="float-end text-muted">
              <Tooltip>
                <TooltipTrigger>
                  Parameterized
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={data.k.parameterized ?? defaultHelmholtzEquationSettings.k.parameterized}
                    onChange={(e) =>
                      handleSettingChange({
                        k: {
                          ...data.k,
                          parameterized: e.target.checked,
                          min: e.target.checked ? data.k.min : null,
                          max: e.target.checked ? data.k.max : null,
                        },
                      })
                    }
                  />
                </TooltipTrigger>
                <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
              </Tooltip>
            </span>
          </h5>
          {data.k.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.k.symbol ?? defaultHelmholtzEquationSettings.k.symbol}
                  onChange={(e) => handleSettingChange({ k: { ...data.k, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.k.value ?? defaultHelmholtzEquationSettings.k.value}
                  onChange={(e) => handleSettingChange({ k: { ...data.k, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.k.min ?? defaultHelmholtzEquationSettings.k.min}
                  onChange={(e) => handleSettingChange({ k: { ...data.k, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.k.max ?? defaultHelmholtzEquationSettings.k.max}
                  onChange={(e) => handleSettingChange({ k: { ...data.k, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.k.value ?? defaultHelmholtzEquationSettings.k.value}
              onChange={(e) =>
                handleSettingChange({
                  k: {
                    ...data.k,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Dimensions</h5>
          <Select
            className="react-select settings-select"
            options={[
              { value: 2, label: "2D" },
              { value: 3, label: "3D" },
            ]}
            defaultValue={data.dim ? { value: data.dim, label: data.dim + "D" } : { value: 3, label: "3D" }}
            onChange={(option) => handleSettingChange({ dim: option.value })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Mixed form</h5>
          <input
            type="checkbox"
            checked={data.mixed_form ?? defaultHelmholtzEquationSettings.mixed_form}
            onChange={(e) => handleSettingChange({ mixed_form: e.target.checked })}
          />
        </Col>
      </Row>
    </>
  );
}
