import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

type ResponseData = {
  type: string,
  text: string,
}

export default function NotificationsProvider() {
  const { user } = useAuth0();

  // TODO: re-enable websocket notifications after websocket server is able to be reached publicly
  // const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/ws/notifications/?userid=${user.sub}`);

  // socket.addEventListener('message', (e) => {
  //   const data: ResponseData = JSON.parse(e.data);
  //   console.log(data)
  //   if (data.type === "geometry_update") {
  //     toast(data.text, {
  //       theme: 'dark'
  //     })
  //   }
  // })

  return (
    <ToastContainer />
  );
}