import { useQueryRecentUniversityPosts } from "queries/academy";
import { Card, CardBody, Col, Row } from "react-bootstrap";
import { UniversityStory } from "types/academy";

export const UniversityOverview = () => {
  const {
    data: university,
    isLoading: isUniversityLoading,
    isError: isUniversityError,
    error: universityError,
  } = useQueryRecentUniversityPosts();
  const stories: UniversityStory[] = university?.stories;
  return (
    <Card>
      <CardBody>
        <h4>Most recent university posts</h4>
        <Row>
          {stories &&
            stories.map((story) => {
              return (
                <Col key={story.id} sm={6} xl={6} className="mb-3">
                  <a
                    href={`https://dimensionlab.org/products/simlai/university/${story.slug}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      padding: "8px",
                      cursor: "pointer",
                      background: "#202020",
                      borderRadius: "4px",
                    }}>
                    <p style={{ fontWeight: "500" }}>{story.name}</p>
                    <img
                      src={story?.content?.thumbnail?.filename ?? ""}
                      alt={story.name}
                      style={{ width: "100%", objectFit: "cover", aspectRatio: "16/9" }}
                    />
                  </a>
                </Col>
              );
            })}
        </Row>
      </CardBody>
    </Card>
  );
};
