import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useReactFlow } from "reactflow";
import { containsOnlyNumbers } from "utils/string";

interface Props {
  data: any;
  arg: string;
}

const CustomEquationArgsInputs = (props: Props) => {
  const [isInRangeDefaultValue, setIsInRangeDefaultValue] = useState(true);
  const { data, arg } = props;
  const { setNodes } = useReactFlow();
  
  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  // useEffect(() => {
  //   if(Number(data[arg]?.value) < data[arg]?.min || Number(data[arg]?.value) > data[arg]?.max) {
  //     setIsInRangeDefaultValue(false)
  //   }
  //   else {
  //     setIsInRangeDefaultValue(true)
  //   }
  // }, [data[arg]?.value])

  useEffect(() => {
    if (data.argTypes[arg] === "boolean" && !data[arg]) {
      handleSettingChange({
        [arg]: false,
      });
    }
  }, [data.argTypes[arg]]);

  if (data.argTypes[arg] === "number") {
    return (
      <Row>
        {<Col>
          <span className="float-end text-muted">
            <Tooltip>
              <TooltipTrigger>
                Parameterized
                <input
                  type="checkbox"
                  className="ms-1"
                  checked={data[arg]?.parameterized ?? false}
                  onChange={(e) =>
                    handleSettingChange({
                      [arg]: {
                        ...data[arg],
                        parameterized: e.target.checked,
                        min: e.target.checked ? data[arg]?.min : null,
                        max: e.target.checked ? data[arg]?.max : null,
                      },
                    })
                  }
                />
              </TooltipTrigger>
              <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
            </Tooltip>
          </span>
        </Col>}
        {data[arg]?.parameterized ? (
          <Row className="gx-1">
          <Col xs="4">
            <input
              type="symbol"
              className="form-control mx-0"
              placeholder="Symbol"
              value={data[arg]?.symbol ?? ""}
              onChange={(e) => handleSettingChange({ [arg]: { ...data[arg], symbol: e.target.value } })}
            />
            <span className="text-muted font-10 mt-1">Symbol</span>
          </Col>
          <Col>
            <input
              type="number"
              className={`${isInRangeDefaultValue ? `` : `border border-danger`} form-control mx-0`}
              placeholder="Default"
              step={0.1}
              defaultValue={data[arg]?.value ?? ""}
              onChange={(e) => handleSettingChange({ [arg]: { ...data[arg], value: parseFloat(e.target.value) } })}
            />
            <span className={`${isInRangeDefaultValue ? `text-muted` : `text-danger`} font-10 mt-1`}>{isInRangeDefaultValue ? `Default` : `Not in range`}</span>
          </Col>
          <Col>
            <input
              type="number"
              className="form-control mx-0"
              placeholder="Min"
              step={0.1}
              defaultValue={data[arg]?.min ?? ""}
              onChange={(e) => handleSettingChange({ [arg]: { ...data[arg], min: parseFloat(e.target.value) } })}
            />
            <span className="text-muted font-10 mt-1">Min</span>
          </Col>
          <Col>
            <input
              type="number"
              className="form-control mx-0"
              placeholder="Max"
              step={0.1}
              defaultValue={data[arg]?.max ?? ""}
              onChange={(e) => handleSettingChange({ [arg]: { ...data[arg], max: parseFloat(e.target.value) } })}
            />
            <span className="text-muted font-10 mt-1">Max</span>
          </Col>
        </Row>
        ): ( 
          <input
                type="number"
                className="form-control"
                step={0.1}
                placeholder="Number value"
                defaultValue={data[arg]?.parameterized ? data[arg]?.value : (data[arg] ?? "")}
                onChange={(e) =>
                  handleSettingChange({
                    [arg]: parseFloat(e.target.value),
                  })
                }
              />
        )}
      </Row>
    )
  }

  if (data.argTypes[arg] === "boolean") {
    return (
      <Row>
        <Col>
          <label htmlFor="bool-val">Value: </label>
          <input
            type="checkbox"
            className="ms-1"
            name="bool-val"
            checked={(data[arg] === true || data[arg] === false) ? data[arg]: false}
            onChange={(e) =>
              handleSettingChange({
                [arg]: e.target.checked,
              })
            }
          />
          <p className="text-muted">{(data[arg] === true || data[arg] === false) ? `` : `** no value, yet`}</p>
        </Col>
      </Row>
    )
  }

  if (data.argTypes[arg] === "string") {
    return (
      <input
        type="text"
        className="form-control"
        placeholder="String value"
        defaultValue={(data[arg] !== true && data[arg] !== false) ? data[arg] : ""}
        onChange={(e) =>
          handleSettingChange({
            [arg]: e.target.value,
          })
        }
      />
    )
  }

  if (data.argTypes[arg] === "list") {
    return (
      <>
        <label>Enter list values separated by comma:</label>
        <input
          type="text"
          className="form-control"
          placeholder='e.g.: u, 1, z'
          defaultValue={(data[arg] !== true && data[arg] !== false) ? data[arg].join(",") : ""}
          onChange={(e) =>
            handleSettingChange({
              [arg]: e.target.value.split(",").map((v) => {
                if (containsOnlyNumbers(v)) {
                  return parseFloat(v);
                }

                return v;
              }),
            })
          }
        />
      </>
    )
  }

  return <div>No param type selected</div>
}

export default CustomEquationArgsInputs;