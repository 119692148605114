import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { Form } from "react-bootstrap";
import { useReactFlow } from "reactflow";

export function GeometrySettings({ data }: any) {
  const { setNodes } = useReactFlow();

  const updateNode = () => {
    setNodes((nds: any) =>
      nds.map((nd: any) => {
        if (nd.id === data.id) {
          nd.data = {
            ...nd.data,
            airtight: !data.data.airtight,
          };
        }
        return nd;
      })
    );
  };

  const updateThumbnail = () => {
    setNodes((nds: any) =>
      nds.map((nd: any) => {
        if (nd.id === data.id) {
          nd.data = {
            ...nd.data,
            thumbnail: !data.data.thumbnail,
          };
        }

        if (nd.id !== data.id && nd.data.mode === "stl_geometry") {
          nd.data = {
            ...nd.data,
            thumbnail: false,
          };
        }
        return nd;
      })
    );
  }

  return (
    <>
      <Form>
        <Tooltip>
          <TooltipTrigger>
            <Form.Check
              className="checkbox"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "end",
              }}
              type="switch"
              name="airtight"
              label="Airtight"
              checked={data.data.airtight}
              onChange={() => updateNode()}
            />
          </TooltipTrigger>
          <TooltipContent className="Tooltip">
            Setting this on allows the neural network sample points from inside of the geometry
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <Form.Check
              className="checkbox"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "end",
              }}
              type="switch"
              name="thumbnail"
              label="Use as thumbnail"
              checked={data.data.thumbnail}
              onChange={() => updateThumbnail()}
            />
          </TooltipTrigger>
          <TooltipContent className="Tooltip">
            Setting this on uses this geometry as the thumbnail for the simulator
          </TooltipContent>
        </Tooltip>
      </Form>
    </>
  );
}
