export const signallingServerAddress = "ws://127.0.0.1";
// export const signallingServerAddress = process.env.REACT_APP_PIXEL_STREAMING_URL;
// export const signallingServerAddress = "https://0fb8-3-78-22-24.ngrok-free.app"

export const outputsPath = "/home/outputs";

export const commands = {
  "initPointCloud": "initPointCloud",
  "switchPointCloud": "switchPointCloud",
  "onSlicerChangeMode": "onSlicerChangeMode",
  "onViewChangeMode": "onViewChangeMode",
  "onLoadGeometry": "onLoadGeometry",
  "onNiagaraParamsUpdate": "onNiagaraParamsUpdate",
  "onInit": "onInit",
  "onColorProfileChange": "onColorProfileChange",
  "onMagnitudeSourceChange": "onMagnitudeSourceChange",
  // "onChangeGeoMaterial": "onChangeGeoMaterial", // replaced by onChangeGeoOpacity
  "onChangeGeoOpacity": "onChangeGeoOpacity",
};

export const niagaraParams = {
  PointCount: {
    type: "int",
  },
  ScaleSpriteMin: {
    type: "vec2",
  },
  ScaleSpriteMax: {
    type: "vec2",
  },
  ScaleSpriteTresh: {
    type: "float",
  }
}


  // Define view modes
export const VIEW_MODES = {
  'PointCloud': "Point Cloud",
  'PointCloudVelocities': "Point Clould - Highlighted Velocities",
  'VectorFieldsFine': "Vector Fields",
  // 'VectorFields2': "Vector Fields - Highlighted Velocities",
  'VectorFieldsAnimated': "Vector Fields - Phase Flow",
}

export const views = Object.entries(VIEW_MODES).reduce((acc: any, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export const MAX_POINT_COUNT = 2000000;

export const VIEW_MODE_PROFILES = {
  'PointCloud': {
    // PointCount: { val: 1048576, type: "int" },
    ScaleSpriteMin: { val: [0.2, 0.2], type: "vec2" },
    ScaleSpriteMax: { val: [0, 0], type: "vec2" },
    ScaleSpriteTresh: { val: 50, type: "float" },
    MeshRendererEnabled: { val: false, type: "bool" },
    SpriteRendererEnabled: { val: true, type: "bool" },
    SpriteRendererAnimEnabled: { val: false, type: "bool" },
    ColorScale: { val: [5,5,5], type: "vec3" },
    // SpriteSize: { val: [10, 10], type: "vec2" },
  },
  'PointCloudVelocities': {
    // PointCount: { val: 1048576, type: "int" },
    ScaleSpriteMin: { val: [0.05, 0.05], type: "vec2" },
    ScaleSpriteMax: { val: [7, 7], type: "vec2" },
    ScaleSpriteTresh: { val: 50, type: "float" },
    MeshRendererEnabled: { val: false, type: "bool" },
    SpriteRendererEnabled: { val: true, type: "bool" },
    SpriteRendererAnimEnabled: { val: false, type: "bool" },
    ColorScale: { val: [1,1,1], type: "vec3" },
  },
  'VectorFieldsAnimated': {
    // PointCount: { val: 148576, type: "int" },
    ScaleSpriteMin: { val: [0.05, 0.05], type: "vec2" },
    ScaleSpriteMax: { val: [7, 7], type: "vec2" },
    ScaleSpriteTresh: { val: 50, type: "float" },
    MeshRendererEnabled: { val: false, type: "bool" },
    SpriteRendererEnabled: { val: false, type: "bool" },
    SpriteRendererAnimEnabled: { val: true, type: "bool" },
    ColorScale: { val: [1,1,1], type: "vec3" },
  },
  'VectorFields': {
    // PointCount: { val: 52428, type: "int" },
    ScaleSpriteMin: { val: [0.1, 0.1], type: "vec2" },
    ScaleSpriteMax: { val: [0, 0], type: "vec2" },
    ScaleSpriteTresh: { val: 50, type: "float" },
    MeshRendererEnabled: { val: true, type: "bool" },
    SpriteRendererEnabled: { val: false, type: "bool" },
    SpriteRendererAnimEnabled: { val: false, type: "bool" },
    MagMinScale: { val: [0.2, 0.5, 0.2], type: "vec3" },
    MagMaxScale: { val: [1, 1.5, 1], type: "vec3" },
    ColorScale: { val: [1,1,1], type: "vec3" },
  },
  'VectorFieldsFine': {
    // PointCount: { val: 52428, type: "int" },
    ScaleSpriteMin: { val: [0.1, 0.1], type: "vec2" },
    ScaleSpriteMax: { val: [0, 0], type: "vec2" },
    ScaleSpriteTresh: { val: 50, type: "float" },
    MeshRendererEnabled: { val: true, type: "bool" },
    SpriteRendererEnabled: { val: false, type: "bool" },
    SpriteRendererAnimEnabled: { val: false, type: "bool" },
    MagMinScale: { val: [0.2, 0.5, 0.2], type: "vec3" },
    MagMaxScale: { val: [0.2, 0.5, 0.2], type: "vec3" },
    ColorScale: { val: [1,1,1], type: "vec3" },
  },
  'VectorFields2': {
    // PointCount: { val: 52428, type: "int" },
    ScaleSpriteMin: { val: [0.1, 0.1], type: "vec2" },
    ScaleSpriteMax: { val: [0, 0], type: "vec2" },
    ScaleSpriteTresh: { val: 50, type: "float" },
    MeshRendererEnabled: { val: true, type: "bool" },
    SpriteRendererEnabled: { val: false, type: "bool" },
    SpriteRendererAnimEnabled: { val: false, type: "bool" },
    MagMinScale: { val: [0.2, 0.5, 0.2], type: "vec3" },
    MagMaxScale: { val: [1, 1.5, 1], type: "vec3" },
    ColorScale: { val: [1,1,1], type: "vec3" },
  }
};

export const SLICER_ON: any = {
    // PointCount: { val: 148576, type: "int" },
    // SpriteSize: { val: [30, 30], type: "vec2" },
    // SpriteRendererEnabled: { val: true, type: "bool" },
    // EmissiveEnabled: { val: 1, type: "int" },
}
export const SLICER_OFF: any = {
    // PointCount: { val: 148576, type: "int" },
    // SpriteSize: { val: [5, 5], type: "vec2" },
    // SpriteRendererEnabled: { val: true, type: "bool" },
    // EmissiveEnabled: { val: 0, type: "int" },
  }

export const MAGNITUDE_COLOR_SCALES = [
  {
      name: 'rainbow',
      colors: [
          'rgb(0, 0, 127)',
          'rgb(0, 127, 255)',
          'rgb(0, 255, 255)',
          'rgb(255, 255, 0)',
          'rgb(255, 127, 0)',
          'rgb(255, 0, 0)'
      ]
  },
  {
      name: 'viridis',
      colors: [
          'rgb(68, 1, 84)',
          'rgb(62, 73, 137)',
          'rgb(38, 130, 142)',
          'rgb(53, 183, 121)',
          'rgb(181, 221, 42)',
          'rgb(253, 231, 37)'
      ]
  },
  {
      name: 'magma',
      colors: [
          'rgb(0, 0, 4)',
          'rgb(59, 15, 112)',
          'rgb(140, 41, 129)',
          'rgb(183, 55, 121)',
          'rgb(222, 73, 104)',
          'rgb(254, 159, 109)'
      ]
  },
  {
      name: 'plasma',
      colors: [
          'rgb(13, 8, 135)',
          'rgb(106, 0, 168)',
          'rgb(177, 42, 144)',
          'rgb(225, 100, 98)',
          'rgb(253, 174, 97)',
          'rgb(254, 224, 147)'
      ]
  }
];