import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryMode } from "components/VisualUI/nodes/DatasetNode";
import { toast } from "react-toastify";
import { Dataset } from "types";

type DatasetsListResponse = {
  count: number;
  next: any;
  previous: any;
  results: Dataset[];
};

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useQueryDatasets() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<DatasetsListResponse, Error>({
    queryKey: ["datasets"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/datasets/?limit=100`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching datasetsfrom the database.");
      }
      return res.json();
    },
  });
}

export function useQueryDataset(id: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Dataset, Error>({
    queryKey: ["dataset", id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const url = `${process.env.REACT_APP_API_URL}/api/v1/datasets/${id}/`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error fetching dataset ID ${id} from the database.`);
      }
      return res.json();
    },
    enabled: !!id,
  });
}

type DatasetContent = {
  [key: string]: string;
}

export const useQueryDatasetContent = (
    id: string, 
    page?: number, 
    rowsPerPage?: number, 
    datasetMode?: QueryMode,
    filename?: string,
    fileInDataset?: string, 
    // h5_key?: string,
    datasetDetails?: Dataset["dataset_details"],
  ) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<DatasetContent, Error>({
    queryKey: ["datasetContent", id, page, rowsPerPage, datasetMode, filename, fileInDataset, datasetDetails],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      let url = "";

      switch (datasetMode) {
        case "csv":
          url = `${process.env.REACT_APP_API_URL}/api/v1/datasets/get-dataset-contents/${id}/?page=${page.toString()}&rows_per_page=${rowsPerPage ?? 10}&filename=${filename}`
          break;
        // case "h5":
        //   url = `${process.env.REACT_APP_API_URL}/api/v1/datasets/get-dataset-contents/${id}/?page=${page.toString()}&rows_per_page=${rowsPerPage ?? 10}&filename=${filename}&h5_key=${h5_key}`
        //   break;
        case "archive-csv": 
          url = `${process.env.REACT_APP_API_URL}/api/v1/datasets/get-dataset-contents/${id}/?page=${page.toString()}&rows_per_page=${rowsPerPage ?? 10}&filename=${filename}&dataset_filename=${fileInDataset}`
          break;
        // case "archive-h5":
        //   url = `${process.env.REACT_APP_API_URL}/api/v1/datasets/get-dataset-contents/${id}/?page=${page.toString()}&rows_per_page=${rowsPerPage ?? 10}&filename=${filename}&dataset_filename=${fileInDataset}&h5_key=${h5_key}`
        //   break;
      }
      if (!url) {
        throw new Error("No url was provided for query.");
      }
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || `There was an error fetching dataset ID ${id}'s contents from the database.`);
      }
      return res.json();
    },
    enabled: !!id,
    // enabled: false,
  });
}