import React from "react";
import classNames from "classnames";
import css from "./styles.module.css"

type SpinnerProps = {
  tag?: string;
  className?: string;
  size?: "lg" | "md" | "sm";
  variant?: "bordered" | "grow" | "gradient";
  color?: string;
};

export function Spinner(props: SpinnerProps) {
  const color = props.color || "secondary";
  const size = props.size || "";

  return (
    <div
      role="status"
      className={classNames(
        { [css.spinnerGradient]: props.variant === "gradient" },
        [`text-${color}`],
        { [`avatar-${size}|`]: size },
        props.className
      )}
    />
  );
};

Spinner.defaultProps = {
  variant: "gradient",
};

