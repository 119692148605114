import { defaultSommerfeldBCSettings } from "modulus-interop/equations/electromagnetic";
import { SommerfeldBCType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Col, Row } from "react-bootstrap";
import { useReactFlow } from "reactflow";

export function SommerfeldBCSettings({ data }: { data: ModulusComponentDataCommon & SommerfeldBCType }) {
  const { setNodes } = useReactFlow();

  const handleSettingChange = (key: string, value: any) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            [key]: value,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row className="mt-2 mb-2">
        <Col>
          <small>Perfect Electric Conduct</small>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>ux</h5>
          <input
            type="text"
            className="form-control"
            defaultValue={data.ux ?? defaultSommerfeldBCSettings.ux}
            onChange={(e) => handleSettingChange("ux", e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>uy</h5>
          <input
            type="text"
            className="form-control"
            defaultValue={data.uy ?? defaultSommerfeldBCSettings.uy}
            onChange={(e) => handleSettingChange("uy", e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>uz</h5>
          <input
            type="text"
            className="form-control"
            defaultValue={data.uz ?? defaultSommerfeldBCSettings.uz}
            onChange={(e) => handleSettingChange("uz", e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
}
