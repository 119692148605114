import { useEffect, useRef } from "react";

import { signallingServerAddress, commands } from "./../constants";

import { Environment } from "types";

import {
  Config,
  AllSettings,
  PixelStreaming,
  Flags,
  NumericParameters,
} from "@epicgames-ps/lib-pixelstreamingfrontend-ue5.4";
import {
  Application,
  UIElementCreationMode,
  PixelStreamingApplicationStyle,
} from "@epicgames-ps/lib-pixelstreamingfrontend-ui-ue5.4";

export interface PixelStreamingWrapperProps {
  initialSettings?: Partial<AllSettings>;
}
const PixelStreamingApplicationStyles = new PixelStreamingApplicationStyle();

export function usePixelStreaming({
  environment,
  setAppVersion,
  setMinMaxMagnitude,
  updateLs,
  setLogHistory,
}: {
  environment: Environment;
  setAppVersion: any;
  setMinMaxMagnitude: any;
  updateLs: any;
  setLogHistory: any;
}) {
  let application = useRef<any>();
  const videoParent = useRef<HTMLDivElement>(null);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";
  useEffect(() => {
    PixelStreamingApplicationStyles.applyStyleSheet();
    const wsUrl = environment?.url?.replace("https", "wss");
    const ss = wsUrl && `${wsUrl}/pixelstreaming/`;
    // const ss = signallingServerAddress;
    const initialSettings = {
      AutoConnect: true,
      AutoPlayVideo: true,
      ss,
      StartVideoMuted: true,
      HoveringMouse: true,
      XRControllerInput: false,
      WaitForStreamer: true,
      MatchViewportRes: true,
      HoveringMouseMode: true,
      // StreamerId: "Camera1",
    };
    const config = new Config({ initialSettings, useUrlParams: true });
    // config.setFlagEnabled(Flags.AFKDetection, true);
    // config.setNumericSetting(NumericParameters.AFKTimeoutSecs, 300);
    const stream = new PixelStreaming(config);

    application.current = new Application({
      stream: stream,
      onColorModeChanged: (isLightMode) => PixelStreamingApplicationStyles.setColorMode(isLightMode),
      settingsPanelConfig: {
        isEnabled: false,
        visibilityButtonConfig: { creationMode: UIElementCreationMode.UseCustomElement },
      },
      fullScreenControlsConfig: {
        creationMode: UIElementCreationMode.UseCustomElement,
        customElement: document.getElementById("myCustomFullscreenButton"),
      },
      xrControlsConfig: {
        creationMode: UIElementCreationMode.UseCustomElement,
        customElement: document.getElementById("myCustomXRButton"),
      },
      videoQpIndicatorConfig: {
        disableIndicator: true,
      },
      statsPanelConfig: {
        isEnabled: false,
        visibilityButtonConfig: { creationMode: UIElementCreationMode.UseCustomElement },
      },
    });
    application.current.stream.addEventListener("videoInitialized", onVideoInitialized);
    application.current.stream.addResponseEventListener("fu", handleIncomingMessages);
    const ws = application.current.stream.webSocketController.webSocket;

    videoParent.current.appendChild(application.current.rootElement);
    return () => {
      videoParent?.current?.removeChild(application.current.rootElement);
      application?.current.stream.removeEventListener("videoInitialized", onVideoInitialized);
      application?.current.stream.removeResponseEventListener("fu", handleIncomingMessages);
    };
  }, []);

  function handleIncomingMessages(e: any) {
    if (typeof e !== "object" && !e.includes("webpack")) {
      const message = e.split(" ");
      const time = new Date().toLocaleTimeString();
      message.unshift(time);
      setLogHistory((logs: any) => [...logs, message]);
      if (message[1] === "minMaxMagnitude" || message[1] === "getAdditionalData_ok") {
        const jsonData = JSON.parse(e.substring(e.indexOf("{"), e.lastIndexOf("}") + 1));
        const min = parseFloat(jsonData.minMagnitude);
        const max = parseFloat(jsonData.maxMagnitude);
        const appVersion = jsonData.appVersion;
        appVersion && setAppVersion(`v${appVersion}`);
        if (min === 0 && max === 0) return;
        updateLs({ minMagnitude: min, maxMagnitude: max, appVersion: `v${appVersion}` });
        setMinMaxMagnitude([min, max]);
      }
    }
  }

  function onVideoInitialized() {
    const data = {
      type: "message",
      event: commands.onInit,
      payload: {
        empty: true,
        wss: environment?.url?.replace("https", "wss"),
      },
    };
    setTimeout(() => sendMessage(data), 500);
  }

  const sendMessage = (message: any) => {
    application?.current?.stream.emitUIInteraction(message);
    if (isProduction) {
      console.log("Sent to Unreal:  " + JSON.stringify(message));
    }
  };

  const getApplication = () => {
    return application.current;
  };

  return { videoParent, sendMessage, getApplication };
}
