import { useQuery } from "@tanstack/react-query";
import { StoriesResponse, UniversityStoriesResponse } from "types/academy";

export function useQueryRecentArticles() {
  const storyblokApiToken = process.env.REACT_APP_STORYBLOK_API_TOKEN;
  return useQuery<StoriesResponse, Error>({
    queryKey: ["academy-blogposts"],
    queryFn: async () => {
      const res = await fetch(
        `https://api.storyblok.com/v2/cdn/stories?starts_with=blog/&sort_by=first_published_at:desc&per_page=6&version=published&token=${storyblokApiToken}`,
        {}
      );
      if (!res.ok) {
        throw new Error("There was an error fetching blogposts.");
      }
      return res.json();
    },
  });
}

export function useQueryRecentUniversityPosts() {
  const storyblokApiToken = process.env.REACT_APP_STORYBLOK_API_TOKEN;
  return useQuery<UniversityStoriesResponse, Error>({
    queryKey: ["academy-universityposts"],
    queryFn: async () => {
      const res = await fetch(
        `https://api.storyblok.com/v2/cdn/stories?starts_with=university/&is_startpage=0&sort_by=first_published_at:desc&per_page=6&version=published&token=${storyblokApiToken}`,
        {}
      );
      if (!res.ok) {
        throw new Error("There was an error fetching university posts.");
      }
      return res.json();
    },
  });
}
