import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { Simulator } from "types";

type SimulatorsListResponse = {
  count: number;
  next: any;
  previous: any;
  results: Simulator[];
};

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useQuerySimulators(page?: number, limit?: number) {
  const { getAccessTokenSilently } = useAuth0();
  page = page ?? 1;
  limit = limit ?? 15;
  return useQuery<SimulatorsListResponse, Error>({
    queryKey: ["simulators"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulators/?page=${page}&offset=${page === 1 ? 0 : (limit * (page - 1))}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching datasetsfrom the database.");
      }
      return res.json();
    },
    refetchOnWindowFocus: false,
  });
}

export function useQuerySimulator(id: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Simulator, Error>({
    queryKey: ["simulator", id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulators/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error fetching simulator with ID ${id} from the database.`);
      }
      return res.json();
    },
    enabled: !!id,
  });
}
