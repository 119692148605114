import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export function useDeleteModel() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<void, Error, string>({
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulatormodels/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || "There was an error deleting the model.";
          throw new Error(errorMsg);
        } catch (err) {
          throw new Error("There was an error deleting the model.");
        }
      }
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      queryClient.invalidateQueries({ queryKey: ["modelsforsimulators"] });
      toast.success("Model deleted successfully.", {
        theme: "dark",
      });
    },
  });
}
