const parseEquationArgs = (equation: string) => {
  const trimmedEquation = equation?.replace(/\n/g, '');
  const equationArgsPart = trimmedEquation?.split('def __init__(')[1];
  const equationArgs = equationArgsPart?.split(')')[0];

  const extracted = equationArgs?.split(', ').filter((arg) => !arg.includes("self")).map((arg) => {
    const argName = arg.split('=')[0];
    const argValue = arg.split('=')[1];
    return {
      argName: argName,
      argValue: argValue,
    }
  });
  return extracted
}

export { parseEquationArgs };