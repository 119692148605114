import type { Simulator, SimulatorCreate, SimulatorUpdate } from "types";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useCreateSimulator() {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Simulator, Error, SimulatorCreate>({
    mutationFn: async (simulator: SimulatorCreate) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulators/`, {
        method: "POST",
        body: JSON.stringify(simulator),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorRes = await res.json();
        const errorMsg = errorRes?.error || errorRes?.label[0] || "There was an error creating a new simulator."
        throw new Error(errorMsg);
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      const errorMsg = error.message;
      const capitalisedErrMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
      toast.error(capitalisedErrMsg, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (data: Simulator, __, ___) => {
      toast.success("Simulator created successfully.", {
        theme: "dark",
      });

      return navigate(`/model-engineer/simulators/${data.id}`, { replace: true });
    },
  });
}

export function useUpdateSimulator() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Simulator, Error, SimulatorUpdate>({
    mutationFn: async (simulator: Partial<SimulatorUpdate>) => {
      console.log(simulator)
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulators/${simulator.id}/`, {
        method: "PATCH",
        body: JSON.stringify(simulator),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || "There was an error updating the simulator."
          throw new Error(errorMsg);
        } catch(err) {
          throw new Error("There was an error updating the simulator.");
        }
      }
      return res.json();
    },
    onMutate: async (simulator: Partial<SimulatorUpdate>) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['simulator', simulator.id] })

      // Snapshot the previous value
      const previousSimulator = queryClient.getQueryData(['simulator', simulator.id])

      // i had to comment this out, because it was setting simulator to be composed only of id, label, and desc
      // therefore geometry viewer crashed as there was no geometries in modulus_components
      // Optimistically update to the new value
      // queryClient.setQueryData(['simulator', simulator.id], simulator)

      // Return a context with the previous and new simulator
      return { previousSimulator, simulator }
    },
    onError: (error, __, context: any) => {
      queryClient.setQueryData(
        ['simulator', context.simulator.id],
        context.previousSimulator,
      )
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
      sessionStorage.setItem("sim-save-error", "true");

      // TODO: probably return some error to the component (state from props???)
      // so it can revert saving that node (particularly with custom equations)
    },
    onSuccess: (data: Simulator, __, ___) => {
      toast.success("Simulator updated successfully.", {
        theme: "dark",
      });
      sessionStorage.removeItem("sim-save-error");
      localStorage.removeItem("nodes-data");
      queryClient.setQueryData(["simulator", { id: data.id }], data);
    },
    // Always refetch after error or success:
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: ['simulator', data.id] })
    },
  });
}

export function useDeleteSimulator() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Simulator, Error, string>({
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulators/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || "There was an error deleting the simulator."
          throw new Error(errorMsg);
        } catch(err) {
          throw new Error("There was an error deleting the simulator.");
        }
      }

      // as this function needs to return some json data, but server returns nothing,
      // we need to catch error from res.json() (it will throw one, because it cannot parse nothing) and return empty object
      let resJson;
      try {
        resJson = await res.json();
      } catch (e) {
        resJson = {};
      }

      return resJson;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      queryClient.invalidateQueries({ queryKey: ["simulators"] });
      toast.success("Simulator deleted successfully.", {
        theme: "dark",
      });
      return navigate("/model-engineer/simulators", { replace: true });
    },
  });
}
