import { Link } from "react-router-dom";
import { Table, Form, Pagination } from "react-bootstrap";
import { Dataset } from "types";
import prettyBytes from "pretty-bytes";
import { useDeleteDataset } from "mutations";
import UpdateDatasetModal from "../EditDatasetModal";
import { useState } from "react";
import { isDevelopment } from "lib/env";
import { useQueryDatasets } from "queries";
import { useAuth0 } from "@auth0/auth0-react";

type RecentProps = {
  datasets: any;
};

export const DatasetsTable = ({ datasets }: RecentProps) => {
  const deleteMutation = useDeleteDataset();
  const [editModalState, setEditModalState] = useState({
    active: false, 
    dataset: undefined,
  })

  isDevelopment && console.log(datasets);
  const { user } = useAuth0();

  const { refetch } = useQueryDatasets();

  return (
    <div className="mt-3">
      {editModalState.dataset && <UpdateDatasetModal 
        setState={setEditModalState}
        state={editModalState.active}
        dataset={editModalState.dataset}
      />}
      <Table responsive={true} className="table table-centered table-nowrap">
        <thead>
          <tr>
            <th>Name <i className="mdi mdi-menu-down"></i></th>
            <th>Format <i className="mdi mdi-menu-down"></i></th>
            <th>Size <i className="mdi mdi-menu-down"></i></th>
            <th>Last Modified <i className="mdi mdi-menu-down"></i></th>
            <th>Public <i className="mdi mdi-menu-down"></i></th>
            <th>Owner <i className="mdi mdi-menu-down"></i></th>
            <th>Action <i className="mdi mdi-menu-down"></i></th>
          </tr>
        </thead>

        <tbody>
          {datasets &&
            datasets!.map((dataset: Dataset) => {
              return (
                <tr key={"dataset-" + dataset.id}>
                  <td className="name">
                    <span>
                      <i className="mdi mdi-star-outline"></i>
                      {/* <i className="mdi mdi-star"></i>{dataset.label} */}
                      <Link to={`/model-engineer/datasets/${dataset.id}`} className="text-reset">
                        {dataset.label}
                      </Link>
                    </span>
                  </td>
                  <td className="format">
                    <span>{dataset.format}</span>
                  </td>
                  <td className="size">
                    {prettyBytes(dataset.size!)}
                  </td>
                  <td className="last-modified">
                    <span>{new Date(dataset.updated_at).toLocaleString()}</span>
                  </td>
                  <td className="public">
                    <span>                  
                      {dataset.public ? "Yes" : "No"}
                    </span>
                  </td>
                  <td className="owner">
                    <span>
                      {dataset.owner === user?.sub ? "My own" : "Shared"}
                    </span>
                  </td>
                  <td className="action">
                    <span>
                      <i className="mdi mdi-share"></i>
                      <i className="mdi mdi-information"></i>
                      <i onClick={() => setEditModalState({
                        active: true,
                        dataset: dataset
                      })} className="mdi mdi-pencil"></i>
                      <i onClick={() => {
                          deleteMutation.mutate(dataset.id, {
                            onSuccess: () => {
                              refetch();
                            }
                          })
                        }} 
                        className="mdi mdi-delete"></i>
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <Pagination className="pagination-custom">
        <Pagination.Prev />
        <Pagination.Item active>{1}</Pagination.Item>
        <Pagination.Item>{2}</Pagination.Item>
        <Pagination.Ellipsis />
        <Pagination.Item>{20}</Pagination.Item>
        <Pagination.Next />
      </Pagination>
    </div>
  );
};
