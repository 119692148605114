import { Row, Col } from "react-bootstrap";

// components
import { PageTitle } from "components/PageTitle";
import { LatestActivity } from "../LatestActivity";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { QuickStart } from "../QuickStart";
import { useQueryEnvironments } from "queries";
import { useChangelogOpenStore } from "hooks";
import ChangelogPopup from "layouts/LeftSidebar/BottomSidebarContent/ChangelogPopup";
import { changelogItems } from "constants/changelog";
import { CostWrapper } from "./CostWrapper";
import { ArticlesOverview } from "components/Academy/ArticlesOverview";
import { UniversityOverview } from "components/Academy/UniversityOverview";

export const OverviewPage = () => {
  const { user } = useAuth0();

  useEffect(() => {
    process.env.REACT_APP_ENVIRONMENT !== "production" && console.log(user);
  }, [user]);

  const { status, isError: isEnvironmentsError, data: environments, error: environmentsError } = useQueryEnvironments();

  const { isOpen, toggle, toggleRead } = useChangelogOpenStore();

  useEffect(() => {
    if (!localStorage.getItem("first-visit")) {
      toggle();
      const saving = {
        version: changelogItems.version,
        firstVisit: false,
      };
      localStorage.setItem("first-visit", JSON.stringify(saving));
    }
    interface FirstVisit {
      version: string;
      firstVisit: boolean;
    }
    const firstVisit: FirstVisit = JSON?.parse(localStorage?.getItem("first-visit") || "{}");
    if (firstVisit.version !== changelogItems.version) {
      toggle();
      const saving = {
        version: changelogItems.version,
        firstVisit: false,
      };
      localStorage.setItem("first-visit", JSON.stringify(saving));
    }
  }, []);

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Overview", path: "/model-engineer/overview", active: true }]}
        title={"Overview"}
      />

      <Row>
        <Col xs={12} xl={6}>
          <Row>
            <Col lg={12}>
              <QuickStart />
            </Col>
            <Col lg={12}>
              <LatestActivity environments={environments} />
            </Col>
            <Col lg={12}>
              <UniversityOverview />
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={6}>
          <Row>
            <Col lg={12}>
              <ArticlesOverview />
            </Col>
            <Col lg={12}>
              <CostWrapper />
            </Col>
          </Row>
        </Col>
      </Row>
      <ChangelogPopup isOpen={isOpen} toggle={toggle} toggleRead={toggleRead} />
    </>
  );
};
