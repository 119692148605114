import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Handle, Position, useNodeId, useReactFlow } from "reactflow";
import TeX from "@matejmazur/react-katex";
import { useVisualUIStore } from "components/VisualUI/store";
import { equationDefaultSettings } from "modulus-interop/equations";

import "./pde-node.css";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import CustomEquationPopUp from "./customEquationComponents/CustomEquationPopUp";
import { isDevelopment } from "lib/env";
interface PDEOption {
  readonly value: string;
  readonly label: string;
}

const basicPdeOptions: readonly PDEOption[] = [
  { value: "curl", label: "Curl" },
  { value: "grad_normal", label: "Gradient boundary condition" },
  { value: "normal_dot_vec", label: "Normal dot vector" },
];

const navierStokesOptions: readonly PDEOption[] = [
  { value: "navier_stokes", label: "Navier Stokes" },
  { value: "compressible_integral_continuity", label: "Compressible Integral Continuity" },
  { value: "flux_continuity", label: "Flux Continuity" },
];

const turbulenceOptions: readonly PDEOption[] = [{ value: "turbulence_zero_eq", label: "Zero equation" }];

const linearElasticityOptions: readonly PDEOption[] = [
  { value: "linear_elasticity", label: "Linear elasticity" },
  { value: "linear_elasticity_plane_stress", label: "Linear elasticity plane stress" },
];

const diffusionOptions: readonly PDEOption[] = [
  { value: "diffusion", label: "Diffusion" },
  { value: "diffusion_interface", label: "Diffusion interface conditions" },
  { value: "advection_diffusion", label: "Advection diffusion" },
];

const waveOptions: readonly PDEOption[] = [
  { value: "wave_equation", label: "Wave equation" },
  { value: "helmholtz_equation", label: "Helmholtz equation" },
];

const electromagneticsOptions: readonly PDEOption[] = [
  { value: "maxwell_freq_real", label: "Maxwell's equation" },
  { value: "pec", label: "Perfect Electric Conduct" },
  { value: "sommerfeld_bc", label: "Sommerfeld radiation condition" },
];

const groupedOptionsOutside = [
  {
    label: "Custom equation",
    options: [
      {
        value: "custom",
        label: "Create new custom equation",
      },
    ],    
  },
  {
    label: "Basic",
    options: basicPdeOptions,
  },
  {
    label: "Navier-Stokes",
    options: navierStokesOptions,
  },
  {
    label: "Turbulence",
    options: turbulenceOptions,
  },
  {
    label: "Diffusion",
    options: diffusionOptions,
  },
  {
    label: "Elasticity",
    options: linearElasticityOptions,
  },
  {
    label: "Wave equations",
    options: waveOptions,
  },
  {
    label: "Electromagnetics",
    options: electromagneticsOptions,
  },
];

const allOptionsOutside = [
  ...basicPdeOptions,
  ...navierStokesOptions,
  ...turbulenceOptions,
  ...linearElasticityOptions,
  ...diffusionOptions,
  ...waveOptions,
  ...electromagneticsOptions,
];

export function PDENode({ data }: { data: any }) {
  const allOptions = [
    ...allOptionsOutside,
    data.custom_equation_label ? { value: "custom", label: data.custom_equation_label } : { value: "", label: "" },
  ]
  const groupedOptions = [
    ...groupedOptionsOutside,
    data.custom_equation_label ? { label: "Custom equations list", options: [{ value: "custom", label: data.custom_equation_label }] } : { label: "", options: [] },
  ]

  const nodeId = useNodeId();
  const { setNodes, deleteElements, getNode, getNodes } = useReactFlow();
  const { setSidebarNodeId } = useVisualUIStore();
  const [showLatexView, toggleLatexView] = useState(false);
  
  const [equation, setEquation] = useState(allOptions.find((o) => o.value === data.mode) ?? { value: "", label: "" });
  const [showEquationView, toggleEquationView] = useState(false);

  const handleSetEquation = (option: { value: string; label: string }) => {
    setEquation(option);

    const settings = {
      ...equationDefaultSettings[option.value],
      mode: option.value,
      id: nodeId
    };

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...settings,
          };
        }

        return node;
      })
    );
  };

  const handleCloseLatexView = () => toggleLatexView(false);
  const handleShowLatexView = () => toggleLatexView(true);

  const handleShowEquationView = () => toggleEquationView(true);
  const handleCloseEquationView = () => toggleEquationView(false);

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...data,
          };
        }

        return node;
      })
    );
  }, [data.sympy_equations]);

  useEffect(() => {
    if (showEquationView) {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            const snapshotData = node.data;
            node.data = {
              ...data,
              data_snapshot: snapshotData,
            }
          }

          return node;
        })
      );
    } else {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            const { data_snapshot, ...newData } = node.data;
            node.data = {
              ...newData,
            }
          }
          return node;
        })
      );
    }
  }, [showEquationView])
  
  return (
    <div className="equation-node" data-tour='tour_equation_node'>
      <div className="title">
        <div className="node-icons">
          {data.sympy_equations && (
            <Tooltip>
              <TooltipTrigger>
                <i className="mdi mdi-function-variant" onClick={handleShowLatexView} />
              </TooltipTrigger>
              <TooltipContent className="Tooltip">Show LaTeX equations</TooltipContent>
            </Tooltip>
          )}
          {/* <i className="dripicons-gear" onClick={() => setSidebarNodeId(nodeId)} /> */}
          <i
            className="dripicons-cross"
            onClick={() => {
              deleteElements({ nodes: [{ id: nodeId }] });
              setSidebarNodeId("");
            }}
          />
        </div>
        <span>Equation</span>
      </div>

      <div className="content" onClick={(event) => {
          const node = getNode(nodeId);
          node.selected = true;

          const nodes = getNodes();
          nodes.forEach((n) => {
            if (n.id !== nodeId) {
              n.selected = false;
            }
          });
          setNodes((nds) => nodes.map((n) => n));
      }}>
        <Row>
          <Col style={{
            width: "100%",
            textAlign: "center",
          }}>
            <p 
              style={{
                fontWeight: "lighter",
                fontSize: "0.7rem",
                marginBottom: "0.2rem",
              }}
              className="text-secondary"
            >
              Selected equation:
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{
            width: "100%",
            textAlign: "center",
          }}>
            <p 
              style={{fontWeight: "lighter"}}
            >
              {/* if its custom equation use label from there, otherwise use equation label or display that there isnt any yet */}
              {data.mode === "custom" ? data.custom_equation_label : (equation.label ? equation.label : "No equation selected")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button 
              variant="primary" 
              className="btn btn-primary" 
              style={{color: "#B4B6C3", padding: "10px 5px", width: "100%", fontWeight: "lighter"}}
              onClick={handleShowEquationView}
              id="tour_button_change_equation"
            >
              Change equation
            </Button>
          </Col>
        </Row>
      </div>

      <Handle type="source" position={Position.Bottom} id="nn" />

      <Modal show={showLatexView} onHide={handleCloseLatexView} className="equation-latex-modal">
        <Modal.Header closeButton>
          <Modal.Title>Equations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data &&
            data.latex_equations &&
            Object.entries(data.latex_equations).map(([variable, eq]) => (
              <div key={variable}>
                <strong>{variable}:</strong>
                <TeX block={true}>{eq}</TeX>
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLatexView}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <CustomEquationPopUp 
        data={data}
        groupedOptions={groupedOptions}
        equation={equation}
        setEquation={setEquation} // this only saves equation, mechanism for saving custom equations is different
        handleSetEquation={handleSetEquation} // ^^ these two are NOT the same -> this saves predefined equation directly
        showEquationView={showEquationView}
        handleCloseEquationView={handleCloseEquationView} 
      />
    </div>
  );
}