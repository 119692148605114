import classNames from "classnames";
import css from "../styles.module.css";
import { Environment } from "types";
import { RunningCostCounter } from "components/RunningCostCounter";
import { cloudTiers } from "cloud-settings";
import { Debugger } from "components/PixelStreamingDebugger";
import { Row, Col } from "react-bootstrap";

type LeftBottomPanelProps = {
  environment: Environment;
  appVersion: string;
  logs: any;
};

export default function LeftBottomPanel({ environment, appVersion, logs }: LeftBottomPanelProps) {
  let stateIcon = "🟢";
  if (environment?.state === "starting") {
    stateIcon = "🟡";
  } else if (environment?.state === "stopped") {
    stateIcon = "🔴";
  }

  return (
    <div className={classNames(css.leftBottomPanel, "d-flex flex-column font-10")}>
      <div>
        Environment: {environment.label} {stateIcon}
      </div>
      <div>
        <strong>Server type: </strong>
        <span>{cloudTiers[environment?.server_type]?.label}</span>
      </div>
      <RunningCostCounter environment={environment} />
      <Row className={"mt-1"}>
        <Col>
          <div>Simulation Studio {appVersion}</div>
        </Col>
        <Col className="col-4 ms-auto">
          <Debugger logs={logs} />
        </Col>
      </Row>
    </div>
  );
}
