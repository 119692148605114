import { Simulator } from "types";

export function reorderObjects(cacheObj: { [key: string]: object }, obj: { [key: string]: object, length: any }, lookupKey: string) {
    if (Object.keys(obj).length === 0) return {};
    if (cacheObj[lookupKey] === undefined) return obj;
    const index = Object.values(obj).indexOf(cacheObj[lookupKey]);
    const { [Object.keys(obj)[index]]: selectedItem, ...rest } = obj;
    const selectedValue = Object.keys(obj).find(name => obj[name] === selectedItem);
    return { [selectedValue]: selectedItem, ...rest };
  }

export function compileSelectedParameters(simulator: Simulator, controls: any) {
    const selectedParameters: Record<string, number> = {};

    Object.keys(simulator.modulus_components.variable_parameters.equations).forEach((key: string) => {
      selectedParameters[key] = controls[key];
    });
    Object.keys(simulator.modulus_components.variable_parameters.inputs).forEach((key: string) => {
      selectedParameters[key] = controls[key];
    });
    return selectedParameters;
  };

  export function processGeometries(simulators: any, baseDataPath: string) {
    var geometries: any = {};
    simulators[0] &&
      simulators[0].modulus_components &&
      Object.entries(simulators[0].modulus_components.geometries).map(([key, value] : any) => {
        const label = value.data.label;
        const path = label.replace(".stl", ".fbx");
        return (geometries[path] = baseDataPath + `/${path}`);
      });
    return geometries;
  }