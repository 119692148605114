import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import prettyBytes from "pretty-bytes";

type GPUUsedMemoryChartProps = {
  data: number[][];
  maxValue?: number;
};

export const GPUUsedMemoryChart = ({data, maxValue}: GPUUsedMemoryChartProps) => {

  const apexChartOpts: ApexOptions = {
    chart: {
      type: "area",
      height: 400,
    },
    colors: ["#2c8ef8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return prettyBytes(value);
        },
      },
      min: 0,
    },
    tooltip: {
      x: {
        format: "HH:mm:ss",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0,
        colorStops: [
          {
            offset: 0,
            color: "#2c8ef8",
            opacity: 1
          },
          {
            offset: 50,
            color: "#2c8ef8",
            opacity: 0.5
          },
          {
            offset: 100,
            color: "#2c8ef8",
            opacity: 0
          }
        ]
      },
    },
  };

  if (maxValue) {
    (apexChartOpts.yaxis as any).max = maxValue;
  }

  return (
    <Card>
      <Card.Body>
        <div className="chartjs-chart" style={{ height: "400px" }}>
          <Chart
            options={apexChartOpts}
            series={[
              {
                data
              }
            ]}
            type="area"
            className="apex-charts"
            height={400}
          />
        </div>
      </Card.Body>
    </Card>
  );
};
