import { Button, Col, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useSubscriptionCheckoutSession } from "mutations";
import { useSubscriptionsPricesList } from "queries/billing";

export type PricingPlan = {
  id: string;
  name: string;
  price?: string;
  contactSales?: boolean;
  features: string[][];
  isRecommended: boolean;
};

type PricingCardProps = {
  duration: string,
  discountCode?: string;
};

const PricingCard = ({ duration, discountCode }: PricingCardProps) => {
  const { user } = useAuth0();
  const subscriptionCheckoutStripe = useSubscriptionCheckoutSession();

  const { status, isError: isPricesError, data: prices, error: pricesError } = useSubscriptionsPricesList();

  if (status === "loading") {
    return <span>Loading...</span>;
  }

  if (isPricesError) {
    return <span>Error loading the prices data: {pricesError}</span>;
  }

  if (!prices) {
    return <span>You don't have any available prices.</span>;
  }

  console.log(prices)

  return (
    <div className="container-cards">
      <Row>
        {prices?.data?.filter((price: any) => price.recurring.interval === duration)
          .sort((a: any, b: any) => a.unit_amount - b.unit_amount)
          .map((price: any, index: number) => (
            <Col sm="3" className="card" key={index}>
              <span className="title">
                {price.metadata?.title.toUpperCase() ?? price.nickname.toUpperCase()}
              </span>

              <span className="price">
                {price.unit_amount / 100} {price.currency.toUpperCase()} <i> / {duration}</i>
              </span>

              <div className="features">
                {price.metadata?.features.split("\n").map((feature: string, i: number) => <span key={`feature-${i.toString()}`} className="feature">{feature}</span>)}
              </div>
              <Button
                className="btn blue btn-primary btn-rounded"
                onClick={() => subscriptionCheckoutStripe.mutate({ customerId: user.customer_id, planId: price.id, discountCode: discountCode })}>
                Choose Plan
              </Button>
            </Col>
          ))}
        <Col sm="3" className="card">
          <span className="title">
            {"Enterprise".toUpperCase()}
          </span>

          <span className="price empty">
            <i>Have a bigger challenge to solve?</i>
          </span>

          <div className="features">
            <span className="feature">All features from Pro</span>
            <span className="feature">Custom computing resources with tens of GPUs</span>
            <span className="feature">24/7 support</span>
            <span className="feature">Custom simulator development</span>
          </div>
          <a href="mailto:sales@siml.ai"
            className="btn blue btn-primary btn-rounded"
          >
            Contact us
          </a>
        </Col>
      </Row>
    </div>
  );
};

export { PricingCard };
