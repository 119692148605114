import { useState, Suspense } from "react";
import { PixelStreamingEditor } from "components/PixelStreamingEditor";

import { ScrollToTop } from "components/ScrollToTop";
import { Link, Outlet, useParams } from "react-router-dom";

// import styles
import css from "./styles.module.css";
import { useQueryProject } from "queries/projects";
import { PageTitle } from "components/PageTitle";
import { Anchor, Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import {
    useDeleteProject,
    useDestroyProjectInAWS,
    useStartProjectInAWS,
    useStopProjectInAWS,
} from "mutations/projects";
import { useTranslation } from "react-i18next";
import { cloudTiers } from "cloud-settings";
import { Spinner } from "components/Spinner";
import SimlLoader from "components/SimlLoader";
import { environment } from "lib/env";

const loading = () => <div className="" />;

export const SingleProjectPage = () => {
    let { id } = useParams();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const deleteMutation = useDeleteProject();
    const destroyAWSServer = useDestroyProjectInAWS();
    const [loadingCloud, setLoadingCloud] = useState(false);

    const { status, isError: isProjectError, data: project, error: projectError } = useQueryProject(id!);

    if (status === "loading") {
        return <SimlLoader />;
    }

    if (isProjectError) {
        return <span>Error loading the project data: {projectError}</span>;
    }
    if (!project) {
        return <span>project data not found.</span>;
    }

    const isExternal = project?.cloud_provider === "external";
    const isDeployed = project!.aws_instance_id || project!.paperspace_id;
    const isRunning = isDeployed && project!.state === "running";
    const isStarting = isDeployed && project!.state === "starting";
    const isStopping = project!.state === "stopping";
    const isStopped = project!.state === "stopped";

    console.log(id);

    /*
     * Handle clicking on archive button
     */
    const handleArchive = (e: any) => {
        e.preventDefault();

        // Step 0: Let the user know how many projects depend on this Environment and what is the archiving process
        if (window.confirm(`${t("Do you really want to destroy the project")} "${project!.label}"?`)) {
            // Step 1: Start gracefully destroying the cloud infrastructure (deployed servers, etc.)
            if (project?.cloud_provider === "aws" && project?.aws_instance_id) {
                setLoadingCloud(true);
                destroyAWSServer.mutateAsync(project!).then(() => {
                    setLoadingCloud(false);
                    queryClient.invalidateQueries(["project", { id: project?.id! }]);
                });
            }
            // Step 2: Show the progress bar while the graceful shutdown is in process.

            // Step 3: Remove environment from the DB
            deleteMutation.mutate(project.id!);
        }
    };

    const DeleteContainer = () => (
        <Dropdown className="edit-container">
            <Dropdown.Toggle variant="link" as="a" className="card-drop arrow-none cursor-pointer p-0 shadow-none">
                <i className="dripicons-dots-3" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as={Anchor} onClick={handleArchive}>
                    <i className="mdi mdi-delete me-1" />
                    Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    console.log(project);

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="enviroment-single my-2 cursor-pointer p-0">
                                <Link to={`/model-engineer/environments/${project?.id}`}>
                                    <i className="mdi mdi-subdirectory-arrow-left me-1" />
                                    Go to environment
                                </Link>
                                <a href="#" onClick={handleArchive} className="ms-3">
                                    <i className="mdi mdi-delete me-1" />
                                    Delete environment
                                </a>
                            </div>
                            <div>
                                <Suspense fallback={loading()}>
                                    <Outlet />
                                    <ScrollToTop />
                                    <PixelStreamingEditor environment={project} />
                                </Suspense>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
