import { useAuth0 } from "@auth0/auth0-react";
import SimulatorChartStats from "components/Charts/simulatorChartStats";
import { SingleEnvironmentProps } from "pages/dashboard/environments/EnvironmentCard";
import { useSubscription } from "queries";
import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Environment, Simulator } from "types";

export const LatestActivity = ({environments}: any) => {
  const [modelEngineerCount, setModelEngineerCount] = React.useState(0);
  const [simulatorCount, setSimulatorCount] = React.useState(0);
  const [trainedSimulators, setTrainedSimulators] = React.useState<any[]>();
  const { user } = useAuth0();
  const subscription = useSubscription(user.customer_id);

  useEffect(() => {
    const getModelEngineerCount = () => {
      let modelEngineerCount = 0;
      let simulatorCount = 0;
  
      if( environments && environments.results ) {
        environments.results.map((environment: any) => {
          if (environment.mode == "model_engineer" && environment.state == "running") {
            modelEngineerCount++;
          }
          if (environment.mode == "simulation_studio" && environment.state == "running") {
            simulatorCount++;
          }
        });
      }
      
      const response = {
        modelEngineerCount: modelEngineerCount,
        simulatorCount: simulatorCount
      }
  
      return response;
    }
    
    const response = getModelEngineerCount();
    setModelEngineerCount(response.modelEngineerCount);
    setSimulatorCount(response.simulatorCount);
  }, [environments])

  useEffect(() => {
    const getTrainedSimulators = () => {
      let newTrainedSimulators: any[] = [];
      if (environments && environments.results) {
        newTrainedSimulators = environments.results.filter((environment: any) => {
          if (environment.simulators.length != 0) {
            const maxSteps = environment.simulators[0].modulus_components.conf.training.max_steps;
            const sortedTelemetry = environment.trainingtelemetry.sort((a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
            const lastStep = sortedTelemetry[sortedTelemetry.length - 1]?.step;
  
            if ( (lastStep / maxSteps) >= 1) {
              return environment;
            } else {
              return null;
            }
          } else {
            return null;
          }
        });
      }
      
      setTrainedSimulators(newTrainedSimulators);
    }

    getTrainedSimulators();
  }, [environments])

  const monthlyPlans = subscription?.data?.map((s: any) => s)?.filter((s: any) => s?.plan?.metadata?.title);
    let tempPlan = "";
    if (monthlyPlans?.length > 0) {
      tempPlan = monthlyPlans[monthlyPlans?.length - 1]?.plan?.metadata?.title ?? "";
    }
  const currentPlan = tempPlan;

  const limitForWhatWasReached = () => {
    if (currentPlan === "Pro") return "none";

    if (modelEngineerCount >= getMaxAllowedForPlan("model-engineer") && simulatorCount >= getMaxAllowedForPlan("simulation-studio")) {
      return "both";
    }

    if (modelEngineerCount >= getMaxAllowedForPlan("model-engineer")) {
      return "models";
    }

    if (simulatorCount >= getMaxAllowedForPlan("simulation-studio")) {
      return "projects";
    }

    return "none";
  }

  const getMaxAllowedForPlan = (mode: string) => {
    switch (currentPlan) {
      case "": {
        return mode === "model-engineer" ? 1 : 1;
      }
      case "Starter": {
        return mode === "model-engineer" ? 20 : 20;
      }
      case "Pro": {
        return 999;
      }
      case "Enterprise": {
        return 999;
      }
      default : {
        return 0;
      }
    }
  }
  return (
    <Card>
      <Card.Body className="latest-activity-card">
        <h4 className="header-title mb-1">Latest activity</h4>
          <span>
            {trainedSimulators && trainedSimulators.length > 0 ? `You have trained ${trainedSimulators.length} simulators.` : "No trained simulators yet"}
          </span>

          <div className="latest-activity-simulators-container">
            {trainedSimulators && trainedSimulators.length > 0 && trainedSimulators.map((environment: Environment) => {
              return <SimulatorChartStats environment={environment} key={`environment-${environment.id}`}/>
            })}
          </div>

          <div className="in-progress-info-container">
              <span>In progress</span>
              <Container>
                <Row>
                  <Col>
                    <span style={{fontSize: "2.2rem"}}>{`${modelEngineerCount}/${getMaxAllowedForPlan("model-engineer") === 999 ? `∞` : getMaxAllowedForPlan("model-engineer")}`}</span>
                  </Col>
                  <Col>
                    <span style={{fontSize: "2.2rem"}}>{`${simulatorCount}/${getMaxAllowedForPlan("simulation-studio") === 999 ? `∞` : getMaxAllowedForPlan("simulation-studio")}`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span style={{fontSize: "1.2rem"}}>simulator models</span>
                  </Col>
                  <Col>
                    <span style={{fontSize: "1.2rem"}}>simulation projects</span>
                  </Col>
                </Row>
                { limitForWhatWasReached() !== "none" &&
                  <Row>
                    <Col>
                      <span style={{fontSize: "1rem"}} className="text-secondary">
                        { limitForWhatWasReached() === "both" ? "Max models and projects reached, " : "Max "}{limitForWhatWasReached()} reached, <Link to="/model-engineer/billing" className="text-info">upgrade</Link> to add more.
                      </span>
                    </Col>
                  </Row>
                }
              </Container>
          </div>
      </Card.Body>
    </Card>
  );
};
