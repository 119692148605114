import { useState } from "react";

export const useChangelogOpenStore = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [unread, setUnread] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleRead = (isRead: boolean) => setUnread(isRead);
  return {
    isOpen,
    toggle,
    unread,
    toggleRead,
  };
}

