import React from "react";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

// components
import { PageTitle } from "components/PageTitle";

import { UserBox } from "../UserBox";
import { About } from "../About";
import { Settings } from "../Settings";

export const ProfilePage = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Pages", path: "/profile" },
          { label: "Profile", path: "/profile", active: true },
        ]}
        title={"Profile"}
      />
      <Row>
        <Col xl={4} lg={5}>
          {/* User information */}
          <UserBox />
        </Col>
        {/* <Col xl={8} lg={7}>
          <Tab.Container defaultActiveKey="timeline">
            <Card>
              <Card.Body>
                <Nav variant="pills" className="nav nav-pills bg-nav-pills nav-justified mb-3">
                  <Nav.Item className="nav-item">
                    <Nav.Link href="#" eventKey="aboutme" className="nav-link rounded-0">
                      About
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link href="#" eventKey="settings" className="nav-link rounded-0">
                      Settings
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="aboutme">
                    <About />
                  </Tab.Pane>
                  <Tab.Pane eventKey="settings">
                    <Settings />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col> */}
      </Row>
    </>
  );
};
