import { ScrollToTop } from "components/ScrollToTop";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const loading = () => <div className="" />;

export const DefaultLayout = () => {
  return (
    <Suspense fallback={loading()}>
      <Outlet />
      <ScrollToTop />
    </Suspense>
  );
};
