import { Simulator } from "types";
import { CADExplorer } from "./CADExplorer";
import { useEffect, useState } from "react";

import css from "./styles.module.css";

type GeometryViewerProps = {
  simulator: Simulator;
};

export function GeometryViewer({ simulator }: GeometryViewerProps) {
  const { modulus_components: { geometries } } = simulator;
  const [showableGeometries, setShowableGeometries] = useState<string[]>([]);

  useEffect(() => {
    const localStorage = window.localStorage;
    if (!localStorage) { return; }
    if (localStorage.getItem(`${simulator?.id}_showableGeometries`)) {
      setShowableGeometries(JSON.parse(localStorage.getItem(`${simulator?.id}_showableGeometries`)));
    } else {
      setShowableGeometries(Object.values(simulator.modulus_components.geometries).map((geom) => geom.data.file_name));
    }
    localStorage.setItem(`${simulator?.id}_showableGeometries`, JSON.stringify(Object.values(simulator.modulus_components.geometries).map((geom) => geom.data.file_name)));
  }, []);

  useEffect(() => {
    const localStorage = window.localStorage;
    if (!localStorage) { return; }

    localStorage.setItem(`${simulator?.id}_showableGeometries`, JSON.stringify(showableGeometries))
  }, [showableGeometries])

  if (!simulator) {
    return null;
  }

  return (
    <div className={css.cadViewerContainer}>
      <CADExplorer geometries={Object.values(geometries).filter((geo) => showableGeometries.includes(geo.data.file_name)).map(geom => geom.data)} /> 
      <div className={css.cadSidebar}>
        {simulator && simulator.geometries && Object.values(geometries).map((geometry, index) => {
          return (
            <div className={css.sidebarItems}>
              <input type="checkbox" checked={showableGeometries?.includes(geometry?.data?.file_name)} id={`geometry-checkbox-${geometry?.data?.label}`} onChange={(e) => {
                if (!(showableGeometries.includes(geometry?.data?.file_name))) {
                  setShowableGeometries([...showableGeometries, geometry?.data?.file_name])
                } else {
                  setShowableGeometries(showableGeometries.filter((geom) => geom !== geometry?.data?.file_name));
                }
              }}/>
              <label htmlFor={`geometry-checbkox-${geometry?.data?.label}`}>
                {geometry?.data?.label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  );
}
