import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { compareVersions } from "compare-versions";
import { CreateEnvironmentData, Environment, UpdateEnvironmentData } from "types";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export function useCreateEnvironment() {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const deploymentAWS = useDeployEnvironmentToAWS();
  const deploymentPaperspace = useDeployEnvironmentToPaperspace();

  return useMutation<Environment, Error, CreateEnvironmentData>({
    mutationFn: async (environment: CreateEnvironmentData) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/`, {
        method: "POST",
        body: JSON.stringify(environment),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error creating a new environment.");
      }
      return res.json();
    },
    onMutate: (data: CreateEnvironmentData) => {
      console.log("sending", data);
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (data: Environment, __, ___) => {
      console.log("Environment project created.", data);
      toast.success("Environment project created.", {
        theme: "dark",
      });

      if (data.cloud_provider === "paperspace" && data.state === "starting") {
        deploymentPaperspace.mutate(data.id);
      }

      if (data.cloud_provider === "aws" && data.state === "starting") {
        deploymentAWS.mutate(data.id);
      }

      return navigate(`/model-engineer/environments/${data.id}`);
    },
  });
}

export function useUpdateEnvironment() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Environment, Error, UpdateEnvironmentData>({
    mutationFn: async (environment: UpdateEnvironmentData) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/${environment.id}/`, {
        method: "PATCH",
        body: JSON.stringify(environment),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error( errorResponse.error || "There was an error updating the environment.");
      }
      return res.json();
    },
    onMutate: (data: Partial<UpdateEnvironmentData>) => {
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (data: Environment, __, ___) => {
      console.log("Environment updated.", data);
      queryClient.setQueryData(["environment", { id: data.id }], data);
      toast.success("Successfully updated the environment.", {
        theme: "dark",
      });
      return navigate(0); // TODO: fix this reloading
    },
  });
}

export function useDeleteEnvironment() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/${id}/`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error( errorResponse.error || "There was an error deleting the environment.");
      }
      return id;
    },
    onError: (error: Error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      console.log("successfully deleted the environment");
      queryClient.invalidateQueries({ queryKey: ["environments"] });
      toast.success("Successfully deleted the environment.", {
        theme: "dark",
      });
      return navigate("/model-engineer/environments", { replace: true });
    },
  });
}

export function useDeployEnvironmentToAWS() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<string, Error, string>({
    mutationFn: async (environmentId: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/aws/deploy/${environmentId}/`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "There was an error deploying the environment.");
      }
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully deployed the environment.", {
        theme: "dark",
      });
    },
  });
}

export function useStartEnvironmentInAWS() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Environment, Error, Environment>({
    mutationFn: async (environment: Environment) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/aws/start/${environment.id}/`, {
        method: "POST",
        body: JSON.stringify(environment),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error starting the environment.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully started the environment.", {
        theme: "dark",
      });
    },
  });
}

export function useStopEnvironmentInAWS() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Environment, Error, Environment>({
    mutationFn: async (environment: Environment) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/aws/stop/${environment.id}/`, {
        method: "POST",
        body: JSON.stringify(environment),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error stopping the environment.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully stopped the environment.", {
        theme: "dark",
      });
    },
  });
}

export function useDestroyEnvironmentInAWS() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Environment, Error, Environment>({
    mutationFn: async (environment: Environment) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/aws/destroy/${environment.id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error destroying the environment.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully destroyed the environment.", {
        theme: "dark",
      });
    },
  });
}

export function useDeployEnvironmentToPaperspace() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<string, Error, string>({
    mutationFn: async (environmentId: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/paperspace/deploy/${environmentId}/`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "There was an error deploying the environment.");
      }
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully deployed the environment.", {
        theme: "dark",
      });
    },
  });
}

export function useStartEnvironmentInPaperspace() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Environment, Error, Environment>({
    mutationFn: async (environment: Environment) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/paperspace/start/${environment.id}/`, {
        method: "POST",
        body: JSON.stringify(environment),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error starting the environment.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully started the environment.", {
        theme: "dark",
      });
    },
  });
}

export function useStopEnvironmentInPaperspace() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Environment, Error, Environment>({
    mutationFn: async (environment: Environment) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/paperspace/stop/${environment.id}/`, {
        method: "POST",
        body: JSON.stringify(environment),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error stopping the environment.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully stopped the environment.", {
        theme: "dark",
      });
    },
  });
}

export function useDestroyEnvironmentInPaperspace() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Environment, Error, Environment>({
    mutationFn: async (environment: Environment) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/paperspace/destroy/${environment.id}/`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error destroying the environment.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully destroyed the environment.", {
        theme: "dark",
      });
    },
  });
}

type TrainingData = {
  environment: Environment;
  simulatorId: string;
  isSimulationStudio?: boolean;
  numGpus?: number;
  isLocalhost?: boolean;
  isExternal?: boolean;
};

export function useTraining() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<TrainingData, Error, any>({
    mutationFn: async ({ environment, simulatorId, isSimulationStudio, numGpus, isLocalhost, isExternal }) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      let baseUrl = "";
      if (environment.url.includes("env-")) {
        baseUrl = `${environment.url}/site`;
      } else if (
        environment.cloud_provider !== "external" &&
        environment.url.includes("https") &&
        !environment.url.includes("env-") &&
        !environment.url.includes(":9000")
      ) {
        baseUrl = `${environment.url}/site`;
      } else {
        baseUrl = environment.url;
      }
      const url = `${baseUrl}/simulator-training/start/${environment.id}/${simulatorId}?use_sim_studio=${
        isSimulationStudio || "false"
      }&num_gpus=${numGpus || "1"}`;

      let res = null;
      if (isLocalhost || isExternal) {
        res = await fetch(url, {
          method: "POST",
          mode: "no-cors",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            "authorization": `Bearer ${accessToken}`
          }
        });
      } else {
        res = await fetch(url, {
          method: "POST",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            "authorization": `Bearer ${accessToken}`
          }
        });
        if (!res.ok) {
          const errorResponse = await res.json();
          throw new Error(errorResponse?.detail || "There was an error running a simulator training.");
        }
      }

      return environment;
    },
    onMutate: (data: any) => {
      console.log("sending", data);
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Simulator started training.", {
        theme: "dark",
      });
    },
  });
}

export function useStopTraining() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<TrainingData, Error, any>({
    mutationFn: async ({ environment, simulatorId, isLocalhost, isExternal }) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      let baseUrl = "";
      if (environment.url.includes("env-")) {
        baseUrl = `${environment.url}/site`;
      } else if (
        environment.cloud_provider !== "external" &&
        environment.url.includes("https") &&
        !environment.url.includes("env-") &&
        !environment.url.includes(":9000")
      ) {
        baseUrl = `${environment.url}/site`;
      } else {
        baseUrl = environment.url;
      }
      const url = `${baseUrl}/simulator-training/stop/${environment.id}/${simulatorId}`;
      let res = null;
      if (isLocalhost || isExternal) {
        res = await fetch(url, {
          method: "POST",
          mode: "no-cors",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            "authorization": `Bearer ${accessToken}`
          }
        });
      } else {
        res = await fetch(url, {
          method: "POST",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            "authorization": `Bearer ${accessToken}`
          }
        });
        if (!res.ok) {
          const errorResponse = await res.json();
          throw new Error(errorResponse?.detail || "There was an error stopping a simulator training.");
        }
      }
        
      return environment;
    },
    onMutate: (data: any) => {
      console.log("sending", data);
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Simulator stopped training.", {
        theme: "dark",
      });
    },
  });
}

type InferenceData = {
  environment: Environment;
  simulatorId: string;
  useCpu: boolean;
  resX: number;
  resY: number;
  resZ: number;
  saveVtk: boolean;
  saveJson: boolean;
  saveLaz: boolean;
  downloadVti: boolean;
  parameters?: { [key: string]: number };
};

export function useInference() {
  return useMutation({
    mutationFn: async (data: InferenceData) => {
      const useCpu = data.useCpu ?? false;
      let baseUrl = "";
      if (data.environment.url.includes("env-")) {
        baseUrl = `${data.environment.url}/site`;
      } else if (
        data.environment.cloud_provider !== "external" &&
        data.environment.url.includes("https") &&
        !data.environment.url.includes("env-") &&
        !data.environment.url.includes(":9000")
      ) {
        baseUrl = `${data.environment.url}/site`;
      } else {
        baseUrl = data.environment.url;
      }
      const url = `${baseUrl}/simulator-inferencing/simulation-studio-inference/${data.environment.id}/${data.simulatorId}?res_x=${data.resX}&res_y=${data.resY}&res_z=${data.resZ}&save_vtk=${data.saveVtk}&save_json=${data.saveJson}&save_laz=${data.saveLaz}&use_cpu=${useCpu}`;
      // console.log(data.parameters)
      // console.log(JSON.stringify({ parameters: data.parameters || {} }));
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ parameters: data.parameters || {} }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      });
      console.log(res)
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse || "There was an error running an inference on simulator model.");
      }
      return res.json();
    },
    onMutate: (data: InferenceData) => {
      console.log("sending", data);
      return data;
    },
    onError: (error: any, __: any, ___: any) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (data: any, __: any, ___: any) => {
      console.log("Simulator inference successful");
      console.log(data);
      toast.success("Simulator inference successful.", {
        theme: "dark",
      });
      return data;
    },
  });
}
