import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { SimulatorModel } from "types";

type SimulatorModelsListResponse = {
  count: number;
  next: any;
  previous: any;
  results: SimulatorModel[];
};

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useQuerySimulatorModels() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<SimulatorModelsListResponse, Error>({
    queryKey: ["simulatormodels"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulatormodels/?limit=150`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching simulatormodels from the database.");
      }
      return res.json();
    },
  });
}

export function useQuerySimulatorModel(id: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<SimulatorModel, Error>({
    queryKey: ["simulatormodel", id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/simulatormodels/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error fetching simulatormodel with ID ${id} from the database.`);
      }
      return res.json();
    },
  });
}

export function useQueryRetrieveModelsForSimulators(ids: string[], finetune: boolean) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<SimulatorModel[], Error>({
    queryKey: ["modelsforsimulators", ids, finetune],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/simulatormodels/retrievemodelsforsimulators/`, { "simulators": ids, "finetune": finetune }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (res.status !== 200) {
        throw new Error("There was an error fetching models from simulators from the database.");
      }
      return res.data;
    },
  });
}