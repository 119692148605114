import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useQueryDatasets, useSubscription, useStripeCustomerData } from "queries";
import { Dataset } from "types";
import prettyBytes from "pretty-bytes";
import { useBuyCredits } from "mutations";
import { track } from "@vercel/analytics";

type CreditsWidgetProps = {
  stats?: string;
};

const apexBarChartOpts = {
  chart: {
    stacked: true,
    sparkline: {
      enabled: true
    }
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '100%',
    },
  },
  stroke: {
    width: 0,
  },
  tooltip: {
    enabled: false
  },
  yaxis: {
    max: 100
  },
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#D28DF7", "#7CA8FF"]
    }
  },
};

export const CreditsWidget = ({ stats }: CreditsWidgetProps) => {
  const { user } = useAuth0();
  const buyCredits = useBuyCredits();
  const subscription = useSubscription(user.customer_id);
  const { status, error, data: datasets } = useQueryDatasets();

  const { 
    isLoading: isStripeCustomerDataLoading,
    isError: isStripeCustomerDataError,
    data: stripeCustomerData,
    error: stripeCustomerDataError,
  } = useStripeCustomerData(user.customer_id);

  // monthly plans like starter, pro, etc. are mixed in plans array with
  // environment tiers like tier_1, tier_3...
  // so we need to filter out monthly only monthly plans
  // and get the newest one
  const monthlyPlans = subscription?.data?.map((s: any) => s)?.filter((s: any) => s?.plan?.metadata?.title);
  let tempPlan = "";
  if (monthlyPlans?.length > 0) {
    tempPlan = monthlyPlans[monthlyPlans?.length - 1]?.plan?.metadata?.title ?? "";
  }
  const currentPlan = tempPlan;

  const sizes = (datasets?.results.map((d: Dataset) => d.size) as number[]) || [];
  const combinedSize = sizes.reduce((size: number, total: number) => size + total, 0);
  const maxStorage = 50_000_000_000; // 50 GB, hardcoded for now... TODO: base on subscription plan
  const storageUsedPercent = (combinedSize / maxStorage) * 100;

  const handleBuyCredits = () => {
    buyCredits.mutate(user.customer_id);
  };

  return (
    <div className="credits-container">
      <span className="title">
        Compute credits
      </span>

      <div className="credits">
        <span>
          { isStripeCustomerDataError ? 
            `NaN` : (isStripeCustomerDataLoading ? 
              `...` : (stripeCustomerData?.metadata?.available_credit ?? `0`))
          }
        </span>

        <Button className="blue" onClick={() => {
          handleBuyCredits();
          track("Buy Credits");
         }}
        >
          buy credits
        </Button>
      </div>

      <span className="plan-type">Your plan: <i style={
        // 'Pro' plan is really short and it looks bad, so added padding to make badge look wider
        {
          paddingLeft: `${currentPlan === "Pro" ? `16px` : ``}`,
          paddingRight: `${currentPlan === "Pro" ? `16px` : ``}`,
        }
      }>{currentPlan !== "" ? currentPlan : "Free"}</i></span>

      <div className="storage">
        <div>
          <span>STORAGE</span>
          <span>
            {prettyBytes(combinedSize)} ({storageUsedPercent.toFixed(1)}%) of {prettyBytes(maxStorage)} used
          </span>
        </div>
        <Chart
          options={apexBarChartOpts}
          series={[{
            data: [storageUsedPercent]
          }]}
          className="apex-charts"
          type="bar"
          height={4}
        />
      </div>

      {/* <Button className="blue upgrade-button" disabled>
        upgrade your plan
      </Button> */}
    </div>
  );
};
