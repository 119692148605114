import { randInt } from "three/src/math/MathUtils";
import { Simulator } from "types";

export const getSimulatorThumbnail = (simulator: Simulator) => {
  const filenameUseAsThumbnail =
      simulator &&
      simulator?.modulus_components &&
      simulator?.modulus_components?.geometries &&
      Object.values(simulator?.modulus_components?.geometries)
        ?.map((geo) => geo?.data)
        ?.map((data) => {
          if (data?.thumbnail) {
            return data?.file_name;
          }
        })
        ?.filter((filename) => filename !== undefined)?.[0];

    const thumbnails = simulator?.geometries
      ?.map((geo) => geo?.geometries?.map((geo) => geo?.thumbnail_url))
      ?.flatMap((geo) => geo);
    const thumbnailUrlUser = thumbnails?.find((thumbnail) =>
      thumbnail?.includes(filenameUseAsThumbnail?.split(".")?.[0])
    );
    const randomThumbnailIndex = randInt(0, thumbnails?.length - 1);
    const thumbnailUrl = thumbnailUrlUser ? thumbnailUrlUser : thumbnails?.[randomThumbnailIndex];

    return thumbnailUrl;
}