import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";

interface GeometryContextType {
  numFiles: number;
  setNumFiles: Dispatch<SetStateAction<number>>;
  numUploaded: number;
  setNumUploaded: Dispatch<SetStateAction<number>>;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
}

const GeometryContext = createContext<GeometryContextType>({
  numFiles: 0,
  setNumFiles: () => {},
  numUploaded: 0,
  setNumUploaded: () => {},
  message: "",
  setMessage: () => {},
});

export const GeometryProvider = ({ children }: { children: ReactNode}) => {
  const [numFiles, setNumFiles] = useState(0);
  const [numUploaded, setNumUploaded] = useState(0);
  const [message, setMessage] = useState("");

  const value: GeometryContextType = {
    numFiles,
    setNumFiles,
    numUploaded,
    setNumUploaded,
    message,
    setMessage,
  };

  return (
    <GeometryContext.Provider value={value}>
      {children}
    </GeometryContext.Provider>
  )
}

export const useGeometryContext = () => {
  const context = useContext(GeometryContext);
  if (!context) {
    throw new Error("useGeometryContext must be used within a GeometryProvider");
  }

  return context;
}