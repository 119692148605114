import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AccountLayout } from "layouts/AccountLayout";
import logoutIcon from "assets/images/logout-icon.svg";

const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer footer-alt">
      <p className="text-white">
        {t("Back to ")}{" "}
        <Link to={"/account/login"} className="text-white ms-1">
          <b>{t("Log In")}</b>
        </Link>
      </p>
    </footer>
  );
};

export const Logout = () => {
  const { t } = useTranslation();

  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <div className="text-center">
        <h4 className="mt-0">{t("See You Again !")}</h4>
        <p className="text-white mb-4">{t("You are now successfully signed out.")}</p>
      </div>

      <div className="logout-icon m-auto">
        <img src={logoutIcon} alt="" />
      </div>
    </AccountLayout>
  );
};
