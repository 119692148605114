import { AdvectionDiffusion, defaultAdvectionDiffusionSettings } from "./advection-diffusion";
import {
  defaultDiffusionInterfaceSettings,
  defaultDiffusionSettings,
  Diffusion,
  DiffusionInterface,
} from "./diffusion";
import {
  defaultMaxwellFreqRealSettings,
  defaultPECSettings,
  defaultSommerfeldBCSettings,
  MaxwellFreqReal,
  PEC,
  SommerfeldBC,
} from "./electromagnetic";
import {
  defaultLinearElasticityPlaneStressSettings,
  defaultLinearElasticitySettings,
  LinearElasticity,
  LinearElasticityPlaneStress,
} from "./linear-elasticity";
import {
  CompressibleIntegralContinuity,
  Curl,
  defaultCompressibleIntegralContinuitySettings,
  defaultCurlSettings,
  defaultFluxContinuitySettings,
  defaultGradNormalSettings,
  defaultNavierStokesSettings,
  FluxContinuity,
  GradNormal,
  NavierStokes,
} from "./navier-stokes";
import { defaultNormalDotVecSettings, NormalDotVec } from "./normal-dot-vec";
import type { Equation, EquationCreator, EquationNode } from "./types";
import {
  defaultHelmholtzEquationSettings,
  defaultWaveEquationSettings,
  HelmholtzEquation,
  WaveEquation,
} from "./wave-equation";
import { defaultZeroEquationSettings, ZeroEquation } from "./zero-equation";
export { NavierStokes, NormalDotVec };

export const equationDefaultSettings: { [key: string]: Partial<Equation> } = {
  navier_stokes: defaultNavierStokesSettings,
  curl: defaultCurlSettings,
  compressible_integral_continuity: defaultCompressibleIntegralContinuitySettings,
  flux_continuity: defaultFluxContinuitySettings,
  grad_normal: defaultGradNormalSettings,
  normal_dot_vec: defaultNormalDotVecSettings,
  turbulence_zero_eq: defaultZeroEquationSettings,
  linear_elasticity: defaultLinearElasticitySettings,
  linear_elasticity_plane_stress: defaultLinearElasticityPlaneStressSettings,
  diffusion: defaultDiffusionSettings,
  diffusion_interface: defaultDiffusionInterfaceSettings,
  advection_diffusion: defaultAdvectionDiffusionSettings,
  maxwell_freq_real: defaultMaxwellFreqRealSettings,
  pec: defaultPECSettings,
  sommerfeld_bc: defaultSommerfeldBCSettings,
  wave_equation: defaultWaveEquationSettings,
  helmholtz_equation: defaultHelmholtzEquationSettings,
};

export const equationCreators: { [key: string]: (settings?: any) => EquationCreator } = {
  navier_stokes: NavierStokes,
  normal_dot_vec: NormalDotVec,
  curl: Curl,
  compressible_integral_continuity: CompressibleIntegralContinuity,
  flux_continuity: FluxContinuity,
  grad_normal: GradNormal,
  turbulence_zero_eq: ZeroEquation,
  linear_elasticity: LinearElasticity,
  linear_elasticity_plane_stress: LinearElasticityPlaneStress,
  diffusion: Diffusion,
  diffusion_interface: DiffusionInterface,
  advection_diffusion: AdvectionDiffusion,
  maxwell_freq_real: MaxwellFreqReal,
  pec: PEC,
  sommerfeld_bc: SommerfeldBC,
  wave_equation: WaveEquation,
  helmholtz_equation: HelmholtzEquation,
};

export function generateEquationCode(equation: EquationNode): string {
  const creator = equationCreators[equation.data.mode];
  return creator(equation.data).generateCode();
}

export function equationsToCode(equations: Record<string, EquationNode>): string {
  return Object.values(equations)
    .map((e) => generateEquationCode(e))
    .join("\n");
}
