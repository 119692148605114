type CloudTier = {
  cloud_provider: "aws" | "paperspace" | "local" | "external";
  label: string;
  machine_type: string;
  gpu_label: string;
  gpus: number;
  vcpus: number;
  ram: number;
  price_per_minute: number; // € / min
  available: boolean;
  price_id: string;
};

export const cloudTiers: Record<string, CloudTier> = {
  tier_1: {
    cloud_provider: "aws",
    label: "Tier 1 (1x T4 GPU, 8 vCPUs, 32GB RAM)",
    machine_type: "g4dn.2xlarge",
    gpu_label: "T4",
    gpus: 1,
    vcpus: 8,
    ram: 32,
    price_per_minute: 0.015, // €0.90 / hr, €0.00025 / sec, €0.015 / min
    available: true,
    price_id: process.env.REACT_APP_ENVIRONMENT === "production" ? "price_1NF0mgBOAa7eFpM2oH3RtSYr" : "price_1NDCA5BOAa7eFpM2l6ODIx5s",
  },
  tier_2: {
    cloud_provider: "aws",
    label: "Tier 2 (1x K80 GPU, 4 vCPUs, 61GB RAM)",
    machine_type: "p2.xlarge",
    gpu_label: "K80",
    gpus: 1,
    vcpus: 4,
    ram: 61,
    price_per_minute: 0.03, // €1.80 / hr, €0.0005 / sec, €0.03 / min
    available: true,
    price_id: process.env.REACT_APP_ENVIRONMENT === "production" ? "price_1NF0mgBOAa7eFpM2pQZHxlpB" : "price_1NDCA5BOAa7eFpM2Vyf8BgU3",
  },
  tier_3: {
    cloud_provider: "paperspace",
    label: "Tier 3 (1x A6000 GPU, 8 vCPUs, 45GB RAM)",
    machine_type: "A6000",
    gpu_label: "A6000",
    gpus: 1,
    vcpus: 8,
    ram: 45,
    price_per_minute: 0.054, // €3.24 / hr, €0.0009 / sec, €0.054 / min
    available: true,
    price_id: process.env.REACT_APP_ENVIRONMENT === "production" ? "price_1NF0mgBOAa7eFpM2y4ZfRJNJ" : "price_1NDCA5BOAa7eFpM2u6tGwBrk",
  },
  tier_4: {
    cloud_provider: "paperspace",
    label: "Tier 4 (1x V100 GPU, 8 vCPUs, 30GB RAM)",
    machine_type: "V100-32G",
    gpu_label: "V100 (32GB VRAM)",
    gpus: 1,
    vcpus: 8,
    ram: 30,
    price_per_minute: 0.072, // €4.32 / hr, €0.0012 / sec, €0.072 / min
    available: true,
    price_id: process.env.REACT_APP_ENVIRONMENT === "production" ? "price_1NF0mgBOAa7eFpM2iqL9U1zf" : "price_1NDCA5BOAa7eFpM27jZdW08o",
  },
  tier_5: {
    cloud_provider: "aws",
    label: "Tier 5 (1x V100 GPU, 8 vCPUs, 61GB RAM)",
    machine_type: "p3.2xlarge",
    gpu_label: "V100",
    gpus: 1,
    vcpus: 8,
    ram: 61,
    price_per_minute: 0.081, // €4.86 / hr, €0.00135 / sec, €0.081 / min
    available: true,
    price_id: process.env.REACT_APP_ENVIRONMENT === "production" ? "price_1NF0mgBOAa7eFpM20KDB85P4" : "price_1NDCA5BOAa7eFpM28RjZIS73",
  },
  tier_6: {
    cloud_provider: "paperspace",
    label: "Tier 6 (1x A100 GPU, 12 vCPUs, 90GB RAM)",
    machine_type: "A100-80G",
    gpu_label: "A100 (80GB VRAM)",
    gpus: 1,
    vcpus: 12,
    ram: 90,
    price_per_minute: 0.09, // €5.40 / hr, €0.0015 / sec, €0.09 / min
    available: true,
    price_id: process.env.REACT_APP_ENVIRONMENT === "production" ? "price_1NF0mgBOAa7eFpM2UMirbqgS" : "price_1NDCA5BOAa7eFpM2YULxpICr",
  },
};

export const localTiers: Record<string, CloudTier> = {
  tier_1: {
    cloud_provider: "local",
    label: "Local testing",
    machine_type: "N/A",
    gpu_label: "N/A",
    gpus: 1, // Users should be able to override this
    vcpus: null,
    ram: null,
    price_per_minute: 0,
    available: true,
    price_id: null,
  },
}

export const externalTiers: Record<string, CloudTier> = {
  tier_1: {
    cloud_provider: "external",
    label: "Externally-hosted Simulator Training Environment",
    machine_type: "N/A",
    gpu_label: "N/A",
    gpus: 1, // Users should be able to override this
    vcpus: null,
    ram: null,
    price_per_minute: 0,
    available: true,
    price_id: null,
  },
}
