import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

export const TrainLrChart = ({data}: {data: number[][]}) => {

  const apexChartOpts: ApexOptions = {
    chart: {
      type: "area",
      height: 350,
    },
    colors: ["#2c8ef8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      curve: 'smooth',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "numeric",
      labels: {
        formatter(value: string) {
          const num = Number(value)
          if (num % 10 !== 0) return
          return Math.abs(num) > 999 ? (Math.sign(num) * ((Math.abs(num) / 1000))).toFixed(0) + 'k' : (Math.sign(num) * Math.abs(num)).toString();
        },
      },
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return value.toExponential(3);
        },
      },
      decimalsInFloat: 14,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0,
        colorStops: [
          {
            offset: 0,
            color: "#2c8ef8",
            opacity: 1
          },
          {
            offset: 50,
            color: "#2c8ef8",
            opacity: 0.5
          },
          {
            offset: 100,
            color: "#2c8ef8",
            opacity: 0
          }
        ]
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        <div className="chartjs-chart" style={{ height: "350px" }}>
          <Chart
            options={apexChartOpts}
            series={[
              {
                data
              }
            ]}
            type="area"
            className="apex-charts"
            height={350}
          />
        </div>
      </Card.Body>
    </Card>
  );
};
