import cadex from "@cadexchanger/web-toolkit";
import { useEffect, useRef } from "react";
import { fetchFile, updateSceneSmoothly } from "./helpers";
import css from "./styles.module.css";
import { CADGeometry } from "types/geometry";
import { STLGeometryType } from "modulus-interop/geometries/types";
import { randInt } from "three/src/math/MathUtils";
import { isDevelopment } from "lib/env";

let scene: cadex.ModelPrs_Scene | null = new cadex.ModelPrs_Scene();
let viewPort: cadex.ModelPrs_ViewPort | null = null;
let sceneNodeFactory: cadex.ModelPrs_SceneNodeFactory | null = new cadex.ModelPrs_SceneNodeFactory();

const loadAndDisplayModel = async (geometry: CADGeometry | STLGeometryType) => {
  // Clean up scene to display new model
  scene.clear();
  await scene.update();

  try {
    // Load model by URL.
    const model = new cadex.ModelData_Model();
    const loadedResult = await model?.loadFile(geometry.cdxfb_url, dataLoader, false);
    isDevelopment && console.log(`${geometry.file_name} is loaded\n`, loadedResult);

    if (loadedResult) {
      // Create visualization graph for model.
      if (!sceneNodeFactory){
        sceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();
      }

      let repMask = loadedResult.hasBRepRep
        ? cadex.ModelData_RepresentationMask.ModelData_RM_BRep
        : cadex.ModelData_RepresentationMask.ModelData_RM_Poly;
      const sceneNode = await sceneNodeFactory.createGraphFromModel(model, repMask);
      if (!sceneNode) {
        throw new Error("Unable to create scene node from model.");
      }
      sceneNode.displayMode = loadedResult.hasBRepRep
        ? cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
        : cadex.ModelPrs_DisplayMode.Shaded;
      sceneNode.selectionMode = cadex.ModelPrs_SelectionMode.Body | cadex.ModelPrs_SelectionMode.PolyShape;

      scene.addRoot(sceneNode);

      // Update scene to apply changes.
      await updateSceneSmoothly(scene, viewPort);

      // Move camera to position when the whole model is in sight
      viewPort?.fitAll();
    }
  } catch (err: any) {
    console.log("Unable to load and display model: ", err);
  }
};

type CADExplorerProps = {
  geometries: CADGeometry[] | STLGeometryType[];
};

const dataLoader: cadex.ModelData_CDXFBBufferProvider = async (
  modelPath: string,
  theObjId: string,
  progressScope: cadex.Base_ProgressScope
) => fetchFile(modelPath + "/" + theObjId, progressScope);

export function CADExplorer(props: CADExplorerProps) {
  const container = useRef(null);

  useEffect(() => {
    if (container.current && props.geometries) {
      if (!scene) {
        scene = new cadex.ModelPrs_Scene();
      }
      if (!viewPort) {
        viewPort = new cadex.ModelPrs_ViewPort({} as any, container.current! as HTMLElement);
        viewPort.attachToScene(scene!);
      }

      if (props.geometries && scene && viewPort) {
        props.geometries.forEach((geometry) => {
          loadAndDisplayModel(geometry).catch(console.error);
        });
      }
    }

    return () => {
      viewPort.detachFromScene();
      scene.clear();
      scene.update();
      scene = null;
      viewPort = null;
    };
  }, [props.geometries]);

  return <div className={css.cadViewer} ref={container} key={randInt(0,201243213124)}/>;
}
