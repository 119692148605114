import { useCreateSimulator, useUpdateSimulator } from "mutations";
import { useRef } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Simulator } from "types";

interface CopySimulatorModalProps {
  show: boolean;
  onHide: () => void;
  simulator: Simulator;
}

export function CopySimulatorModal({show, onHide, simulator}: CopySimulatorModalProps) {
  const labelInput = useRef<undefined | HTMLInputElement>();
  const descriptionInput = useRef<undefined | HTMLInputElement>();
  const checkboxInput = useRef<undefined | HTMLInputElement>();
  const createSimulator = useCreateSimulator();
  const updateSimulator = useUpdateSimulator();
  const navigate = useNavigate();

  const handleSaveClick = () => {
    createSimulator.mutate({
      label: labelInput.current.value,
      slug: labelInput.current.value,
      copied_from: simulator.id,
      desc: descriptionInput.current.value,
      public: checkboxInput.current.checked
    }, {
      onSuccess: (data) => {
        onHide();
        updateSimulator.mutate({
          id: data.id,
          modulus_components: simulator.modulus_components
        }, {
          onSuccess: (response) => {
            onHide();
            return navigate(`/model-engineer/simulators/${response.id}`);
          }
        })
      }
    })
  }

  return (  
    <Modal className="custom-modal" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Copy modal details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Label</Form.Label>
            <Form.Control type="text" ref={labelInput}/>
          </Form.Group>

          <Form.Group className="mt-1">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" ref={descriptionInput} />
          </Form.Group>

          <Form.Check 
            type="checkbox"
            label="Public"
            className="mt-2"
            ref={checkboxInput}
          />

          <Button className="blue mt-2" onClick={() => handleSaveClick()}>Save</Button>
        </Form>
      </Modal.Body>

    </Modal>
  )
}