/*
  this is just for the tutorial simulator (car wind tunnel parameterized)

*/
export const isTutorial = (simulatorId: string) => {
  return simulatorId === "e9f44b51-4850-4515-a694-4e1e804de225"; // app-tour (tutorial) gets started only on this simulator
}

// order for gradual display of nodes
export const nodeTutorialOrder = [
  "d4ef5d34-3c0e-4d3f-9efd-98b076a8d364",
  "08734460-014b-47df-bba4-55e94c9388b0",
  "4e45e2c7-5a97-4b1d-babe-d37c02b0d2e5",
  "8c52a322-1791-40c9-804d-ca7f61c940a0",
  "9e0f8605-0632-4012-b15b-4968b3ab96dc",
  "d3999593-8a07-4e25-9622-f3dcdca61c1b",
  "e633a1d9-b362-49b1-bc51-0da891bc5ee7",
]

// order for gradual display of edges
export const edgeTutorialOrder = [
  "reactflow__edge-08734460-014b-47df-bba4-55e94c9388b0nn-d4ef5d34-3c0e-4d3f-9efd-98b076a8d364equation",
  "reactflow__edge-d4ef5d34-3c0e-4d3f-9efd-98b076a8d364geometry-group-4e45e2c7-5a97-4b1d-babe-d37c02b0d2e5nn",
  "reactflow__edge-e633a1d9-b362-49b1-bc51-0da891bc5ee7e633a1d9-b362-49b1-bc51-0da891bc5ee7-37b3f268-2d74-41c9-9db5-b8500cd43da6closed.stl",
  "reactflow__edge-9e0f8605-0632-4012-b15b-4968b3ab96dc9e0f8605-0632-4012-b15b-4968b3ab96dc-aec15283-1173-46e6-8d80-180be5896afbopen.stl",
  "reactflow__edge-d3999593-8a07-4e25-9622-f3dcdca61c1bd3999593-8a07-4e25-9622-f3dcdca61c1b-4fe49754-29e0-4295-91a1-fc739f983597in.stl",
  "reactflow__edge-8c52a322-1791-40c9-804d-ca7f61c940a08c52a322-1791-40c9-804d-ca7f61c940a0-9d0a0776-9116-4135-9991-c1ba0c344691out.stl"
]