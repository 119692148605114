import { defaultFullyConnectedArchSettings } from "modulus-interop/architectures/fully-connected";
import { FullyConnectedArch } from "modulus-interop/architectures/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Button, Col, Form, Row } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useReactFlow } from "reactflow";
import { useParameterizedInputs } from "./hooks";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

type TSelectOption = {
  value: string;
  label: string;
};

export function FullyConnectedSettings({ data }: { data: ModulusComponentDataCommon & FullyConnectedArch }) {
  const { setNodes } = useReactFlow();
  const {
    newParameterToAdd,
    setNewParameterToAdd,
    handleAddParameterizedInput,
    handleRemoveParameterizedInput
  } = useParameterizedInputs();
  
  const [inRangeArray, setInRangeArray] = useState<Boolean[]>([true]);

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  useEffect(() => {
    if (!data || !data.parameterized_inputs) {
      return;
    }

    const isInRange = Object.entries(data.parameterized_inputs).map(([_, input]) => {
      return input.value >= input.min && input.value <= input.max;
    });
    setInRangeArray(isInRange);
  }, [data.parameterized_inputs])

  return (
    <>
      <Row>
        <Col>
          <h5>Input variables</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.input_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.input_keys.map((k) => ({ value: k, label: k }))}
            onChange={(inputVars) =>
              handleSettingChange({
                input_keys: inputVars.map((v) => v.value),
              })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Parameterized input variables</h5>

          {!isEmpty(data.parameterized_inputs) && (
            <>
              <Row className="gx-1 mb-1">
                <Col xs={4}>
                  <span className="text-muted font-10 mt-1">Symbol</span>
                </Col>
                <Col>
                  <span className={`${(!inRangeArray.includes(false)) ? `text-muted` : `text-danger`} font-10 mt-1`}>{`${(!inRangeArray.includes(false)) ? `Default` : `Not in range`}`}</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Min</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Max</span>
                </Col>
                <Col xs={1}></Col>
              </Row>
              {Object.entries(data.parameterized_inputs).map(([key, input], index) => (
                <Row key={`parameterized_inputs_${key}`} className="gx-1 mb-1">
                  <Col xs={4}>
                    <input
                      type="symbol"
                      className="form-control mx-0"
                      placeholder="Symbol"
                      defaultValue={input.symbol}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, symbol: e.target.value },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className={`${(inRangeArray[index] || !input.value) ? `` : `border border-danger`} form-control mx-0`}
                      placeholder="Default"
                      step={0.1}
                      defaultValue={input.value}
                      onChange={(e) => {
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, value: parseFloat(e.target.value) },
                          },
                        })}
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Min"
                      step={0.1}
                      defaultValue={input.min}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, min: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Max"
                      step={0.1}
                      defaultValue={input.max}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, max: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col xs={1}>
                    <Button className="btn-remove-small" onClick={() => handleRemoveParameterizedInput(data, key)}>
                      <i className="mdi mdi-close-circle-outline" />
                    </Button>
                  </Col>
                </Row>
              ))}
            </>
          )}
          <Row className="gx-1 mt-1">
            <Col xs={8}>
              <Form.Control
                type="text"
                placeholder="Symbol (name) of the variable"
                value={newParameterToAdd}
                onChange={(e) => setNewParameterToAdd(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                className="btn-add"
                disabled={!newParameterToAdd}
                onClick={() => handleAddParameterizedInput(data)}>
                <i className="mdi mdi-plus" /> Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Output variables</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.output_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.output_keys.map((k) => ({ value: k, label: k }))}
            onChange={(outputVars) =>
              handleSettingChange({ output_keys: outputVars.map((v) => v.value) })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>List of keys to detach gradients</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.detach_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.detach_keys.map((k) => ({ value: k, label: k }))}
            onChange={(detachKeys) =>
              handleSettingChange({ detach_keys: detachKeys.map((v) => v.value) })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Layer size</h5>
          <input
            type="number"
            className="form-control"
            defaultValue={data.layer_size ?? defaultFullyConnectedArchSettings.layer_size}
            onChange={(e) => handleSettingChange({ layer_size: parseInt(e.target.value) })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Number of layers</h5>
          <input
            type="number"
            className="form-control"
            defaultValue={data.num_layers ?? defaultFullyConnectedArchSettings.num_layers}
            onChange={(e) => handleSettingChange({ num_layers: parseInt(e.target.value) })}
          />
        </Col>
      </Row>
    </>
  );
}
