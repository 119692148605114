import React from "react";
import { Row, Col } from "react-bootstrap";
import { track } from "@vercel/analytics"

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col md={8}>
              © 2021-{currentYear} DimensionLab s.r.o., creators of the Siml.ai platform. It's made with love, care, and a lot of coffee.
            </Col>

            <Col md={4}>
              <div className="text-md-end footer-links d-none d-md-block">
                <a href="https://siml.ai/" target="_blank" onClick={() => track("Footer About")}>About</a>
                <a href="https://share-eu1.hsforms.com/1K3kq8CzUTNOuMjKtSgpwWgfzcwl" target="_blank" onClick={() => track("Footer Support")}>Support</a>
                <a href="mailto:hello@dimensionlab.org" onClick={() => track("Footer Contact Us")}>Contact Us</a>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};
