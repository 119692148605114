import { checkRequiredFields } from "modulus-interop/utils";
import { v4 as uuidv4 } from "uuid";
import slugify from "slugify";
import { DiffusionCreator, DiffusionInterfaceCreator, DiffusionInterfaceType, DiffusionType } from "./types";

function checkRequiredPhysicsFields<T>(requiredFields: string[], settings: T) {
  if (requiredFields.includes("T") && !settings.hasOwnProperty("T")) {
    throw Error("The dependent variable (T) not set!");
  }

  if (requiredFields.includes("D") && !settings.hasOwnProperty("D")) {
    throw Error("Diffusivity (D) not set!");
  }

  if (requiredFields.includes("Q") && !settings.hasOwnProperty("Q")) {
    throw Error("The source term (Q) not set!");
  }

  if (requiredFields.includes("T_1") && !settings.hasOwnProperty("T_1")) {
    throw Error(
      "First of the two dependent variables (T_1) to match the boundary conditions at the interface not set!"
    );
  }

  if (requiredFields.includes("T_2") && !settings.hasOwnProperty("T_2")) {
    throw Error(
      "Second of the two dependent variables (T_2) to match the boundary conditions at the interface not set!"
    );
  }

  if (requiredFields.includes("D_1") && !settings.hasOwnProperty("D_1")) {
    throw Error("Diffusivity at the first interface (D_1) not set!");
  }

  if (requiredFields.includes("D_2") && !settings.hasOwnProperty("D_2")) {
    throw Error("Diffusivity at the second interface (D_2) not set!");
  }
}

export const defaultDiffusionSettings: Partial<DiffusionType> = {
  T: "T",
  D: { symbol: "D_T", value: 0, parameterized: false },
  Q: { symbol: "Q_T", value: 0, parameterized: false },
  dim: 3,
  time: false,
  mixed_form: false,
};

export function Diffusion(settings?: DiffusionType): DiffusionCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("Diffusion"),
    slug: Object.freeze("diffusion"),
    settings: {
      ...defaultDiffusionSettings,
      ...settings,
    },
    set(settings: Partial<DiffusionType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredPhysicsFields(["T", "D", "Q"], this.settings);
      checkRequiredFields(["dim"], this.settings);
    },
    generateCode() {
      this.validate();
      const { T, D, Q, dim, time, mixed_form } = this.settings;

      return `
    from modulus.eq.pdes.diffusion import Diffusion
    ${this.slug} = Diffusion(
        T=${T},
        D=${D.parameterized ? `Symbol("${D.symbol}")` : D.value || D}},
        Q=${Q.parameterized ? `Symbol("${Q.symbol}")` : Q.value || Q}},
        time=${time ? "True" : "False"},
        mixed_form=${mixed_form},
        dim=${dim})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}

export const defaultDiffusionInterfaceSettings: Partial<DiffusionInterfaceType> = {
  T_1: "T_1",
  T_2: "T_2",
  D_1: { symbol: "D1_T1", value: 0, parameterized: false },
  D_2: { symbol: "D2_T2", value: 0, parameterized: false },
  dim: 3,
  time: false,
};

export function DiffusionInterface(settings?: DiffusionInterfaceType): DiffusionInterfaceCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("DiffusionInterface"),
    slug: Object.freeze("diffusion_interface"),
    settings: {
      ...defaultDiffusionInterfaceSettings,
      ...settings,
    },
    set(settings: Partial<DiffusionInterfaceType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredPhysicsFields(["T_1", "T_2", "D_1", "D_2"], this.settings);
      checkRequiredFields(["dim"], this.settings);
    },
    generateCode() {
      this.validate();
      const { T_1, T_2, D_1, D_2, dim, time } = this.settings;

      return `
    from modulus.eq.pdes.diffusion import DiffusionInterface
    ${this.slug} = DiffusionInterface(
        T_1=${T_1},
        T_2=${T_2},
        D_1=${D_1.parameterized ? `Symbol("D_1_${slugify(T_1, { lower: true, replacement: "_", trim: true})}")` : D_1.value || D_1}},
        D_2=${D_2.parameterized ? `Symbol("D_2_${slugify(T_2, { lower: true, replacement: "_", trim: true})}")` : D_2.value || D_2}},
        dim=${dim},
        time=${time ? "True" : "False"})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}
