import { useUpdateDataset } from "mutations";
import { Dispatch, SetStateAction, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import slugify from "slugify";
import { Dataset } from "types/dataset";

export default function UpdateDatasetModal({setState, state, dataset}: {
  setState: Dispatch<SetStateAction<{
    active: boolean,
    dataset: Dataset
  }>>, 
  state: boolean, 
  dataset: Dataset
}) {
  const nameInput = useRef<HTMLInputElement>();
  const descriptionInput = useRef<HTMLTextAreaElement>();
  const publicInput = useRef<HTMLInputElement>();
  const mutation = useUpdateDataset()

  const updateDataset = () => {
    mutation.mutate({
      ...dataset,
      public: publicInput.current.checked,
      label: slugify(nameInput.current.value),
      desc: descriptionInput.current.value
    }, {
      onSuccess: () => {
        setState(prev => ({...prev, active: false}));
      }
    });
  }
  
  return (
    <Modal show={state} className="custom-modal" onHide={() => setState(prev => ({...prev, active: false}))}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Dataset</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form className="custom-form">
        <div className="items-row">
          <Form.Group className="flex-1">
            <Form.Label>Dataset name</Form.Label>
            <Form.Control type="text" placeholder="Enter dataset name" ref={nameInput} defaultValue={dataset.label} />
          </Form.Group>
          <Form.Check 
            className="checkbox"
            type="switch"
            label="Public"
            ref={publicInput}
            defaultChecked={dataset.public}
          />
        </div>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control 
            as="textarea" 
            type="textarea" 
            placeholder="Enter dataset description" 
            ref={descriptionInput}
            defaultValue={dataset.desc}
            />
        </Form.Group>
      </Form>

      <div className="d-flex mt-2 gap-1 justify-content-end">
        <Button className="blue btn-rounded" onClick={() => updateDataset()}>Update Dataset</Button>
      </div>
    </Modal.Body>
  </Modal>
  )
}