import { Row, Col, Dropdown, Button, Anchor, Tab, Nav, Accordion, useAccordionButton } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-react";

import { PageTitle } from "components/PageTitle";
import { GPUUtilizationChart } from "./GPUUtilizationChart";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { GPUUsedMemoryChart } from "./GPUUsedMemoryChart";
import { useMeteredSubscriptionCheckoutSession } from "mutations";
import {
  // useUpdateEnvironment,
  useDeleteEnvironment,
  useDeployEnvironmentToAWS,
  useDeployEnvironmentToPaperspace,
  useStartEnvironmentInAWS,
  useDestroyEnvironmentInAWS,
  useStopEnvironmentInPaperspace,
  useStopEnvironmentInAWS,
  useStartEnvironmentInPaperspace,
  useDestroyEnvironmentInPaperspace,
} from "mutations";
import { useQueryEnvironment } from "queries";
import { useStopTraining, useTraining } from "mutations/environments";
import { TrainLossChart } from "./TrainLossChart";
import { TrainLrChart } from "./TrainLrChart";
import { TrainLossAggregatedChart } from "./TrainLossAggregatedChart";
import { Spinner } from "components/Spinner";
import prettyBytes from "pretty-bytes";
import { filterOutliers, getFilteredDataForTrainLossAggregatedChartWithoutOutliers, getFilteredIndividualLossesDataWithoutOutliers } from "utils";
import { cloudTiers } from "cloud-settings";
import Select from "react-select";

import "./environment.css";
import { RunningCostCounter } from "components/RunningCostCounter";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import SimlLoader from "components/SimlLoader";
import { Parameter } from "utils";
import { SITELogs } from "./SITElogs";
import { track } from "@vercel/analytics";

export const EnvironmentDetailPage = () => {
  let { id } = useParams();
  // const { user } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loadingCloud, setLoadingCloud] = useState(false);
  const [numGpus, setNumGpus] = useState(1);
  // const updateMutation = useUpdateEnvironment();
  const deleteMutation = useDeleteEnvironment();
  const deploymentAWS = useDeployEnvironmentToAWS();
  const deploymentPaperspace = useDeployEnvironmentToPaperspace();
  const startAWSServer = useStartEnvironmentInAWS();
  const startPaperspaceServer = useStartEnvironmentInPaperspace();
  const stopAWSServer = useStopEnvironmentInAWS();
  const stopPaperspaceServer = useStopEnvironmentInPaperspace();
  const destroyAWSServer = useDestroyEnvironmentInAWS();
  const destroyPaperspaceServer = useDestroyEnvironmentInPaperspace();
  // Mutations for calling the MLOps in STE
  const trainSimulator = useTraining();
  const stopTrainingSimulator = useStopTraining();

  const { status, isError: isEnvironmentError, data: environment, error: environmentError } = useQueryEnvironment(id!);
  const meteredSubscriptionCheckoutStripe = useMeteredSubscriptionCheckoutSession();
  const { user } = useAuth0();

  if (status === "loading") {
    return <SimlLoader />;
  }

  if (isEnvironmentError) {
    return <span>Error loading the environment data: {environmentError}</span>;
  }
  if (!environment) {
    return <span>Environment data not found.</span>;
  }

  console.log(environment);

  /*
   * Handle clicking on archive button
   */
  const handleArchive = (e: any) => {
    e.preventDefault();

    // Step 0: Let the user know how many projects depend on this Environment and what is the archiving process
    if (window.confirm(`${t("Do you really want to destroy the environment")} "${environment!.label}"?`)) {
      // Step 1: Start gracefully destroying the cloud infrastructure (deployed servers, etc.)
      if (environment?.cloud_provider === "paperspace" && environment?.paperspace_id) {
        setLoadingCloud(true);
        destroyPaperspaceServer.mutateAsync(environment!).then(() => {
          setLoadingCloud(false);
          queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
        });
      }

      if (environment?.cloud_provider === "aws" && environment?.aws_instance_id) {
        setLoadingCloud(true);
        destroyAWSServer.mutateAsync(environment!).then(() => {
          setLoadingCloud(false);
          queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
        });
      }
      // Step 2: Show the progress bar while the graceful shutdown is in process.

      // Step 3: Remove environment from the DB
      deleteMutation.mutate(environment.id!);
    }
  };

  /*
   * Handle deployment of the Paperspace server
   */
  const handlePaperspaceDeploy = () => {
    setLoadingCloud(true);
    deploymentPaperspace
      .mutateAsync(environment?.id)
      .then(() => {
        setLoadingCloud(false);
        queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
        return environment.id;
      })
      .catch((err) => {
        setLoadingCloud(false);
        console.log(err);
      });
  };

  /*
   * Handle starting the Paperspace server
   */
  const handlePaperspaceStart = () => {
    setLoadingCloud(true);
    startPaperspaceServer
      .mutateAsync(environment!)
      .then(() => {
        setLoadingCloud(false);
        queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
      })
      .catch((err) => {
        setLoadingCloud(false);
        console.log(err);
      });
  };

  /*
   * Handle stopping the Paperspace server
   */
  const handlePaperspaceStop = () => {
    setLoadingCloud(true);
    stopPaperspaceServer
      .mutateAsync(environment!)
      .then(() => {
        setLoadingCloud(false);
        queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
      })
      .catch((err) => {
        setLoadingCloud(false);
        console.log(err);
      });
  };

  /*
   * Handle deployment of the AWS instance
   */
  const handleAWSDeploy = () => {
    setLoadingCloud(true);
    deploymentAWS
      .mutateAsync(environment?.id)
      .then(() => {
        setLoadingCloud(false);
        queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
        return environment.id;
      })
      .catch((err) => {
        setLoadingCloud(false);
        console.log(err);
      });
  };

  /*
   * Handle starting the AWS server
   */
  const handleAWSStart = () => {
    setLoadingCloud(true);
    startAWSServer
      .mutateAsync(environment!)
      .then(() => {
        setLoadingCloud(false);
        queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
      })
      .catch((err) => {
        setLoadingCloud(false);
        console.log(err);
      });
  };

  /*
   * Handle stopping the AWS server
   */
  const handleAWSStop = () => {
    setLoadingCloud(true);
    stopAWSServer
      .mutateAsync(environment!)
      .then(() => {
        setLoadingCloud(false);
        queryClient.invalidateQueries(["environment", { id: environment?.id! }]);
      })
      .catch((err) => {
        setLoadingCloud(false);
        console.log(err);
      });
  };

  // TODO: refactor this ugly code
  let startFn = handlePaperspaceStart;
  let stopFn = handlePaperspaceStop;

  if (environment?.cloud_provider === "aws") {
    startFn = handleAWSStart;
    stopFn = handleAWSStop;
  }

  const isExternal = environment?.cloud_provider === "external";
  const isDeployed = environment!.aws_instance_id || environment!.paperspace_id;
  const isRunning = isDeployed && environment!.state === "running";
  const isStarting = isDeployed && environment!.state === "starting";
  const isStopping = environment!.state === "stopping";
  const isStopped = environment!.state === "stopped";

  const simulatorIds = environment?.simulators?.map((s) => s.id);
  const trainingTelemetry = environment?.trainingtelemetry
    .filter((tt) => simulatorIds.includes(tt.simulator))
    .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

  const gpusList = trainingTelemetry?.[0]?.gpu_info && Object.keys(trainingTelemetry[0].gpu_info);
  const trainLossesList = trainingTelemetry?.[0]?.train_losses && Object.keys(trainingTelemetry[0].train_losses);

  const filterTelemetryBySimulator = (simulatorId: string) =>
    trainingTelemetry?.filter((tt) => tt.simulator === simulatorId);

  const iterationsDone = trainingTelemetry?.[trainingTelemetry.length - 1]?.step ?? 0;

  const maxIter = environment!.simulators?.[0]?.modulus_components?.conf?.training?.max_steps ?? 1_000_000;
  const progress = ((iterationsDone / maxIter) * 100).toFixed(1);

  const handleTraining = (simulatorId: string) => {
    const url = window.location.href;
    const isLocalhost = url.startsWith("http://localhost:4000");
    track("Start Training");

    trainSimulator.mutate({
      environment: environment,
      simulatorId,
      numGpus,
      isSimulatorStudio: environment!.mode === "simulator_studio" ?? false,
      isLocalhost,
      isExternal: isExternal
    });
  };

  const handleStopTraining = (simulatorId : string) => {
    const url = window.location.href;
    const isLocalhost = url.startsWith("http://localhost:4000");
    track("Stop Training");

    stopTrainingSimulator.mutate({
      environment: environment,
      simulatorId,
      isLocalhost,
      isExternal: isExternal
    });
  }

  const DeployContainerAndStatuses = () => {
    const deployFunctionToRun = () => {
      if (environment?.cloud_provider === "paperspace") {
        track("Deploy Paperspace");
        return handlePaperspaceDeploy();
      }
      if (environment?.cloud_provider === "aws") {
        track("Deploy AWS");
        return handleAWSDeploy();
      } 
      return null;
    };

    return (
      <>
        {!isExternal && !isDeployed && (
          <>
            <Button
              className="btn-rounded blue"
              onClick={deployFunctionToRun}>
              Deploy environment server <sup>*</sup>
            </Button>
            <small className="text-secondary">
              <sup>*</sup>
              <i>This will start recording usage that will be billed by the end of the month.</i>
            </small>
          </>
        )}
        {isStarting && (
          <div>
            <div className="mt-2 mb-2">
              Cloud instance is still in process of starting. It should be ready within 5-15 minutes.
            </div>
          </div>
        )}
      </>
    );
  };

  const RunningEnvironmentStatusAndFunction = () => (
    <>
      {isRunning && (
        <div>
          <div>
            <Button variant="danger" className="btn-rounded" onClick={stopFn} disabled={isStarting}>
              Stop running server
            </Button>
          </div>
        </div>
      )}
      {isDeployed && isStopped && (
        <div>
          <div className="mt-2">
            <Button variant="success" className="btn-rounded" onClick={startFn} disabled={isStopping}>
              Start environment server
            </Button>
          </div>
        </div>
      )}
    </>
  );

  const DeleteContainer = () => (
    <Dropdown className="edit-container">
      <Dropdown.Toggle variant="link" as="a" className="card-drop arrow-none cursor-pointer p-0 shadow-none">
        <i className="dripicons-dots-3" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Anchor} onClick={handleArchive}>
          <i className="mdi mdi-delete me-1" />
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Environments", path: "/model-engineer/environments" },
          {
            label: "Environment Details",
            path: `/model-engineer/environments/${environment?.id}`,
            active: true,
          },
        ]}
        title={"Environment Details"}
      />

      <div className="enviroment-single">
        { user?.sub === environment?.owner && <DeleteContainer /> }
        <span className="title">{environment.label}</span>
        <div className="d-flex flex-row">
          <div className={`badge ${environment.state} mb-0`}>{environment.state.toUpperCase()} </div>
          {isStarting ||
            (isStopping && (
              <div className="mt-2 mx-1 mb-0">
                <Spinner size="sm" variant="bordered" />
              </div>
            ))}
        </div>
        <div className="content-grid">
          <div className="simulators-container">
            <span className="title">Simulators trained here:</span>

            <div className="list">
              {environment.simulators ? (
                environment.simulators?.map((sim) => (
                  <div key={`simulator-${sim.id}`} className="simulator-line">
                    <Row className="gx-1">
                      <Col xs={12} sm={5}>
                        <i className="mdi mdi-star-outline pe-2"></i>
                        <Tooltip>
                          <TooltipContent className="Tooltip">{sim.label}</TooltipContent>
                          <TooltipTrigger>
                          <span 
                            className="name me-1"
                            onClick={() => navigate(`/model-engineer/simulators/${sim.id}`)}
                            style={{cursor: "pointer"}}
                          >
                            {sim.label.length > 20 ? `${sim.label.slice(0, 17)}...` : sim.label}
                          </span>
                          </TooltipTrigger>
                        </Tooltip>
                      </Col>
                      <Col xs={10} sm={5}>
                        <span>
                          {iterationsDone.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          <span>/ {maxIter.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </span>
                        <span>
                          ({progress}
                          <span>%</span>)
                        </span>
                      </Col>
                      <Col xs={1}>
                        <i
                          className="mdi mdi-pencil"
                          onClick={() => navigate(`/model-engineer/simulators/${sim.id}`)}
                          style={{cursor: "pointer"}}></i>
                      </Col>
                    </Row>

                    <Accordion className="environment-simulator-mini-settings">
                      <Row className="gx-1 my-2">
                        {!isExternal &&
                          environment.url &&
                          environment.simulators &&
                          environment.state === "running" &&
                          environment?.trainingtelemetry?.length > 0 && (
                            <>
                              { user?.sub === environment?.owner &&  (
                                <>
                                  <Col xs={12} sm="4">
                                    <a href={`http://${environment.public_ip}:5000`} target="_blank" rel="noreferrer">
                                      <Button size="sm" className="blue btn-rounded">
                                        Go to inferencer app
                                      </Button>
                                    </a>
                                  </Col>
                                  <Col xs={12} sm="5">
                                    <Link to={`/model-engineer/projects/${environment.id}`}>
                                      <Button size="sm" className="blue btn-rounded">
                                        Go to Simulation Studio
                                      </Button>
                                    </Link>
                                  </Col>
                                </>
                              )}
                            </>
                          )}
                        {environment.simulators && environment.state === "running" && (
                          <>
                            { user?.sub === environment?.owner && (
                              <>
                                {!environment.is_training ? 
                                  (
                                    <Col xs={2} sm="3">
                                      <Button size="sm" className="blue btn-rounded" onClick={() => handleTraining(sim.id)}>
                                        {trainSimulator.isLoading ? "Loading..." : "Train"}
                                      </Button>
                                    </Col> 
                                  ) :
                                  (
                                    <Col xs={2} sm="3">
                                      <Button size="sm" className="btn-danger btn-rounded" onClick={() => handleStopTraining(sim.id)}>
                                        {stopTrainingSimulator.isLoading ? "Loading..." : "Stop training"}
                                      </Button>
                                    </Col>
                                  )}
                              </>
                            )}
                            {environment.simulators && environment.state === "running" && environment.num_gpus > 1 && (
                              <Col xs={5} sm="4">
                                <span className="font-12 fw-bold float-start p-1">Number of GPUs:</span>
                                <Select<{ label: string; value: number }>
                                  className="react-select settings-select"
                                  options={[
                                    { label: "1", value: 1 },
                                    { label: "2", value: 2 },
                                    { label: "4", value: 4 },
                                    { label: "8", value: 8 },
                                  ]}
                                  defaultValue={{ label: "1", value: 1 }}
                                  onChange={(option) => setNumGpus(option.value)}
                                  styles={{
                                    menu: () => ({
                                      position: "absolute",
                                      top: 30,
                                      right: 22,
                                      width: 100,
                                      background: "#c7c7c7",
                                      border: "1px solid #c7c7c7",
                                      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
                                    }),
                                    option: (baseStyles, state) => ({
                                      ...baseStyles,
                                      backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
                                    }),
                                  }}
                                />
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    </Accordion>
                  </div>
                ))
              ) : (
                <div className="mb-3">No simulators trained here yet.</div>
              )}
            </div>

            <div className="server-info">
              <div>
                <strong>Server type: </strong>
                <span>{cloudTiers[environment?.server_type]?.label}</span>
              </div>
              <RunningCostCounter environment={environment} />

              {user?.sub === environment?.owner && environment?.cloud_provider !== "external" && (
                <>
                  <DeployContainerAndStatuses />
                  <RunningEnvironmentStatusAndFunction />
                </>
              )}
            </div>
          </div>
          <div className="metrics">
            <span className="title">
              <strong>Metrics</strong>
            </span>

            {environment.simulators && environment?.trainingtelemetry?.length > 0 ? (
              <Tab.Container defaultActiveKey="simulator1">
                <Row>
                  <Col>
                    <Nav variant="pills" className="text-secondary">
                      {environment.simulators?.map((simulator, i) => (
                        <Nav.Item key={`metrics-simulator-nav-item-${i + 1}`}>
                          <Nav.Link eventKey={`simulator${i + 1}`} className="text-secondary">
                            {simulator.label}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                      {gpusList?.map((key, i) => (
                        <Nav.Item key={`metrics-gpus-nav-item-${i + 1}`}>
                          <Nav.Link eventKey={`gpu-${key}`} className="text-secondary">
                            GPU {i + 1}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                      <Nav.Item key={`site-logs`}>
                        <Nav.Link eventKey={`site-logs`} className="text-secondary">
                          SITE Logs
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                {environment.simulators?.map((simulator, i) => (
                  <Tab.Content key={`metrics-graphs-training-${i + 1}`}>
                    <Tab.Pane eventKey={`simulator${i + 1}`} title={simulator.label}>
                      <Row>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <div className="font-12 fw-bold mt-2">Learning rate:</div>
                          <TrainLrChart
                            data={filterTelemetryBySimulator(simulator.id)?.map((item) => [item.step, item.train_lr])}
                          />
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <div className="font-12 fw-bold mt-2">Loss aggregated:</div>
                          <TrainLossAggregatedChart
                            maxValue={Math.max(
                              ...filterOutliers(
                                filterTelemetryBySimulator(simulator.id)?.map((item) => item.train_loss_aggregated)
                              )
                            )}
                            data={getFilteredDataForTrainLossAggregatedChartWithoutOutliers(filterTelemetryBySimulator(simulator.id)).map((item) => [
                              item.step,
                              item.train_loss_aggregated,
                            ])
                          }
                          />
                        </Col>
                        {trainLossesList?.map((key) => {
                          const validKey = key as Parameter['key'];
                          return (
                          <Col xs="12" sm="12" md="12" lg="6" xl="6" key={`metrics-graph-loss-${key}`}>
                            <div className="font-12 fw-bold mt-2">{key}:</div>
                            <TrainLossChart
                              maxValue={Math.max(
                                ...filterOutliers(
                                  filterTelemetryBySimulator(simulator.id)?.map((item) => item.train_losses[key])
                                )
                              )}
                              data={getFilteredIndividualLossesDataWithoutOutliers(filterTelemetryBySimulator(simulator.id), validKey).map((item) => [
                                item.step,
                                item.parameterBasedMetric
                              ])}
                            />
                          </Col>
                        )})}
                      </Row>
                    </Tab.Pane>
                    {gpusList?.map((key: string, index: number) => (
                      <Tab.Pane eventKey={`gpu-${key}`} title={`gpu-${index + 1}`} key={`gpu-${key}`}>
                        <Row>
                          <Col>
                            <div className="font-14 fw-bold mt-2">GPU: {key}</div>
                            <div className="font-14 fw-bold mt-2 mb-4">
                              GPU total memory:{" "}
                              {trainingTelemetry?.[0].gpu_info[key]?.total_memory
                                ? prettyBytes(trainingTelemetry?.[0].gpu_info[key]?.total_memory * 1_000_000)
                                : "N/A"}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <div className="font-12 fw-bold mt-2">GPU used memory:</div>
                            <GPUUsedMemoryChart
                              maxValue={trainingTelemetry?.[0].gpu_info[key]?.total_memory * 1_000_000}
                              data={filterTelemetryBySimulator(simulator.id)?.map((item) => [
                                Date.parse(item.created_at),
                                item.gpu_info[key]?.memory_used * 1_000_000,
                              ])}
                            />
                          </Col>
                          <Col sm="6">
                            <div className="font-12 fw-bold mt-2">GPU utilization:</div>
                            <GPUUtilizationChart
                              data={filterTelemetryBySimulator(simulator.id)?.map((item) => [
                                Date.parse(item.created_at),
                                item.gpu_info[key]?.load * 100,
                              ])}
                            />
                          </Col>
                        </Row>
                      </Tab.Pane>
                    ))}
                    <Tab.Pane eventKey={"site-logs"} title="site-logs" key={"site-logs"}>
                        <SITELogs environment_id={id} url={environment?.url}/>
                    </Tab.Pane>
                  </Tab.Content>
                ))}
              </Tab.Container>
            ) : (
              <div className="mt-3">
                No metrics available yet. After you start training the simulator, you'll see training telemetry data
                plots here
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
