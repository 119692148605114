import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import type { Simulator } from "types";
import { useForm } from "react-hook-form";

export function useSimulatorForm() {
  /*
   * create form validation schema
   */
  const createSchemaResolver = yupResolver(
    yup.object({
      label: yup.string().required("Please enter simulator label"),
    })
  );

  /*
   * update form validation schema
   */
  const updateSchemaResolver = yupResolver(
    yup.object({
      label: yup.string().required("Please enter simulator label"),
    })
  );

  /*
   * form methods
   */
  const methods = useForm<Simulator>({ resolver: createSchemaResolver });
  const {
    handleSubmit: handleSubmitCreateForm,
    register: createRegister,
    control: createControl,
    formState: { errors: createFormErrors },
  } = methods;

  const {
    handleSubmit: handleSubmitUpdateForm,
    register: updateRegister,
    control: updateControl,
    formState: { errors: updateFormErrors },
  } = useForm<Partial<Simulator>>({ resolver: updateSchemaResolver });

  return {
    createSchemaResolver,
    updateSchemaResolver,
    handleSubmitCreateForm,
    handleSubmitUpdateForm,
    createRegister,
    updateRegister,
    createControl,
    updateControl,
    createFormErrors,
    updateFormErrors,
  };
}
