import { Badge, Card } from "react-bootstrap";
import Chart from "react-apexcharts";

import type { Environment, EnvironmentHistorySnapshot } from "types";
import { Link } from "react-router-dom";
import { toUsd } from "utils/numbers";
import { calculateRunningTimeFormated } from "utils/calculateRunningTime";
import { useEffect, useRef, useState } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";

export type SingleEnvironmentProps = {
  environment: Environment;
};

const apexBarChartOpts = {
  chart: {
    stacked: true,
    sparkline: {
      enabled: true
    }
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '100%',
    },
  },
  stroke: {
    width: 0,
  },
  tooltip: {
    enabled: false
  },
  yaxis: {
    max: 100
  },
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#7CA8FF", "#D28DF7"]
    }
  },
};

export const EnvironmentCard = ({ environment }: SingleEnvironmentProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState<number>(0);

  useEffect(() => {
    if (elementRef.current) {
      setCardWidth(elementRef.current.clientWidth);
    }
  }, [window.innerWidth])

  const calculateMaxCharacters = (cardWidth: number) => {
    if (cardWidth < 200) {
      return 20;
    } else if (cardWidth < 300) {
      return 25;
    } else if (cardWidth < 400) {
      return 30;
    } else if (cardWidth < 500) {
      return 35;
    } else {
      return 40;
    }
  }

  const [secondsRunning, setSecondsRunning] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const environmentHistory = environment.history;
      // Get the start of the deployment
      const startOfDeployment = environmentHistory.find((h) => h.state_changed_to === "running")?.created_at;
      // Capture the time from the start of the deployment until now
      const timeFromDeployment = Math.abs(new Date().getTime() - new Date(startOfDeployment!).getTime()) / 1000;
      // Get the running time of the environment - time elapsed when the environment was in "running" state: 
      // a) if the environment was already stopped at least once, filter out the time when it was stopped and use
      // `time_elapsed_since_last_state_change` to calculate the running time of the "running" states, accumulate it
      // b) if the environment was never stopped, use the time from the start of the deployment until now
      const stoppedCheckpoints = environmentHistory.filter(h => h.state_changed_to === "stopped");
      let runningTime = stoppedCheckpoints.length > 0
        ? stoppedCheckpoints.reduce((acc, h) => { return acc + h.time_elapsed_since_last_state_change }, 0)
        : timeFromDeployment;

      if (environment?.state === "running") {
        const currentTime = new Date().getTime();
        const startedCheckpoints = environmentHistory
          .filter((h) => h.state_changed_to === "started")
          .sort(
            (a: EnvironmentHistorySnapshot, b: EnvironmentHistorySnapshot) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
        const latestStartedCheckpointTimestamp = startedCheckpoints?.[0]?.timestamp;
        const latestStartedCheckpointTime = latestStartedCheckpointTimestamp
          ? new Date(latestStartedCheckpointTimestamp!).getTime()
          : currentTime;
        // Accumulate the time elapsed since the last "started" checkpoint
        runningTime += Math.abs(currentTime - latestStartedCheckpointTime) / 1000;
      }
      setSecondsRunning(runningTime)
    }, 1000);
    return () => clearInterval(interval);
  }, [environment]);

  const currentCost = secondsRunning / 60 * environment!.price_per_minute;
  const telemetry = environment!.trainingtelemetry.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());;
  const iterationsDone = telemetry?.[telemetry.length - 1]?.step ?? 0;

  const maxIter = environment!.simulators?.[0]?.modulus_components?.conf?.training?.max_steps ?? 1_000_000;
  const progress = ((iterationsDone / maxIter) * 100).toFixed(1);

  return (
    <Link to={`/model-engineer/environments/${environment.id}`}>
      <Card className="d-block card-link">
        <Card.Body className="enviroment-card" ref={elementRef}>
          <div className="simulator-chart-container">
            <span className="title">
              <Tooltip>
                <TooltipContent className="Tooltip">{environment.label}</TooltipContent>
                <TooltipTrigger>
                    <span className="text-reset">
                      {environment?.label?.length > calculateMaxCharacters(cardWidth) ? `${environment?.label?.slice(0, calculateMaxCharacters(cardWidth))}...` : environment?.label}
                    </span>
                </TooltipTrigger>
              </Tooltip>
            </span>

            <div className="percentage-completed">
              <div>
                <span>{progress}<span>%</span></span>
                <span> Completed</span>
              </div>
              <Badge className={environment.state}>{environment.state}</Badge>
            </div>

            <div className="iteration-statistics">
              <span className="iterations-text">Iterations:</span>
              <span>{iterationsDone.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <span>/ {maxIter.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></span>
            </div>

            <Chart
              options={apexBarChartOpts}
              series={[{
                // The percentage needs to be calculated from real data
                data: [Number(progress)]
              }]}
              className="apex-charts"
              type="bar"
              height={4}
              />

            <div className="iteration-details">
              <div>
                <span className="title">Running duration:</span>
                <span>{calculateRunningTimeFormated(secondsRunning)}</span>
              </div>
              <div>
                <span className="title">Estimated cost:</span>
                <span>{toUsd(currentCost)}</span>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
};
