interface DatasetResponse { 
  [key: string]: string;
}

interface DatasetRow {
  [key: string]: number[];

}

export const parseDatasetPreview = (datasetResponse: DatasetResponse) => {
  if (!datasetResponse) return null;
  let datasetRow: DatasetRow = {};
  Object.keys(datasetResponse).map((key) => {
    const valueArray = datasetResponse[key].split(",");
    const valueAsNumbers = valueArray.map((value) => Number(value) ?? 0);
    datasetRow = {
      ...datasetRow,
      [key]: valueAsNumbers,
    };
  });

  const data: {}[] = [];

  Object.keys(datasetRow).map((key) => {
    const row = datasetRow[key];
    row.map((value, index) => {
      if (!data[index]) {
        data[index] = {};
      }
      data[index] = {
        ...data[index],
        [key]: value,
      };
    });
  });

  return {
    data: data,
    headers: Object.keys(datasetResponse),
  };
};