import cadex from "@cadexchanger/web-toolkit";

export function modelUrl(theModelPath: string) {
  return "/assets/models/" + theModelPath;
}

class CancellationObserver extends cadex.Base_ProgressStatusObserver {
  xhr: XMLHttpRequest;
  constructor(xhr: XMLHttpRequest) {
    super();
    this.xhr = xhr;
  }

  /**
   * @override
   */
  changedValue() {}

  /**
   * @override
   */
  completed() {}

  /**
   * @override
   */
  canceled() {
    this.xhr.abort();
  }
}

/**
 * Remote file data provider.
 * @returns {Promise<ArrayBuffer>}
 */
export async function fetchFile(url: string, progressScope: cadex.Base_ProgressScope): Promise<ArrayBuffer | any> {
  const downloadScope = new cadex.Base_ProgressScope(progressScope);

  let cancellarionObserver: CancellationObserver | undefined = undefined;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    xhr.open("GET", url, true);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(new Error(xhr.statusText));
    };
    xhr.onprogress = (event) => {
      downloadScope.increment(event.loaded - downloadScope.value);
    };
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED && xhr.status === 200) {
        const fileSize = xhr.getResponseHeader("content-length");
        downloadScope.setRange(0, Number(fileSize));
      }
    };

    cancellarionObserver = new CancellationObserver(xhr);
    downloadScope.owner.register(cancellarionObserver);

    xhr.send();
  }).finally(() => {
    downloadScope.close();
    if (cancellarionObserver) {
      downloadScope.owner.unregister(cancellarionObserver);
    }
  });
}

/**
 * Moves the camera periodically to position when the whole model is in sight (for better user UX)
 */
export async function updateSceneSmoothly(
  scene: cadex.ModelPrs_Scene,
  viewPort: cadex.ModelPrs_ViewPort,
  progressScope?: cadex.Base_ProgressScope
) {
  // Fit all camera ~3 times per second
  let lastBBoxChangedTime = 0;
  const onSceneBBoxChanged = () => {
    const currentTime = new Date().getTime();
    if (currentTime - lastBBoxChangedTime > 300) {
      lastBBoxChangedTime = currentTime;
      viewPort.fitAll();
    }
  };
  scene.addEventListener("boundingBoxChanged", onSceneBBoxChanged);

  // Update scene to apply changes.
  await scene.update(progressScope);

  scene.removeEventListener("boundingBoxChanged", onSceneBBoxChanged);
}
