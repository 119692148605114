import { v4 as uuidv4 } from "uuid";
import type { DGMArch, DGMCreator } from "./types";
import { Activation } from "../activation-functions";
import { checkRequiredFields } from "modulus-interop/utils";
import { Parameter, SimulatorComponentSettings } from "modulus-interop/types";

export const defaultDGMArchSettings: Partial<DGMArch> = {
  input_keys: ["x", "y", "z"],
  parameterized_inputs: {},
  output_keys: ["u", "v", "w", "p"],
  detach_keys: [],
  layer_size: 512,
  nr_layers: 6,
  activation_fn: Activation.SILU,
  skip_connections: false,
  weight_norm: true,
  adaptive_activations: false,
};

export function DGMArch(
  settings?: DGMArch,
  variableParameters?: SimulatorComponentSettings["variable_parameters"]
): DGMCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("DGM"),
    slug: Object.freeze("dgm"),
    settings: {
      ...defaultDGMArchSettings,
      ...settings,
    },
    set(settings: Partial<DGMArch>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredFields(["input_keys", "output_keys"], this.settings);
    },
    generateCode() {
      this.validate();
      const {
        input_keys,
        output_keys,
        detach_keys,
        layer_size,
        nr_layers,
        skip_connections,
        weight_norm,
        adaptive_activations,
      } = this.settings;

      const parameterizedEquationsKeys = Object.values(variableParameters.equations)?.map(
        (input: Parameter) => `Key("${input?.symbol.toString()}")`
      );
      const parameterizedInputsKeys = Object.values(variableParameters.inputs)?.map(
        (input: Parameter) => `Key("${input?.symbol.toString()}")`
      );
      const allInputs = [...input_keys, ...parameterizedEquationsKeys, ...parameterizedInputsKeys];

      return `
    from modulus.models.dgm import DGMArch
    architecture_${this.slug} = DGMArch(
        input_keys=[${allInputs?.map((key: string) => `Key("${key.toString()}")`).join(", ")}],
        output_keys=[${output_keys?.map((key: string) => `Key("${key.toString()}")`).join(", ")}],
        detach_keys=[${detach_keys?.map((key: string) => `Key("${key.toString()}")`).join(", ")}],
        layer_size=${layer_size},
        nr_layers=${nr_layers},
        skip_connections=${skip_connections ? "True" : "False"}},
        weight_norm=${weight_norm ? "True" : "False"}},
        adaptive_activations=${adaptive_activations ? "True" : "False"})

    nodes = nodes + [architecture_${this.slug}.make_node(name="architecture_${this.slug}")]`;
    },
  };
}
