import { MENU_ITEMS, MenuItemType } from "constants/menu";

const getMenuItems = () => {
  // NOTE - You can fetch from server and return here as well
  return MENU_ITEMS;
};

// TODO: fix types
const findAllParent = (menuItems: MenuItemType[], menuItem: MenuItemType): string[] => {
  let parents = [];
  const parent = findMenuItem(menuItems, menuItem["parentKey"]);

  if (parent) {
    parents.push(parent["key"]);
    if (parent["parentKey"]) parents = [...parents, ...findAllParent(menuItems, parent)];
  }
  return parents;
};

// TODO: fix types
const findMenuItem = (menuItems: MenuItemType[], menuItemKey: string): any => {
  if (menuItems && menuItemKey) {
    for (var i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === menuItemKey) {
        return menuItems[i];
      }
      var found = findMenuItem(menuItems[i].children, menuItemKey);
      if (found) return found;
    }
  }
  return null;
};

export { getMenuItems, findAllParent, findMenuItem };
