import 'styles/siml-loader.css';
import leftSimlLoader from 'assets/images/siml-loader/left-siml-loader.svg';
import rightSimlLoader from 'assets/images/siml-loader/right-siml-loader.svg';

const SimlLoader = () => {
  return (
    <section className="siml-loader-component">
      <div className="siml-loader-container">
        <img src={leftSimlLoader} alt="left side of loader" id="left" />
        <img src={rightSimlLoader} alt="right side of loader" id="right" />
      </div>
    </section>
  )
}

export default SimlLoader;

// you might ask why do we need this
// basically the top one is wrapped inside a parent which takes the min-height as something below 100vh
// if you want to place it somewhere into something (not to take the whole viewport height), use this one
export const SimlLoaderIntegrated = () => {
  return (
    <div className="siml-loader-container">
      <img src={leftSimlLoader} alt="left side of loader" id="left" />
      <img src={rightSimlLoader} alt="right side of loader" id="right" />
    </div>
  )
}