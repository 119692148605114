import { Card } from "react-bootstrap";

export const LoadMoreCard = ({
  currentPage,
  loadingState,
  updatePage,
  updateLoadingState
}: {
  currentPage: number,
  loadingState: boolean,
  updatePage: (val: number) => void,
  updateLoadingState: (val: boolean) => void
}) => {
  return (
    <button 
      onClick={() => {
        updatePage(currentPage + 1);
        updateLoadingState(true);
      }} 
      disabled={loadingState}
      style={{ background: "transparent", border: "none", padding: 0, width: "100%", height: "100%" }}
    >
      <Card 
        className="d-block card-link simulator-card" 
        style={{
          alignContent: "center",
          minHeight: "300px"
        }}
      >
        <i className="mdi mdi-dots-horizontal" style={{ fontSize: "4rem", color: loadingState && "gray" }}/>
      </Card>
    </button>
  )
}