import { useState } from "react";
import { Handle, Position, useNodeId, useReactFlow } from "reactflow";

import "./monitor-node.css";

export function MonitorNode({ data }: { data: any }) {
  const nodeId = useNodeId();
  const reactFlowInstance = useReactFlow();
  const [variable, setVariable] = useState("");

  return (
    <div className="monitor-node">
      <Handle type="target" position={Position.Right} id="monitor" />
      <div
        className="close-icon"
        onClick={() => {
          reactFlowInstance.setNodes(reactFlowInstance.getNodes().filter((n: any) => n.id !== nodeId));
        }}
      />
      <div className="title">Monitor</div>
      <div className="mb-2">
        <label htmlFor="type">Variable:</label>
        <select
          id="type"
          name="type"
          onChange={(e: any) => {
            e.preventDefault();
            setVariable(e.target.value);
          }}
          defaultValue={variable}>
          <option value="">Select variable to be monitored...</option>
          <option value="a">a</option>
          <option value="b">b</option>
          <option value="c">c</option>
        </select>
      </div>

      {variable && <pre><code>{JSON.stringify(variable)}</code></pre>}
    </div>
  );
}
