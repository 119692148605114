import { useQuery } from "@tanstack/react-query";
import type { Environment } from "types";
import { useAuth0 } from "@auth0/auth0-react";

type ProjectsListResponse = {
  count: number;
  next: any;
  previous: any;
  results: Environment[];
};

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useQueryProjects() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<ProjectsListResponse, Error>({
    queryKey: ["projects"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching projects from the database.");
      }
      return res.json();
    },
  });
}

export function useQueryProject(id: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Environment, Error>({
    queryKey: ["project", id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error fetching project with ID ${id} from the database.`);
      }
      return res.json();
    },
  });
}
