import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

type AccountLayoutProps = {
  bottomLinks?: React.ReactElement<any>;
  children?: any;
};

export const AccountLayout = ({ bottomLinks, children }: AccountLayoutProps) => {
  useEffect(() => {
    if (document.body) document.body.classList.add("authentication-bg");

    return () => {
      if (document.body) document.body.classList.remove("authentication-bg");
    };
  }, []);

  return (
    <>
      <div className="auth-fluid">
        {/* Auth fluid left content */}
        <div className="auth-fluid-form-box">
          <div className="align-items-center d-flex h-100">
            <Card.Body>
              {/* logo */}
              <div className="auth-brand text-center text-lg-start">
                <Link to="/" className="logo-dark">
                  <span>Model Engineer</span>
                </Link>
              </div>

              {children}

              {/* footer links (social signup) */}
              {bottomLinks}
            </Card.Body>
          </div>
        </div>
      </div>
    </>
  );
};
