import { useState } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// components
import { PageTitle } from "components/PageTitle";
import { EnvironmentCard } from "../EnvironmentCard";
import type { Environment } from "types";
import { useQueryEnvironments, useStripeCustomerData } from "queries";
import DropdownMenu, { Props } from "components/dropdownMenu";
import SimlLoader from "components/SimlLoader";
import { toUsd } from "utils/numbers";
import { customSort } from "utils";
import css from "./style.module.css";
import { track } from "@vercel/analytics"

export const EnvironmentsListPage = () => {
  const [stateFilter, setEnvStateFilter] = useState("all");
  const [view, setView] = useState("grid");
  const { user } = useAuth0();
  const {
    isLoading: isStripeCustomerDataLoading,
    isError: isStripeCustomerDataError,
    data: stripeCustomerData,
    error: stripeCustomerDataError,
  } = useStripeCustomerData(user.customer_id);

  const DROPDOWN_DATA: Props['data'] = [
    {
      key: 'all',
      title: 'All',
      callback: () => setEnvStateFilter('all')
    },
    {
      key: 'running',
      title: 'Running',
      callback: () => setEnvStateFilter('running')
    },
    {
      key: 'stopped',
      title: 'Stopped',
      callback: () => setEnvStateFilter('stopped')
    },
  ]
  
  const { status, isError: isEnvironmentsError, data: environments, error: environmentsError } = useQueryEnvironments();

  if (status === "loading") {
    return <SimlLoader/>;
  }

  if (isEnvironmentsError) {
    return <span>Error loading the environments data: {environmentsError}</span>;
  }

  if (!environments) {
    return <span>You don't have any available Environments.</span>;
  }

  console.log(environments)

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Environments", path: "/model-engineer/environments", active: true }]}
        title={"Simulator Training Environments"}
      />

      <Row>
        <Col>
        <div className="btn-group me-2 d-inline-block">
          <Link to="/model-engineer/environments/new">
            <Button className="btn-rounded blue" onClick={() => track("Create Environment")}>
              <i className="mdi mdi-plus" /> Create Environment
            </Button>
          </Link>
          </div>
          <div className="btn-group d-inline-block font-14">
          <strong>Your current credits: </strong>
        <span className={`fw-bold ${isStripeCustomerDataError ? "text-danger" : "text-success"}`}>{isStripeCustomerDataError ?
          `NaN` : (isStripeCustomerDataLoading ?
            `...` : toUsd(Number(stripeCustomerData?.metadata?.available_credit)))
        }
        </span>
        </div>
        </Col>
        <Col className="d-inline-flex justify-content-end">
          <DropdownMenu data={DROPDOWN_DATA} />

          <div className="btn-group mb-3 ms-2 d-none d-sm-inline-block">
            <Button
              variant={view === "grid" ? "primary" : "link"}
              className={view === "grid" ? "" : "text-muted"}
              onClick={() => setView("grid")}>
              <i className="dripicons-view-apps" />
            </Button>
          </div>
          <div className="btn-group mb-3 d-none d-sm-inline-block">
            <Button
              variant={view === "list" ? "primary" : "link"}
              className={view === "list" ? "" : "text-muted"}
              onClick={() => setView("list")}>
              <i className="dripicons-checklist" />
            </Button>
          </div>
        </Col>
      </Row>

      { environments?.results?.filter((result) => result?.mode === "model_engineer")?.length === 0 && <Row xs={12} lg={2} className="mt-2">
        <Card>
          <Card.Body>
            <Card.Title style={{fontSize: "1.2rem", fontWeight: "bold"}}>You haven't created any environments yet</Card.Title>
            <Card.Text style={{fontSize: "0.8rem"}}>
              Users can then leverage our Infrastructure-as-a-Service called Environments, which is a part of Siml.ai platform. With one click, you can start training AI-based physics simulators on servers with one or multiple GPUs - our software can handle server nodes with hundreds of GPUs.
              <br/><br/>
              Learn more about Environments in our <Link to="https://docs.siml.ai/model-engineer/environments" target="_blank" className="text-info" style={{fontWeight: "bold"}}>documentation</Link>!
            </Card.Text>
          </Card.Body>
        </Card>
      </Row> }
      {environments?.results?.length > 0 && <Row>
        {environments!.results
          .filter((env: Environment) => (stateFilter === "all" ? true : env.state === stateFilter))
          .filter((env: Environment) => env.mode === "model_engineer" || env.mode === null)
          .sort(customSort)
          .map((env: Environment) => (
            <Col lg={6} xxl={3} key={"environment-" + env.id} className={css.environmentCardContainer}>
              <EnvironmentCard environment={env} />
            </Col>
          ))}
      </Row>}
    </>
  );
};
