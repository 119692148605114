import { getMenuItems } from "helpers/menu";
import { AppMenu } from "../AppMenu";

type SideBarContentProps = {
  hideUserProfile: boolean;
  menuClickHandler?: () => any;
  isLight?: boolean;
};

export const SideBarContent = (props: SideBarContentProps) => {
  return (
    <>
      <AppMenu menuItems={getMenuItems()} />

      <div className="clearfix" />
    </>
  );
};
