import { useVisualUIStore } from "components/VisualUI/store";
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import { Handle, NodeTypes, Position, useReactFlow } from "reactflow";
import "./datasetNode.css";
import { useEffect, useState } from "react";
import { useQueryDataset, useQueryDatasets } from "queries";
import Select from "react-select";
import { Table } from "components/Table";
import { usePaginationState } from "./utils/pagination";
import { useQueryDatasetContent } from "queries/datasets";
import { parseDatasetPreview } from "./utils/parseDatasetPreview";
import { PaginationRow } from "./PaginationRow";
import { Dataset } from "types";

export type QueryMode = "csv" | "h5" | "archive-csv" | "archive-h5";
interface DatasetNodeData  {
  id: string;
  dataset_id: string;
  filename: string;
  h5_key: string;
  dataset_mode: QueryMode;
  file_from_dataset: string;
  h5_key_from_file: string;
  mapping: { [key: string]: string };
  dataset_details: Dataset["dataset_details"];
};

const DatasetNode = ( { data }: { data: DatasetNodeData}) => {
  
  const ROWS_PER_PAGE = 11; // 10 rows per page + 1 (so we can check if there is a next page)
  const nodeId = data.id;
  const { deleteElements, getNodes, setNodes, getNode } = useReactFlow();
  const { setSidebarNodeId } = useVisualUIStore();
  const [showModal, toggleModal] = useState(false);
  const { isLoading, isError, error, data: datasets } = useQueryDatasets();
  const {
    isLoading: isDatasetLoading,
    isError: isDatasetError,
    error: datasetError,
    data: dataset,
  } = useQueryDataset(data?.dataset_id ?? null);
  const [options, setOptions] = useState(datasets?.results?.map((dataset) => ({ value: dataset?.id, label: dataset?.label })) ?? []);
  const { activePage, updateActivePage } = usePaginationState();
  const { 
    isLoading: isDatasetContentLoading, 
    isError: isDatasetContentError, 
    error: datasetContentError, 
    data: datasetContent, 
    refetch: refetchDatasetContent } = useQueryDatasetContent(
      data?.dataset_id,
      activePage,
      ROWS_PER_PAGE,
      data?.dataset_mode,
      data?.filename,
      data?.file_from_dataset,
      // data?.h5_key
    );
  const [rowsData, setRowsData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [noNextPage, setNoNextPage] = useState(false);
  const [headers, setHeaders] = useState(data?.mapping ? Object.keys(data?.mapping) : []);

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  const isDatasetArchive = () => {
    const selectedDataset = datasets?.results?.find((dataset) => dataset?.id === data?.dataset_id);
    return selectedDataset?.dataset_details?.files_in_archive ? true : false;
  }

  // const isH5File = () => {
  //   const selectedDataset = datasets?.results?.find((dataset) => dataset?.id === data?.dataset_id);
  //   return selectedDataset?.dataset_details?.h5_keys ? true : false;
  // }

  useEffect(() => {
    const localStorage = window.localStorage;
    if (!datasetContent) return;

    if (!localStorage.getItem("dataset-headers")) {
      if (!datasetContent) return;
      const parsedData = parseDatasetPreview(datasetContent);
      if (!parsedData || !parsedData.headers) return;
      const receivedHeaders = parsedData.headers;
      localStorage.setItem("dataset-headers", JSON.stringify(receivedHeaders));
      setHeaders(receivedHeaders);
    } else {
      const headersCached = JSON.parse(localStorage.getItem("dataset-headers"));
      const receivedHeaders = parseDatasetPreview(datasetContent)?.headers;
    
      if (headersCached !== receivedHeaders) {
        localStorage.setItem("dataset-headers", JSON.stringify(receivedHeaders));
        setHeaders(receivedHeaders);
      }
    }

    return () => {
      localStorage.removeItem("dataset-headers");
    }
  }, [datasetContent, data?.dataset_id, data?.file_from_dataset])

  useEffect(() => {
    if (!datasetContent) return;
    const parsedDatasetContent = parseDatasetPreview(datasetContent);
    if (!parsedDatasetContent || !parsedDatasetContent?.data) return;
    const count = parsedDatasetContent.data.length;

    // If the count is less than the rows per page, then we know that there is no next page
    if (count < ROWS_PER_PAGE) {
      setNoNextPage(true); // disables the next page button
      return;
    } else {
      // if the next row is null, then we know that there is no next page
      // we are displaying 10 rows per page, so we check if the 11th row is null (we fetched extra one to be able to check this)
      if (!parsedDatasetContent.data[ROWS_PER_PAGE]) {
        setNoNextPage(true);
        return;
      }
    }
    setNoNextPage(false);
    setColumns(parsedDatasetContent.headers.map((header) => ({ Header: header, accessor: header })));
    const data = parsedDatasetContent.data;
    setRowsData(data);
  }, [datasetContent])

  useEffect(() => {
    // TODO: after h5 support is added, remove this filter
    // filter only csv files
    const filtered = datasets?.results?.filter((dataset) => 
      dataset?.dataset_details?.filename?.endsWith(".csv") ||
      dataset?.dataset_details?.filename?.endsWith(".zip") ||
      dataset?.dataset_details?.filename?.endsWith(".tar") ||
      dataset?.dataset_details?.filename?.endsWith(".tar.gz")
    );
    setOptions(filtered?.map((dataset) => ({ value: dataset?.id, label: dataset?.label })) ?? []);
  }, [datasets]);

  useEffect(() => {
    const datasetDetails = datasets?.results?.find((dataset) => dataset?.id === data?.dataset_id)?.dataset_details;
    const filename = datasetDetails?.filename;
    if (filename?.endsWith(".csv")) {
      handleSettingChange({ dataset_mode: "csv" });
      return;
    } 
    // if (filename?.endsWith(".h5")) {
    //   handleSettingChange({ dataset_mode: "h5" });
    //   console.log("changed mode to h5")
    //   return;
    // } 
    if (filename?.endsWith(".zip") || filename?.endsWith(".tar") || filename?.endsWith(".tar.gz")) {
      const fileFromDataset = data?.file_from_dataset;
      if (!fileFromDataset) return;
      if (fileFromDataset?.endsWith(".csv")) {
        handleSettingChange({ dataset_mode: "archive-csv" });
        return;
      } 
      // else if (fileFromDataset?.endsWith(".h5")) {
      //   handleSettingChange({ dataset_mode: "archive-h5" });
      //   console.log("changed mode to archive-h5")
      //   return;
      // }
    }
  }, [data?.dataset_id, dataset?.dataset_details?.filename, data?.file_from_dataset])

  return (
    <div className="dataset-node">
      <div className="title">
        <div className="node-icons">
          {/* <i className="dripicons-gear" onClick={() => setSidebarNodeId(nodeId)} /> */}
          <i
            className="dripicons-cross"
            onClick={() => {
              deleteElements({ nodes: [{ id: nodeId }] });
              setSidebarNodeId("");
            }}
          />
        </div>
        <span>Dataset</span>
      </div>

      <div className="content" onClick={(event) => {
          const node = getNode(nodeId);
          node.selected = true;

          const nodes = getNodes();
          nodes.forEach((n) => {
            if (n.id !== nodeId) {
              n.selected = false;
            }
          });
          setNodes((nds) => nodes.map((n) => n));
      }}>
        <Row>
          <Col>
            <p className="text-muted text-center">{data?.dataset_id ? datasets?.results?.find((dataset) => dataset?.id === data.dataset_id)?.label : "No dataset selected"}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button 
              variant="primary" 
              className="btn btn-primary" 
              style={{color: "#B4B6C3", padding: "10px 5px", width: "100%", fontWeight: "lighter"}}
              onClick={() => {toggleModal(true)}}
            >
              Change dataset
            </Button>
          </Col>
        </Row>
      </div>

      <Handle type="source" position={Position.Bottom} id="nn" />

      <Modal show={showModal} size="lg">
        <ModalHeader>
          <h3>Dataset</h3>
        </ModalHeader>
        <ModalBody>
          <Select
            className="react-select nodrag mb-3"
            options={options}
            onChange={(e) => {
              const { file_from_dataset, h5_key_from_file, ...rest } = data;
              setNodes((nodes) => {
                return nodes.map((nd) => {
                  if (nd.id === data.id) {
                    nd.data = {
                      ...rest,
                      dataset_id: e.value,
                      dataset_label: e.label,
                      filename: datasets?.results?.find((dataset) => dataset?.id === e.value)?.dataset_details?.filename,
                      dataset_details: datasets?.results?.find((dataset) => dataset?.id === e.value)?.dataset_details,
                    }
                  }
                  return nd;
                })
              })
              updateActivePage(1);
            }}
            value={data?.dataset_id ? { value: data?.dataset_id, label: dataset?.label } : { value: "", label: "Select dataset..." }}
            defaultValue={{ value: "", label: "Select dataset..." }}
            styles={{
              option: (baseStyles, state) => ({
                backgroundColor: state.isFocused ? "#474b58" : "#373A45",
                display: "inline-block",
                color: "#B4B6C3 !important",
                padding: "10px 5px",
                width: "100%",
              }),
            }}
          />
          { isDatasetArchive() && <Select
            className="react-select nodrag mb-3"
            options={
              dataset?.dataset_details?.files_in_archive ? dataset?.dataset_details?.files_in_archive?.split(",")?.
                map((file) => ({ value: file, label: file })).
                filter((file => file?.label?.endsWith("csv"))) : [] // TODO: after h5 support is added, remove this filter
            }
            onChange={(e) => {
              const { h5_key_from_file, ...rest } = data;
              const newRest = {
                ...rest,
                file_from_dataset: e.value,
              }
              handleSettingChange(newRest);
              updateActivePage(1);
              refetchDatasetContent();
            }}
            defaultValue={{ value: "", label: "Select file from dataset..." }}
            value={data?.file_from_dataset ? { value: data?.file_from_dataset, label: data?.file_from_dataset } : { value: "", label: "Select file from dataset..." }}
            styles={{
              option: (baseStyles, state) => ({
                backgroundColor: state.isFocused ? "#474b58" : "#373A45",
                display: "inline-block",
                color: "#B4B6C3 !important",
                padding: "10px 5px",
                width: "100%",
              }),
            }}
          />}
          {/* { (isH5File() && !data?.file_from_dataset?.endsWith(".csv")) && 
            <Select
              className="react-select nodrag mb-3"
              options={
                dataset?.dataset_details?.h5_keys[data?.file_from_dataset ? data?.file_from_dataset : dataset?.dataset_details?.filename]?.split(",")?.map((key) => ({ value: key, label: key }))
              }
              onChange={(e) => {
                handleSettingChange({ h5_key_from_file: e.value });
              }}
              defaultValue={{ value: "", label: "Select h5 key from file..." }}
              value={data?.h5_key_from_file ? { value: data?.h5_key_from_file, label: data?.h5_key_from_file } : { value: "", label: "Select h5 key from file..." }}
              styles={{
                option: (baseStyles, state) => ({
                  backgroundColor: state.isFocused ? "#474b58" : "#373A45",
                  display: "inline-block",
                  color: "#B4B6C3 !important",
                  padding: "10px 5px",
                  width: "100%",
                }),
              }}
          />} */}
          <Row className="mb-4 d-flex justify-content-start" xs={1} sm={2}>
            { !datasetContentError &&
              headers && headers.map((header: any, index: number) => (
                <Row className="d-flex flex-row justify-content-end" key={index}>
                  <Col style={{ display: "flex", flexDirection: "column", gap: "5px", paddingTop: "4px", paddingBottom: "4px"}}>
                    <label htmlFor={`variable${index}`} style={{ wordBreak: "break-word"}}>{header}</label>
                    <Form.Control type="text" placeholder={`variable name`} id={`variable${index}`} onChange={(e) => {
                      handleSettingChange({
                        mapping: {
                          ...data.mapping,
                          [header]: e.target.value
                        }
                      })
                    }}
                    value={data.mapping?.[header] ?? ""}
                    />
                  </Col>
                </Row>
              ))
            }
          </Row>
          { datasetContentError && <p className="text-danger text-center font-weight-bold">Error fetching dataset content</p>}
          { !datasetContentError && (dataset?.dataset_details?.files_in_archive ? ( data?.file_from_dataset ? true : false) : true) &&
            rowsData && columns && (
            <div style={{width: "100%", height: "100%"}}>
              {isDatasetContentLoading && 
                <div style={{position: "absolute", width: "100%", height: "100%", zIndex: "999", top: "50%", left: "50%"}}>
                  <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
              <span style={{filter: `${isDatasetContentLoading ? `blur(5px)` : `none`}`, transition: "ease-in-out"}}>
                <Table columns={columns} data={rowsData} pageSize={10} />
              </span>
            </div>
          )}
          { !datasetContentError && data?.dataset_id && <PaginationRow updateActivePage={updateActivePage} activePage={activePage} isDatasetContentLoading={isDatasetContentLoading} noNextPage={noNextPage}/>}
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={() => {toggleModal(false)}}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DatasetNode;