import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { SimulatorTutorialData, UserTutorialData } from "types/tutorial";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useQuerySimulatorTutorialData() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<SimulatorTutorialData, Error>({
    queryKey: ["simulatorTutorialData"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/tutorials/get-simulator-tutorial-data/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error getting simulator tutorial data from the database.`);
      }
      return res.json();
    },
  });
}

export function useQueryUserTutorialData() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<UserTutorialData, Error>({
    queryKey: ["getUserTutorialData"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/tutorials/get-user-tutorial-data/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error getting user tutorial data from the database.`);
      }
      return res.json();
    },
  });
}