import { Handle, NodeProps, Position, useNodeId, useReactFlow } from "reactflow";
import classNames from "classnames";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFileUploader } from "components/VisualUI/FileDropzone/hooks";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";

const truncateFileName = (fileName: string) => {
  if (fileName.length < 21) return fileName;
  return (fileName.slice(0, 6) + "..." + fileName.slice(fileName.length - 9)).trim();
};

export function GeometryNode({ data }: NodeProps) {
  const nodeId = useNodeId();
  const { removeFileNode } = useFileUploader();
  const { setNodes } = useReactFlow();

  const updateThumbnail = () => {
    setNodes((nds: any) =>
      nds.map((nd: any) => {
        if (nd.id === nodeId) {
          nd.data = {
            ...nd.data,
            thumbnail: true,
          };
          return nd;
        }

        if (nd.id !== data.id && nd.data.mode === "stl_geometry") {
          nd.data = {
            ...nd.data,
            thumbnail: false,
          };
        }
        return nd;
      })
    );
  }

  return (
    <Card className={classNames(["mt-1 shadow-none border mb-0"])}>
      <div className="ps-1 pb-1">
        <Row className="align-items-center">
          <Col className="" xs={7}>
            <Link to="#" className="font-10">
              {truncateFileName(data.file_name)}
            </Link>
            <p className="mb-0">
              <span className="font-10">{data.size} {data.airtight && ' - Airtight'}</span>
            </p>
          </Col>
          <Col className="text-end" xs={3}>
            <Row style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center"
            }}>
              <Col xs={6} style={{ padding: 0, paddingTop: "2px" }}>
                <Tooltip>
                  <TooltipTrigger>
                    <Link to="#" className="btn btn-link text-muted shadow-none" style={{ padding: 0 }}>
                      <i className="mdi mdi-image" onClick={() => updateThumbnail()} style={{ color: data?.thumbnail ? "white" : "gray" }}/>
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent style={{ background: "gray", padding: "5px"}}>
                    Use as thumbnail
                  </TooltipContent>
                </Tooltip>
              </Col>
              <Col xs={6} style={{ padding: 0 }}>
                <Link to="#" className="btn btn-link text-muted shadow-none" style={{ padding: 0 }}>
                  <i className="dripicons-cross" onClick={() => removeFileNode(nodeId)} />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Handle type="target" position={Position.Right} id={data.file_name} />
    </Card>
  );
}
