import { defaultPointwiseInteriorConstraintSettings } from "modulus-interop/constraints/interior";
import { useModulusInterop } from "modulus-interop/reactflow";
import { useEffect, useReducer, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import omit from "lodash/omit";
import { useReactFlow } from "reactflow";
import { containsOnlyNumbers } from "utils/string";
import { ConnectedInputSelect } from "./ConnectInputSelect";
import { isEmpty } from "lodash";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { isSimpleValueOrExpressionLike } from "./helpers";

type ParameterOption = {
  value: string;
  label: string;
};

type PointwiseInteriorSettingsProps = {
  data: any;
};

const expandReducer = (state: { [x: string]: boolean; }, action: { type: any; payload: string; }) => {
  switch (action.type) {
    case 'TOGGLE_EXPAND':
      return {
        ...state,
        [action.payload]: !state[action.payload]
      };
    case 'ADD_KEY':
      return {
        ...state,
        [action.payload]: false
      };
    case "REMOVE_KEY":
      return Object.keys(state)
        .filter((key) => key !== action.payload)
        .reduce((acc, key) => ({ ...acc, [key]: state[key] }), {});
    default:
      return state;
  }
};

export function PointwiseInteriorSettings({ data }: PointwiseInteriorSettingsProps) {
  const { setNodes, getNode } = useReactFlow();
  const { serializeNodes } = useModulusInterop();
  const [newOutvar, setNewOutvar] = useState(null);
  const [newLambdaWeighting, setNewLambdaWeighting] = useState(null);
  const [outvarOptions, setOutvarOptions] = useState([]);
  const [lwOptions, setLambdaWeightingOptions] = useState([]);
  const [parameterizedInputsOptions, setParameterizedInputsOptions] = useState([]);
  const expandedMapping = data?.outvar && Object.keys(data?.outvar)?.length > 0 && Object.keys(data?.outvar)?.reduce((acc, o) => {
    // @ts-expect-error
    acc[o] = isSimpleValueOrExpressionLike(data?.outvar[o]) === "expressionLike";
    return acc;
  }, {});
  const [expand, toggleExpand] = useReducer(expandReducer, expandedMapping)

  const node = getNode(data.id);

  useEffect(() => {
    const modulusComponents = serializeNodes();
    const outvarKeys = modulusComponents.outputs.concat(modulusComponents.variables);

    setOutvarOptions(
      outvarKeys
        .filter((o) => (node?.data.outvar ? !Object.keys(node?.data.outvar).includes(o) : true))
        .map((o) => ({ value: o, label: o }))
    );

    setLambdaWeightingOptions(
      outvarKeys
        .filter((o) => (node?.data.lambda_weighting ? !Object.keys(node?.data.lambda_weighting).includes(o) : true))
        .map((o) => ({ value: o, label: o }))
    );

    if (!isEmpty(modulusComponents.variable_parameters) && !isEmpty(modulusComponents.variable_parameters.inputs)) {
      setParameterizedInputsOptions(
        Object.values(serializeNodes().variable_parameters.inputs).map((input) => ({
          label: input.symbol,
          value: input.symbol,
        }))
      );
    }
  }, [node?.data.outvar, node?.data.lambda_weighting]);

  const handleAddOutvar = () => {
    handleSettingChange("outvar", { ...node?.data.outvar, [newOutvar]: 0.0 });
    toggleExpand({ type: "ADD_KEY", payload: newOutvar });
    setNewOutvar(null);
  };

  const handleRemoveOutvar = (label: string) => {
    handleSettingChange("outvar", omit(node?.data.outvar, label));
    toggleExpand({ type: "REMOVE_KEY", payload: label })
  };

  const handleAddLambdaWeighting = () => {
    handleSettingChange("lambda_weighting", { ...node?.data.lambda_weighting, [newLambdaWeighting]: 1.0 });
    setNewLambdaWeighting(null);
  };

  const handleRemoveLambdaWeighting = (label: string) => {
    handleSettingChange("lambda_weighting", omit(node?.data.lambda_weighting, label));
  };

  const handleSettingChange = (key: string, value: any) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...defaultPointwiseInteriorConstraintSettings,
            ...data,
            mode: "pointwise_interior_constraint",
            [key]: value,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h5>Set constraints</h5>
          {node?.data.outvar &&
            Object.entries(node?.data.outvar).map(([label, value]) => (
              <Row key={`outvar-${label}`} className="gx-1 mb-1">
                <Col xs={4}>
                  <Form.Control
                    type="text"
                    id={`outvar-${label}-label`}
                    value={label}
                    disabled={true}
                    onChange={(e) =>
                      handleSettingChange("outvar", {
                        ...node?.data.outvar,
                        [label]: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                      })
                    }
                  />
                </Col>
                {
                  !(expand as {[key: string]: boolean})[label] && // require view not to be expanded to show single row input
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      id={`outvar-${label}-value`}
                      value={value as string | number}
                      onChange={(e) =>
                        handleSettingChange("outvar", {
                          ...node?.data.outvar,
                          [label]: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                        })
                      }
                    />
                  </Col>
                }
                <Col xs={1}>
                  <ConnectedInputSelect
                    options={parameterizedInputsOptions}
                    callback={(selectedVariable: ParameterOption) =>
                      handleSettingChange("outvar", {
                        ...node?.data.outvar,
                        [label]: `Symbol("${selectedVariable.value}")`,
                      })
                    }
                  />
                </Col>
                <Col xs={1}>
                  <Button className="btn-remove-small" onClick={() => handleRemoveOutvar(label)}>
                    <Tooltip>
                      <TooltipTrigger>
                        <i className="mdi mdi-close-circle-outline" />
                      </TooltipTrigger>
                      <TooltipContent className="Tooltip">Remove constraint</TooltipContent>
                    </Tooltip>
                  </Button>
                </Col>
                <Col xs={1}>
                  <Button className="btn-remove-small" onClick={() => toggleExpand({ type: "TOGGLE_EXPAND", payload: label })}>
                    <Tooltip>
                      <TooltipTrigger>
                        <i className="mdi mdi-arrow-expand" />
                      </TooltipTrigger>
                      <TooltipContent className="Tooltip">Show large textarea</TooltipContent>
                    </Tooltip>
                  </Button>
                </Col>
                { (expand as {[key: string]: boolean})[label] &&
                  <Row className="gx-1 mb-1 mt-1">
                      <textarea
                        style={{ 
                          width: "100%", 
                          minHeight: "64px", 
                          fontSize: "12px",
                          background: "#373a45",
                          color: "white",
                          padding: "4px 10px",
                          border: "0px solid transparent",
                          borderRadius: "4px"
                        }}
                        spellCheck={false}
                        id={`outvar-${label}-value`}
                        value={value as string | number}
                        onChange={(e) =>
                          handleSettingChange("outvar", {
                            ...node?.data.outvar,
                            [label]: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                          })
                        }
                      />
                  </Row>
                }
              </Row>
            ))}

          <Row className="gx-1 mt-1">
            <Col xs={8}>
              <Select<ParameterOption>
                className="react-select settings-select"
                options={outvarOptions}
                defaultValue={{ label: "Select variable", value: "" }}
                value={{ label: newOutvar ?? "Select variable", value: newOutvar ? newOutvar : "" }}
                onChange={(option) => setNewOutvar(option.value)}
                styles={{
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
                  }),
                }}
              />
            </Col>
            <Col>
              <Button disabled={!newOutvar} onClick={handleAddOutvar} className="btn-add">
                <i className="mdi mdi-plus" /> Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Spatial pointwise weighting of the constraint</h5>
          {node?.data.lambda_weighting &&
            Object.entries(node?.data.lambda_weighting).map(([label, value]) => (
              <Row key={`lw-${label}`} className="gx-1 mb-1">
                <Col xs={4}>
                  <Form.Control
                    type="text"
                    id={`lw-${label}-label`}
                    value={label}
                    disabled={true}
                    onChange={(e) =>
                      handleSettingChange("lambda_weighting", {
                        ...node?.data.lambda_weighting,
                        [label]: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    id={`lw-${label}-value`}
                    defaultValue={value as string | number}
                    onChange={(e) =>
                      handleSettingChange("lambda_weighting", {
                        ...node?.data.lambda_weighting,
                        [label]: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={1}>
                  <Button onClick={() => handleRemoveLambdaWeighting(label)} className="btn-remove-small">
                    <Tooltip>
                      <TooltipTrigger>
                        <i className="mdi mdi-close-circle-outline" />
                      </TooltipTrigger>
                      <TooltipContent className="Tooltip">Remove weighting</TooltipContent>
                    </Tooltip>
                  </Button>
                </Col>
              </Row>
            ))}

          <Row className="gx-1 mt-1">
            <Col xs={8}>
              <Select<ParameterOption>
                className="react-select settings-select"
                options={lwOptions}
                defaultValue={{ label: "Select variable", value: "" }}
                value={{
                  label: newLambdaWeighting ?? "Select variable",
                  value: newLambdaWeighting ? newLambdaWeighting : "",
                }}
                onChange={(option) => setNewLambdaWeighting(option.value)}
                styles={{
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
                  }),
                }}
              />
            </Col>
            <Col>
              <Button className="btn-add" disabled={!newLambdaWeighting} onClick={handleAddLambdaWeighting}>
                <i className="mdi mdi-plus-thick" /> Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="c-row mt-2">
        <span>Batch size</span>
        <input
          type="number"
          className="form-control"
          defaultValue={node?.data.batch_size ?? defaultPointwiseInteriorConstraintSettings.batch_size}
          onChange={(e) => handleSettingChange("batch_size", parseInt(e.target.value))}
        />
      </div>

      <div className="c-row">
        <span>Batch per epoch</span>
        <input
          type="number"
          className="form-control"
          defaultValue={node?.data.batch_per_epoch ?? defaultPointwiseInteriorConstraintSettings.batch_per_epoch}
          onChange={(e) => handleSettingChange("batch_per_epoch", parseInt(e.target.value))}
        />
      </div>

      <Row>
        <Col>
          <Form.Group className="">
            <Form.Check
              type="checkbox"
              label="Fixed dataset?"
              onChange={(e) => handleSettingChange("fixed_dataset", e.target.checked)}
              checked={node?.data.fixed_dataset ?? defaultPointwiseInteriorConstraintSettings.fixed_dataset}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="">
            <Form.Check
              type="checkbox"
              label="Shuffle dataset?"
              onChange={(e) => handleSettingChange("shuffle", e.target.checked)}
              checked={node?.data.shuffle ?? defaultPointwiseInteriorConstraintSettings.shuffle}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="">
            <Form.Check
              type="checkbox"
              label="Compute SDF derivatives when sampling geometry?"
              onChange={(e) => handleSettingChange("compute_sdf_derivatives", e.target.checked)}
              checked={
                node?.data.compute_sdf_derivatives ?? defaultPointwiseInteriorConstraintSettings.compute_sdf_derivatives
              }
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="">
            <Form.Check
              type="checkbox"
              label="Use quasirandom sampling (Halton sequence)?"
              onChange={(e) => handleSettingChange("quasirandom", e.target.checked)}
              checked={node?.data.quasirandom ?? defaultPointwiseInteriorConstraintSettings.quasirandom}
            />
          </Form.Group>
        </Col>
      </Row>

      <div>
        <Col>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Use this constraint for training?"
              onChange={(e) => handleSettingChange("used_for_training", e.target.checked)}
              checked={node?.data.used_for_training ?? defaultPointwiseInteriorConstraintSettings.used_for_training}
            />
          </Form.Group>
        </Col>
      </div>

      <Row>
        <Col>
          <h5>Criteria</h5>
          <input
            type="text"
            className="form-control"
            defaultValue={node?.data.criteria ?? ""}
            onChange={(e) => handleSettingChange("criteria", e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
}
