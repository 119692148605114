import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { defaultNavierStokesSettings } from "modulus-interop/equations/navier-stokes";
import { NavierStokesType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useReactFlow } from "reactflow";
import { containsOnlyNumbers } from "utils/string";

export function NavierStokesSettings({ data }: { data: ModulusComponentDataCommon & NavierStokesType }) {
  const { setNodes } = useReactFlow();
  const [isInRangeDefaultValue, setIsInRangeDefaultValue] = useState(true);

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  useEffect(() => {
    if(Number(data.nu.value) < data.nu.min || Number(data.nu.value) > data.nu.max) {
      setIsInRangeDefaultValue(false)
    }
    else {
      setIsInRangeDefaultValue(true)
    }
  }, [data.nu.value])

  return (
    <>
      <Row>
        <Col>
          <h5>
            Kinematic viscosity (nu)
            <span className="float-end text-muted">
              <Tooltip>
                <TooltipTrigger>
                  Parameterized
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={data.nu.parameterized ?? defaultNavierStokesSettings.nu.parameterized}
                    onChange={(e) =>
                      handleSettingChange({
                        nu: {
                          ...data.nu,
                          parameterized: e.target.checked,
                          min: e.target.checked ? data.nu.min : null,
                          max: e.target.checked ? data.nu.max : null,
                        },
                      })
                    }
                  />
                </TooltipTrigger>
                <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
              </Tooltip>
            </span>
          </h5>
          {data.nu.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.nu.symbol ?? defaultNavierStokesSettings.nu.symbol}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className={`${isInRangeDefaultValue ? `` : `border border-danger`} form-control mx-0`}
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.nu.value ?? defaultNavierStokesSettings.nu.value}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, value: parseFloat(e.target.value) } })}
                />
                <span className={`${isInRangeDefaultValue ? `text-muted` : `text-danger`} font-10 mt-1`}>{isInRangeDefaultValue ? `Default` : `Not in range`}</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.nu.min ?? defaultNavierStokesSettings.nu.min}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.nu.max ?? defaultNavierStokesSettings.nu.max}
                  onChange={(e) => handleSettingChange({ nu: { ...data.nu, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              step={0.1}
              defaultValue={data.nu.value ?? defaultNavierStokesSettings.nu.value}
              onChange={(e) =>
                handleSettingChange({
                  nu: {
                    ...data.nu,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>
            Density (rho)
            <span className="float-end text-muted">
              <Tooltip>
                <TooltipTrigger>
                  Parameterized
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={data.rho.parameterized ?? defaultNavierStokesSettings.rho.parameterized}
                    onChange={(e) =>
                      handleSettingChange({
                        rho: {
                          ...data.rho,
                          parameterized: e.target.checked,
                          min: e.target.checked ? data.rho.min : null,
                          max: e.target.checked ? data.rho.max : null,
                        },
                      })
                    }
                  />
                </TooltipTrigger>
                <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
              </Tooltip>
            </span>
          </h5>
          {data.rho.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.rho.symbol ?? defaultNavierStokesSettings.rho.symbol}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.rho.value ?? defaultNavierStokesSettings.rho.value}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.rho.min ?? defaultNavierStokesSettings.rho.min}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.rho.max ?? defaultNavierStokesSettings.rho.max}
                  onChange={(e) => handleSettingChange({ rho: { ...data.rho, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              step={0.1}
              defaultValue={data.rho.value ?? defaultNavierStokesSettings.rho.value}
              onChange={(e) =>
                handleSettingChange({
                  rho: {
                    ...data.rho,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Dimensions</h5>
          <Select
            className="react-select settings-select"
            options={[
              { value: 2, label: "2D" },
              { value: 3, label: "3D" },
            ]}
            defaultValue={data.dim ? { value: data.dim, label: data.dim + "D" } : { value: 3, label: "3D" }}
            onChange={(option) => handleSettingChange({ dim: option.value })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Time-dependent</h5>
          <input
            type="checkbox"
            checked={data.time ?? defaultNavierStokesSettings.time}
            onChange={(e) => handleSettingChange({ time: e.target.checked })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Mixed form</h5>
          <input
            type="checkbox"
            checked={data.mixed_form ?? defaultNavierStokesSettings.mixed_form}
            onChange={(e) => handleSettingChange({ mixed_form: e.target.checked })}
          />
        </Col>
      </Row>
    </>
  );
}
