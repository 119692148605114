import { Row, Col, Button } from "react-bootstrap";

import { PageTitle } from "components/PageTitle";
import { SimulatorCard } from "../SimulatorCard";
import type { Simulator } from "types";
import { Link, useNavigate } from "react-router-dom";
import { useQuerySimulators } from "queries";
import DropdownMenu, { Props } from "components/dropdownMenu";
import { useEffect, useMemo, useState } from "react";
import SimlLoader from "components/SimlLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuerySimulatorTutorialData } from "queries/tutorial";
import { TryTutorialPopUp } from "components/TryTutorialPopUp";
import { getSimulatoTutorialStatus, updateTutorialLocalStorage } from "./tutorialHelper";
import { track } from "@vercel/analytics";
import { getSimulatorThumbnail } from "./thumbnail";
import { LoadMoreCard } from "components/LoadMore";

export const SimulatorsListPage = () => {
  const [publicFilter, setPublicFilter] = useState<string | "all" | "my">("all");
  const [view, setView] = useState("grid");
  const SIMULATOR_TUTORIAL_STATUS = getSimulatoTutorialStatus(1);
  const [showTutorialPopUp, setShowTutorialPopUp] = useState(SIMULATOR_TUTORIAL_STATUS === "not_started");
  const { user } = useAuth0();
  const { 
    isLoading: isSimulatorTutorialDataLoading,
    isError: isSimulatorTutorialDataError,
    data: simulatorTutorialData,
    error: simulatorTutorialDataError,
  } = useQuerySimulatorTutorialData();
  
  const [simulatorList, updateSimList] = useState<Simulator[][]>([]);
  const [simListPage, updatePage] = useState(1);
  const { isLoading: isSimulatorsLoading, isError, data: simulators, error, refetch: simulatorsRefetch } = useQuerySimulators(simListPage);
  const tutorialId = simulatorTutorialData?.tutorial?.simulator_tutorial_id;
  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();

  const handleCloseTutorialPopUp = (showAgain: boolean) => {
    if (!showAgain) {
      setShowTutorialPopUp(false);
      updateTutorialLocalStorage({ newStatus: "not_interested", version: 1 });
    } else {
      setShowTutorialPopUp(false);
      updateTutorialLocalStorage({ newStatus: "not_started", version: 1 });
    }
  }

  const simulatorThumbnails = useMemo(() => {
    return simulatorList.flat(2).map((simulator) => {
      return {
        [simulator.id]: getSimulatorThumbnail(simulator)
      }
    }).reduce((accumulator, currentObject) => {
      return { ...accumulator, ...currentObject };
    }, {});
  }, [simulatorList])

  useEffect(() => {
    if (tutorialId) {
      const status = getSimulatoTutorialStatus(1);
      if (status?.startsWith("started_")) {
        navigate(`${tutorialId}/true`, { replace: true })
      }
    }
  }, [tutorialId])

  useEffect(() => {
    if (!simulators?.results) return;
    if (simulatorList?.length === 0) {
      updateSimList([simulators?.results])
      return;
    } else {
      const currentIds = new Set(simulatorList?.map((sim) => sim.map((simNested) => simNested.id)).flatMap(((val) => val)));
      const proposedIds = new Set(simulators?.results?.map((sim) => sim.id));
      
      let isSame = false;
      for (const id of currentIds) {
        if (proposedIds.has(id)) {
          isSame = true;
        }
      }
      if (!isSame) {
        updateSimList([...simulatorList, simulators?.results]);
      }
    }
  }, [simulators])


  useEffect(() => {
    simulatorsRefetch();
  }, [simListPage])

  const DROPDOWN_DATA: Props['data'] = [
    {
      key: 'all',
      title: 'All simulators',
      callback: () => setPublicFilter('all')
    },
    {
      key: 'my',
      title: 'My simulators',
      callback: () => setPublicFilter('my')
    },
  ]

  useEffect(() => {
    const localStorage = window.localStorage;
    if (!localStorage) return;

    const pfStorage = localStorage.getItem("publicFilter");
    if (!pfStorage) {
      localStorage.setItem("publicFilter", JSON.stringify(publicFilter))
    } else {
      if (pfStorage !== undefined && pfStorage !== null && pfStorage !== "undefined") {
        setPublicFilter(JSON.parse(pfStorage))
      }
    }
  }, [])

  useEffect(() => {
    const localStorage = window.localStorage;
    if (!localStorage) return;

    localStorage.setItem("publicFilter", JSON.stringify(publicFilter));
  }, [publicFilter])

  // disable button for 5 seconds after clicking, to prevent spamming
  // + we don't have "isSimulatorsLoading" state as true, so we can't use it to disable the button
  useEffect(() => {
    if (loadingMore) {
      const timer = setTimeout(() => {
        setLoadingMore(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [loadingMore])

  if (isSimulatorsLoading) {
    return <SimlLoader/>
  }

  if (isError || isSimulatorTutorialDataError) {
    return <span>Error loading the data: {error || false || simulatorTutorialDataError}</span>;
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Simulators", path: "/model-engineer/simulators", active: true },
        ]}
        title={"Simulators"}
      />

      <Row className="justify-content-between">
        <Col>
          <Link to="/model-engineer/simulators/new">
            <Button variant="primary" className="btn-rounded mb-3 blue" onClick={() => track("Create Simulator")}>
              <i className="mdi mdi-plus" /> Create Simulator
            </Button>
          </Link>
        </Col>

        <Col className="d-inline-flex justify-content-end">
          <DropdownMenu data={DROPDOWN_DATA} activeTitle={DROPDOWN_DATA.find((data) => data.key === publicFilter)?.title}/>

          <div className="btn-group mb-3 ms-2 d-none d-sm-inline-block">
            <Button
              variant={view === "grid" ? "primary" : "link"}
              className={view === "grid" ? "" : "text-muted"}
              onClick={() => setView("grid")}>
              <i className="dripicons-view-apps" />
            </Button>
          </div>
          <div className="btn-group mb-3 d-none d-sm-inline-block">
            <Button
              variant={view === "list" ? "primary" : "link"}
              className={view === "list" ? "" : "text-muted"}
              onClick={() => setView("list")}>
              <i className="dripicons-checklist" />
            </Button>
          </div>
        </Col>
      </Row>

      <section>
        <Row>
          { simulatorList.flat(2)
            .filter((simulator: Simulator) => (publicFilter === "all" ? true : (simulator.public === false || (simulator.public && simulator.owner === user.sub))))
            .filter(simulator =>  // this filters simulators, hides those that are not user's own and are empty
              simulator?.owner === user.sub || 
              (!(simulator?.owner === user.sub)  && 
              (Object.keys(simulator.modulus_components.constraints || {}).length > 0 || // funny thing, js considers empty object as true, so you can't do just `simulator.modulus_components.constraints`, but need to check for object keys length
              Object.keys(simulator.modulus_components.architectures || {}).length > 0 || 
              Object.keys(simulator.modulus_components.equations || {}).length > 0 || 
              Object.keys(simulator.modulus_components.geometry_groups || {}).length > 0)))
            // .sort((a: Simulator, b: Simulator) => (a.updated_at > b.updated_at ? -1 : 1))
            .map((simulator: Simulator) => {
            return (
              <Col xs={12} lg={6} xl={2} key={"simulator-" + simulator.id} className="mb-3">
                <SimulatorCard simulator={simulator} thumbnail={simulatorThumbnails[simulator?.id]} />
              </Col>
            );
          }
        )}
        { simulators?.next && 
          <Col xs={12} lg={6} xl={2} className="mb-3">
            <LoadMoreCard 
              currentPage={simListPage}
              loadingState={loadingMore}
              updateLoadingState={setLoadingMore}
              updatePage={updatePage}
            />
          </Col>
        }
        </Row>
      </section>

      <TryTutorialPopUp type="simulator" show={showTutorialPopUp} close={handleCloseTutorialPopUp} id={tutorialId}/>
    </>
  );
};
