import { defaultFourierNetArchSettings } from "modulus-interop/architectures/fourier";
import { FourierNetArch } from "modulus-interop/architectures/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useReactFlow } from "reactflow";
import { useParameterizedInputs } from "./hooks";
import { isEmpty } from "lodash";

type TSelectOption = {
  value: string;
  label: string;
};

const freqEncodingOptions: TSelectOption[] = [
  { value: "axis", label: "Samples along axis of spectral space with the given list range of frequencies" },
  { value: "gaussian", label: "Samples frequency of Fourier series from Gaussian" },
  { value: "diagonal", label: "Samples along diagonal of spectral space with the given list range of frequencies" },
  { value: "full", label: "Samples along entire spectral space for all combinations of frequencies in given list" }
]

export function FourierNetArchSettings({ data }: { data: ModulusComponentDataCommon & FourierNetArch }) {
  const { setNodes } = useReactFlow();
  const {
    newParameterToAdd,
    setNewParameterToAdd,
    handleAddParameterizedInput,
    handleRemoveParameterizedInput
  } = useParameterizedInputs();

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h5>Input variables</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.input_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.input_keys.map((k) => ({ value: k, label: k }))}
            onChange={(inputVars) =>
              handleSettingChange({
                input_keys: inputVars.map((v) => v.value),
              })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Parameterized input variables</h5>

          {!isEmpty(data.parameterized_inputs) && (
            <>
              <Row className="gx-1 mb-1">
                <Col xs={4}>
                  <span className="text-muted font-10 mt-1">Symbol</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Default</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Min</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Max</span>
                </Col>
                <Col xs={1}></Col>
              </Row>
              {Object.entries(data.parameterized_inputs).map(([key, input]) => (
                <Row key={`parameterized_inputs_${key}`} className="gx-1 mb-1">
                  <Col xs={4}>
                    <input
                      type="symbol"
                      className="form-control mx-0"
                      placeholder="Symbol"
                      defaultValue={input.symbol}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, symbol: e.target.value },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Value"
                      step={0.1}
                      defaultValue={input.value}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, value: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Min"
                      step={0.1}
                      defaultValue={input.min}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, min: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Max"
                      step={0.1}
                      defaultValue={input.max}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, max: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col xs={1}>
                    <Button className="btn-remove-small" onClick={() => handleRemoveParameterizedInput(data, key)}>
                      <i className="mdi mdi-close-circle-outline" />
                    </Button>
                  </Col>
                </Row>
              ))}
            </>
          )}
          <Row className="gx-1 mt-1">
            <Col xs={8}>
              <Form.Control
                type="text"
                placeholder="Symbol (name) of the variable"
                value={newParameterToAdd}
                onChange={(e) => setNewParameterToAdd(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                className="btn-add"
                disabled={!newParameterToAdd}
                onClick={() => handleAddParameterizedInput(data)}>
                <i className="mdi mdi-plus" /> Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Output variables</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.output_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.output_keys.map((k) => ({ value: k, label: k }))}
            onChange={(outputVars) =>
              handleSettingChange({ output_keys: outputVars.map((v) => v.value) })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>List of keys to detach gradients</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.detach_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.detach_keys.map((k) => ({ value: k, label: k }))}
            onChange={(detachKeys) =>
              handleSettingChange({ detach_keys: detachKeys.map((v) => v.value) })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Frequency encoding</h5>
          <Select<TSelectOption>
            className="react-select settings-select"
            options={freqEncodingOptions}
            defaultValue={
              data.frequencies
                ? freqEncodingOptions.find(o => o.value === data.frequencies?.frequency_type)
                : { value: "axis", label: "Samples along axis of spectral space with the given list range of frequencies" }
            }
            onChange={(option) => handleSettingChange({ frequencies: { ...data.frequencies, frequency_type: option.value } })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Frequencies</h5>
          <input
            type="text"
            className="form-control"
            defaultValue={data.frequencies ? data.frequencies?.freqs.toString() : defaultFourierNetArchSettings.frequencies.freqs.toString()}
            onChange={(e) => handleSettingChange({ frequencies: { ...data.frequencies, freqs: e.target.value } })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Layer size for every hidden layer of the model</h5>
          <input
            type="number"
            className="form-control"
            defaultValue={data.layer_size ?? defaultFourierNetArchSettings.layer_size}
            onChange={(e) => handleSettingChange({ layer_size: parseInt(e.target.value) })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Number of hidden layers of the model</h5>
          <input
            type="number"
            className="form-control"
            defaultValue={data.nr_layers ?? defaultFourierNetArchSettings.nr_layers}
            onChange={(e) => handleSettingChange({ nr_layers: parseInt(e.target.value) })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Apply skip connections every 2 hidden layers?</h5>
          <input
            type="checkbox"
            checked={data.skip_connections ?? defaultFourierNetArchSettings.skip_connections}
            onChange={(e) => handleSettingChange({ skip_connections: e.target.checked })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Use weight norm on fully connected layers?</h5>
          <input
            type="checkbox"
            checked={data.weight_norm ?? defaultFourierNetArchSettings.weight_norm}
            onChange={(e) => handleSettingChange({ weight_norm: e.target.checked })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Use an adaptive activation functions?</h5>
          <input
            type="checkbox"
            checked={data.adaptive_activations ?? defaultFourierNetArchSettings.adaptive_activations}
            onChange={(e) => handleSettingChange({ adaptive_activations: e.target.checked })}
          />
        </Col>
      </Row>
    </>
  );
}
