import { Environment, Project } from "types";

/**
 * Parameter for .sort() function:
 *  - sorts environments or projects by the following criteria:
 * 1. Running environments first
 * 2. Most recently updated environments first
 * @param a Environment or Project
 * @param b Environment or Project
 * @returns number
 */
export function customSort(a: Environment | Project, b: Environment | Project): number {
  if (a.state === "running" && b.state !== "running") {
    return -1;
  } else if (a.state !== "running" && b.state === "running") {
    return 1;
  } else {
    const aDate = new Date(a.updated_at);
    const bDate = new Date(b.updated_at);
    return bDate.getTime() - aDate.getTime();
  }
}