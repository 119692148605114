import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dataset } from "types";
import { useState } from "react";
import { useForm } from "react-hook-form";

export function useDatasetForm() {
  const formats = [
    { value: "json", name: "JSON" },
    { value: "csv", name: "CSV"},
    { value: "npz", name: "Numpy (.npz)" },
    { value: "h5", name: "HDF5" },
  ];

  const [selectedFormat, setFormats] = useState<{ value: "json" | "npz" | "csv" | "h5"; name: string }>({
    value: "h5",
    name: "HDF5",
  });

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object({
      label: yup.string().required("Please enter dataset label"),
      format: yup.string().required("Please select dataset format"),
    })
  );

  /*
   * form methods
   */
  const methods = useForm<Dataset>({});
  const {
    handleSubmit,
    register,
    control,
    formState: { errors: formErrors },
  } = methods;

  return {
    formats,
    selectedFormat,
    setFormats,
    schemaResolver,
    handleSubmit,
    register,
    control,
    formErrors,
  };
}
