import { v4 as uuidv4 } from "uuid";
import type { FullyConnectedArch, FullyConnectedCreator } from "./types";
import { Activation } from "../activation-functions";
import { checkRequiredFields } from "modulus-interop/utils";
import { Parameter, SimulatorComponentSettings } from "modulus-interop/types";

export const defaultFullyConnectedArchSettings: Partial<FullyConnectedArch> = {
  input_keys: ["x", "y", "z"],
  parameterized_inputs: {},
  output_keys: ["u", "v", "w", "p"],
  detach_keys: [],
  layer_size: 512,
  num_layers: 6,
  activation_fn: Activation.SILU,
  skip_connections: false,
  weight_norm: true,
  adaptive_activations: false,
};

export function FullyConnectedArch(
  settings?: FullyConnectedArch,
  variableParameters?: SimulatorComponentSettings["variable_parameters"]
): FullyConnectedCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("FullyConnected"),
    slug: Object.freeze("fully_connected"),
    settings: {
      ...defaultFullyConnectedArchSettings,
      ...settings,
    },
    set(settings: Partial<FullyConnectedArch>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredFields(["input_keys", "output_keys"], this.settings);
    },
    generateCode() {
      this.validate();
      const { input_keys, output_keys, detach_keys, layer_size, num_layers } = this.settings;

      const parameterizedEquationsKeys = Object.values(variableParameters.equations)?.map(
        (input: Parameter) => `Key("${input?.symbol.toString()}")`
      );
      const parameterizedInputsKeys = Object.values(variableParameters.inputs)?.map(
        (input: Parameter) => `Key("${input?.symbol.toString()}")`
      );
      const allInputs = [...input_keys, ...parameterizedEquationsKeys, ...parameterizedInputsKeys];

      return `
    architecture_${this.slug} = modulus_models["fully_connected"](
        input_keys=[${allInputs?.map((key: string) => `Key("${key.toString()}")`).join(", ")}],
        output_keys=[${output_keys?.map((key: string) => `Key("${key.toString()}")`).join(", ")}],
        detach_keys=[${detach_keys ? detach_keys.map((key: string) => `"${key.toString()}"`).join(", ") : "[]"}],
        layer_size=${layer_size},
        nr_layers=${num_layers})

    nodes = nodes + [architecture_${this.slug}.make_node(name="architecture_${this.slug}")]`;
    },
  };
}
