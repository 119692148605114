import { useState } from "react";

export const usePaginationState = () => {
  const [activePage, setActivePage] = useState(1);

  const updateActivePage = (page: number) => {
    setActivePage(page);
  };

  return {
    activePage,
    updateActivePage,
  }
}