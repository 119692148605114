import { useState } from "react";
import { useAsyncDebounce, FilterValue } from "react-table";
import classNames from "classnames";

type GlobalFilterProps = {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
  searchBoxClass?: string;
};

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, searchBoxClass }: GlobalFilterProps) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState<any>(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className={classNames(searchBoxClass)}>
      <span className="d-flex align-items-center">
        Search :{" "}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          className="form-control w-auto ms-1"
        />
      </span>
    </div>
  );
};

export default GlobalFilter;
