import React, { useState } from "react";
import { Row, Col, Button, Modal, Dropdown, Card } from "react-bootstrap";

// components
import { PageTitle } from "components/PageTitle";
import { SingleProjectCard } from "../SingleProjectCard";

import { Environment, Project } from "types";
import { CreateProjectModal } from "../CreateProjectModal";
import { useQueryProjects } from "queries/projects";
import SimlLoader from "components/SimlLoader";
import { Link } from "react-router-dom";
import css from "../../environments/EnvironmentsListPage/style.module.css"

export const ProjectsPage = () => {
  const [modalActive, setModalActive] = useState(false);
  const { status, isError: isProjectsError, data: environments, error: projectsError } = useQueryProjects();

  if (status === "loading") {
    return <SimlLoader/>;
  }

  if (isProjectsError) {
    return <span>Error loading the projects data: {projectsError}</span>;
  }

  if (!environments) {
    return <span>You don't have any available projects.</span>;
  }

  return (
    <>
      <Modal show={modalActive} className="custom-modal" onHide={() => setModalActive(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Project</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateProjectModal />
        </Modal.Body>
      </Modal>

      <PageTitle
        breadCrumbItems={[
          { label: "Projects", path: "/model-engineer/projects", active: true },
        ]}
        title={"Projects"}
      />

      <Row className="justify-content-between">
        <Col>
          <Button className="btn-rounded blue fit" onClick={() => setModalActive(true)}>
            <i className="mdi mdi-plus" /> Create Project
          </Button>
        </Col>

        <Col className="d-inline-flex justify-content-end">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              All
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* This has to be ignored because of some compatibility issues.. */}
              {/* @ts-ignore */}
              <Dropdown.Item href="#/action-1">All</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Ongoing</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Finished</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="btn-group mb-3 ms-2 d-none d-sm-inline-block">
            <Button variant="secondary">
              <i className="dripicons-view-apps" />
            </Button>
          </div>
          <div className="btn-group mb-3 d-none d-sm-inline-block">
            <Button variant="link" className="text-muted">
              <i className="dripicons-checklist" />
            </Button>
          </div>
        </Col>
      </Row>

      {environments?.results?.filter((result) => result.mode === "simulation_studio")?.length === 0 && 
        <Row xs={12} lg={2} className="mt-2">
          <Card>
            <Card.Body>
              <Card.Title style={{fontSize: "1.2rem", fontWeight: "bold"}}>You haven't created any projects yet</Card.Title>
              <Card.Text style={{fontSize: "0.8rem"}}>
                Simulation Studio let's you easily create and run simulations. To start using it, you have to create new Project.
                <br/><br/>
                Learn more about Environments in our <Link to="https://docs.siml.ai/simulation-studio/projects" target="_blank" className="text-info" style={{fontWeight: "bold"}}>documentation</Link>!
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      }
      {environments?.results?.length > 0 && 
        <Row>
          {environments!.results.filter((env: Environment) => env.mode === "simulation_studio").map((project: Project) => {
            return (
              <Col lg={6} xxl={3} key={"proj-" + project.id} className={css.environmentCardContainer}>
                <SingleProjectCard project={project} />
              </Col>
            );
          })}
      </Row>}
    </>
  );
};
