import React, { useState, useEffect } from "react";
import { MAGNITUDE_COLOR_SCALES } from "../constants";
import classNames from "classnames";
import css from "../styles.module.css";
import { commands } from "../constants";

interface MagnitudeScaleProps {
  value: number[];
  updateLs: (s: any) => void;
  sendMessage: (s: any) => void;
  selected: string;
}

const MagnitudeScale = ({ value, selected, updateLs, sendMessage }: MagnitudeScaleProps) => {
  const [selectedScale, setSelectedScale] = useState(selected || "rainbow");
  const [isOpen, setIsOpen] = useState(false);

  const min = value ? value[0] : null;
  const max = value ? value[1] : null;
  const scale = value ? createScale(min, max) : null;

  const handleScaleSelect = (scaleName: any) => {
    setSelectedScale(scaleName);
    const data = {
      type: "message",
      event: commands.onColorProfileChange,
      payload: {
        colorProfile: scaleName,
      },
    };
    sendMessage(data);
    updateLs({ colorProfile: scaleName });
    setIsOpen(false);
  };

  useEffect(() => {
    if (selected) {
      handleScaleSelect(selected);
    }
  }, []);

  return (
    <div>
      <div>
        <div className={classNames([css.magnitude_scale_container])} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <i
              style={{
                paddingLeft: "12px",
              }}
              className="dripicons-cross"
            />
          ) : (
            <i
              style={{
                paddingLeft: "12px",
              }}
              className="dripicons-view-apps"
            />
          )}
        </div>
        {isOpen &&
          MAGNITUDE_COLOR_SCALES.map((scale: any, index: any) => (
            <div
              key={scale.name}
              style={{
                width: "40px",
                height: "20px",
                background: `linear-gradient(to right, ${scale.colors.join(", ")})`,
                border: "1px solid black",
                cursor: "pointer",
                marginTop: "20px",
                position: "absolute",
                right: "2%",
                bottom: `${9 + index * 2.5}%`,
                zIndex: 99999,
              }}
              onClick={() => handleScaleSelect(scale.name)}
            />
          ))}
      </div>
      <div>
        {selectedScale && (
          <div
            style={{
              width: "200px",
              height: "30px",
              background: `linear-gradient(to right, ${MAGNITUDE_COLOR_SCALES.find(
                (scale: any) => scale.name === selectedScale
              ).colors.join(", ")})`,
              border: "1px solid white",
              marginTop: "20px",
              position: "absolute",
              right: "2%",
              bottom: "2%",
              zIndex: 99999,
            }}>
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                marginTop: "3px",
              }}>
              V Magnitude
            </div>

            {scale &&
              scale.map((num, index) => {
                const position = (index / 5) * 100;
                return (
                  <div
                    key={num}
                    style={{
                      position: "absolute",
                      left: `${position}%`,
                      top: "25px",
                      transform: "translateX(-50%)",
                      fontWeight: "bold",
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <div
                      style={{
                        width: "1px",
                        height: "10px",
                        background: "white",
                      }}
                    />
                    <div>{Number(Math.floor(num * 1000) / 1000)}</div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

const createScale = (min: number, max: number) => {
  return [
    min,
    min + (max - min) / 5,
    min + ((max - min) / 5) * 2,
    min + ((max - min) / 5) * 3,
    min + ((max - min) / 5) * 4,
    max,
  ];
};

export default MagnitudeScale;
