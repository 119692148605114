import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, Card } from "react-bootstrap";

import { PageTitle } from "components/PageTitle";
import type { Plugin } from "types";
import { useQueryPlugins } from "queries";
import DropdownMenu, { Props } from "components/dropdownMenu";
import { useState } from "react";
import SimlLoader from "components/SimlLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { PluginCard } from "../PluginCard";
import PluginCardPopup from "../pluginCardPopup";

export const PluginsListPage = () => {
  const [publicFilter, setPublicFilter] = useState("all");
  const [view, setView] = useState("grid");
  const { isLoading, isError, data: plugins, error } = useQueryPlugins();
  const { user } = useAuth0();
  const [showEquationView, setShowEquationView] = useState(false);
  const [clickedPlugin, setClickedPlugin] = useState("");

  const handleShowEquationView = (id: string) => {
    setClickedPlugin(id);
    setShowEquationView(true);
  };
  const handleCloseEquationView = () => setShowEquationView(false);

  const DROPDOWN_DATA: Props['data'] = [
    {
      key: 'all',
      title: 'Public Plugins',
      callback: () => setPublicFilter('all')
    },
    {
      key: 'my',
      title: 'My plugins',
      callback: () => setPublicFilter('my')
    },
  ]

  if (isLoading) {
    return <SimlLoader/>
  }

  if (isError) {
    return <span>Error loading the data: {error}</span>;
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Plugins", path: "/model-engineer/plugins", active: true },
        ]}
        title={"Plugins"}
      />

      <Row className="justify-content-between">
        {/* <Col>
          <Link to="/model-engineer/simulators/new">
            <Button variant="primary" className="btn-rounded mb-3 blue">
              <i className="mdi mdi-plus" /> Create Simulator
            </Button>
          </Link>
        </Col> */}

        <Col className="d-inline-flex justify-content-end">
          <DropdownMenu data={DROPDOWN_DATA} />

          <div className="btn-group mb-3 ms-2 d-none d-sm-inline-block">
            <Button
              variant={view === "grid" ? "primary" : "link"}
              className={view === "grid" ? "" : "text-muted"}
              onClick={() => setView("grid")}>
              <i className="dripicons-view-apps" />
            </Button>
          </div>
          <div className="btn-group mb-3 d-none d-sm-inline-block">
            <Button
              variant={view === "list" ? "primary" : "link"}
              className={view === "list" ? "" : "text-muted"}
              onClick={() => setView("list")}>
              <i className="dripicons-checklist" />
            </Button>
          </div>
        </Col>
      </Row>

      <Row style={{
        rowGap: "1.5rem",
      }}>
        { !plugins?.results && (
          <Row xs={1} lg={2} className="mt-2" style={{marginLeft: "0", marginRight: "0"}}>
            <Card>
              <Card.Body>
                <span style={{fontSize: "1rem"}}>No plugins found, yet.</span>
              </Card.Body>
            </Card>
          </Row>
        )}
        { plugins && plugins?.results && plugins?.results
          .filter((plugin: Plugin) => (publicFilter === "all" ? true : (plugin.public === false || (plugin.public && plugin.owner === user.sub))))
          .map((plugin: Plugin) => {
          return (
            <Col lg={6} xxl={3} key={"plugin-" + plugin.id} 
              onClick={(e) => {
                if ((e.target as Element).classList.contains("dripicons-cross")) return;
                handleShowEquationView(plugin.id)
              }
            } style={{cursor: "pointer"}}>
              <PluginCard plugin={plugin} />
            </Col>
          );
        })}
      </Row>

      <Modal show={showEquationView} size="xl">
        <ModalHeader>
          <strong>{plugins?.results?.find((plugin) => plugin.id === clickedPlugin)?.label}</strong>
        </ModalHeader>
        <ModalBody>
            <PluginCardPopup code={plugins?.results?.find((plugin) => plugin.id === clickedPlugin)?.code}/>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-primary" onClick={handleCloseEquationView}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
