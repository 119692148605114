import { useEffect, useState } from "react";
import { Handle, Position, useNodeId, useReactFlow } from "reactflow";
import { useVisualUIStore } from "../../../store";

import "../visualizer-node.css";

import inf_data_p from "../../../example-images/inf_data_p.png";
import inf_data_u from "../../../example-images/inf_data_u.png";
import inf_data_v from "../../../example-images/inf_data_v.png";
import inf_data_w from "../../../example-images/inf_data_w.jpg";
import validator_u from "../../../example-images/validator_u.png";
import validator_v from "../../../example-images/validator_v.png";

const exampleVisualizations: { [key: string]: any } = {
  p: inf_data_p,
  u: inf_data_u,
  v: inf_data_v,
  w: inf_data_w,
  val_u: validator_u,
  val_v: validator_v,
};

export function Visualizer2DImgNode({ data }: { data: any }) {
  const nodeId = useNodeId();
  const reactFlowInstance = useReactFlow();
  const [type, setType] = useState(undefined);
  const [variable, setVariable] = useState("");
  const [imgClass, setImgClass] = useState("viz-2d-img-empty");
  const [imgSrc, setImgSrc] = useState("https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg");
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const handleCloseSettings = () => setShowSettingsModal(false);
  const handleShowSettings = () => setShowSettingsModal(true);

  useEffect(() => {
    // TODO: generate Modulus code
  }, [type]);

  return (
    <>
      <Handle type="target" position={Position.Top} id="visualizer-viz2DImg" />
      <div className="node-icons">
        {/* <i className="dripicons-gear" onClick={handleShowSettings} /> */}
        <i
          className="dripicons-cross"
          onClick={() => {
            reactFlowInstance.setNodes(reactFlowInstance.getNodes().filter((n: any) => n.id !== nodeId));
          }}
        />
      </div>
      <div className="title">Visualizer: 2D</div>
      <div className="mb-2">
        <label htmlFor="type">Type:</label>
        <select
          id="type"
          name="type"
          onChange={(e: any) => {
            e.preventDefault();
            setType(e.target.value);
            setVariable("");
            setImgClass("viz-2d-img-empty");
            setImgSrc("https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg");
          }}
          defaultValue={type}>
          <option value="">Select visualization type...</option>
          <option value="singleImage">Single physical variable</option>
          <option value="modelValidator">Model validator</option>
        </select>
      </div>

      {type === "singleImage" && (
        <div className="mb-2">
          <label htmlFor="variable">Physical variable:</label>
          <select
            id="variable"
            name="variable"
            onChange={(e: any) => {
              e.preventDefault();
              setVariable(e.target.value);
              if (!e.target.value) {
                setImgClass("viz-2d-img-empty");
                setImgSrc("https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg");
                return;
              }

              setImgClass("viz-2d-img");
              setImgSrc(exampleVisualizations[e.target.value]);
            }}
            defaultValue={variable}>
            <option value="">Select a variable...</option>
            <option value="u">u</option>
            <option value="v">v</option>
            <option value="p">Pressure</option>
          </select>
        </div>
      )}

      {type === "modelValidator" && (
        <div className="mb-2">
          <label htmlFor="variable">Physical variable:</label>
          <select
            id="variable"
            name="variable"
            onChange={(e: any) => {
              e.preventDefault();
              setVariable(e.target.value);
              if (!e.target.value) {
                setImgClass("viz-2d-img-empty");
                setImgSrc("https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg");
                return;
              }

              setImgClass("viz-2d-img validator-img");
              setImgSrc(exampleVisualizations[`val_${e.target.value}`]);
            }}
            defaultValue={variable}>
            <option value="">Select a variable...</option>
            <option value="u">u</option>
            <option value="v">v</option>
          </select>
        </div>
      )}

      {type && <img className={imgClass} src={imgSrc} alt="" />}
    </>
  );
}
