import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { defaultDiffusionInterfaceSettings } from "modulus-interop/equations/diffusion";
import { DiffusionInterfaceType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useReactFlow } from "reactflow";
import { containsOnlyNumbers } from "utils/string";

export function DiffusionInterfaceSettings({ data }: { data: ModulusComponentDataCommon & DiffusionInterfaceType }) {
  const { setNodes } = useReactFlow();

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row className="mt-2 mb-2">
        <Col>
          <small>Matches the boundary conditions at an interface</small>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Dependent variables to match the first boundary conditions at the interface (T_1)</h5>
          <input
            type="text"
            className="form-control"
            placeholder="Please specify a value"
            defaultValue={data.T_1 ?? defaultDiffusionInterfaceSettings.T_1}
            onChange={(e) => {
              handleSettingChange({
                T_1: e.target.value,
                D_1: {
                  ...data.D_1,
                  symbol: `D1_${e.target.value}`,
                },
              });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Dependent variables to match the second boundary conditions at the interface (T_2)</h5>
          <input
            type="text"
            className="form-control"
            placeholder="Please specify a value"
            defaultValue={data.T_2 ?? defaultDiffusionInterfaceSettings.T_2}
            onChange={(e) => {
              handleSettingChange({
                T_2: e.target.value,
                D_2: {
                  ...data.D_2,
                  symbol: `D2_${e.target.value}`,
                },
              });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col>
              <h5>
                Diffusivity at the 1st interface (D_1)
                <span className="float-end text-muted">
                  <Tooltip>
                    <TooltipTrigger>
                      Parameterized
                      <input
                        type="checkbox"
                        className="ms-1"
                        checked={data.D_1.parameterized ?? defaultDiffusionInterfaceSettings.D_1.parameterized}
                        onChange={(e) =>
                          handleSettingChange({
                            D_1: {
                              ...data.D_1,
                              parameterized: e.target.checked,
                              min: e.target.checked ? data.D_1.min : null,
                              max: e.target.checked ? data.D_1.max : null,
                            },
                          })
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
                  </Tooltip>
                </span>
              </h5>
            </Col>
          </Row>
          {data.D_1.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.D_1.symbol ?? defaultDiffusionInterfaceSettings.D_1.symbol}
                  onChange={(e) => handleSettingChange({ D_1: { ...data.D_1, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.D_1.value ?? defaultDiffusionInterfaceSettings.D_1.value}
                  onChange={(e) => handleSettingChange({ D_1: { ...data.D_1, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.D_1.min ?? defaultDiffusionInterfaceSettings.D_1.min}
                  onChange={(e) => handleSettingChange({ D_1: { ...data.D_1, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.D_1.max ?? defaultDiffusionInterfaceSettings.D_1.max}
                  onChange={(e) => handleSettingChange({ D_1: { ...data.D_1, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.D_1.value ?? defaultDiffusionInterfaceSettings.D_1.value}
              onChange={(e) =>
                handleSettingChange({
                  D_1: {
                    ...data.D_1,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col>
              <h5>
                Diffusivity at the 2nd interface (D_2)
                <span className="float-end text-muted">
                  <Tooltip>
                    <TooltipTrigger>
                      Parameterized
                      <input
                        type="checkbox"
                        className="ms-1"
                        checked={data.D_2.parameterized ?? defaultDiffusionInterfaceSettings.D_2.parameterized}
                        onChange={(e) =>
                          handleSettingChange({
                            D_2: {
                              ...data.D_2,
                              parameterized: e.target.checked,
                              min: e.target.checked ? data.D_2.min : null,
                              max: e.target.checked ? data.D_2.max : null,
                            },
                          })
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
                  </Tooltip>
                </span>
              </h5>
            </Col>
          </Row>
          {data.D_2.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.D_2.symbol ?? defaultDiffusionInterfaceSettings.D_2.symbol}
                  onChange={(e) => handleSettingChange({ D_2: { ...data.D_2, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.D_2.value ?? defaultDiffusionInterfaceSettings.D_2.value}
                  onChange={(e) => handleSettingChange({ D_2: { ...data.D_2, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.D_2.min ?? defaultDiffusionInterfaceSettings.D_2.min}
                  onChange={(e) => handleSettingChange({ D_2: { ...data.D_2, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.D_2.max ?? defaultDiffusionInterfaceSettings.D_2.max}
                  onChange={(e) => handleSettingChange({ D_2: { ...data.D_2, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={data.D_2.value ?? defaultDiffusionInterfaceSettings.D_2.value}
              onChange={(e) =>
                handleSettingChange({
                  D_2: {
                    ...data.D_2,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Dimensions</h5>
          <Select
            className="react-select settings-select"
            options={[
              { value: 2, label: "2D" },
              { value: 3, label: "3D" },
            ]}
            defaultValue={data.dim ? { value: data.dim, label: data.dim + "D" } : { value: 3, label: "3D" }}
            onChange={(option) => handleSettingChange({ dim: option.value })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Time-dependent</h5>
          <input
            type="checkbox"
            checked={data.time ?? defaultDiffusionInterfaceSettings.time}
            onChange={(e) => handleSettingChange({ time: e.target.checked })}
          />
        </Col>
      </Row>
    </>
  );
}
