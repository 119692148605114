import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { track } from "@vercel/analytics"

type ProfileMenuItem = {
  label: string;
  icon: string;
  redirectTo: string;
  openInNewWindow?: boolean;
};

type ProfileDropdownProps = {
  menuItems: Array<ProfileMenuItem>;
  profilePic?: any;
  username: string;
  userTitle?: string;
};

export const ProfileDropdown: FC<ProfileDropdownProps> = (props) => {
  const { user, logout } = useAuth0();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  /*
   * toggle profile-dropdown
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
      <Dropdown.Toggle
        variant="button"
        id="dropdown-profile"
        as={Link}
        to="#"
        onClick={toggleDropdown}
        className="nav-link dropdown-toggle nav-user arrow-none me-0">
        <span className="account-user-avatar">
          <img src={user.picture} className="rounded-circle" alt="user" />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
        <div onClick={toggleDropdown}>
          {props.menuItems.map((item, i) => {
            if (item.openInNewWindow) {
              return (
                <a href={item.redirectTo} className="dropdown-item notify-item" key={i + "-profile-menu"} target="_blank" onClick={() => track(`Profile ${item.label}`)}>
                  <i className={`${item.icon} me-1`} />
                  <span>{item.label}</span>
                </a>
              );
            }

            return (
              <Link to={item.redirectTo} className="dropdown-item notify-item" key={i + "-profile-menu"} onClick={() => track(`Profile ${item.label}`)}>
                <i className={`${item.icon} me-1`} />
                <span>{item.label}</span>
              </Link>
            );
          })}
          <button
            className="dropdown-item notify-item"
            onClick={() => logout({ returnTo: window.location.origin + "/account/logout" })}>
            <i className="mdi mdi-logout me-1" />
            <span>Logout</span>
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
