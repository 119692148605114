import { checkRequiredFields } from "modulus-interop/utils";
import { v4 as uuidv4 } from "uuid";
import {
  MaxwellFreqRealCreator,
  MaxwellFreqRealType,
  PECCreator,
  PECType,
  SommerfeldBCCreator,
  SommerfeldBCType,
} from "./types";

function checkRequiredPhysicsFields<T>(requiredFields: string[], settings: T) {
  if (requiredFields.includes("k") && !settings.hasOwnProperty("k")) {
    throw Error("Wave number (k) not set!");
  }
}

export const defaultMaxwellFreqRealSettings: Partial<MaxwellFreqRealType> = {
  ux: "ux",
  uy: "uy",
  uz: "uz",
  k: { symbol: "k_MaxwellFreqReal", value: 1.0, parameterized: false },
  mixed_form: false,
};

export function MaxwellFreqReal(settings?: MaxwellFreqRealType): MaxwellFreqRealCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("MaxwellFreqReal"),
    slug: Object.freeze("maxwell_freq_real"),
    settings: {
      ...defaultMaxwellFreqRealSettings,
      ...settings,
    },
    set(settings: Partial<MaxwellFreqRealType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredPhysicsFields(["k"], this.settings);
    },
    generateCode() {
      this.validate();
      const { ux, uy, uz, k, mixed_form } = this.settings;

      return `
    from modulus.eq.pdes.electromagnetic import MaxwellFreqReal
    ${this.slug} = MaxwellFreqReal(
        ux=${ux},
        uy=${uy},
        uz=${uz},
        k=${k.parameterized ? `Symbol("${k.symbol}")` : k.value || k}},
        mixed_form=${mixed_form})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}

export const defaultPECSettings: Partial<PECType> = {
  ux: "ux",
  uy: "uy",
  uz: "uz",
  dim: 3,
};

export function PEC(settings?: PECType): PECCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("PEC"),
    slug: Object.freeze("pec"),
    settings: {
      ...defaultPECSettings,
      ...settings,
    },
    set(settings: Partial<PECType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredFields(["dim"], this.settings);
    },
    generateCode() {
      this.validate();
      const { ux, uy, uz, dim } = this.settings;

      return `
    from modulus.eq.pdes.electromagnetic import PEC
    ${this.slug} = PEC(
        ux=${ux},
        uy=${uy},
        uz=${uz},
        dim=${dim})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}

export const defaultSommerfeldBCSettings: Partial<SommerfeldBCType> = {
  ux: "ux",
  uy: "uy",
  uz: "uz",
};

export function SommerfeldBC(settings?: SommerfeldBCType): SommerfeldBCCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("SommerfeldBC"),
    slug: Object.freeze("sommerfeld_bc"),
    settings: {
      ...defaultSommerfeldBCSettings,
      ...settings,
    },
    set(settings: Partial<SommerfeldBCType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredFields(["dim"], this.settings);
    },
    generateCode() {
      this.validate();
      const { ux, uy, uz } = this.settings;

      return `
    from modulus.eq.pdes.electromagnetic import SommerfeldBC
    ${this.slug} = SommerfeldBC(
        ux=${ux},
        uy=${uy},
        uz=${uz})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}
