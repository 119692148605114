import { createContext, useContext, useEffect, useState } from "react";
import { getSimulatoTutorialStatus, updateTutorialLocalStorage } from "../SimulatorsListPage/tutorialHelper";

const TourContext = createContext({
    tourStep: 0,
    changeTourStep: (val: number) => {}
});

const TourProvider = ({ children }: { children: any }) => {
  const [tourStep, setTourStep] = useState(() => {
    const status = getSimulatoTutorialStatus(1)
    return status?.startsWith("started_") ? Number(status?.split("_")?.[1]) : 0
  });

  const changeTourStep = (val: number) => {
    setTourStep(val);
    updateTutorialLocalStorage({ newStatus: "started_" + `${val}`, version: 1})
  };

  return (
    <TourContext.Provider value={{ tourStep, changeTourStep }}>
      {children}
    </TourContext.Provider>
  );
};

const useTourContext = () => {
    const context = useContext(TourContext);
    if (!context) {
      throw new Error('context must be used within context provider');
    }
    return context;
  };

export { TourProvider, useTourContext };