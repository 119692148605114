
export const getDate = (whenAgo: "year" | "month" | "day", now: Date) => {
  let val = "";
  switch (whenAgo) {
    case "year":
      val = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).toString();
      break;
    case "month":
      val = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getFullYear() + "-" + (new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getMonth() + 1).toString().padStart(2, '0') + "-" + new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getDate().toString().padStart(2, '0');
      break;
    case "day":
      val = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).getFullYear() + 
            "-" + (new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).getMonth() + 1).toString().padStart(2, '0') + 
            "-" + new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).getDate().toString().padStart(2, '0');
      break;
    default:
      break;
  }
  return val;
}

export const getNowAsNiceString = () => {
  const now = new Date();
  const niceString = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, '0') + "-" + now.getDate().toString().padStart(2, '0');
  return niceString;
}