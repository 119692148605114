import { Button } from "react-bootstrap";
import { useVisualUIStore } from "../store";

import "./sidebar.css";
import { SettingsLoader } from "../SettingsLoader";
import { useReactFlow } from "reactflow";

function capitalizeFirstLetter(str: String) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const Sidebar = ({setIsSidebarOpen} : any) => {
  const { settingsSidebarNodeId, setSidebarNodeId } = useVisualUIStore();
  const { getNode } = useReactFlow();

  var title = ""
  var color = "#fff"

  const node = getNode(settingsSidebarNodeId);

  if(settingsSidebarNodeId && getNode && node) {
    const type = node?.type;
  
    // workaround for the fact that the sidebar is not closing when the setSidebarNodeId is set to "" from the nodes
    if(!type) {
      setIsSidebarOpen(false)
    }

    title = type ? capitalizeFirstLetter(type) : "";
    if(type === "equation") {
      color = "#003543"
    }
    if(type === "architecture") {
      color = "#8dbb00"
    }
    if(type === "constraint") {
      color = "#8800a3"
    }
  }
  // TODO: Stylize the component based on the type of node
  return (
    <aside style={{ display: settingsSidebarNodeId ? "block" : "none", minWidth: "400px" }} data-tour='tour_neural_net_settings_panel'>
      {/* <Button size="sm" variant="primary" onClick={() => setSidebarNodeId("")}>
        Close
      </Button> */}
      <h1>{title}</h1>
      <SettingsLoader node={node}/>
    </aside>
  );
};
