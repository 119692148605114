import { FileDropzone } from "components/VisualUI/FileDropzone";
import { GeometryNode } from "modulus-interop/geometries/types";
import { useModulusInterop } from "modulus-interop/reactflow";
import { serializeSimulator } from "modulus-interop/schema";
import { ModulusHydraConfig } from "modulus-interop/types";
import { useUpdateSimulator } from "mutations";
import { useQuerySimulator } from "queries";
import { useParams } from "react-router-dom";
import omit from "lodash/omit"
import { Handle, Node, NodeProps, Position, useNodeId, useReactFlow } from "reactflow";

import "./geometry-node.css";
import { GeometryProvider } from "./geometryContext";

export function GeometryGroupNode({ data }: NodeProps) {
  let { id } = useParams();
  const nodeId = useNodeId();
  const { deleteElements } = useReactFlow();
  const { data: simulator } = useQuerySimulator(id);
  const { serializeNodes } = useModulusInterop();

  // const fileNodes = nodes.filter((node) => node.type === "geometry" && node.parentNode === nodeId);

  const updateMutation = useUpdateSimulator();

  const onFileUpload = (fileNodes: Node<any, string>[]) => {
    const conf = simulator?.modulus_components?.conf ?? {} as ModulusHydraConfig;
    const modulusComponents = serializeNodes();
    const serializedSimulator = serializeSimulator(simulator.id, conf, {
      ...modulusComponents,
      geometries: fileNodes.reduce((a, v) => ({ ...a, [v.id]: v}), {}) 
    });

    updateMutation.mutate({
      id: simulator.id,
      modulus_components: serializedSimulator,
    });
  };

  const onFileRemove = (nodeId: string) => {
    const conf = simulator?.modulus_components?.conf ?? {} as ModulusHydraConfig;
    const modulusComponents = serializeNodes();
    const serializedSimulator = serializeSimulator(simulator.id, conf, {
      ...modulusComponents,
      geometries: omit(modulusComponents.geometries, nodeId) 
    });

    updateMutation.mutate({
      id: simulator.id,
      modulus_components: serializedSimulator,
    });
  };

  return (
    <div className="geometry-group-node" style={{ height: data.height, background: '#0D101B' }} data-tour='tour_geometry_node'>
      <Handle type="target" position={Position.Left} id="nn" />
        <div className="title">
            <div className="node-icons">
            <i
              className="dripicons-cross"
              onClick={() => {
                deleteElements({ nodes: [{ id: nodeId }] });
              }}
            />
          </div>
          <div>Geometry</div>
        </div>
      <div className="content">
        <GeometryProvider>
          <FileDropzone 
            accept={["igs", "wrl", "xml", "stp", "stl"]}
            onFileUpload={onFileUpload}
            onFileRemove={onFileRemove}
          />
        </GeometryProvider>
      </div>
      <Handle type="source" position={Position.Bottom} id="monitoring" />
    </div>
  );
}
