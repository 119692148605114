import { Row, Col, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// components
import { PageTitle } from "components/PageTitle";
import { FormInput } from "components/FormInput";
import { useSimulatorForm } from "../hooks";
import slugify from "slugify";
import { useCreateSimulator } from "mutations";
import { useQueryDatasets } from "queries";
import SimlLoader from "components/SimlLoader";

export const CreateSimulatorPage = () => {
  const { t } = useTranslation();

  const {
    handleSubmitCreateForm,
    createRegister,
    createControl,
    createFormErrors,
  } = useSimulatorForm();

  const createMutation = useCreateSimulator();

  const { isLoading, isError, data: datasets, error } = useQueryDatasets();

  if (isLoading) {
    return <SimlLoader/>
  }

  if (isError) {
    return <span>Error loading the data: {error}</span>;
  }

  console.log(datasets)

  /*
   * Handle form submission
     (well actually this will be passed as argument to handleSubmitCreateForm
     its kind of a workaround, so that we can use try catch block)
   */
  const onSubmit = async (formData: any) => {
    try {
      console.log("sending", {
        label: formData["label"],
        desc: formData["desc"],
        public: formData["public"],
        slug: slugify(formData["label"].toLowerCase()),
        datasets: formData["datasets"] || [],
      });
  
      await createMutation.mutateAsync({
        label: formData["label"],
        desc: formData["desc"],
        public: formData["public"],
        slug: slugify(formData["label"].toLowerCase()),
        datasets: formData["datasets"] || []
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Simulators", path: "/model-engineer/simulators" },
          { label: "Create a simulator", path: "/model-engineer/simulators/new", active: true },
        ]}
        title={"Create physics simulator"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <form onSubmit={handleSubmitCreateForm(onSubmit)}>
                    <Row>
                      <Col xl={6}>
                        <FormInput
                          type="text"
                          name="label"
                          label={t("Simulator name")}
                          placeholder={t("Enter label for the simulator")}
                          containerClass={"mb-3"}
                          register={createRegister}
                          key="label"
                          errors={createFormErrors}
                          control={createControl}
                        />

                        <FormInput
                          name="desc"
                          label={t("Description")}
                          placeholder={t("Simulator description")}
                          type="textarea"
                          rows="3"
                          containerClass={"mb-3"}
                          register={createRegister}
                          key="desc"
                          errors={createFormErrors}
                          control={createControl}
                        />

                        <FormInput
                          name="public"
                          label={t("Public?")}
                          type="checkbox"
                          containerClass={"mb-3"}
                          register={createRegister}
                          key="public"
                          errors={createFormErrors}
                          control={createControl}
                        />
                      </Col>
                    </Row>

                    {/* <Row className="mb-4">
                      <Col>
                        <h4>Dataset selection</h4>
                        <p>Picking a dataset is only needed if your simulator will be data-driven or hybrid (will use data + physics for training).</p>


                        <FormInput
                          name="dataset"
                          label="Available datasets"
                          type="select"
                          containerClass="mb-3"
                          className="form-select"
                          register={createRegister}
                          key="dataset"
                          errors={createFormErrors}
                          control={createControl}
                        >
                          <option value="">Select dataset</option>
                          {datasets!.results.map((ds: Dataset) => (
                            <option key={ds.id} value={ds.id}>
                              {ds.label}
                            </option>
                          ))}
                        </FormInput>
                      </Col>
                    </Row> */}

                    <Row className="mt-2">
                      <Col>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={createMutation.isLoading}
                        >
                          {t("Create simulator and move to visual editor")}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
