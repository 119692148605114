import { useState } from "react"

type Props = {
  tabs: {
    key: string,
    text: string,
    callback?: Function
  }[]
  children: JSX.Element,
  defaultValue?: string
}

// TODO: There should be tests here, to check if the keys are allright but lets consider we dont make mistakes for now :)

const DisplayedComponent = ({content, activeKey}: {content: JSX.Element[], activeKey: string}) => {
  const Component = content.find(item => item.key === activeKey);
  return Component
}

export function TabSwitchContent({ tabs, children, defaultValue }: Props) {
  const [activeKey, setActiveKey] = useState(defaultValue || tabs[0].key);

  return (
    <div className="custom-tab-switch">
      <nav>
        <ul>
          {tabs.map(tab => (
            <li 
            key={tab.key} 
            className={activeKey === tab.key ? 'active' : ''}
            onClick={() => {setActiveKey(tab.key)}}
            >
              {tab.text}
            </li>
          ))}
        </ul>
      </nav>

      <div className="content">
        <DisplayedComponent content={children.props.children} activeKey={activeKey}/>
      </div>
    </div>
  )
}

export function TabSwitchChartData({ tabs, children, setData }: Props & {
  setData: Function
}) {
  const [activeKey, setActiveKey] = useState(tabs[0].key);

  return (
    <div className="custom-tab-switch">
      <nav>
        <ul>
          {tabs.map(tab => (
            <li 
            key={tab.key} 
            className={activeKey === tab.key ? 'active' : ''}
            onClick={() => {setActiveKey(tab.key); setData(tab.callback())}}
            >
              {tab.text}
            </li>
          ))}
        </ul>
      </nav>

      <div className="content">
        {children}
      </div>
    </div>
  )
}