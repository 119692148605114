import { FullyConnectedArch, defaultFullyConnectedArchSettings } from "./fully-connected";
import { AFNOArch, defaultAFNOArchSettings } from "./afno";
import { Architecture, ArchitectureCreator, ArchitectureNode } from "./types";
import { defaultFNOArchSettings, FNOArch } from "./fno";
import {
  defaultFourierNetArchSettings,
  defaultModifiedFourierNetArchSettings,
  FourierNetArch,
  ModifiedFourierNetArch,
} from "./fourier";
import { defaultSirenArchSettings, SirenArch } from "./siren";
import { MovingTimeWindowArch } from "./moving-time-window";
import { defaultDGMArchSettings, DGMArch } from "./dgm";
import { Parameter, SimulatorComponentSettings } from "modulus-interop/types";
export { FullyConnectedArch, AFNOArch, FNOArch, FourierNetArch, ModifiedFourierNetArch };

export const architectureDefaultSettings: { [key: string]: Partial<Architecture> } = {
  fully_connected: defaultFullyConnectedArchSettings,
  dgm: defaultDGMArchSettings,
  afno: defaultAFNOArchSettings,
  fno: defaultFNOArchSettings,
  fourier: defaultFourierNetArchSettings,
  modified_fourier: defaultModifiedFourierNetArchSettings,
  siren: defaultSirenArchSettings,
};

export const architectureCreators: {
  [key: string]: (
    settings?: any,
    variableParameters?: SimulatorComponentSettings["variable_parameters"]
  ) => ArchitectureCreator;
} = {
  fully_connected: FullyConnectedArch,
  dgm: DGMArch,
  afno: AFNOArch,
  fno: FNOArch,
  fourier: FourierNetArch,
  modified_fourier: ModifiedFourierNetArch,
  siren: SirenArch,
  moving_time_window: MovingTimeWindowArch,
};

export function generateArchitectureCode(
  architecture: ArchitectureNode,
  variableParameters?: SimulatorComponentSettings["variable_parameters"]
): string {
  const creator = architectureCreators[architecture.data.mode];
  return creator(architecture.data, variableParameters).generateCode();
}

export function architeturesToCode(
  architectures: Record<string, ArchitectureNode> | {},
  variableParameters?: SimulatorComponentSettings["variable_parameters"]
): string {
  return Object.values(architectures)
    .map((a) => generateArchitectureCode(a, variableParameters))
    .join("\n");
}
