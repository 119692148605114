import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Plugin, PluginCreate, PluginUpdate } from "types";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useCreatePlugin() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Plugin, Error, PluginCreate>({
    mutationFn: async (plugin: PluginCreate) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/plugins/`, {
        method: "POST",
        body: JSON.stringify(plugin),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorRes = await res.json();
        const errorMsg = errorRes?.error || errorRes?.label[0] || "There was an error creating a new plugin."
        throw new Error(errorMsg);
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      const errorMsg = error.message;
      const capitalisedErrMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
      toast.error(capitalisedErrMsg, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (data: Plugin, __, ___) => {
      console.log("sent: ", data)
      toast.success("Plugin created successfully.", {
        theme: "dark",
      });
    },
  });
}

export function useUpdatePlugin() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Plugin, Error, PluginUpdate>({
    mutationFn: async (plugin: Partial<PluginUpdate>) => {
      console.log(plugin)
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/plugins/${plugin.id}/`, {
        method: "PATCH",
        body: JSON.stringify(plugin),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || "There was an error updating the plugin."
          throw new Error(errorMsg);
        } catch(err) {
          throw new Error("There was an error updating the plugin.");
        }
      }
      return res.json();
    },
    onMutate: async (plugin: Partial<PluginUpdate>) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['plugin', plugin.id] })

      // Snapshot the previous value
      const previousPlugin = queryClient.getQueryData(['plugin', plugin.id])

      // Optimistically update to the new value
      queryClient.setQueryData(['plugin', plugin.id], plugin)

      // Return a context with the previous and new simulator
      return { previousPlugin, plugin }
    },
    onError: (error, __, context: any) => {
      queryClient.setQueryData(
        ['plugin', context.plugin.id],
        context.previousPlugin,
      )
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);

      // TODO: probably return some error to the component (state from props???)
      // so it can revert saving that node (particularly with custom equations)
    },
    onSuccess: (data: Plugin, __, ___) => {
      toast.success("Plugin updated successfully.", {
        theme: "dark",
      });
      queryClient.setQueryData(["plugin", { id: data.id }], data);
    },
    // Always refetch after error or success:
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: ['plugin', data.id] })
    },
  });
}

export function useDeletePlugin() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Plugin, Error, string>({
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/plugins/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || "There was an error deleting the plugin."
          throw new Error(errorMsg);
        } catch(err) {
          throw new Error("There was an error deleting the plugin.");
        }
      }

      // as this function needs to return some json data, but server returns nothing,
      // we need to catch error from res.json() (it will throw one, because it cannot parse nothing) and return empty object
      let resJson;
      try {
        resJson = await res.json();
      } catch (e) {
        resJson = {};
      }

      return resJson;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      queryClient.invalidateQueries({ queryKey: ["plugins"] });
      toast.success("Plugin deleted successfully.", {
        theme: "dark",
      });
    },
  });
}
