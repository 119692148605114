import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { StripeCustomerData } from "types";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export function useSubscriptionsPricesList() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<SubscriptionResponse, Error>({
    queryKey: ["subscriptionsPricesList"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/billing/price-search/?query=active:'true' AND metadata['main_subscription']:'true'`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching subscriptions prices.");
      }
      return res.json();
    },
  });
}

type SubscriptionResponse = Record<string, any>; // TODO: implement type for Stripe subscription

export function useSubscription(customerId: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<SubscriptionResponse, Error>({
    queryKey: ["subscription"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/billing/subscriptions/${customerId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching your subscriptions from the database.");
      }
      return res.json();
    },
  });
}

export function useStripeCustomerData(customerId: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<StripeCustomerData, Error>({
    queryKey: ["stripeCustomerData"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/billing/get-stripe-customer-data/${customerId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching your compute credits from the database.");
      }
      return res.json();
    },
  });
}

