import { useAuth0 } from "@auth0/auth0-react";
import { FileUploader } from "components/FileUploader";
import { FileType } from "components/VisualUI/types";
import { useCreateDataset } from "mutations";
import { useAnnounceDataset } from "mutations/datasets";
import { useQueryDatasets } from "queries";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

// ?NOTES
// Get only first one from array, lets consider user will not upload multiple files.
// In case they do, the first one will be selected.
const isH5File = (fileName: string) => {
  if (!fileName) return false;
  return fileName.split('.').pop() === "h5";
}

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export default function CreateDatasetModal({setState, state}: {setState: Dispatch<SetStateAction<boolean>>, state: boolean}) {
  const [filesData, setFilesData] = useState<undefined | FileType[]>()
  const [h5Key, setH5Key] = useState<string>("");
  const [isH5, setIsH5] = useState<boolean>(false);
  const [archiveContainsH5, toggleArchiveContainsH5] = useState<boolean>(false);
  const [nameInput, setNameInput] = useState<string>("");
  const [descriptionInput, setDescriptionInput] = useState<string>("");
  const [publicInput, setPublicInput] = useState<boolean>(false);
  const createDataset = useCreateDataset();
  const announceDataset = useAnnounceDataset();
  const { refetch } = useQueryDatasets();
  const [uploading, setUploading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  async function postCreateDataset() {
    const fileName = filesData[0]?.name;
    const entityId = uuidv4();
    const accessToken = await getAccessTokenSilently(auth0Opts);
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/common/generate-r2-presignedurl/?file_name=${fileName}&entity_type=dataset&entity_id=${entityId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!res.ok) {
      toast.error("There was an error fetching pre-signed url for r2 upload.", {
        theme: "dark"
      });
      throw new Error("There was an error fetching pre-signed url for r2 upload.");
    }
    const data = await res.json();
    const presignedUrl: string = data?.presigned_url;

    setUploading(true);
    const file = filesData[0];
    await createDataset.mutateAsync({file: file, presignedUrl: presignedUrl}, {
      onSettled: () => {
        setUploading(false);
        setState(false);
      }
    });

    const datasetForm = new FormData();
    datasetForm.append('dataset_id', entityId);
    datasetForm.append('dataset_file_name', fileName);
    datasetForm.append('label', nameInput);
    datasetForm.append('size', file?.size?.toString() || "0");
    datasetForm.append('slug', slugify(nameInput));
    datasetForm.append('desc', descriptionInput);
    datasetForm.append('public', publicInput.toString());
    datasetForm.append('metadata_url', 'http://www.test.com');
    
    await announceDataset.mutateAsync(datasetForm, {
      onSettled: () => {
        refetch();
      }
    });
  }
  const disableButton = () => {
    return !nameInput || !filesData || uploading;
  }

  const getFileExtension = (fileName: string) => {
    return fileName.split('.').pop();
  }

  const isArchive = (fileName: string) => {
    const extension = getFileExtension(fileName);
    return extension === "zip" || extension === "gz" || extension === "tar" || extension === "bz2";
  }
  // listen for remove file button click
  // hack because it doesnt seem to update state,
  // thus key for h5 would stay present and block dataset creation
  const removeFileButton = document.getElementById("remove-file-from-upload");
  if (removeFileButton) {
    removeFileButton.addEventListener("click", () => {
      setFilesData(null);
      setH5Key("");
      setIsH5(false);
    });
  }

  useEffect(() => {
    if (filesData) {
      setIsH5(isH5File(filesData[0]?.name));
    }
  }, [filesData]);

  
  return (
    <Modal show={state} className="custom-modal" onHide={() => setState(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Create Dataset</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form className="custom-form">
        <div className="items-row">
          <Form.Group className="flex-1">
            <Form.Label>Dataset name <span className="text-danger">*</span></Form.Label>
            <Form.Control type="text" placeholder="Enter dataset name" onChange={(e) => setNameInput(e.target.value)}/>
          </Form.Group>
          <Form.Check 
            className="checkbox"
            type="switch"
            label="Public"
            onChange={(e) => setPublicInput(e.target.checked)}
          />
        </div>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" type="textarea" placeholder="Enter dataset description" onChange={(e) => setDescriptionInput(e.target.value)}/>
        </Form.Group>
        <FileUploader 
          className="mt-3" 
          onFileUpload={(data) => {setFilesData(data)}}
          multiple={false}
          accept={["csv", "zip", "tar", "gz", "bz2"]}
        />
        {/* <span className="mt-2 d-block">You can upload file of size up to <b>25GB</b> on 
          <Link to="#" className="text-blue"> Starter plan</Link>
        </span> */}
      </Form>
      <Row className="mt-2">
        <span className="text-muted">* Please note we currently only support standalone CSV files, or in archives, for datasets</span>
      </Row>
      <Row>
        <span className="text-muted">** If you upload archive with other file formats, you won't be able to use other than csv files from it, as of now.</span>
      </Row>

      <div className="d-flex mt-2 gap-1 justify-content-end">
        <Button className="blue btn-rounded" onClick={() => postCreateDataset()} disabled={disableButton()}>{uploading ? "Uploading..." : "Create Dataset"}</Button>
      </div>
    </Modal.Body>
  </Modal>
  )
}