import { useCallback } from "react";
import { Node, useReactFlow } from "reactflow";
import { configDefaults } from "./config-defaults";
import { Parameter, SimulatorComponentSettings } from "./types";

export function useModulusInterop() {
  const { setNodes, setEdges, getNodes, getEdges, getViewport, setViewport } = useReactFlow();

  const serializeNodes = useCallback(() => {
    const nodes = getNodes();
    let variableParameters: SimulatorComponentSettings["variable_parameters"] = {
      equations: {},
      inputs: {},
    };

    const architectures: SimulatorComponentSettings["architectures"] = nodes
      .filter((node) => node.type === "architecture")
      .reduce((a, node) => ({ ...a, [node.id]: node }), {});

    const datasets: SimulatorComponentSettings["datasets"] = nodes
      .filter((node) => node.type === "dataset")
      .reduce((a, node) => ({ ...a, [node.id]: node }), {});

    const equations: SimulatorComponentSettings["equations"] = nodes
      .filter((node) => node.type === "equation" && node.data.mode !== "custom")
      .reduce((a, node) => ({ ...a, [node.id]: node }), {});

    const custom_equations: SimulatorComponentSettings["custom_equations"] = nodes
      .filter((node) => node.type === "equation" && node.data.mode === "custom")
      .reduce((a, node) => ({ ...a, [node.id]: node }), {});

    let inputs: string[] = [];
    let outputs: string[] = [];

    Object.values(architectures).forEach((a) => {
      a.data.input_keys?.forEach((k) => inputs.push(k));
      a.data.output_keys?.forEach((k) => outputs.push(k));
      a.data?.parameterized_inputs && Object.values(a.data?.parameterized_inputs)
        .filter((input) => {
          if (a.data.input_keys.includes(input.symbol)) {
            delete a.data.parameterized_inputs[input.symbol];
            return false;
          }

          return true;
        })
        .forEach((input) =>
          Object.assign(variableParameters.inputs, {
            [input.symbol]: input,
          })
        );
    });

    let variables: string[] = [];

    Object.values(equations).forEach((e) => {
      e.data.sympy_equations && Object.keys(e.data.sympy_equations).forEach((k) => variables.push(k));
      Object.entries(e.data).forEach(([_, val]) => {
        if (
          typeof val === "object" &&
          Object.hasOwn(val, "parameterized") &&
          Object.hasOwn(val, "min") &&
          Object.hasOwn(val, "max") &&
          Object.hasOwn(val, "symbol") &&
          val.parameterized
        ) {
          Object.assign(variableParameters.equations, {
            [val.symbol]: { ...val, equation_id: e.id, equation_slug: e.data.mode },
          });
        }
      });
    });

    Object.values(custom_equations).forEach((e) => {
      e.data.sympy_equations && Object.keys(e.data.sympy_equations).forEach((k) => variables.push(k));
      Object.entries(e.data).forEach(([_, val]) => {
        if (
          typeof val === "object" &&
          Object.hasOwn(val, "parameterized") &&
          Object.hasOwn(val, "min") &&
          Object.hasOwn(val, "max") &&
          Object.hasOwn(val, "symbol") &&
          val.parameterized
        ) {
          const label = getNodes().find(n => n.id === e.id)?.data.custom_equation_label.replace(/ /g, '_').toLowerCase();
          Object.assign(variableParameters.equations, {
            [val.symbol]: { ...val, equation_id: e.id, equation_slug: label},
          });
        }
      });
    });

    return {
      conf: configDefaults,
      inputs: [...new Set(inputs)],
      outputs: [...new Set(outputs)],
      variables: [...new Set(variables)],
      variable_parameters: variableParameters,
      geometry_groups: nodes
        .filter((node) => node.type === "geometryGroup")
        .reduce((a, node) => ({ ...a, [node.id]: node }), {}),
      geometries: nodes.filter((node) => node.type === "geometry").reduce((a, node) => ({ ...a, [node.id]: node }), {}),
      architectures,
      equations,
      custom_equations,
      datasets,
      constraints: [...nodes.filter((node) => node.type === "constraint")].reduce(
        (a, node) => ({ ...a, [node.id]: node }),
        {}
      ),
      inferencers: nodes
        .filter((node) => node.type === "inferencer")
        .reduce((a, node) => ({ ...a, [node.id]: node }), {}),
      validators: nodes
        .filter((node) => node.type === "validator")
        .reduce((a, node) => ({ ...a, [node.id]: node }), {}),
      monitors: nodes.filter((node) => node.type === "monitor").reduce((a, node) => ({ ...a, [node.id]: node }), {}),
      edges: getEdges().filter((edge) => nodes.map(n => n.id).includes(edge.target)),
      viewport: getViewport(),
    };
  }, [setNodes, setEdges, getNodes, getEdges]);

  const deserializeNodes = useCallback(
    (modulusComponents: Partial<SimulatorComponentSettings>) => {
      const nodes: Node[] = [];

      if (modulusComponents.geometry_groups) nodes.push(...Object.values(modulusComponents.geometry_groups));
      if (modulusComponents.geometries) nodes.push(...Object.values(modulusComponents.geometries));
      if (modulusComponents.equations) nodes.push(...Object.values(modulusComponents.equations));
      if (modulusComponents.custom_equations) nodes.push(...Object.values(modulusComponents.custom_equations));
      if (modulusComponents.architectures) nodes.push(...Object.values(modulusComponents.architectures));
      if (modulusComponents.constraints) nodes.push(...Object.values(modulusComponents.constraints));
      if (modulusComponents.inferencers) nodes.push(...Object.values(modulusComponents.inferencers));
      if (modulusComponents.validators) nodes.push(...Object.values(modulusComponents.validators));
      if (modulusComponents.monitors) nodes.push(...Object.values(modulusComponents.monitors));
      if (modulusComponents.datasets) nodes.push(...Object.values(modulusComponents.datasets));

      setNodes(nodes);

      if (modulusComponents.edges) {
        setEdges(modulusComponents.edges ?? []);
      }

      if (modulusComponents.viewport) {
        setViewport(modulusComponents.viewport);
      }
    },
    [setNodes, setEdges, getNodes, getEdges]
  );

  return {
    serializeNodes,
    deserializeNodes,
  };
}
