import "reflect-metadata";
import { createRoot } from "react-dom/client";

import "./i18n";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { inject } from '@vercel/analytics';
import AllRoutes from "routes";

// Equations rendering
import 'katex/dist/katex.min.css';
// Themes
import "./assets/scss/app.scss";
import "./assets/scss/app-dark.scss";

async function main() {
  const queryClient = new QueryClient();
  const isDev = process.env.REACT_APP_ENVIRONMENT === "development";
  const isStaging = process.env.REACT_APP_ENVIRONMENT === "staging";
  const isProd = process.env.REACT_APP_ENVIRONMENT === "production";

  const component = (
    <QueryClientProvider client={queryClient}>
      <AllRoutes />
      {(isDev || isStaging) && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );

  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(component);

  if (isProd) inject();
}

main();
