import { defaultPointwiseBoundaryConstraintSettings, PointwiseBoundaryConstraint } from "./boundary";
import { defaultPointwiseInteriorConstraintSettings, PointwiseInteriorConstraint } from "./interior";
import { defaultIntegralBoundaryConstraintSettings, IntegralBoundaryConstraint } from "./integral";
import { Constraint, ConstraintCreator, ConstraintNode } from "./types";
export { PointwiseBoundaryConstraint, PointwiseInteriorConstraint, IntegralBoundaryConstraint };

export const constraintDefaultSettings: { [key: string]: Partial<Constraint> } = {
  pointwise_boundary_constraint: defaultPointwiseBoundaryConstraintSettings,
  pointwise_interior_constraint: defaultPointwiseInteriorConstraintSettings,
  integral_boundary_constraint: defaultIntegralBoundaryConstraintSettings,
};

export const constraintCreators: { [key: string]: (settings?: any) => ConstraintCreator } = {
  pointwise_boundary_constraint: PointwiseBoundaryConstraint,
  pointwise_interior_constraint: PointwiseInteriorConstraint,
  integral_boundary_constraint: IntegralBoundaryConstraint,
};

export function generateConstraintCode(constraint: ConstraintNode): string {
  const creator = constraintCreators[constraint.data.mode];
  return creator(constraint.data).generateCode();
}

export function constraintsToCode(constraints: Record<string, ConstraintNode>): string {
  return Object.values(constraints)
    .map((c) => generateConstraintCode(c))
    .join("\n");
}
