import React from "react";
import { Navigate, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { AccountLayout } from "layouts/AccountLayout";

const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer footer-alt">
      <p className="text-white">
        {t("Already have account?")}{" "}
        <Link to={"/account/login"} className="text-white ms-1">
          <b>{t("Log In")}</b>
        </Link>
      </p>
    </footer>
  );
};

export const Signup = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, user } = useAuth0();

  const handleSignup = async () => {
    await loginWithRedirect({
      screen_hint: "signup",
      appState: {
        returnTo: "/model-engineer/overview",
      },
    });
  };

  if (user) return <Navigate to={"/model-engineer/overview"} />;

  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <h4 className="mt-0">{t("Sign Up")}</h4>
      <p className="text-white mb-4">{t("Don't have an account? Create your account, it takes less than a minute.")}</p>

      <div className="mb-0 d-grid text-center">
        <Button variant="outline-secondary" type="submit" onClick={handleSignup}>
          <i className="mdi mdi-account-circle" /> {t("Sign Up")}
        </Button>
      </div>
    </AccountLayout>
  );
};
