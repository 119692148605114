import { FormInput } from "components/FormInput";
import { Modal, Button, Row, Col } from "react-bootstrap"
import { useSimulatorForm } from "../hooks";
import { useUpdateSimulator } from "mutations";
import { Simulator } from "types";

type Props = {
  show: boolean;
  onHide: () => void;
  simulator: Simulator;
}


const EditSimulatorModal = (props: Props) => {
  const { show, onHide, simulator } = props;
  const { handleSubmitUpdateForm, updateRegister, updateControl, updateFormErrors } = useSimulatorForm();
  const updateMutation = useUpdateSimulator();

  /*
  * Handle form submission
  */
  const onSubmit = handleSubmitUpdateForm(async (formData: any) => {
    console.log("sim before: ", simulator)
    console.log("updating", {
      label: formData["label"],
      desc: formData["desc"],
    });

    // Step 1: Save the data in DB first
    await updateMutation.mutateAsync({
      id: simulator.id,
      label: formData["label"],
      desc: formData["desc"],
    });
    
    console.log("sim after: ", simulator)
    onHide();
  });

  return (
    <Modal className="custom-modal" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit simulator details</Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body>
          {/* {updateMutation.error && <h5 onClick={() => updateMutation.reset()}>{updateMutation.error}</h5>} */}
            <Row>
              <Col>
                <FormInput
                  type="text"
                  name="label"
                  label={"Simulator name*"}
                  placeholder={"Enter label for the simulator"}
                  containerClass={"mb-3"}
                  register={updateRegister}
                  key="label"
                  errors={updateFormErrors}
                  control={updateControl}
                  defaultValue={simulator!.label}
                  maxLength={50}
                />

                <FormInput
                  name="desc"
                  label={"Description"}
                  placeholder={"Simulator description"}
                  type="textarea"
                  rows="3"
                  containerClass={"mb-3"}
                  register={updateRegister}
                  key="desc"
                  errors={updateFormErrors}
                  control={updateControl}
                  defaultValue={simulator!.desc}
                  maxLength={300}
                />
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="mt-2">
              <Col>
                <Button type="submit" variant="info" disabled={updateMutation.isLoading}>
                  {"Save"}
                </Button>{" "}
                <Button
                  style={{backgroundColor: "rgb(66, 66, 66)"}}
                  onClick={() => onHide()}
                  disabled={updateMutation.isLoading}>
                  {"Cancel"}
                </Button>
              </Col>
            </Row>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EditSimulatorModal