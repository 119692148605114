import { create } from "zustand";
import { set as setLodash, omit } from "lodash";
import type { Parameter, ModulusHydraConfig } from "modulus-interop/types";

export const defaultModulusHydraConfig = {
  defaults: {
    arch: ["fully_connected"],
    scheduler: "tf_exponential_lr",
    optimizer: "adam",
    loss: "sum",
  },
  scheduler: {
    decay_rate: 0.95,
    decay_steps: 15_000,
  },
  training: {
    rec_results_freq: 10_000,
    rec_constraint_freq: 50_000,
    max_steps: 100_000,
  },
};

type RFState = {
  modulusHydraConfig: ModulusHydraConfig;
  settingsSidebarNodeId: string;
  setSidebarNodeId: (nodeType: string) => void;
  updateConfig: (config: any) => void;
  reset: () => void;
};

export const useVisualUIStore = create<RFState>((set, get) => ({
  modulusHydraConfig: defaultModulusHydraConfig,
  settingsSidebarNodeId: "",
  setSidebarNodeId: (nodeType: string) => {
    set(() => ({ settingsSidebarNodeId: nodeType }));
  },
  updateConfig: (config: any) => {
    set(() => ({ modulusHydraConfig: { ...get().modulusHydraConfig, config } }));
  },
  reset: () => {
    console.log("Not implemented yet / used")
  },
}));
