import { defaultWaveEquationSettings } from "modulus-interop/equations/wave-equation";
import { WaveEquationType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useReactFlow } from "reactflow";
import { containsOnlyNumbers } from "utils/string";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";

export function WaveEquationSettings({ data }: { data: ModulusComponentDataCommon & WaveEquationType }) {
  const { setNodes } = useReactFlow();

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row className="mt-2 mb-2">
        <Col>
          <small>Wave equation</small>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>The dependent variable (u)</h5>
          <input
            type="text"
            className="form-control"
            placeholder="Please specify a value"
            defaultValue={data.u ?? defaultWaveEquationSettings.u}
            onChange={(e) => handleSettingChange({ u: e.target.value })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>
            Wave speed coefficient (c)
            <span className="float-end text-muted">
              <Tooltip>
                <TooltipTrigger>
                  Parameterized
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={data.c.parameterized ?? defaultWaveEquationSettings.c.parameterized}
                    onChange={(e) =>
                      handleSettingChange({
                        c: {
                          ...data.c,
                          parameterized: e.target.checked,
                          min: e.target.checked ? data.c.min : null,
                          max: e.target.checked ? data.c.max : null,
                        },
                      })
                    }
                  />
                </TooltipTrigger>
                <TooltipContent className="Tooltip">Allows pre-training the simulator for a range of values</TooltipContent>
              </Tooltip>
            </span>
          </h5>
          {data.c.parameterized ? (
            <Row className="gx-1">
              <Col xs="4">
                <input
                  type="symbol"
                  className="form-control mx-0"
                  placeholder="Symbol"
                  value={data.c.symbol ?? defaultWaveEquationSettings.c.symbol}
                  onChange={(e) => handleSettingChange({ c: { ...data.c, symbol: e.target.value } })}
                />
                <span className="text-muted font-10 mt-1">Symbol</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Value"
                  step={0.1}
                  defaultValue={data.c.value ?? defaultWaveEquationSettings.c.value}
                  onChange={(e) => handleSettingChange({ c: { ...data.c, value: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Default</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Min"
                  step={0.1}
                  defaultValue={data.c.min ?? defaultWaveEquationSettings.c.min}
                  onChange={(e) => handleSettingChange({ c: { ...data.c, min: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Min</span>
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control mx-0"
                  placeholder="Max"
                  step={0.1}
                  defaultValue={data.c.max ?? defaultWaveEquationSettings.c.max}
                  onChange={(e) => handleSettingChange({ c: { ...data.c, max: parseFloat(e.target.value) } })}
                />
                <span className="text-muted font-10 mt-1">Max</span>
              </Col>
            </Row>
          ) : (
            <input
              type="text"
              className="form-control"
              step={0.1}
              defaultValue={data.c.value ?? defaultWaveEquationSettings.c.value}
              onChange={(e) =>
                handleSettingChange({
                  c: {
                    ...data.c,
                    value: containsOnlyNumbers(e.target.value) ? parseFloat(e.target.value) : e.target.value,
                    parameterized: false,
                    min: null,
                    max: null,
                  },
                })
              }
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Dimensions</h5>
          <Select
            className="react-select settings-select"
            options={[
              { value: 2, label: "2D" },
              { value: 3, label: "3D" },
            ]}
            defaultValue={data.dim ? { value: data.dim, label: data.dim + "D" } : { value: 3, label: "3D" }}
            onChange={(option) => handleSettingChange({ dim: option.value })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Time-dependent</h5>
          <input
            type="checkbox"
            checked={data.time ?? defaultWaveEquationSettings.time}
            onChange={(e) => handleSettingChange({ time: e.target.checked })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Mixed form</h5>
          <input
            type="checkbox"
            checked={data.mixed_form ?? defaultWaveEquationSettings.mixed_form}
            onChange={(e) => handleSettingChange({ mixed_form: e.target.checked })}
          />
        </Col>
      </Row>
    </>
  );
}
