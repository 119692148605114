import { folder, button } from "leva";
import { useState } from "react";
import { Environment, Simulator } from "types";

import { commands, views, outputsPath, VIEW_MODE_PROFILES, MAX_POINT_COUNT } from "./../constants";
import { reorderObjects, processGeometries } from "../utils";

import { Parameter } from "modulus-interop/types";

type ViewMode = keyof typeof VIEW_MODE_PROFILES;

interface GlobalControlsProps {
  environment: Environment;
  simulators: Simulator[];
  getApplication: () => any;
  cache: any;
  stores: any;
  updateLs: any;
  sendMessage: any;
}
export const GlobalControls = ({
  environment,
  getApplication,
  cache,
  stores,
  updateLs,
  sendMessage,
}: GlobalControlsProps) => {
  const { simulators } = environment;
  const simulatorId = simulators[0]?.id;
  const baseDataPath = `${outputsPath}/${environment.id}/${simulatorId}/v1`;
  const simulatorDataPath = `${baseDataPath}/inference_data.json`;

  const { left, right } = stores;
  const viewModes = reorderObjects(cache, views, "viewModeSelect");
  const geometries = processGeometries(simulators, baseDataPath);

  function onChangeResolution(lsMessage: any, simLabel: string) {
    const viewPath = `${simLabel}.View`;
    const pointCount = left.get(`${viewPath}.resX`) * left.get(`${viewPath}.resY`) * left.get(`${viewPath}.resZ`);
    left.set({ [`${viewPath}.pointCount`]: pointCount }, false);
    updateLs(lsMessage);
  }
  const globalControls: Record<string, any> = {};
  if (simulators && simulators.length > 0) {
    simulators.forEach((simulator: Simulator) => {
      if (simulator && simulator.modulus_components && simulator.modulus_components.variable_parameters) {
        const equations: Record<string, any> = {};
        const inputs: Record<string, any> = {};

        Object.entries(simulator.modulus_components.variable_parameters.equations).forEach(
          ([key, eqnParams]: [string, Parameter]) => {
            Object.assign(equations, {
              [key]: {
                label: key,
                value: cache[`${simulator.id}_${key}`] || eqnParams.value,
                min: eqnParams.min,
                max: eqnParams.max,
                parameterized: true,
                onChange: (v: any) => {
                  updateLs({ [`${simulator.id}_${key}`]: v });
                },
              },
            });
          }
        );

        Object.entries(simulator.modulus_components.variable_parameters.inputs).forEach(
          ([key, inputParams]: [string, Parameter]) => {
            Object.assign(inputs, {
              [key]: {
                label: key,
                value: cache[`${simulator.id}_${key}`] || inputParams.value,
                min: inputParams.min,
                max: inputParams.max,
                parameterized: true,
                onChange: (v: any) => {
                  updateLs({ [`${simulator.id}_${key}`]: v });
                },
              },
            });
          }
        );

        const simLabel = simulator.label;

        globalControls[simLabel] = folder({
          View: folder({
            "Full Screen": button(() => {
              const app = getApplication();
              app?.stream.videoElementParent.requestFullscreen();
            }),
            // XR: button(() => {
            //   const app = getApplication();
            //   app?.stream.toggleXR();
            // }),
            resX: {
              label: "Resolution X",
              value: cache.resX || 32,
              min: 1,
              max: 100,
              step: 1,
              onChange: (v: ViewMode) => onChangeResolution({ resX: v }, simLabel),
            },
            resY: {
              label: "Resolution Y",
              value: cache.resY || 32,
              min: 1,
              max: 100,
              step: 1,
              onChange: (v: ViewMode) => onChangeResolution({ resY: v }, simLabel),
            },
            resZ: {
              label: "Resolution Z",
              value: cache.resZ || 32,
              min: 1,
              max: 100,
              step: 1,
              onChange: (v: ViewMode) => onChangeResolution({ resZ: v }, simLabel),
            },
            viewModeSelect: {
              label: "View Mode",
              options: viewModes,
              onChange: (v: ViewMode) => {
                const pointCount = left.get(`${simLabel}.View.pointCount`);
                // TODO: limit max value of the pointCount input based on the view mode (performance reasons)
                const limitedPointCountTemporary = Math.min(MAX_POINT_COUNT, pointCount);
                left.set({ [`${simLabel}.View.pointCount`]: limitedPointCountTemporary }, false);
                updateLs({ viewModeSelect: v });
                const data = {
                  type: "message",
                  event: commands.onViewChangeMode,
                  payload: {
                    niagaraParams: {
                      ...VIEW_MODE_PROFILES[v],
                      // PointCount: { val: pointCount, type: "int" },
                    },
                  },
                };
                sendMessage(data);
              },
            },
            geometry: {
              label: "Geometry",
              options: geometries,
              onChange: (v: string) => {
                updateLs({ geometry: v });
                const data = {
                  type: "message",
                  event: commands.onLoadGeometry,
                  payload: {
                    geometry: v,
                  },
                };
                sendMessage(data);
              },
            },
            pointCount: {
              label: "Point Count",
              value:
                left.get(`${simLabel}.View.resX`) *
                left.get(`${simLabel}.View.resY`) *
                left.get(`${simLabel}.View.resZ`),
            },
          }),
          "Equations inputs": folder(equations, { collapsed: false }),
          "Initial conditions": folder(inputs, { collapsed: false }),
        });
      }
    });
  }

  return { globalControls, simulatorDataPath };
};
