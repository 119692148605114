import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { Button } from "react-bootstrap";

type CreditsSpendingWidgetProps = {
  data?: Array<number>;
  dates?: Array<string>;
  isMonthly?: boolean;
  handleChangeRange?: (val: boolean) => void;
};

export const CreditsSpendingWidget = ({ data, dates, isMonthly, handleChangeRange }: CreditsSpendingWidgetProps) => {
  const options: ApexOptions = {
    tooltip: {
      enabled: true,
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => `${isMonthly ? "Daily" : "Hourly"} usage ($)`,
        },
      },
    },
    stroke: {
      width: 1,
      curve: "straight",
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: ["#7C7F8B"],
          fontSize: "12px",
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      offsetY: 20,
    },
    grid: {
      show: false,
    },
    fill: {
      type: "gradient",
      opacity: 0.4,
    },
    colors: ["#7CA8FF", "#A69DFF"],
    xaxis: {
      categories: dates,
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        offsetX: 0,
        offsetY: -5,
        style: {
          fontSize: "8px",
          colors: ["#7C7F8B"],
        },
        hideOverlappingLabels: true,
        rotate: window.innerWidth < 1024 ? -90 : 0,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="d-flex justify-content-between">
        <span className="title">Compute credits {isMonthly ? "monthly" : "daily"} usage</span>
        <div className="d-flex gap-1">
          <Button
            variant={isMonthly ? `info` : `primary`}
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              handleChangeRange(true);
            }}>
            30 days
          </Button>
          <Button
            variant={isMonthly ? `primary` : `info`}
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              handleChangeRange(false);
            }}>
            24 hours
          </Button>
        </div>
      </div>

      <span className="credits">
        {/* monthly usage is the sum of day's usages */}$ {data.reduce((a, b) => a + b, 0).toFixed(2)}
      </span>

      <Chart
        options={options}
        type="area"
        series={[
          {
            data: data,
            name: "Data",
          },
        ]}
        height={150}
      />
    </div>
  );
};
