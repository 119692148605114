import { ModulusHydraConfig, SimulatorComponentSettings } from "./types";

export function serializeSimulator(
  simulatorId: string,
  conf: ModulusHydraConfig,
  modulusComponents: SimulatorComponentSettings
) {
  return {
    version: "v1",
    simulator: simulatorId,
    order: [
      "geometries",
      "equations",
      "networks",
      "architectures",
      "constraints",
      "validators",
      "monitors",
      "inferencers",
    ],
    conf,
    inputs: modulusComponents.inputs,
    outputs: modulusComponents.outputs,
    variable_parameters: modulusComponents.variable_parameters,
    geometry_groups: modulusComponents.geometry_groups,
    geometries: modulusComponents.geometries,
    equations: modulusComponents.equations,
    datasets: modulusComponents.datasets,
    custom_equations: modulusComponents.custom_equations,
    architectures: modulusComponents.architectures,
    constraints: modulusComponents.constraints,
    inferencers: modulusComponents.inferencers,
    validators: modulusComponents.validators,
    monitors: modulusComponents.monitors,
    edges: modulusComponents.edges,
    viewport: modulusComponents.viewport,
  };
}
