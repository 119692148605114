import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateProjectData, Project, UpdateProjectData } from "types";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

type DeploymentData = {
  id: string;
  server_type: string;
  label: string;
};

export function useCreateProject() {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Project, Error, CreateProjectData>({
    mutationFn: async (project: CreateProjectData) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/`, {
        method: "POST",
        body: JSON.stringify(project),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error creating a new project.");
      }
      return res.json();
    },
    onMutate: (data: CreateProjectData) => {
      console.log("sending", data);
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (data: Project, __, ___) => {
      console.log("Project created successfully.", data);
      toast.success("Project created successfully.", {
        theme: "dark",
      });

      return navigate(`/model-engineer/projects/${data.id}`);
    },
  });
}

export function useUpdateProject() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Project, Error, UpdateProjectData>({
    mutationFn: async (project: UpdateProjectData) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/${project.id}/`, {
        method: "PATCH",
        body: JSON.stringify(project),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error updating the project.");
      }
      return res.json();
    },
    onMutate: (data: Partial<UpdateProjectData>) => {
      return data;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (data: Project, __, ___) => {
      console.log("Project updated.", data);
      queryClient.setQueryData(["project", { id: data.id }], data);
      toast.success("Project updated successfully.", {
        theme: "dark",
      });
      return navigate(0); // TODO: fix this reloading
    },
  });
}

export function useDeleteProject() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<string, Error, string>({
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.error || "There was an error deleting the project.");
      }
      return id;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      console.log("successfully deleted project");
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      toast.success("Project deleted successfully.", {
        theme: "dark",
      });
      return navigate("/model-engineer/projects", { replace: true });
    },
  });
}

export function useStartProjectInPaperspace() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Project, Error, Project>({
    mutationFn: async (project: Project) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/paperspace/start/${project.id}/`, {
        method: "POST",
        body: JSON.stringify(project),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error starting the project.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully started the project.", {
        theme: "dark",
      });
    },
  });
}

export function useStartProjectInAWS() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Project, Error, Project>({
    mutationFn: async (project: Project) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/aws/start/${project.id}/`, {
        method: "POST",
        body: JSON.stringify(project),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error starting the project.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully started the project.", {
        theme: "dark",
      });
    },
  });
}

export function useStopProjectInAWS() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Project, Error, Project>({
    mutationFn: async (project: Project) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/aws/stop/${project.id}/`, {
        method: "POST",
        body: JSON.stringify(project),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error stopping the project.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully stopped the project.", {
        theme: "dark",
      });
    },
  });
}

export function useDestroyProjectInAWS() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Project, Error, Project>({
    mutationFn: async (project: Project) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/aws/destroy/${project.id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error destroying the project.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully destroyed the project.", {
        theme: "dark",
      });
    },
  });
}

export function useStopProjectInPaperspace() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Project, Error, Project>({
    mutationFn: async (project: Project) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/paperspace/stop/${project.id}/`, {
        method: "POST",
        body: JSON.stringify(project),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error stopping the project.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully stopped the project.", {
        theme: "dark",
      });
    },
  });
}

export function useDestroyProjectInPaperspace() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<Project, Error, Project>({
    mutationFn: async (project: Project) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cloud/ste/paperspace/destroy/${project.id}/`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!res.ok) {
        throw new Error("There was an error destroying the project.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: "dark",
      });
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      toast.success("Successfully destroyed the project.", {
        theme: "dark",
      });
    },
  });
}
