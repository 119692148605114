import { useQuery } from "@tanstack/react-query";
import type { Environment } from "types";
import { useAuth0 } from "@auth0/auth0-react";

type EnvironmentsListResponse = {
  count: number;
  next: any;
  previous: any;
  results: Environment[];
};

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useQueryEnvironments() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<EnvironmentsListResponse, Error>({
    queryKey: ["environments"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching environments from the database.");
      }
      return res.json();
    },
    refetchInterval: 5000,
  });
}

export function useQueryEnvironment(id: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Environment, Error>({
    queryKey: ["environment", id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/environments/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error fetching environment with ID ${id} from the database.`);
      }
      return res.json();
    },
    refetchInterval: 5000,
  });
}

interface EnvironmentHistoryResponse {
  [key: string]: number;
}

export function useQueryCreditsUsage(
  startDate: string, 
  endDate: string, 
  frequency: "hour" | "day",
  envId?: string, 
  ) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<EnvironmentHistoryResponse[], Error>({
    queryKey: ["creditsusage", frequency, envId],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/creditsusagehistory/retrievecreditsusage/${envId ? envId + "/" : ``}?start_date=${startDate}&end_date=${endDate}&frequency=${frequency}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error(`There was an error fetching environment history with ID ${envId} from the database.`);
      }
      return res.json();
    },
  });
}