import React, { ReactElement } from "react";
import { useForm, Resolver, SubmitHandler, DefaultValues } from "react-hook-form";

const mapInputRecursive = (
  children: ReactElement[],
  callback: (child: ReactElement) => ReactElement
): ReactElement[] => {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    // if "name" prop is set, we can assume it's a form input
    if ((child as ReactElement).props.name) {
      return callback(child);
    }

    if ((child as ReactElement).props.children) {
      const props = {
        children: mapInputRecursive((child as ReactElement).props.children, callback),
      };
      child = React.cloneElement(child, props);
    }

    return child;
  });
};

type VerticalFormProps<TFormValues> = {
  defaultValues?: DefaultValues<TFormValues>;
  resolver?: Resolver<TFormValues>;
  children?: React.ReactNode;
  onSubmit: SubmitHandler<TFormValues>;
  formClass?: string;
  loadedData?: DefaultValues<TFormValues> | undefined;
};

const VerticalForm = <TFormValues extends Record<string, any> = Record<string, any>>({
  defaultValues,
  resolver,
  children,
  onSubmit,
  formClass,
}: VerticalFormProps<TFormValues>) => {
  /*
   * form methods
   */
  const methods = useForm<TFormValues>({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formClass} noValidate={true}>
      {Array.isArray(children) &&
        mapInputRecursive(children, (child) =>
          React.createElement(child.type, {
            ...{
              ...child.props,
              register,
              key: child.props.name,
              errors,
              control,
            },
          })
        )}
    </form>
  );
};

export default VerticalForm;
