import { folder } from "leva";
import { commands, SLICER_OFF, SLICER_ON } from "./../constants";
import { Simulator } from "types";
import { reorderObjects } from "../utils";

export const RightControls = ({
  simulators,
  cache,
  stores,
  updateLs,
  sendMessage,
}: {
  simulators: Simulator[];
  cache: any;
  stores: any;
  updateLs: any;
  sendMessage: any;
}) => {
  const { left, right } = stores;

  function getSlicerState(type: String, simLabel: string) {
    const { right } = stores;
    const x = right.get(`${simLabel}.Slicing.slicerX${type}`);
    const y = right.get(`${simLabel}.Slicing.slicerY${type}`);
    const z = right.get(`${simLabel}.Slicing.slicerZ${type}`);
    updateLs({ [`slicerX${type}`]: x, [`slicerY${type}`]: y, [`slicerZ${type}`]: z });
    return { val: [x, y, z], type: "vec3" };
  }
  function onSlicerMove(label: string) {
    const SlicerOffsets = getSlicerState("Val", label);
    const SlicerWidth = getSlicerState("Width", label);
    const data = {
      type: "message",
      event: commands.onNiagaraParamsUpdate,
      payload: {
        niagaraParams: {
          SlicerOffsets,
          SlicerWidth,
        },
      },
    };
    sendMessage(data);
  }

  function setSlicerMode(simLabel: string) {
    const { right } = stores;
    const slicerAxisEnabled = getSlicerState("Enabled", simLabel);
    // isSlicerAxisGeoEnabled - dont forget to add this to isSlicingEnabled!
    const isSlicingEnabled = slicerAxisEnabled.val[0] || slicerAxisEnabled.val[1] || slicerAxisEnabled.val[2];
    let niagaraParams = isSlicingEnabled ? SLICER_ON : SLICER_OFF;
    // Mode always set to 0 for now, 1 will be if isSlicerAxisGeoEnabled is true
    const mode = 0;

    niagaraParams = {
      ...niagaraParams,
      SlicerXEnabled: { val: slicerAxisEnabled.val[0], type: "bool" },
      SlicerYEnabled: { val: slicerAxisEnabled.val[1], type: "bool" },
      SlicerZEnabled: { val: slicerAxisEnabled.val[2], type: "bool" },
      // SlicerGeoEnabled: { val: false, type: "bool" },
      SlicingEnabled: { val: isSlicingEnabled, type: "bool" },
      SlicerWidth: {
        val: [
          right.get(`${simLabel}.Slicing.slicerXWidth`),
          right.get(`${simLabel}.Slicing.slicerYWidth`),
          right.get(`${simLabel}.Slicing.slicerZWidth`),
        ],
        type: "vec3",
      },
    };

    const data = {
      type: "message",
      event: commands.onSlicerChangeMode,
      payload: {
        slicerEnabled: isSlicingEnabled,
        mode,
        niagaraParams,
      },
    };
    console.log(data);

    sendMessage(data);
  }

  function calculateSlicerWidth(pointCount: number, simLabel: string) {
    var slicerWidth = 0;
    if (pointCount >= 1000000) {
      slicerWidth = 1.6;
    } else if (pointCount >= 421875) {
      slicerWidth = 2;
    } else if (pointCount >= 125000) {
      slicerWidth = 4;
    } else if (pointCount >= 32768) {
      slicerWidth = 6.5;
    } else if (pointCount >= 1000) {
      slicerWidth = 24;
    }
    right.set({ [`${simLabel}.Slicing.slicerXWidth`]: slicerWidth }, false);
    right.set({ [`${simLabel}.Slicing.slicerYWidth`]: slicerWidth }, false);
    right.set({ [`${simLabel}.Slicing.slicerZWidth`]: slicerWidth }, false);
  }
  let rightControls: Record<string, any> = {};

  if (simulators && simulators.length > 0) {
    simulators.forEach((simulator: Simulator) => {
      let outputsOptions = Object.entries(simulator.modulus_components.outputs).reduce((acc: any, [key, value]) => {
        acc[value] = value;
        return acc;
      }, {});
      if (outputsOptions.u && outputsOptions.v && outputsOptions.w) {
        outputsOptions = {
          uvw: `${outputsOptions.u},${outputsOptions.v},${outputsOptions.w}`,
          ...outputsOptions,
        };
      }
      outputsOptions = reorderObjects(cache, outputsOptions, "magnitudeSource");
      const simLabel = simulator.label;
      const outputs = {
        label: "Magnitude Source",
        options: outputsOptions,
        onChange: (v: any) => {
          updateLs({ magnitudeSource: v });
          const data = {
            type: "message",
            event: commands.onMagnitudeSourceChange,
            payload: {
              val: v,
            },
          };
          sendMessage(data);
        },
      };

      rightControls[simLabel] = folder({
        geometryOpacity: {
          label: "Geometry Opacity",
          value: cache?.geometryOpacity ?? 1,
          min: 0,
          max: 1,
          step: 0.1,
          onChange: (v: boolean) => {
            updateLs({ geometryOpacity: v });
            const data = {
              type: "message",
              event: commands.onChangeGeoOpacity,
              payload: {
                val: v,
              },
            };
            sendMessage(data);
          },
        },
        pointOpacity: {
          label: "Point Opacity",
          value: cache?.pointOpacity ?? 1,
          min: 0,
          max: 1,
          step: 0.1,
          onChange: (v: boolean) => {
            updateLs({ pointOpacity: v });
            const data = {
              type: "message",
              event: commands.onNiagaraParamsUpdate,
              payload: {
                niagaraParams: {
                  PointOpacity: { val: v, type: "float" },
                },
              },
            };
            sendMessage(data);
          },
        },
        emissiveEnabled: {
          label: "Emissive",
          value: cache?.emissiveEnabled ?? true,
          parameterized: true,
          onChange: (v: boolean) => {
            updateLs({ emissiveEnabled: v });
            const data = {
              type: "message",
              event: commands.onNiagaraParamsUpdate,
              payload: {
                niagaraParams: {
                  EmissiveEnabled: { val: v, type: "int" },
                },
              },
            };
            sendMessage(data);
          },
          transient: false,
        },
        Slicing: folder(
          {
            slicerXEnabled: {
              label: "X",
              value: cache?.slicerXEnabled ?? false,
              parameterized: true,
              onChange: (v: boolean) => {
                setSlicerMode(simLabel);
                // TODO: get slicerVal max value / 2 for the mid point
                const midpoint = 5;
                if (v) right.set({ [`${simLabel}.Slicing.slicerXVal`]: midpoint }, false);
                onSlicerMove(simLabel);
              },
            },
            slicerXVal: {
              label: "Slice X",
              value: cache.slicerXVal || 5,
              min: 0,
              max: 10,
              parameterized: true,
              onChange: (v: any) => onSlicerMove(simLabel),
              render: (get: (key: string) => boolean) => right.get(`${simLabel}.Slicing.slicerXEnabled`) === true,
            },
            slicerXWidth: {
              label: "Slicer Width X",
              value: cache.slicerXWidth || 30,
              min: 1,
              max: 30,
              parameterized: true,
              onChange: (v: any) => onSlicerMove(simLabel),
              render: (get: (key: string) => boolean) => right.get(`${simLabel}.Slicing.slicerXEnabled`) === true,
            },
            slicerYEnabled: {
              label: "Y",
              value: cache?.slicerYEnabled ?? false,
              parameterized: true,
              onChange: (v: boolean) => {
                setSlicerMode(simLabel);
                // TODO: get slicerVal max value / 2 for the mid point
                const midpoint = 5;
                if (v) right.set({ "Slicing.slicerYVal": midpoint }, false);
              },
              transient: true,
            },
            slicerYVal: {
              label: "Slice Y",
              value: cache.slicerYVal || 5,
              min: 0,
              max: 10,
              parameterized: true,
              onChange: (v: any) => onSlicerMove(simLabel),
              render: (get: (key: string) => boolean) => right.get(`${simLabel}.Slicing.slicerYEnabled`) === true,
            },
            slicerYWidth: {
              label: "Slicer Width Y",
              value: cache.slicerYWidth || 30,
              min: 1,
              max: 30,
              parameterized: true,
              onChange: (v: any) => onSlicerMove(simLabel),
              render: (get: (key: string) => boolean) => right.get(`${simLabel}.Slicing.slicerYEnabled`) === true,
            },
            slicerZEnabled: {
              label: "Z",
              value: cache?.slicerZEnabled ?? false,
              parameterized: true,
              onChange: (v: boolean) => {
                setSlicerMode(simLabel);
                // TODO: get slicerVal max value / 2 for the mid point
                const midpoint = 5;
                if (v) right.set({ [`${simLabel}.Slicing.slicerZVal`]: midpoint }, false);
              },
            },
            slicerZVal: {
              label: "Slice Z",
              value: cache.slicerZVal || 5,
              min: 0,
              max: 10,
              parameterized: true,
              onChange: (v: any) => onSlicerMove(simLabel),
              render: (get: (key: string) => boolean) => right.get(`${simLabel}.Slicing.slicerZEnabled`) === true,
            },
            slicerZWidth: {
              label: "Slicer Width Z",
              value: cache.slicerZWidth || 30,
              min: 1,
              max: 30,
              parameterized: true,
              onChange: (v: any) => onSlicerMove(simLabel),
              render: (get: (key: string) => boolean) => right.get(`${simLabel}.Slicing.slicerZEnabled`) === true,
            },
            // slicerGeoEnabled: {
            //   label: "Geo",
            //   value: false,
            //   parameterized: true,
            //   onChange: (v: boolean) => {
            //     setSlicerMode();
            //   },
            //
            // }
          },
          { collapsed: true }
        ),
        "Magnitude Source": outputs,
      });
    });
  }
  return { rightControls, setSlicerMode, onSlicerMove };
};
