import { useQueryRecentArticles } from "queries/academy";
import { Card, CardBody, Col, Row } from "react-bootstrap";
import { Story } from "types/academy";

export const ArticlesOverview = () => {
  const {
    data: articles,
    isLoading: isArticlesLoading,
    isError: isArticlesError,
    error: articlesError,
  } = useQueryRecentArticles();
  const stories: Story[] = articles?.stories;
  return (
    <Card>
      <CardBody>
        <h4>Most recent blogposts</h4>
        <Row>
          {stories &&
            stories.map((story) => {
              return (
                <Col key={story.id} sm={6} xl={4} className="mb-3">
                  <a
                    href={`https://dimensionlab.org/blog/${story.slug}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      padding: "8px",
                      cursor: "pointer",
                      background: "#202020",
                      borderRadius: "4px",
                    }}>
                    <p style={{ fontWeight: "500" }}>{story.name}</p>
                    <img
                      src={story.content.image.filename.toString()}
                      alt={story.content.image.alt}
                      style={{ width: "100%", objectFit: "cover", aspectRatio: "16/9" }}
                    />
                  </a>
                </Col>
              );
            })}
        </Row>
      </CardBody>
    </Card>
  );
};
