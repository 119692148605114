import { useChangelogOpenStore } from "hooks";
import ChangelogPopup from "./ChangelogPopup";
import "./bottomSidebar.css"
import { useEffect } from "react";
import { changelogItems } from "constants/changelog";
import { track } from "@vercel/analytics"

const BottomSidebarContent = () => {
  const { isOpen, toggle, unread, toggleRead } = useChangelogOpenStore();

  useEffect(() => {
    if (!(localStorage.getItem("first-visit"))) {
      toggleRead(true);
    }
    interface FirstVisit {
      version: string;
      firstVisit: boolean;
    }
    const firstVisit: FirstVisit = JSON?.parse(localStorage?.getItem("first-visit") || "{}");
    if (firstVisit.version !== changelogItems.version) {
      toggleRead(true);
    }
  }, [isOpen]);
  return (
    <>
      <section className="bottom-sidebar-container">
        <a 
          href="https://docs.siml.ai/" 
          className="bottom-sidebar-item"
          onClick={() => track("Read the docs")}
        >
          <i className="dripicons-blog" style={{fontSize: "18px"}}></i>
          <span style={{fontWeight: "100"}}>Read the docs</span>
        </a>
        <a 
          href="https://discord.gg/UxQyC9PEqt" 
          className="bottom-sidebar-item"
          onClick={() => track("Join our Discord")}
        >
          <i className="dripicons-message" style={{fontSize: "18px"}}></i>
          <span style={{fontWeight: "100"}}>Join our Discord</span>
        </a>
        <a 
          onClick={() => {
            toggle();
            track("What's changed?");
          }}
          className={`bottom-sidebar-item ${unread ? "bottom-sidebar-changelog-unread" : ""}`}
          id="changelog-button"
        >
          <i className="dripicons-feed" style={{fontSize: "18px"}}></i>
          <span style={{fontWeight: "100"}}>What's changed?</span>
        </a>
      </section>

      <ChangelogPopup isOpen={isOpen} toggle={toggle} toggleRead={toggleRead}/>
  </>
  )
}

export default BottomSidebarContent;