import * as THREE from 'three'
import { useRef, useState } from "react";
import { Handle, Position, useNodeId, useReactFlow } from "reactflow";
import { Canvas, useFrame } from '@react-three/fiber'
import { useVisualUIStore } from "../../../store";

import "../visualizer-node.css";

function Box(props: JSX.IntrinsicElements['mesh']) {
  const ref = useRef<THREE.Mesh>(null!)
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  useFrame((state, delta) => (ref.current.rotation.x += 0.01))
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}

export function Visualizer3DNode({ data }: { data: any }) {
  const nodeId = useNodeId();
  const reactFlowInstance = useReactFlow();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const handleCloseSettings = () => setShowSettingsModal(false);
  const handleShowSettings = () => setShowSettingsModal(true);

  return (
    <>
      <Handle type="target" position={Position.Top} id="visualizer-viz3D" />
      <div className="node-icons">
        {/* <i className="dripicons-gear" onClick={handleShowSettings} /> */}
        <i
          className="dripicons-cross"
          onClick={() => {
            reactFlowInstance.setNodes(reactFlowInstance.getNodes().filter((n: any) => n.id !== nodeId));
          }}
        />
      </div>

      <div className="title">Visualizer: 3D</div>

      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Box position={[-1.2, 0, 0]} />
        <Box position={[1.2, 0, 0]} />
      </Canvas>
    </>
  );
}
