import { changelogItems } from "constants/changelog";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";

const ChangelogPopup = ( { isOpen, toggle, toggleRead }: any ) => {

  const handleClose = () => {
    toggle();

    interface FirstVisit {
      version: string;
      firstVisit: boolean;
    }
    const saving: FirstVisit = {
      version: changelogItems.version,
      firstVisit: false,
    }
    localStorage.setItem("first-visit", JSON.stringify(saving));
    toggleRead(false);
  }

  return (
    <Modal show={isOpen} size="lg">
        <ModalHeader>
          <div> 
            <h3>What's changed?</h3>
            <span>{changelogItems.version}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ul>
            {changelogItems.changelog.map((item, index) => {
              return (
                <li key={index}>{item}</li>
              )
            })}
          </ul>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={() => handleClose()}>Got it!</button>
        </ModalFooter>
      </Modal>
  )
}

export default ChangelogPopup;