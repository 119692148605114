import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { isDevelopment } from "lib/env";
import { toast } from "react-toastify";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

/**
 * Create and redirect to new Stripe Checkout for buying compute credits
 * @returns {useMutation} useMutation hook
 * @see https://react-query.tanstack.com/reference/useMutation
 * @see https://stripe.com/docs/payments/checkout/how-checkout-works
 * @see https://stripe.com/docs/api/checkout/sessions/create
 */
export function useBuyCredits() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<any, Error, string>({
    mutationFn: async (customerId: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/billing/create-cloud-compute-credits-checkout/`, {
        method: "POST",
        body: JSON.stringify({ customer_id: customerId }),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error creating credits checkout.");
      }
      return res.json();
    },
    onMutate: () => {
      isDevelopment && console.log("creating credits checkout in Stripe");
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      isDevelopment && console.error(error);
    },
    // TODO: type this properly (see /backend/apps/billing/views.py)
    onSuccess: (response: any, __, ___) => {
      isDevelopment && console.log("credits checkout in Stripe created.", response);
      // BILLING_PORTAL_SESSION_URL is a variable for redirection set by Stripe Customer Portal inside our API
      return window.location.replace(response.CHECKOUT_SESSION_URL);
    },
  });
}

/**
 * Create and redirect to new Stripe Checkout for subscription plan
 * @returns {useMutation} useMutation hook
 * @see https://react-query.tanstack.com/reference/useMutation
 * @see https://stripe.com/docs/payments/checkout/how-checkout-works
 * @see https://stripe.com/docs/api/checkout/sessions/create
 * @see https://stripe.com/docs/api/subscriptions/create
 */
export function useSubscriptionCheckoutSession() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<any, Error, any>({
    mutationFn: async ({ customerId, planId, discountCode }: { customerId: string; planId: string, discountCode: string }) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/billing/create-subscription-checkout-session/${customerId}/${planId}/`,
        {
          method: "POST",
          body: JSON.stringify({ coupon_id: discountCode }),
          headers: {
            "content-type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!res.ok) {
        throw new Error("There was an error creating subscriptions checkout session.");
      }
      return res.json();
    },
    onMutate: () => {
      console.log("creating subscriptions checkout session in Stripe");
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    // TODO: type this properly
    onSuccess: (response: any, __, ___) => {
      console.log("subscriptions checkout session in Stripe created.", response);
      // CHECKOUT_SESSION_URL is a variable for redirection set by Stripe Checkout inside our API
      return window.location.replace(response.CHECKOUT_SESSION_URL);
    },
  });
}

export function useMeteredSubscriptionCheckoutSession() {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation<any, Error, any>({
    mutationFn: async ({ customerId, priceId, environmentId, cloudProvider, tier }: { customerId: string; priceId: string, environmentId: string, cloudProvider: string, tier: string }) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/billing/create-metered-subscription-checkout-session/${customerId}/${priceId}/${environmentId}/${cloudProvider}/${tier}/`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!res.ok) {
        throw new Error("There was an error creating subscriptions checkout session.");
      }
      return res.json();
    },
    onMutate: () => {
      console.log("creating subscriptions checkout session in Stripe");
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    // TODO: type this properly
    onSuccess: (response: any, __, ___) => {
      // CHECKOUT_SESSION_URL is a variable for redirection set by Stripe Checkout inside our API
      if (response.CHECKOUT_SESSION_URL) {
        return window.location.replace(response.CHECKOUT_SESSION_URL);
      }

      toast.success("Environment deployment in progress - you will be billed per minute while the server will be running.", {
        theme: 'dark'
      })
    },
  });
}

/**
 * Create and redirect to Stripe's customer portal to manage subscriptions and see invoices
 * @returns {useMutation} useMutation hook
 * @see https://react-query.tanstack.com/reference/useMutation
 * @see https://stripe.com/docs/api/customer_portal/sessions/create
 */
export function useCustomerPortal() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<any, Error, string>({
    mutationFn: async (customerId: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/billing/customer-portal/${customerId}/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error creating customer portal session.");
      }
      return res.json();
    },
    onMutate: () => {
      console.log("creating customer portal session in Stripe");
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    // TODO: type this properly (see /backend/apps/billing/views.py)
    onSuccess: (response: any, __, ___) => {
      console.log("customer portal session in Stripe created.", response);
      // BILLING_PORTAL_SESSION_URL is a variable for redirection set by Stripe Customer Portal inside our API
      return window.location.replace(response.BILLING_PORTAL_SESSION_URL);
    },
  });
}
