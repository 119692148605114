import { Link } from "react-router-dom";
import { ButtonGroup, ProgressBar, Button } from "react-bootstrap";
import prettyBytes from "pretty-bytes";
import { useState } from "react";
import CreateDatasetModal from "../CreateDatasetModal";

type LeftPanelProps = {
  combinedSize: number;
};

export const LeftPanel = ({ combinedSize }: LeftPanelProps) => {
  const maxStorage = 50_000_000_000; // 50 GB, hardcoded for now... TODO: base on subscription plan
  const storageUsedPercent = (combinedSize / maxStorage) * 100;
  const [modalActive, setModalActive] = useState(false);
  
  return (
    <>
      <CreateDatasetModal setState={setModalActive} state={modalActive} />
      <div className="left-panel">
        <ButtonGroup className="d-block mb-2">
          <Button className="btn-rounded mb-3 blue" onClick={() => setModalActive(true)}>
            <i className="mdi mdi-plus" /> Create Dataset
          </Button>
        </ButtonGroup>

        <div className="navigation">
          <Link to="#">
            <i className="mdi mdi-folder-outline font-18" />
            My Files
          </Link>
          <Link to="#">
            <i className="mdi mdi-clock-outline font-18" />
            Recent
          </Link>
          <Link to="#">
            <i className="mdi mdi-star-outline font-18" />
            Starred
          </Link>
          <Link to="#">
            <i className="mdi mdi-delete font-18" />
            Deleted Files
          </Link>
        </div>

        <div className="bottom-part">
          <div className="custom-row">
            <span className="text-uppercase">Storage</span>
            <span className="badge rounded-pill p-1 px-2 blue">FREE</span>
          </div>
          <ProgressBar variant="success" now={storageUsedPercent} className="my-2 progress-sm" />
          <p className="text-muted font-13 mb-0">
            {prettyBytes(combinedSize)} ({storageUsedPercent.toFixed(1)}%) of {prettyBytes(maxStorage)} used
          </p>
        </div>
      </div>
    </>
  );
};
