/*
  this is heuristics to parse last 24h from data, because API doesn't support time, only date
  so I request yesterday + today and then find which ones are in the range of last 24h
*/

export const parseLast24h = (data: number[]) => {
  if (!data) {
    return [];
  }
  const now = new Date();
  const nowHour = now.getHours();
  const arr48 = Array.from(Array(24).keys()).concat(Array.from(Array(24).keys())); // 00-23, 00-23 together (represent 2 days from api response)

  let last24h: number[] = [];
  const nowIndex = (arr48.lastIndexOf(nowHour)); 

  // get previous 24 values from now to the past
  for (let i = nowIndex; i > (nowIndex - 24); i--) {
    last24h.push(data[i]);
  }
  return last24h.reverse();
}