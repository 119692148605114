export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
  { key: "overview", label: "Overview", url: "/model-engineer/overview", isTitle: false, icon: "uil-telescope" },
  {
    key: "title-workflow",
    label: "Workflow",
    isTitle: true,
  }, 
  {
    key: "simulators",
    label: "Build simulator",
    isTitle: false,
    icon: "dripicons-rocket",
    url: "/model-engineer/simulators",
  },
  {
    key: "environments",
    label: "Train simulator",
    isTitle: false,
    icon: "dripicons-cloud-upload",
    url: "/model-engineer/environments",
  },
  {
    key: "projects",
    label: "Run simulation",
    isTitle: false,
    icon: "dripicons-jewel",
    url: "/model-engineer/projects",
  },
  {
    key: "title-resources",
    label: "Resources",
    isTitle: true,
  },
  {
    key: "datasets",
    label: "Datasets",
    isTitle: false,
    icon: "uil-briefcase",
    url: "/model-engineer/datasets",
  },
  {
    key: "plugins",
    label: "Plugins",
    isTitle: false,
    icon: "dripicons-gear",
    url: "/model-engineer/plugins",
  },
  {
    key: "title-billing",
    label: "Billing",
    isTitle: true,
  },
  {
    key: "billing",
    label: "Plans & Credits",
    isTitle: false,
    icon: "mdi mdi-currency-usd",
    url: "/model-engineer/billing",
  }
];

export { MENU_ITEMS };
