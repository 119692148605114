import { defaultFNOArchSettings } from "modulus-interop/architectures/fno";
import { FNOArch } from "modulus-interop/architectures/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useReactFlow } from "reactflow";
import { architecturesOptions } from "./";
import { useParameterizedInputs } from "./hooks";
import { isEmpty } from "lodash";

type TSelectOption = {
  value: string;
  label: string;
};

export function FNOSettings({ data }: { data: ModulusComponentDataCommon & FNOArch }) {
  const { setNodes, getNodes } = useReactFlow();
  const {
    newParameterToAdd,
    setNewParameterToAdd,
    handleAddParameterizedInput,
    handleRemoveParameterizedInput
  } = useParameterizedInputs();

  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h5>Input variables</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.input_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.input_keys.map((k) => ({ value: k, label: k }))}
            onChange={(inputVars) =>
              handleSettingChange({
                input_keys: inputVars.map((v) => v.value),
              })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>Parameterized input variables</h5>

          {!isEmpty(data.parameterized_inputs) && (
            <>
              <Row className="gx-1 mb-1">
                <Col xs={4}>
                  <span className="text-muted font-10 mt-1">Symbol</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Default</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Min</span>
                </Col>
                <Col>
                  <span className="text-muted font-10 mt-1">Max</span>
                </Col>
                <Col xs={1}></Col>
              </Row>
              {Object.entries(data.parameterized_inputs).map(([key, input]) => (
                <Row key={`parameterized_inputs_${key}`} className="gx-1 mb-1">
                  <Col xs={4}>
                    <input
                      type="symbol"
                      className="form-control mx-0"
                      placeholder="Symbol"
                      defaultValue={input.symbol}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, symbol: e.target.value },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Value"
                      step={0.1}
                      defaultValue={input.value}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, value: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Min"
                      step={0.1}
                      defaultValue={input.min}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, min: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      className="form-control mx-0"
                      placeholder="Max"
                      step={0.1}
                      defaultValue={input.max}
                      onChange={(e) =>
                        handleSettingChange({
                          parameterized_inputs: {
                            ...data.parameterized_inputs,
                            [key]: { ...input, max: parseFloat(e.target.value) },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col xs={1}>
                    <Button className="btn-remove-small" onClick={() => handleRemoveParameterizedInput(data, key)}>
                      <i className="mdi mdi-close-circle-outline" />
                    </Button>
                  </Col>
                </Row>
              ))}
            </>
          )}
          <Row className="gx-1 mt-1">
            <Col xs={8}>
              <Form.Control
                type="text"
                placeholder="Symbol (name) of the variable"
                value={newParameterToAdd}
                onChange={(e) => setNewParameterToAdd(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                className="btn-add"
                disabled={!newParameterToAdd}
                onClick={() => handleAddParameterizedInput(data)}>
                <i className="mdi mdi-plus" /> Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>List of keys to detach gradients</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.detach_keys.map((k) => ({ value: k, label: k }))}
            defaultValue={data.detach_keys.map((k) => ({ value: k, label: k }))}
            onChange={(detachKeys) =>
              handleSettingChange({ detach_keys: detachKeys.map((v) => v.value) })
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Dimensions</h5>
          <Select
            className="react-select settings-select"
            options={[
              { value: 2, label: "2D" },
              { value: 3, label: "3D" },
            ]}
            defaultValue={
              data.dimension ? { value: data.dimension, label: data.dimension + "D" } : { value: 3, label: "3D" }
            }
            onChange={(option) => handleSettingChange({ dimension: option.value })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Pointwise decoder network</h5>
          <Select<TSelectOption>
            className="react-select settings-select"
            options={getNodes()
              .filter((n) => n.type === "architecture")
              .filter((n) => n.id === data.id) // filter itself
              .map((n) => ({ value: n.id, label: architecturesOptions.find(o => o.value === n.data.mode).label }))}
            defaultValue={
              data.decoder_net
                ? architecturesOptions.find(o => o.value === data.decoder_net)
                : { value: "", label: "Select decoder network" }
            }
            onChange={(option) => handleSettingChange({ decoder_net: option.value })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Number of spectral convolution layers</h5>
          <input
            type="number"
            className="form-control"
            defaultValue={data.nr_fno_layers ?? defaultFNOArchSettings.nr_fno_layers}
            onChange={(e) => handleSettingChange({ nr_fno_layers: parseInt(e.target.value) })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Number of Fourier modes with learnable weights</h5>
          <CreatableSelect<number, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.fno_modes}
            defaultValue={data.fno_modes}
            onChange={(modes) => handleSettingChange({ fno_modes: modes })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Padding size for FFT calculations</h5>
          <input
            type="number"
            className="form-control"
            defaultValue={data.padding ?? defaultFNOArchSettings.padding}
            onChange={(e) => handleSettingChange({ padding: parseInt(e.target.value) })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Padding type for FFT calculations</h5>
          <Select
            className="react-select settings-select"
            options={[
              { value: "constant", label: "constant" },
              { value: "reflect", label: "reflect" },
              { value: "replicate", label: "replicate" },
              { value: "circular", label: "circular" },
            ]}
            defaultValue={
              data.padding_type
                ? { value: data.padding_type, label: data.padding_type }
                : { value: "constant", label: "constant" }
            }
            onChange={(option) => handleSettingChange({ padding_type: option.value })}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#2a2c2f' : '#1f2124',
              }),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Use coordinate meshgrid as additional input feature</h5>
          <input
            type="checkbox"
            checked={data.coord_features ?? defaultFNOArchSettings.coord_features}
            onChange={(e) => handleSettingChange({ coord_features: e.target.checked })}
          />
        </Col>
      </Row>
    </>
  );
}
