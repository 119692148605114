import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { Plugin } from "types";

type PluginsListResponse = {
  count: number;
  next: any;
  previous: any;
  results: Plugin[];
};

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useQueryPlugin(id: string) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Plugin, Error>({
    queryKey: ["plugin", id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/plugins/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching plugins from the database.");
      }
      return res.json();
    },
  });
}

export function useQueryPlugins() {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<PluginsListResponse, Error>({
    queryKey: ["plugins"],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/plugins/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        throw new Error("There was an error fetching plugins from the database.");
      }
      return res.json();
    },
  });
}