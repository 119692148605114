import { defaultCurlSettings } from "modulus-interop/equations/navier-stokes";
import { CurlType } from "modulus-interop/equations/types";
import { ModulusComponentDataCommon } from "modulus-interop/types";
import { Col, Row } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useReactFlow } from "reactflow";

type TSelectOption = {
  value: number | string;
  label: string;
};

export function CurlSettings({ data }: { data: ModulusComponentDataCommon & CurlType }) {
  const { setNodes } = useReactFlow();

  const handleSettingChange = (key: string, value: any) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            [key]: value,
          };
        }

        return nd;
      })
    );
  };

  return (
    <>
      <div className="mt-2">
        <Col>
          <small>Note: make sure to use the same number of vector components!</small>
        </Col>
      </div>

      <div>
        <Col>
          <h5>Curl vector variables' names</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={
              data.curl_name
                ? data.curl_name.map((k) => ({ value: k, label: k.toString() }))
                : defaultCurlSettings.curl_name.map((k) => ({ value: k, label: k.toString() }))
            }
            defaultValue={
              data.curl_name
                ? data.curl_name.map((k) => ({ value: k, label: k.toString() }))
                : defaultCurlSettings.curl_name.map((k) => ({ value: k, label: k.toString() }))
            }
            onChange={(curlName) =>
              handleSettingChange(
                "curl_name",
                curlName.map((v) => v.value)
              )
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </div>

      <div>
        <Col>
          <h5>Curl vector values</h5>
          <CreatableSelect<TSelectOption, true>
            className="react-select settings-select"
            classNamePrefix="react-select"
            isMulti={true}
            options={data.vector ? data.vector.map((k) => ({ value: k, label: k.toString() })) : []}
            defaultValue={data.vector ? data.vector.map((k) => ({ value: k, label: k.toString() })) : []}
            onChange={(curlName) =>
              handleSettingChange(
                "vector",
                curlName.map((v) => v.value)
              )
            }
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
              }),
            }}
          />
        </Col>
      </div>
    </>
  );
}
