// path/filename: Debugger/Debugger.js
import React, { useState } from "react";
import "./styles.css"; // Assuming CSS is in the same directory

interface DebuggerInterface {
  logs: String[];
}

export const Debugger = ({ logs }: DebuggerInterface) => {
  const [showConsole, setShowConsole] = useState(false);

  const toggleConsole = () => {
    setShowConsole(!showConsole);
  };

  const constructLog = (log: any, index: any) => {
    const [date, type, ...rest] = log;
    return (
      <p key={index} className="font-monospace">
        <span style={{ color: "gray" }}>{date}</span>{" "}
        <span style={{ color: log.includes("Info:") ? "cyan" : "pink" }}>{type}</span> {rest}
      </p>
    );
  };

  return (
    <>
      <a role="button" className="" onClick={toggleConsole}>
        Show Logs
      </a>
      {showConsole && (
        <div className="debugger-console">
          <div className="debugger-header">
            <span>Simulation Studio Dev Debugger</span>
            <button className="debugger-close-button" onClick={toggleConsole}>
              Close
            </button>
          </div>
          <div className="debugger-body">{logs.map((log: any, index: any) => constructLog(log, index))}</div>
        </div>
      )}
    </>
  );
};
