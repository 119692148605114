// import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateGeometry, useProcessGeometry } from "mutations/geometry";
import { useParams } from "react-router-dom";
import { getIncomers, Node, useNodeId, useReactFlow } from "reactflow";
import { v4 as uuidv4 } from "uuid";
import { useUndoRedo } from "../hooks";
import { FileType } from "./types";
import { useQuerySimulator } from "queries";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useGeometryContext } from "../nodes/GeometryGroupNode/geometryContext";
import { randInt } from "three/src/math/MathUtils";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useFileUploader(entity_id?: string) {
  const geometryGroupParendId = useNodeId();
  const queryClient = useQueryClient();
  const { setEdges, setNodes, getNodes, getEdges, getNode, deleteElements } = useReactFlow();
  const { takeSnapshot } = useUndoRedo();
  const createGeometry = useCreateGeometry();
  const processGeometry = useProcessGeometry();
  const { getAccessTokenSilently } = useAuth0();
  const { setNumUploaded } = useGeometryContext();

  const { data: simulator } = useQuerySimulator(entity_id);
  
  const { id } = useParams();

  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFiles = async (files: FileType[], callback?: (nodes: Node[]) => void) => {
    // we need the parent node object for getting its position
    const geometryGroupNode = getNode(geometryGroupParendId);
    console.log("geometryGroupNode", geometryGroupNode)

    if (!geometryGroupNode) {
      return;
    }

    const geometryUploadData = await Promise.all(files.map(async file => {

      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/common/generate-r2-presignedurl/?file_name=${file.name}&entity_type=geometry&entity_id=${entity_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        toast.error("There was an error fetching pre-signed url for r2 upload.", {
          theme: "dark"
        });
        throw new Error("There was an error fetching pre-signed url for r2 upload.");
      }
      const data = await res.json();

      await createGeometry.mutateAsync({file: file, presignedUrl: data?.presigned_url}, {
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['simulator', id] })
        },
      })

      setNumUploaded((prev) => prev + 1);
    
      const form = new FormData();
      form.append('label', file.name);
      form.append('public', simulator?.public ? 'true' : 'false');
      form.append('simulator', id);

      return processGeometry.mutateAsync(form, {
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['simulator', id] })
        }
      })
    }))

    const fileNodes = getNodes().filter((node) => node.type === "geometry");
    const useAsThumbnailIndex = randInt(0, geometryUploadData?.length - 1);
    const allFileBasedNodes = geometryUploadData.map((geometryData, index) => {
      takeSnapshot();

      // create a unique id for the geometry node that will be added as a child of the clicked node
      const childGeometryId = uuidv4();

      // create a placeholder node that will be added as a child of the clicked node
      return {
        id: childGeometryId,
        // the geometry is placed at the position of the clicked node
        // the layout function will animate it to its new position
        position: { x: 10, y: 125 + fileNodes.length * 50 + index * 50 },
        type: "geometry",
        parentNode: geometryGroupParendId,
        style: { width: 200 },
        data: {
          label: geometryData.label,
          file_name: geometryData.geometries[0].file_name,
          file_type: geometryData.geometries[0].source_type,
          size: formatBytes(geometryData.geometries[0].size),
          mode: "stl_geometry",
          stl_url: geometryData.geometries[0].stl_url,
          cdxfb_url: geometryData.geometries[0].cdxfb_url,
          fbx_url: geometryData.geometries[0].fbx_url,
          usd_url: geometryData.geometries[0].usd_url,
          airtight: false,
          thumbnail: index === useAsThumbnailIndex ? true : false,
          parameterization: {},
          normalize: true,
          center_x: 0,
          center_y: 0,
          center_z: 0,
          scale: 1.0,
        },
      };
    });

    console.log(allFileBasedNodes)

    setNodes((nodes) =>
      nodes
        .map((node) => {
          if (node.id === geometryGroupParendId) {
            // it's important to create a new object here
            // in order to notify react-flow about the change
            node.data = {
              ...node.data,
              height: 135 + (fileNodes.length + files.length) * 50,
            };
            console.log(node);
          }

          return node;
        })
    );

    setNodes((nds) => nds.concat(allFileBasedNodes));

    if (callback) callback(allFileBasedNodes);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /*
   * Removes the selected file
   */
  const removeFileNode = (id: string, callback?: () => void) => {
    takeSnapshot();
    console.log("geometryGroupParendId", geometryGroupParendId)

    // TODO: remove file from server
    const geometryNode = getNode(id);

    const existingConstraints = getIncomers(geometryNode, getNodes(), getEdges())
      .filter((node) => ["boundary", "interior", "integral"].includes(node.type))
      .map((node) => node.id);

    // remove the geometry node itself
    // deleteElements({ nodes: [{ id }] });
    // setNodes((nodes) => nodes.filter((node) => node.id !== id));

    const fileNodes = () => getNodes().filter((node) => node.type === "geometry");
    console.log(fileNodes)

    // remove connected constraint nodes
    setNodes((nodes) =>
      nodes
        .filter((node) => !existingConstraints.includes(node.id))
        .filter((node) => node.id !== id)
        .map((node, index) => {
          console.log(node)
          console.log(geometryGroupParendId)
          console.log(node.id === geometryGroupParendId)
          // get the parent geometry group node
          if (node.id === geometryGroupParendId) {
            // it's important to create a new object here
            // in order to notify react-flow about the change
            console.log(fileNodes().length)
            node.data = {
              ...node.data,
              height: 120 + (fileNodes().length - 1) * 50, // TODO: fix this
            };
          }

          if (node.type === "geometry" && node.parentNode === geometryGroupParendId) {
            node = {
              ...node,
              position: { x: 10, y: 115 + index * 50 }, // TODO: fix this
            };
          }

          return node;
        })
    );
    // remove connected constraint edges
    setEdges((edges) => edges.filter((edge) => !existingConstraints.includes(edge.target)));

    if (callback) callback();
  };

  return {
    handleAcceptedFiles,
    removeFileNode,
  };
}
