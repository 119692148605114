import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";

// components
import { FormInput } from "components/FormInput";
import { useAuth0 } from "@auth0/auth0-react";
import { cloudTiers } from "cloud-settings";
import { useProjectForm } from "pages/dashboard/projects/hooks";
import { CreateEnvironmentData, Simulator, SimulatorModel, SimulatorModelFromRetrieveModelsForSimulatorsEndpoint } from "types";
import { animals, colors, uniqueNamesGenerator } from "unique-names-generator";
import { VerticalForm } from "components/VerticalForm";
import { toUsd } from "utils/numbers";
import { useTranslation } from "react-i18next";
import { useCreateProject } from "mutations/projects";
import { useQuerySimulators, useSubscription } from "queries";
import { formatDate, sortModelsByUpdatedAt } from "utils";
import { useQueryRetrieveModelsForSimulators } from "queries/simulatorModels";

type SelectedSimulatorModel = {
  simulatorModel: SimulatorModelFromRetrieveModelsForSimulatorsEndpoint;
}

export const CreateProjectModal = () => {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const [startServer, setStartServer] = useState(false);
  const [externalURL, toggleExternalURL] = useState(false);
  const [selectedSimulatorModels, selectSimulatorModels] = useState<SelectedSimulatorModel[]>([]);
  const createMutation = useCreateProject();
  const [isWrongInput, setIsWrongInput] = useState(false);
  const subscription = useSubscription(user.customer_id);

  const { createSchemaResolver, serverType, setServerType } = useProjectForm();

  const {
    isLoading: isSimulatorsLoading,
    isError: isSimulatorsError,
    data: simulators,
    error: simulatorsError,
  } = useQuerySimulators(1, 150);

  const getSimulatorLabel = (simulatorId: string) => {
    return simulators?.results.find((sim) => sim.id === simulatorId)?.label;
  }

  const getIdsForQuery = (simulators: Simulator[]) => {
    const arr: string[] = [];

    simulators && simulators.map((sim) => {
      sim.id && arr.push(sim.id);
      sim.copied_from && arr.push(sim.copied_from);
    })

    return arr;
  }

  const { 
    isLoading: isModelsForSimulatorsLoading,
    isError: isModelsForSimulatorsError,
    data: modelsForSimulators,
    error: modelsForSimulatorsError,
  } = useQueryRetrieveModelsForSimulators(getIdsForQuery(simulators && simulators.results && simulators.results), true);

  useEffect(() => {
    if (isWrongInput) {
      setTimeout(() => {
        setIsWrongInput(false);
      }, 5000)
    }
  }, [isWrongInput])

  /*
   * Handle form submission
   */
  const onSubmit = (formData: any) => {
    setIsWrongInput(false);
    if (externalURL) {
      try {
        const url = new URL(formData["url"]);
        if ((url.protocol !== "http:" && url.protocol !== "https:")) {
          setIsWrongInput(true);
          return;
        }
      } catch (e) {
        setIsWrongInput(true);
        return;
      }
    }
    
    const tier = externalURL ? "tier_1" : serverType;
    const provider = externalURL ? "external" : cloudTiers[tier]?.cloud_provider;

    const state = startServer ? "starting" : "stopped";

    if (!externalURL) {
      setStartServer(false);
    }

    const newProject: CreateEnvironmentData = {
      label: formData["label"],
      desc: formData["desc"],
      cloud_provider: provider,
      server_type: tier,
      mode: "simulation_studio",
      storage_gb: 250, // TODO: don't hardcode (even though we really use 250GB everywhere for now), use from server metadata
      simulators: selectedSimulatorModels.map((simModel) => simModel.simulatorModel.simulator_id),
      stripe_customer_id: user.customer_id,
      num_gpus: externalURL ? formData["num_gpus"] || 1 : cloudTiers[tier]?.gpus,
      version: "1.3.0",
      owner: user.sub,
      state: externalURL ? "running" : state,
    };
    if (externalURL) {
      newProject.url = formData["url"];
    }
    if (process.env.ENVIRONMENT !== "production") {
      console.log("sending", newProject);
    }

    createMutation.mutate(newProject);
  };

  const whenCreateBtnDisabled = () => {
    const hasSimulatorModels = selectedSimulatorModels.length > 0;
    const hasServerType = serverType !== "";
    const isLoading = createMutation.isLoading;

    if (externalURL) {
      return !hasSimulatorModels || isLoading;
    } else {
      return !hasSimulatorModels || !hasServerType || isLoading;
    }
  }

  return (
    <Row>
      <Col>
        <div className="custom-form">
          <VerticalForm<CreateEnvironmentData>
            onSubmit={onSubmit}
            resolver={createSchemaResolver}
            defaultValues={{
              label: uniqueNamesGenerator({
                dictionaries: [colors, animals],
                separator: "-",
                length: 2,
              }),
              simulators: [],
              server_type: "",
            }}>
            <Row>
              <Col>
                <FormInput
                  type="text"
                  name="label"
                  label={t("Enter project label")}
                  placeholder={t("Enter project label")}
                  key="label"
                />

                <FormInput
                  type="textarea"
                  name="desc"
                  label={t("Enter project description")}
                  placeholder={t("Enter project description")}
                  containerClass={"mb-3"}
                  key="desc"
                />

                <Form.Group>
                  <Form.Label>{t("Select simulator models")}</Form.Label>
                  {isModelsForSimulatorsLoading ? (
                    <span>Loading...</span>
                  ) : (
                    <Select
                      className="react-select settings-select mb-3"
                      classNamePrefix="react-select"
                      isMulti={true}
                      options={modelsForSimulators && sortModelsByUpdatedAt(modelsForSimulators).map((model: SimulatorModelFromRetrieveModelsForSimulatorsEndpoint) => {
                        return {
                          value: model.id,
                          label: `${getSimulatorLabel(model.simulator_id)} 
                          - ${formatDate(model.updated_at)} 
                          - ${model.public ? `Public` : `Private`} 
                          ${model.owner === user.sub ? `- My Own` : ``}`
                        }
                      })}
                      onChange={(opts) => selectSimulatorModels(opts.map((sim) => {
                        return {
                          simulatorModel: modelsForSimulators && modelsForSimulators.find((model) => model.id === sim.value),
                        }
                      }))}
                      styles={{
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused ? "#2a2c2f" : "#1f2124",
                        }),
                      }}
                    />
                  )}
                  {isModelsForSimulatorsError && <span>Error loading the simulator models data: {modelsForSimulatorsError}</span>}
                </Form.Group>

                <hr />

                <Form.Check
                  name="external_url"
                  label={t("Connect to Simulation Studio running on your server?")}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "end",
                  }}
                  type="switch"
                  key="external_url"
                  onChange={() => toggleExternalURL(!externalURL)}
                />

                {externalURL ? (
                  <>
                    <Row>
                      <Col>
                        <FormInput
                          type="text"
                          name="url"
                          label={isWrongInput ? `Input correct external url address` : t("URL of your own Simulation Studio server")}
                          placeholder={`Example: http://111.22.333.44:9000`}
                          key="url"
                          className={`border ${isWrongInput ? `border-danger text-danger` : `transparent`}`}
                        />
                      </Col>
                    </Row>
                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        Number of active GPUs on your server:
                      </Form.Label>
                      <Col sm={2}>
                        <FormInput
                          type="number"
                          name="num_gpus"
                          defaultValue={1}
                          containerClass={"mb-3 mt-2"}
                          key="num_gpus"
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <FormInput
                      name="server_type"
                      label="Server instance type"
                      type="select"
                      containerClass="mb-3"
                      key="server_type"
                      onChange={(e) => setServerType(e.target.value)}>
                      <option value="">Select server type...</option>
                      {subscription?.data?.length > 0 ? Object.entries(cloudTiers)
                        .filter(([key]) => !["local", "external"].includes(key))
                        .map(([key, value]) => {
                          if (key === "tier_2") return null;
                          return (
                            <option key={key} value={key} disabled={!value.available}>
                              {value.label} - {toUsd(Number((value.price_per_minute * 60).toFixed(2)))} / hour
                            </option>
                          )
                        }) : (
                          <option value="tier_1" disabled={!cloudTiers["tier_1"].available}>
                            {cloudTiers["tier_1"].label} - {toUsd(Number((cloudTiers["tier_1"].price_per_minute * 60).toFixed(2)))} / hour
                          </option>
                        )}
                    </FormInput>
                  </>
                )}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <div className="d-flex mt-2 gap-1 justify-content-end">
                  <Button className={`${isWrongInput ? `btn-danger` : `blue`} btn-rounded`} type="submit" disabled={whenCreateBtnDisabled()}>
                    Create new Project
                  </Button>
                  {!externalURL && (
                    <Button
                      className="blue btn-rounded"
                      type="submit"
                      disabled={whenCreateBtnDisabled()}
                      onClick={() => setStartServer(true)}>
                      Create new Project and deploy
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </VerticalForm>
        </div>
      </Col>
    </Row>
  );
};
