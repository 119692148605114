import { Card } from "react-bootstrap";

export const QuickStart = () => {
  return (
    <Card>
      <Card.Body>
        <h4 className="header-title">Quick start</h4>

        <p>
          Siml.ai is a software platform for AI Engineering, providing a set of tools to easily create, train and deploy
          powerful high-performance AI-based physics simulators (also called surrogate models). Siml.ai Model Engineer
          automates access to powerful GPU-based cloud servers and Siml.ai Simulation Studio provides easy-to-use AI inferencing and 3D visualization
          pipelines.
        </p>

        <p>
          To learn more about physics-informed neural network and how to combine them with complex physics and custom
          partial differential equations, take a look at documentation:
        </p>

        <p><a href="https://docs.siml.ai/" target="_blank"><i className="mdi mdi-file-document-multiple-outline" /> Go to documentation</a></p>
      </Card.Body>
    </Card>
  );
};
