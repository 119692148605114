import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

export function useProjectForm() {
  const [serverType, setServerType] = useState("");

  /*
   * create form validation schema
   */
  const createSchemaResolver = yupResolver(
    yup.object().shape({
      simulators: yup.array().of(yup.string().required("Please select simulators")),
      label: yup.string().required("Please enter project label"),
    })
  );

  return {
    createSchemaResolver,
    serverType,
    setServerType,
  };
}