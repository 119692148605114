import { useEffect, useRef, useState } from "react";
import { Button, Col, FormCheck, Row } from "react-bootstrap"


const LOG_MESSAGES_LIMIT = 250;

export const SITELogs = ({ environment_id, url }: { environment_id: string, url: string }) => {
	const [messages, setMessages] = useState<string[]>();
	const logContainerRef = useRef<HTMLDivElement>(null);
	const autoscrollRef = useRef<HTMLInputElement>(null);
	
	const handleScrollTo = (where: "oldest" | "newest") => {
		const logContainer = logContainerRef.current;
		if (!logContainer) return;

		switch (where) {
			case "oldest":
				logContainer.scrollTop = 0;
				break;
			case "newest":
				logContainer.scrollTop = logContainer.scrollHeight;
				break;
		}
	}

	useEffect(() => {
		const cleanedUrl = url?.startsWith("https") ? url?.split("https://")[1] : (url?.startsWith("http") ? url?.split("http://")[1] : '');
		const socket = new WebSocket(`wss://${cleanedUrl}/readlogs`);

		// Load cached logs from localStorage
		const cachedLogs = window.localStorage.getItem(`site-logs-cache-${environment_id}`);
		if (cachedLogs) {
			const parsedLogs = JSON.parse(cachedLogs);
			setMessages(parsedLogs);
		}

		// WebSocket event handlers
		socket.addEventListener("open", (event) => {
			socket.send("Hello Server");
		});

		socket.addEventListener("message", (event) => {
			setMessages((prevMessages) => {
				let updatedMessages: string[] = [];
				if (prevMessages?.length > 0) {

					// truncating length at LOG_MESSAGES_LIMIT
					if (prevMessages?.length > LOG_MESSAGES_LIMIT) {
						updatedMessages = [...prevMessages.slice(1), event.data];
					} else {
						updatedMessages = [...prevMessages, event.data];
					}
				} else {
					updatedMessages = [event.data];
				}

				// Update localStorage whenever new message is added
				window.localStorage.setItem(`site-logs-cache-${environment_id}`, JSON.stringify(updatedMessages));
				return updatedMessages;
			});
		});

		return () => {
			console.log("cached, unmounting");
			socket.close(1000, "unmounting");
		};
	}, []);

	useEffect(() => {
		const logContainer = logContainerRef.current;
		const autoscrollCheck = autoscrollRef;
		if (autoscrollCheck) {
			const doAutoscroll = autoscrollCheck.current.checked;

			if (doAutoscroll && logContainer) {
				logContainer.scrollTop = logContainer.scrollHeight;
			}
		}
	}, [messages])

	return (
		<Row style={{ gap: "0.5rem", paddingRight: "0.4rem"}}>
			<Col style={{ minHeight: "60vh", maxHeight: "60vh", backgroundColor: "#1a1e22", overflowY: "scroll" }} ref={logContainerRef} xs={12}>
				<Row style={{ gap: "0.4rem", padding: "0.4rem"}}>
					{ messages && messages?.map((msg, i) => (
						<span key={i} className="text-[#1f2937]" style={{ wordBreak: "break-all"}}>{msg}</span>
					))}
				</Row>
			</Col>
			<Col>
				<Row>
					<Col>
						<FormCheck label="Auto-scroll?" ref={autoscrollRef} defaultChecked/>
					</Col>
					<Col className="d-flex justify-content-end gap-1">
						<Button className="d-flex" onClick={() => handleScrollTo("newest")}>
							<i className="dripicons-arrow-thin-down"></i>
						</Button>
						<Button className="d-flex" onClick={() => handleScrollTo("oldest")}>
							<i className="dripicons-arrow-thin-up"></i>
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	)
} 