import { Card, Col, Row } from "react-bootstrap";
import type { Plugin } from "types";
import { isDevelopment } from "lib/env";
import { useDeletePlugin } from "mutations/plugins";

export type PluginCardProps = {
  plugin: Plugin;
};

export const PluginCard = (props: PluginCardProps) => {
  const plugin = props.plugin;
  const deletePlugin = useDeletePlugin();

  isDevelopment && console.log(plugin)
  
  const pluginType = plugin?.category;

  const handleDelete = async () => {
    try {
      await deletePlugin.mutateAsync(plugin.id);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Card className="d-block card-link simulator-card">
      <Card.Body>
        <div className="top-row">
          <div style={{ width: "100%"}}>
            <Row style={{ width: "100%"}}>
              <Col>
                <h4 className="mt-0 mb-2">
                    <span className="text-title">
                      {plugin?.label}
                    </span>
                </h4>
              </Col>
              <Col style={{display: "flex", width: "100%", justifyContent: "end"}}>
                <button 
                  className="dripicons-cross" 
                  style={{ 
                    fontSize: "24px",
                    color: "white",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete()}
                />
              </Col>
            </Row>
            <span
              style={{
                backgroundColor: "#00b7ff",
                padding: "0.3rem 0.6rem",
                borderRadius: "0.5rem",
              }}
            >{pluginType?.replace("_", " ")}</span>

            <div className="accuracy-container">
              <span>0<span>%</span></span>
              <span>Rating</span>
            </div>
          </div>
        </div>


        <p className="text-muted font-13">
          { plugin && plugin?.description && plugin?.description?.length > 100 ? plugin?.description?.slice(0, 100) + "..." : plugin?.description}
        </p>
      </Card.Body>
    </Card>
  );
};
