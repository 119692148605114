import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "react-bootstrap"

interface Props {
    message: string;
    show: boolean;
    onResponse: (res: boolean) => void;
}
export const ConfirmationBox = ({ message, show, onResponse }: Props ) => {
    return (
        <Modal size="sm" show={show}>
            <ModalHeader>{message}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Button variant="danger" onClick={() => onResponse(true)}>Yes, delete.</Button>
                    </Col>
                    <Col>
                        <Button variant="info" onClick={() => onResponse(false)}>No, abort.</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}