import { ModulusHydraConfig } from "./types";

export const configDefaults: ModulusHydraConfig = {
  defaults: {
    scheduler: "tf_exponential_lr",
    optimizer: "adam",
    loss: "sum",
  },
  scheduler: {
    decay_rate: 0.1,
    decay_steps: 10_000,
  },
  training: {
    rec_results_freq: 5_000,
    rec_constraint_freq: 10_000, // not changeable by user
    max_steps: 1_000_000,
    rec_validation_freq: 5_000,
    rec_inference_freq: 1_000,
    rec_monitor_freq: 1_000,
    save_network_freq: 1_000, // not changeable by user
    print_stats_freq: 1_000, // not changeable by user
    summary_freq: 1_000, // not changeable by user
    amp: false,
  },
  cuda_graphs: true,
  cuda_graph_warmup: 10,
  jit: true
};
