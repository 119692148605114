import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// import { LanguageDropdown } from "components/LanguageDropdown";
// import { NotificationDropdown } from "components/NotificationDropdown";
import { ProfileDropdown } from "components/ProfileDropdown";
// import { SearchDropdown } from "components/SearchDropdown";
// import { TopbarSearch } from "components/TopbarSearch";

import profilePic from "assets/images/users/avatar-1.jpg";
import logoSmDark from "assets/images/logo_sm_dark.png";
import logoSmLight from "assets/images/logo_sm.png";
import logo from "assets/images/logo-light.png";

import { notifications, profileMenus, searchOptions } from "./data";
import { useStripeCustomerData, useSubscription } from "queries";
import "../../components/ProfileDropdown/style.css";

type TopbarProps = {
  isMenuOpened?: boolean; // TODO: is still needed? if not, remove
  hideLogo?: boolean;
  navCssClasses?: string;
  openLeftMenuCallBack?: () => void;
  topbarDark?: boolean;
  toggleSidebar?: () => void;
};

export const Topbar = ({ hideLogo, navCssClasses, openLeftMenuCallBack, topbarDark, toggleSidebar }: TopbarProps) => {
  const [, setIsopen] = useState(false);
  const { user } = useAuth0();
  const navbarCssClasses = navCssClasses || "";
  const containerCssClasses = !hideLogo ? "container-fluid" : "";
  const subscription = useSubscription(user.customer_id);

  const monthlyPlans = subscription?.data?.map((s: any) => s)?.filter((s: any) => s?.plan?.metadata?.title);
  let tempPlan = "";
  if (monthlyPlans?.length > 0) {
    tempPlan = monthlyPlans[monthlyPlans?.length - 1]?.plan?.metadata?.title ?? "";
  }
  const currentPlan = tempPlan;

  const {
    isLoading: isCustomerDataLoading,
    isError: isCustomerDataError,
    data: customerData,
    error: customerDataError,
  } = useStripeCustomerData(user?.customer_id);

  const credits = customerData?.metadata?.available_credit;

  /**
   * Toggle the leftmenu when having mobile screen
   */
  const handleLeftMenuCallBack = () => {
    setIsopen((prevState) => !prevState);
    if (openLeftMenuCallBack) openLeftMenuCallBack();
  };

  // insert mouseflow tracking script
  useEffect(() => {
    // @ts-ignore
    window._mfq = window._mfq || [];
    (function() {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.defer = true;
      mf.src = process.env.REACT_APP_MOUSEFLOW_URL as string;
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  }, [])

  return (
    <React.Fragment>
      <div className={`navbar-custom ${navbarCssClasses} px-0`}>
        <div className={containerCssClasses}>
          {!hideLogo && (
            <Link to="/" className="topnav-logo">
              <span className="topnav-logo-lg">
                <img src={logo} alt="logo" height="16" />
              </span>
              <span className="topnav-logo-sm">
                <img src={topbarDark ? logoSmLight : logoSmDark} alt="logo" height="16" />
              </span>
            </Link>
          )}

          <ul className="list-unstyled topbar-menu float-end mb-0">
            {/* <li className="notification-list topbar-dropdown d-xl-none">
              <SearchDropdown />
            </li>
            <li className="dropdown notification-list topbar-dropdown d-none d-lg-block">
              <LanguageDropdown />
            </li>
            <li className="dropdown notification-list">
              <NotificationDropdown notifications={notifications} />
            </li> */}
            {user && (
              <>
                <Link to="/model-engineer/billing">
                  <li style={{paddingTop: "12px", marginRight: "12px"}}>
                    <span style={{fontSize: "16px", color: "white"}}>
                      <i className="mdi mdi-currency-usd"/> {credits ? credits : 0}
                    </span>
                  </li>
                </Link>
                <Link to="/model-engineer/billing"> 
                  <li className="current-plan-badge">
                    <a>
                      {currentPlan !== "" ? currentPlan : "Free"}
                    </a>
                  </li>
                </Link>
                <li className="dropdown notification-list">
                  <ProfileDropdown profilePic={profilePic} menuItems={profileMenus} username={user.name} />
                </li>
              </>
            )}
          </ul>

          <div className="button-menu-mobile disable-btn" onClick={toggleSidebar}>
            <div className="lines">
              <span />
              <span />
              <span />
            </div>
          </div>
          {/* TODO: re-enable search after search is implemented */}
          {/* <TopbarSearch options={searchOptions} /> */}
        </div>
      </div>
    </React.Fragment>
  );
};
