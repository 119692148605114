import { Card, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useState } from "react";

export const TrainLossAggregatedChart = ({ data, maxValue }: { data: number[][], maxValue: number }) => {
  const [logScale, setLogScale] = useState(false)
  const handleLogScale = () => {
    setLogScale(!logScale)
  }
  const apexChartOpts: ApexOptions = {
    chart: {
      type: "area",
      height: 350,
    },
    colors: ["#2c8ef8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "numeric",
      labels: {
        formatter(value: string) {
          const num = Number(value)
          if (num % 10 !== 0) return
          return Math.abs(num) > 999 ? (Math.sign(num) * ((Math.abs(num) / 1000))).toFixed(0) + 'k' : (Math.sign(num) * Math.abs(num)).toString();
        },
      },
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return value.toExponential(3);
        },
      },
      decimalsInFloat: 14,
      max: maxValue * 1.2,
      min: Math.min(...data.map((d) => d[1])) * 0.1,
      logarithmic: logScale,
      logBase: logScale ? 2 : undefined,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0,
        colorStops: [
          {
            offset: 0,
            color: "#2c8ef8",
            opacity: 1
          },
          {
            offset: 50,
            color: "#2c8ef8",
            opacity: 0.5
          },
          {
            offset: 100,
            color: "#2c8ef8",
            opacity: 0
          }
        ]
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        <div className="chartjs-chart" style={{ height: "350px" }}>
          <Row>
            <Col style={{
              display: "flex",
              gap: "10px",
            }}>
              <p
                style={{
                  fontSize: "0.6rem",
                  color: "#6c757d",
                  fontWeight: "lighter",
                  marginBottom: "0px",
                }}
              >
                Log scale?
              </p>  
              <input type="checkbox" onClick={handleLogScale} style={{
              }}/>
            </Col>
          </Row>
          <Chart
            options={apexChartOpts}
            series={[
              {
                data
              }
            ]}
            type="area"
            className="apex-charts"
            height={350}
          />
        </div>
      </Card.Body>
    </Card>
  );
};
