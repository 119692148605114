import MonacoEditor from "components/CodeEditor/Monaco";
import { Modal, Col, Row, Tabs, Tab, Button } from "react-bootstrap";
import Select from "react-select";
import { getEquationSource, parseEquationArgs, parseEquationName } from "../utils";
import { useQueryPlugins } from "queries";
import { useCreatePlugin } from "mutations";
import { useEffect, useState } from "react";
import { SettingsValues } from "components/VisualUI/types";
import { useAuth0 } from "@auth0/auth0-react";
import { useReactFlow } from "reactflow";
import CustomEquationArgsInputs from "./CustomEquationArgsInputs";
import { toast } from "react-toastify";
import { Sidebar } from "components/VisualUI/Sidebar";

interface PDEOption {
  readonly value: string;
  readonly label: string;
};

interface CustomEquationPopUpProps {
  data: any;
  showEquationView: boolean;
  groupedOptions: any;
  equation: PDEOption;
  setEquation: (equation: PDEOption) => void;
  handleCloseEquationView: () => void;
  handleSetEquation: (equation: PDEOption) => void;
}

type EquationType = {
  [key: string]: {
    value: string;
    label: string;
  }
}

type EquationArg = {
  argName: string;
  argValue: string;
}

const CustomEquationPopUp = (props: CustomEquationPopUpProps) => {
  const { 
    handleCloseEquationView, 
    equation,
    groupedOptions,
    data,
    setEquation,
    handleSetEquation,
    showEquationView } = props;

  const [equationArgs, setEquationArgs] = useState<EquationArg[]>(parseEquationArgs(data?.code ?? getEquationSource("custom")));
  const [isPlugins, pluginsToggle] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState<{ value: string, label: string}>({value: "", label: ""});
  const [wantToSaveAsTemplate, setWantToSaveAsTemplate] = useState(false);
  const [isPluginPublic, setIsPluginPublic] = useState(false);
  const [showSource, setShowSource] = useState(false);

  const { user } = useAuth0();
  const { setNodes } = useReactFlow();
  
  const {
    isLoading: isPluginsLoading,
    isError: isPluginsError,
    data: plugins,
    error: pluginsError,
    refetch: refetchPlugins,
  } = useQueryPlugins();

  const createPluginMutation = useCreatePlugin();

  const handleSaveAsTemplate = (e: any) => {
    e.preventDefault();
    
    createPluginMutation.mutate({
      label: data?.custom_equation_label,
      category: "custom_equation",
      code: data?.code,
      public: isPluginPublic,
      owner: user.sub,
      description: data?.plugin_description ?? null,
      inputs: data?.argTypes ? data?.argTypes : {},
      outputs: data?.sympy_equations ? data?.sympy_equations : {},
    });

    refetchPlugins();
  }


  const handleSettingChange = (props: { [key: string]: any }) => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...data,
            ...props,
          };
        }

        return nd;
      })
    );
  };

  const handleRevertData = () => {
    setNodes((nodes) =>
      nodes.map((nd) => {
        if (nd.id === data.id) {
          nd.data = {
            ...nd.data.data_snapshot,
            data_snapshot: nd.data.data_snapshot
          }
        }
        return nd;
      })
    );
  }

  useEffect(() => {
    setEquationArgs(parseEquationArgs(data?.code))
    handleSettingChange({ custom_equation_label: parseEquationName(data?.code) })
  }, [data?.code])

  useEffect(() => {
    if (data?.mode === "custom" && !selectedPlugin.value) {
      setNodes((nodes) =>
        nodes.map((nd) => {
          if (nd.id === data.id) {
            nd.data = {
              id: nd.id,
              mode: equation.value,
            };
          }
  
          return nd;
        })
      );
    }
  }, [equation])

  useEffect(() => {
    if (selectedPlugin.value) {
      const plugin = plugins?.results?.find((plugin) => plugin.id === selectedPlugin.value);
      setEquation({value: "custom", label: plugin.label})
      setNodes((nodes) =>
        nodes.map((nd) => {
          if (nd.id === data.id) {
           if (plugin?.inputs) {
              nd.data = {
                id: nd.id,
                custom_equation_label: plugin.label,
                mode: "custom",
                plugin_id: selectedPlugin.value,
                code: plugin?.code,
                argTypes: plugin?.inputs,
                // TODO: set sympy_equations if plugin has outputs ?
              };
           } else {
              nd.data = {
                id: nd.id,
                custom_equation_label: plugin.label,
                mode: "custom",
                plugin_id: selectedPlugin.value,
                code: plugin?.code,
                // TODO: set sympy_equations if plugin has outputs ?
              };
           }
          }
          return nd;
        })
      );
    }
  }, [selectedPlugin])

  useEffect(() => {
    if (!data?.argTypes && equationArgs) {
      let defaults: {[key: string]: string} = {};
      for (const arg of equationArgs) {
        defaults[arg.argName] = "number";
      }
      handleSettingChange({ argTypes: defaults})
    }
  }, [equationArgs])

  useEffect(() => {
    setSelectedPlugin({ value: "", label: "Select plugin..." })
  }, [isPlugins])

  const handleShowSource = () => {
    setShowSource(!showSource)
  }

  return (
    <Modal show={showEquationView} size="xl" data-tour="tour_equation_pupup">
          <Modal.Header>
            <Modal.Title>Equation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                {/* *** this could bring sidebar to this popup menu */}
            {/* <Sidebar />  */}
            <p>Select {isPlugins ? `plugin` : `an equation`} from the list below.</p>
              </Col>
              <Col style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "1rem",
              }}>
                <Button className="btn btn-primary" onClick={() => pluginsToggle(!isPlugins)}>
                  {isPlugins ? `Equations` : `Plugins`}
                </Button>
              </Col>
            </Row>
            <Col>
              {!isPlugins && <Select
                className="react-select nodrag"
                options={groupedOptions}
                value={equation.label ? equation : { value: "", label: "Select equation..." }}
                onChange={(e) => {
                  if (e.value === "custom") {
                    handleSettingChange({ mode: "custom"});
                    setEquation(e);
                  } else {
                    handleSetEquation(e);
                  }
                }}
                defaultValue={equation}
                styles={{
                  option: (baseStyles, state) => ({
                    backgroundColor: state.isFocused ? "#474b58" : "#373A45",
                    display: "inline-block",
                    color: "#B4B6C3 !important",
                    padding: "10px 5px",
                    width: "100%",
                  }),
                }}
              />}

              {isPlugins && <Select 
                className="react-select nodrag"
                options={plugins?.results?.filter((plugin) => plugin.category === "custom_equation" && (plugin.owner === user.sub || plugin.public)).map((plugin) => {
                  return {
                    value: plugin?.id,
                    label: plugin?.label + `${plugin?.description ? ` - ${plugin?.description}` : ``}`,
                  }
                })}
                value={selectedPlugin.value? selectedPlugin : { value: "", label: "Select plugin..." }}
                onChange={(e) => setSelectedPlugin(e)}
                defaultValue={selectedPlugin}
                styles={{
                  option: (baseStyles, state) => ({
                    backgroundColor: state.isFocused ? "#474b58" : "#373A45",
                    display: "inline-block",
                    color: "#B4B6C3 !important",
                    padding: "10px 5px",
                    width: "100%",
                  }),
                }}
              />}
            </Col>
            {equation.value !== "custom" && <Col style={{display: "flex", justifyContent: "end", marginTop: "10px"}}>
              <Button onClick={handleShowSource}>Show source code</Button>
            </Col>}
            {equation.value && (equation.value === "custom" ? true : showSource) && <Tabs className={equation.value === "custom" ? `mt-3` : ``}>
              <Tab eventKey={"code"} title={`${equation.value === "custom" ? `code` : `source code`}`} key="code">
                <Row>
                  <Col>
                    {(equation.value === "custom") && 
                      <MonacoEditor 
                        height={"60vh"}
                        language="python"
                        fileName="equation.py"
                        defaultCode={equation.value !== "custom" ? getEquationSource(equation.value) : (data?.code ?? getEquationSource("custom"))}
                        code={equation.value !== "custom" ? getEquationSource(equation.value) : (data?.code ?? getEquationSource("custom"))}
                        setCustomSourceCode={(code: string) => handleSettingChange({ code: code })}
                        disabled={equation.value !== "custom" ? true : false}
                        id="equation-editor"
                      /> }
                    
                    {equation.value !== "custom" && equation.value && showSource &&
                      <MonacoEditor
                        height="60vh"
                        language="python"
                        fileName="equation.py"
                        code={getEquationSource(equation.value)}
                        disabled={true}
                        id="equation-shower"
                      />
                    }
                  </Col>
                </Row>
                {equation.value !== "custom" && equation.value && showSource && <Row>
                  <Col>
                    <Button 
                      className="btn btn-primary mt-1"
                      onClick={() => {
                        navigator.clipboard.writeText(getEquationSource(equation.value))
                        toast.success("Copied source to clipboard!", {
                          theme: "dark",
                          autoClose: 1000,
                        });
                      }}
                    >
                      Copy source
                    </Button>
                  </Col>
                </Row>}
              </Tab>
              { equation.value === "custom" && equationArgs && equationArgs.map((arg) => {
                return (
                  <Tab eventKey={arg.argName} title={arg.argName} key={arg.argName} className="">
                    <Row className="mt-2">
                      <Col style={{
                        maxWidth: "500px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}>
                        <Select 
                          className="react-select nodrag"
                          options={[
                            { value: "number", label: "number" },
                            { value: "string", label: "string" },
                            { value: "boolean", label: "boolean" },
                            { value: "list", label: "list"}
                          ]}
                          defaultValue={{ value: "number", label: "number" }}
                          value={{value: data?.argTypes?.[arg.argName], label: data?.argTypes?.[arg.argName]} }
                          onChange={(e) => {
                            const arr = data?.argTypes;
                            handleSettingChange({ argTypes: {
                              ...arr,
                              [arg.argName]: e.value,
                            }})
                          }}
                          styles={{
                            option: (baseStyles, state) => ({
                              backgroundColor: state.isFocused ? "#474b58" : "#373A45",
                              display: "inline-block",
                              color: "#B4B6C3 !important",
                              padding: "10px 5px",
                              width: "100%",
                            }),
                          }}
                        />
                        { data.argTypes && 
                          <CustomEquationArgsInputs data={data} arg={arg.argName}/>
                        }
                      </Col>
                    </Row>
                  </Tab>
                )
              })}
            </Tabs>}
          </Modal.Body>
          <Modal.Footer>
            { wantToSaveAsTemplate && 
            <>
              <Row>
                <label>Enter the plugin description: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Plugin description"
                  defaultValue={""}
                  onChange={(e) =>
                    handleSettingChange({
                      plugin_description: e.target.value,
                    })
                  }
                />
              </Row>
              <Row>
                <label htmlFor="public-template-toggle">Public?</label>
                <input 
                  type="checkbox" 
                  name="public-template-toggle"
                  checked={isPluginPublic}
                  onChange={(e) => {
                    setIsPluginPublic(e.target.checked)
                  }}
                />
              </Row>
            </>
            }
            {(equation.value === "custom") && <Button className="btn btn-info" onClick={(e) => {
                if (wantToSaveAsTemplate) {
                  handleSaveAsTemplate(e);
                  setWantToSaveAsTemplate(false);
                } else {
                  setWantToSaveAsTemplate(true)
                }
              }}>
              {wantToSaveAsTemplate ? `Save as template` : `Save as template?`}
            </Button>}
            {/* <Button className="btn btn-success" form="pde-node" onClick={handleSave}>
              Save Changes & Close
            </Button> */}
            {equation.value === "custom" && <Button className="btn btn-danger" onClick={() => handleRevertData()}>
              Restore data
            </Button>}
            <Button className="btn btn-primary" onClick={() => handleCloseEquationView()} id="tour_equation_node_popup_close">
              Close
            </Button>
          </Modal.Footer>
      </Modal>
  )
}

export default CustomEquationPopUp;