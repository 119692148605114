import { checkRequiredFields } from "modulus-interop/utils";
import { v4 as uuidv4 } from "uuid";
import { ZeroEquationCreator, ZeroEquationType } from "./types";

function checkRequiredPhysicsFields(requiredFields: string[], settings: ZeroEquationType) {
  if (requiredFields.includes("nu") && !settings.hasOwnProperty("nu")) {
    throw Error("Kinematic viscosity (nu) not set!");
  }

  if (requiredFields.includes("rho") && !settings.hasOwnProperty("rho")) {
    throw Error("Density (rho) not set!");
  }
}

export const defaultZeroEquationSettings: Partial<ZeroEquationType> = {
  rho: { symbol: "rho_ZeroEquation", value: 1.0, parameterized: false },
  nu: 0.1,
  max_distance: 1.0,
  dim: 3,
  time: false,
};

export function ZeroEquation(settings?: ZeroEquationType): ZeroEquationCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("ZeroEquation"),
    slug: Object.freeze("turbulence_zero_eq"),
    settings: {
      ...defaultZeroEquationSettings,
      ...settings,
    },
    set(settings: Partial<ZeroEquationType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredPhysicsFields(["nu", "rho"], this.settings);
      checkRequiredFields(["max_distance", "dim", "time"], this.settings);
    },
    generateCode() {
      this.validate();
      const { nu, rho, dim, time, max_distance } = this.settings;

      return `
    from modulus.eq.pdes.turbulence_zero_eq import ZeroEquation
    ${this.slug} = ZeroEquation(
        nu=${nu},
        max_distance=${max_distance},
        rho=${rho.parameterized ? `Symbol("${rho.symbol}")` : rho.value || rho}},
        dim=${dim},
        time=${time ? "True" : "False"})
    nodes = nodes + ${this.slug}.make_nodes()
`;
    },
  };
}
