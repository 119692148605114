import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dataset } from "types";
import { ToastContentProps, toast } from "react-toastify";
import { FileType } from "components/FileUploader/types";
import { ReactChild, ReactFragment, ReactPortal, ReactNode } from "react";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export function useCreateDataset() {

  return useMutation<Dataset & {name: string}, Error, { file: FileType, presignedUrl: string}>({
    mutationFn: async ({file, presignedUrl}: {file: FileType, presignedUrl: string}) => {
      const res = await fetch(`${presignedUrl}`, {
        method: "PUT",
        body: file,
      });
      if (!res.ok) {
        try {
          const errorRes = await res.json();
          const errorMsg = errorRes?.error || `There was an error while uploading ${file.name} dataset.`
          throw new Error(errorMsg);
        } catch(err) {
          console.error(err);
          throw new Error(`There was an error while uploading ${file.name} dataset.`);
        }
      }
      const newPromise = new Promise<any>((resolve, reject) => { 
        // random delay to simulate network latency, dont do res.json()
        setTimeout(() => {
          resolve({id: "1234", name: file.name, file: file});
        }, 1000);
      });
      return newPromise;
    },
    onError: (error: boolean | ReactChild | ReactFragment | ReactPortal | ((props: ToastContentProps<unknown>) => ReactNode), __: any, ___: any) => {
      toast(error, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (data, __: any, ___: any) => {
      toast.success(`Dataset ${data.name} uploaded successfully.`, {
        theme: "dark",
      });
    }
  });
}

export function useAnnounceDataset() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<Dataset, Error, FormData>({
    mutationFn: async (dataset) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/datasets/?dataset_id=${dataset.get('dataset_id')}&dataset_file_name=${dataset.get('dataset_file_name')}`, {
        method: "POST",
        body: dataset,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error || "There was an error announcing a dataset.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      queryClient.invalidateQueries({ queryKey: ["datasets"] });
      toast.success("Dataset announced successfully.", {
        theme: "dark",
      });
    },
  });
}

export function useUpdateDataset() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Dataset, Error, Dataset>({
    mutationFn: async (dataset: Dataset) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/datasets/${dataset.id}/`, {
        method: "PATCH",
        body: JSON.stringify(dataset),
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error || "There was an error updating a dataset.");
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      queryClient.invalidateQueries({ queryKey: ["datasets"] });
      toast.success("Dataset updated successfully.", {
        theme: "dark",
      });
    },
  });
}

export function useDeleteDataset() {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Dataset, Error, string>({
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/datasets/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error || "There was an error deleting a dataset.");
      }

      // as this function needs to return some json data, but server returns nothing,
      // we need to catch error from res.json() (it will throw one, because it cannot parse nothing) and return empty object
      let resJson;
      try {
        resJson = await res.json();
      } catch (e) {
        resJson = {};
      }

      return resJson;
    },
    onError: (error, __, ___) => {
      toast.error(error.message, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (_, __, ___) => {
      queryClient.invalidateQueries({ queryKey: ["datasets"] });
      toast.success("Dataset deleted successfully.", {
        theme: "dark",
      });
    },
  });
}
