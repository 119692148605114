import { v4 as uuidv4 } from "uuid";
import { NormalDotVecCreator, NormalDotVecType } from "./types";

function checkRequiredPhysicsFields(settings: NormalDotVecType): void {
  if (settings.dim > 3) {
    throw Error("Normal dot velocity supports only up to 3 dimensions");
  }

  if (settings.dim <= 0) {
    throw Error("Specify one of 1, 2 or 3 dimensions for normal dot velocity equations!");
  }
}

export const defaultNormalDotVecSettings: Partial<NormalDotVecType> = {
  dim: 3,
};

export function NormalDotVec(settings?: NormalDotVecType): NormalDotVecCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("NormalDotVec"),
    slug: Object.freeze("normal_dot_vec"),
    settings: {
      ...defaultNormalDotVecSettings,
      ...settings,
    },
    set(settings: Partial<NormalDotVecType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredPhysicsFields(this.settings);
    },
    generateCode() {
      this.validate();
      const { dim } = this.settings;

      if (dim === 1) {
        return `
    from modulus.eq.pdes.basic import NormalDotVec
    nodes = nodes + NormalDotVec(["u"]).make_nodes()`;
      }

      if (dim === 2) {
        return `
    from modulus.eq.pdes.basic import NormalDotVec
    nodes = nodes + NormalDotVec(["u", "v"]).make_nodes()`;
      }

      if (dim === 3) {
        return `
    from modulus.eq.pdes.basic import NormalDotVec
    nodes = nodes + NormalDotVec(["u", "v", "w"]).make_nodes()`;
      }
    },
  };
}
