import { IntegralBoundarySettings } from "../nodes/ConstraintNode/IntegralBoundarySettings";
import { PointwiseBoundarySettings } from "../nodes/ConstraintNode/PointwiseBoundarySettings";
import { PointwiseInteriorSettings } from "../nodes/ConstraintNode/PointwiseInteriorSettings";
import { AFNOSettings } from "../nodes/NeuralNetNode/AFNOSettings";
import { DGMSettings } from "../nodes/NeuralNetNode/DGMSettings";
import { FNOSettings } from "../nodes/NeuralNetNode/FNOSettings";
import { FourierNetArchSettings } from "../nodes/NeuralNetNode/FourierNetSettings";
import { FullyConnectedSettings } from "../nodes/NeuralNetNode/FullyConnectedSettings";
import { SirenSettings } from "../nodes/NeuralNetNode/SirenSettings";
import { AdvectionDiffusionSettings } from "../nodes/PDENode/AdvectionDiffusionSettings";
import { CurlSettings } from "../nodes/PDENode/CurlSettings";
import { DiffusionInterfaceSettings } from "../nodes/PDENode/DiffusionInterfaceSettings";
import { DiffusionSettings } from "../nodes/PDENode/DiffusionSettings";
import { FluxContinuitySettings } from "../nodes/PDENode/FluxContinuitySettings";
import { GradNormalSettings } from "../nodes/PDENode/GradNormalSettings";
import { HelmholtzEquationSettings } from "../nodes/PDENode/HelmholtzSettings";
import { IntegralContinuitySettings } from "../nodes/PDENode/IntegralContinuitySettings";
import { LinearElasticityPlaneStressSettings } from "../nodes/PDENode/LinearElasticityPlaneStressSettings";
import { LinearElasticitySettings } from "../nodes/PDENode/LinearElasticitySettings";
import { MaxwellFreqRealSettings } from "../nodes/PDENode/MaxwellFreqRealSettings";
import { NavierStokesSettings } from "../nodes/PDENode/NavierStokesSettings";
import { NormalDotVecSettings } from "../nodes/PDENode/NormalDotVecSettings";
import { PECSettings } from "../nodes/PDENode/PECSettings";
import { SommerfeldBCSettings } from "../nodes/PDENode/SommerfeldBCSettings";
import { WaveEquationSettings } from "../nodes/PDENode/WaveSettings";
import { ZeroEquationSettings } from "../nodes/PDENode/ZeroEquationSettings";
// import { useVisualUIStore } from "../store";
import { GeometrySettings } from "../nodes/GeometryNode/settings";

export const SettingsLoader = ({node}: any) => {

  if (!node) {
    return <></>;
  }

  switch (node.data.mode) {
    // basic equations
    case "normal_dot_vec":
      return <NormalDotVecSettings data={node.data} />;
    // Navier-Stokes equations and related
    case "navier_stokes":
      return <NavierStokesSettings data={node.data} />;
    case "curl":
      return <CurlSettings data={node.data} />;
    case "grad_normal":
      return <GradNormalSettings data={node.data} />;
    case "flux_continuity":
      return <FluxContinuitySettings data={node.data} />;
    case "compressible_integral_continuity":
      return <IntegralContinuitySettings data={node.data} />;
    // turbulence equations
    case "turbulence_zero_eq":
      return <ZeroEquationSettings data={node.data} />;
    // linear elasticity equations
    case "linear_elasticity":
      return <LinearElasticitySettings data={node.data} />;
    case "linear_elasticity_plane_stress":
      return <LinearElasticityPlaneStressSettings data={node.data} />;
    // diffusion equations
    case "diffusion":
      return <DiffusionSettings data={node.data} />;
    case "diffusion_interface":
      return <DiffusionInterfaceSettings data={node.data} />;
    // advection-diffusion equations
    case "advection_diffusion":
      return <AdvectionDiffusionSettings data={node.data} />;
    // wave equtions
    case "wave_equation":
      return <WaveEquationSettings data={node.data} />;
    case "helmholtz_equation":
      return <HelmholtzEquationSettings data={node.data} />;
    // electromagnetics equations
    case "maxwell_freq_real":
      return <MaxwellFreqRealSettings data={node.data} />;
    case "pec":
      return <PECSettings data={node.data} />;

    case "sommerfeld_bc":
      return <SommerfeldBCSettings data={node.data} />;

    case "stl_geometry":
      return <GeometrySettings data={node} />;

    // architectures
    case "fully_connected":
      return <FullyConnectedSettings data={node.data} />;
    case "dgm":
      return <DGMSettings data={node.data} />;
    case "afno":
      return <AFNOSettings data={node.data} />;
    case "fno":
      return <FNOSettings data={node.data} />;
    case "fourier":
      return <FourierNetArchSettings data={node.data} />;
    case "siren":
      return <SirenSettings data={node.data} />;

    // constraints
    case "pointwise_boundary_constraint":
      return <PointwiseBoundarySettings data={node.data} />;
    case "pointwise_interior_constraint":
      return <PointwiseInteriorSettings data={node.data} />;
    case "integral_boundary_constraint":
      return <IntegralBoundarySettings data={node.data} />;

    default:
      return <></>;
  }
};
