import React from "react";
import { Button } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { AccountLayout } from "layouts/AccountLayout";

const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer footer-alt">
      <p className="text-white">
        {t("Don't have an account?")}{" "}
        <Link to={"/account/register"} className="text-white ms-1">
          <b>{t("Sign Up")}</b>
        </Link>
      </p>
    </footer>
  );
};

export const Login = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, user } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/model-engineer/overview",
      },
    });
  };

  if (user) return <Navigate to={"/model-engineer/overview"} replace={true} />;

  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <h4 className="mt-0">{t("Sign In")}</h4>
      <p className="text-white mb-4">{t("Enter your email address and password to access account.")}</p>

      <div className="d-grid mb-0 text-center">
        <Button variant="outline-secondary" type="submit" onClick={handleLogin}>
          <i className="mdi mdi-login" /> {t("Log In")}
        </Button>
      </div>
    </AccountLayout>
  );
};
