import { compressibleIntegralContinuity, curl, fluxContinuity, gradNormal, navierStrokes } from "equations/navierStrokes";
import { 
  diffusionInterface, 
  normalDotVec,
  diffusion,
  advectionDiffusion,
  linearElasticity,
  linearElasticityPlaneStress,
  helmholtz,
  wave,
  maxwellFreqReal,
  sommerfeldBC,
  pec
} from "equations";
import { templateEquation } from "equations/templateEquation";
import { turbulenceZeroEq } from "equations";

const getEquationSource = (equation: string) => {
  switch (equation) {
    case "curl":
      return curl;
    case "grad_normal":
      return gradNormal;
    case "normal_dot_vec":
      return normalDotVec;
    case "navier_stokes":
      return navierStrokes;
    case "compressible_integral_continuity":
      return compressibleIntegralContinuity;
    case "flux_continuity":
      return fluxContinuity;
    case "turbulence_zero_eq":
      return turbulenceZeroEq;
    case "diffusion":
      return diffusion;
    case "diffusion_interface":
      return diffusionInterface;
    case "advection_diffusion":
      return advectionDiffusion;
    case "linear_elasticity":
      return linearElasticity;
    case "linear_elasticity_plane_stress":
      return linearElasticityPlaneStress;
    case "wave_equation":
      return wave;
    case "helmholtz_equation":
      return helmholtz;
    case "maxwell_freq_real":
      return maxwellFreqReal;
    case "sommerfeld_bc":
      return sommerfeldBC;
    case "pec":
      return pec;
    case "custom":
      return templateEquation;
    default:
      return "";
  }
}

export { getEquationSource };