export const showPopup = (status: string) => {
  switch (status) {
    case "completed":
      return false;
    case "not_interested":
      return false;
    case "not_started":
      return true;
    default:
      return true;
  }
}

export const SIMULATOR_TUTORIAL_KEY = "simulator_tutorial_data_"

export const getByKeyFromLocalStorage = (key: string) => {
  if (!key) return "";

  let value = "";
  try {
    value = localStorage.getItem(key);
  } catch (e) {
    console.error(e);
  }

  return value;
}

export const saveByKeyAndDataToLocalStorage = (key: string, data: string | number | boolean | JSON) => {
  try {
    if (typeof data === "string") {
      localStorage.setItem(key, data);
    } else {
      localStorage.setItem(key, JSON.stringify(data))
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const getSimulatoTutorialStatus = (version = 1) => {
  let status = "not_started";
  const retrievedStatus = getByKeyFromLocalStorage(SIMULATOR_TUTORIAL_KEY + version);
  if (!retrievedStatus) {
    return status;
  }
  return getByKeyFromLocalStorage(SIMULATOR_TUTORIAL_KEY + version);
}

export const updateTutorialLocalStorage = (
  { newStatus, version }: { newStatus: string, version: number }
) => {
  if (!version) {
    version = 1;
  }
  saveByKeyAndDataToLocalStorage(SIMULATOR_TUTORIAL_KEY + version, newStatus.toString());
}