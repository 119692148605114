import React from "react";
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";

import { Root } from "./Root";
import { PrivateRoute } from "./PrivateRoute";
import { DefaultLayout } from "layouts/DefaultLayout";
import { VerticalLayout } from "layouts/VerticalLayout";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate";

// auth
import { Login } from "pages/account/Login";
import { Logout } from "pages/account/Logout";
import { Signup } from "pages/account/Signup";

// Overview
import { OverviewPage } from "pages/dashboard/overview/OverviewPage";

// datasets
import { DatasetsPage } from "pages/dashboard/datasets/DatasetsPage";
import { SingleDatasetPage } from "pages/dashboard/datasets/SingleDatasetPage";

// simulators
import { SimulatorsListPage } from "pages/dashboard/simulators/SimulatorsListPage";
import { CreateSimulatorPage } from "pages/dashboard/simulators/CreateSimulatorPage";
import { SimulatorDetailPage } from "pages/dashboard/simulators/SimulatorDetailPage";
import { ProjectsPage } from "pages/dashboard/projects/ProjectsPage"
import { SingleProjectPage } from "pages/dashboard/projects/SingleProjectPage"
// import { CreateProjectPage } from "pages/dashboard/projects/CreateProjectPage"

// Simulator Training Environment - GPU cloud access
import { EnvironmentsListPage } from "pages/dashboard/environments/EnvironmentsListPage";
import { CreateEnvironmentPage } from "pages/dashboard/environments/CreateEnvironmentPage";
import { EnvironmentDetailPage } from "pages/dashboard/environments/EnvironmentDetailPage";

// other pages
import { ProfilePage } from "pages/profile/ProfilePage";
import { BillingPage } from "pages/profile/BillingPage";
import { NotFoundPageFull } from "pages/error/NotFoundPageFull";
import { ServerErrorPage } from "pages/error/ServerErrorPage";
import { PluginsListPage } from "pages/dashboard/plugins/PluginsListPage";

const AllRoutes: React.FC = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Auth0ProviderWithNavigate />}>
        <Route index={true} element={<Root />} />
        <Route path="account" element={<DefaultLayout />}>
          <Route index={true} element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Signup />} />
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route path="model-engineer" element={<PrivateRoute roles={""} component={VerticalLayout} />}>
          <Route index={true} element={<OverviewPage />} />
          <Route path="overview" element={<OverviewPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="billing" element={<BillingPage />} />
          <Route path="datasets">
            <Route index={true} element={<DatasetsPage />} />
            <Route path=":id" element={<SingleDatasetPage />} />
          </Route>
          <Route path="simulators">
            <Route index={true} element={<SimulatorsListPage />} />
            <Route path="new" element={<CreateSimulatorPage />} />
            <Route path=":id" element={<SimulatorDetailPage />} />
            <Route path=":id/:isGuide" element={<SimulatorDetailPage />} />
          </Route>
          <Route path="environments">
            <Route index={true} element={<EnvironmentsListPage />} />
            <Route path="new" element={<CreateEnvironmentPage />} />
            <Route path=":id" element={<EnvironmentDetailPage />} />
          </Route>
          <Route path="projects">
            <Route index={true} element={<ProjectsPage />} />
            <Route path=":id" element={<SingleProjectPage />} />
            {/* <Route path="new" element={<CreateProjectPage />} /> */}
          </Route>
          <Route path="plugins" element={<PluginsListPage />} />
        </Route>
        {/* <Route path="/callback" element={loading()} /> */}
        <Route path="error-404" element={<NotFoundPageFull />} />
        <Route path="error-500" element={<ServerErrorPage />} />
        <Route path="*" element={<NotFoundPageFull />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export { AllRoutes };
