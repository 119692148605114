import { useAuth0 } from "@auth0/auth0-react";
import { useStripeCustomerData } from "queries";
import { useEffect, useState } from "react";
import { Environment, EnvironmentHistorySnapshot } from "types";
import { calculateRunningTimeFormated } from "utils/calculateRunningTime";
import { toUsd } from "utils/numbers";

export function RunningCostCounter({ environment }: { environment: Environment }) {
  const [secondsRunning, setSecondsRunning] = useState(0);
  const { user } = useAuth0();

  const {
    isLoading: isStripeCustomerDataLoading,
    isError: isStripeCustomerDataError,
    data: stripeCustomerData,
    error: stripeCustomerDataError,
    refetch: refetchStripeCustomerData,
  } = useStripeCustomerData(user.customer_id);

  useEffect(() => {
    if (environment?.aws_instance_id || environment?.paperspace_id) {
      const interval = setInterval(() => {
        const environmentHistory = environment.history;
        // Get the start of the deployment
        const startOfDeployment = environmentHistory.find((h) => h.state_changed_to === "running")?.created_at;
        // Capture the time from the start of the deployment until now
        const timeFromDeployment = Math.abs(new Date().getTime() - new Date(startOfDeployment!).getTime()) / 1000;
        // Get the running time of the environment - time elapsed when the environment was in "running" state:
        // a) if the environment was already stopped at least once, filter out the time when it was stopped and use
        // `time_elapsed_since_last_state_change` to calculate the running time of the "running" states, accumulate it
        // b) if the environment was never stopped, use the time from the start of the deployment until now
        const stoppedCheckpoints = environmentHistory.filter((h) => h.state_changed_to === "stopped");

        let runningTime =
          stoppedCheckpoints.length > 0
            ? stoppedCheckpoints.reduce((acc, h) => {
              return acc + h.time_elapsed_since_last_state_change;
            }, 0)
            : timeFromDeployment;

          if (environment?.state === "running") {
            const currentTime = new Date().getTime();
            const startedCheckpoints = environmentHistory
              .filter((h) => h.state_changed_to === "started")
              .sort(
                (a: EnvironmentHistorySnapshot, b: EnvironmentHistorySnapshot) =>
                  new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
              );
            const latestStartedCheckpointTimestamp = startedCheckpoints?.[0]?.timestamp;
            const latestStartedCheckpointTime = latestStartedCheckpointTimestamp
              ? new Date(latestStartedCheckpointTimestamp!).getTime()
              : currentTime;
            // Accumulate the time elapsed since the last "started" checkpoint
            runningTime += Math.abs(currentTime - latestStartedCheckpointTime) / 1000;
          }

        setSecondsRunning(runningTime);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [environment]);

  const currentCost = (secondsRunning / 60) * environment!.price_per_minute;
  
  // refetch every minute
  useEffect(() => {
    const interval = setInterval(() => {
      refetchStripeCustomerData();
    }, 60000);

    return () => clearInterval(interval);
  }, [refetchStripeCustomerData]);

  // scenario when user has no paid plan (monthly) and no credits
  if (!stripeCustomerData?.metadata?.available_credit && !environment.subscription_item_id) {
    return (
      <div>
        <strong className="text-danger">You don't have any available credits.</strong>
      </div>
    );
  }

  // scenario when deployment hasn't started yet
  // we should show the credits available however
  if (!environment.subscription_item_id) {
    return (
      <div className="credits">
        <strong>Your current credits: </strong>
        <span className={`fw-bold ${isStripeCustomerDataError ? "text-danger" : "text-success"}`}>{isStripeCustomerDataError ?
          `NaN` : (isStripeCustomerDataLoading ?
            `...` : toUsd(Number(stripeCustomerData?.metadata?.available_credit)))
        }
        </span>
      </div>
    )
  }

  // if it's not running yet, show only available credits
  if (!secondsRunning) {
      return environment.subscription_item_id && environment.subscription_item_id === "credit" && (<div className="credits">
        <strong>Your current credits: </strong>
        <span className={`fw-bold ${isStripeCustomerDataError ? "text-danger" : "text-success"}`}>{isStripeCustomerDataError ?
          `NaN` : (isStripeCustomerDataLoading ?
            `...` : toUsd(Number(stripeCustomerData?.metadata?.available_credit)))
        }
        </span>
      </div>)
  }

  return (
    <>
      <div>
        <strong>Running duration: </strong>
        <span>{calculateRunningTimeFormated(secondsRunning)}</span>
      </div>
      {environment.subscription_item_id && environment.subscription_item_id === "credit" && (<div className="credits">
        <strong>Your current credits: </strong>
        <span className={`fw-bold ${isStripeCustomerDataError ? "text-danger" : "text-success"}`}>{isStripeCustomerDataError ?
          `NaN` : (isStripeCustomerDataLoading ?
            `...` : toUsd(Number(stripeCustomerData?.metadata?.available_credit)))
        }
        </span>
      </div>)}
      <div>
        <strong>Estimated cost: </strong>
        <span className="fw-bold">{toUsd(currentCost)}</span>
      </div>
    </>
  );
}
